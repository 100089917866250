import { colors } from 'Common';
import { Col, Grid, If } from 'components';
import CardModulo from 'components/cards/CardModulo';

function Modulos({ value, onChange, errors, podeEditarEmpresa }) {
	function onSelectModulo(modulo) {
		const moduloJaSelecionado = value.includes(modulo);
		if (moduloJaSelecionado) {
			onChange(value.filter((item) => item !== modulo).sort());
		} else {
			onChange([...value, modulo].sort());
		}
	}

	return (
		<Grid>
			<Col sm="12" md="6" lg="6" xl="6">
				<CardModulo
					value="COMPRAS"
					selected={value.includes('COMPRAS')}
					onSelect={onSelectModulo}
					title="Gestão de compras"
					subtitle="Processo de compras e importação de documentos fiscais"
					disabled={!podeEditarEmpresa}
				/>
			</Col>
			<Col sm="12" md="6" lg="6" xl="6">
				<CardModulo
					value="ESTOQUE"
					selected={value.includes('ESTOQUE')}
					onSelect={onSelectModulo}
					title="Gestão e controle de estoque"
					subtitle="Cadastro, movimentações e saldo de produtos"
					disabled={!podeEditarEmpresa}
				/>
			</Col>
			<Col sm="12" md="6" lg="6" xl="6">
				<CardModulo
					value="VENDAS"
					selected={value.includes('VENDAS')}
					onSelect={onSelectModulo}
					title="Gestão de vendas"
					subtitle="Processo de venda e emissão de documentos fiscais"
					disabled={!podeEditarEmpresa}
				/>
			</Col>
			<Col sm="12" md="6" lg="6" xl="6">
				<CardModulo
					value="SERVICOS"
					selected={value.includes('SERVICOS')}
					onSelect={onSelectModulo}
					title="Gestão de serviços"
					subtitle="Gerencie e integre os processos relacionados à prestação de serviços da empresa"
					disabled={!podeEditarEmpresa}
				/>
			</Col>
			<Col sm="12" md="6" lg="6" xl="6">
				<CardModulo
					value="FINANCAS"
					selected={value.includes('FINANCAS')}
					onSelect={onSelectModulo}
					title="Gestão de finanças"
					subtitle="Controle de finanças, gestão de títulos e movimento bancário"
					disabled={!podeEditarEmpresa}
				/>
			</Col>
			<Col sm="12" md="6" lg="6" xl="6">
				<CardModulo
					value="CONTROLADORIA"
					selected={value.includes('CONTROLADORIA')}
					onSelect={onSelectModulo}
					title="Controladoria"
					subtitle="Gere os arquivos de escrituração digital como o EFD ICMS/IPI, EFD PIS/COFINS, entre outros. Acompanhe os indicativos da sua empresa, além do controle de logs."
					disabled={!podeEditarEmpresa}
				/>
			</Col>
			<Col sm="12" md="6" lg="6" xl="6">
				<CardModulo
					value="TRANSPORTE"
					selected={value.includes('TRANSPORTE')}
					onSelect={onSelectModulo}
					title="Transporte"
					subtitle="Gerencie e integre os processos relacionados ao transporte da empresa."
					disabled={!podeEditarEmpresa}
				/>
			</Col>
			<Col
				sm="12"
				md="6"
				lg="6"
				xl="6"
				title={!podeEditarEmpresa ? 'Você não possui permissão para editar' : 'Assinatura'}
			>
				<CardModulo
					value="DOCUMENTOS"
					selected={value.includes('DOCUMENTOS')}
					onSelect={onSelectModulo}
					title="Assinatura e gestão de documentos"
					subtitle="Documentos para assinatura eletrônica ou digital e gestão de documentos"
					disabled={!podeEditarEmpresa}
				/>
			</Col>

			<If test={errors}>
				<Col sm="12" style={{ padding: '0px 10px' }}>
					<span style={{ color: colors.vermelho, fontSize: '12px' }}>{errors}</span>
				</Col>
			</If>
		</Grid>
	);
}

export default Modulos;
