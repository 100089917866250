import { colors } from 'Common';
import propTypes from 'prop-types';
import {
	BsCheckCircle,
	BsFillExclamationCircleFill,
	BsFillExclamationDiamondFill,
	BsInfoCircleFill,
} from 'react-icons/bs';

export default function Message({ hidden, colStyle, severity, style, className, text }) {
	function getDivStyle() {
		if (hidden) {
			return {
				display: 'none',
			};
		} else {
			return {
				padding: '0.5em',
				width: '100%',
				...colStyle,
			};
		}
	}

	function getStyle() {
		const estiloPadrao = {
			width: '100%',
			height: 'auto',
			padding: '1rem 1.5rem',
			...style,
		};

		switch (severity) {
			case 'error':
				return {
					...estiloPadrao,
					color: colors.vermelhoDark,
					background: colors.vermelhoLight,
					borderLeft: `6px solid ${colors.vermelho}`,
				};
			case 'warn':
				return {
					...estiloPadrao,
					color: colors.amarelo,
					background: colors.amareloLight,
					borderLeft: `6px solid ${colors.amarelo}`,
				};
			case 'success':
				return {
					...estiloPadrao,
					color: colors.verde,
					background: colors.verdeLight,
					borderLeft: `6px solid ${colors.verde}`,
				};
			default:
				return {
					...estiloPadrao,
					color: colors.azul,
					background: colors.azulLight,
					borderLeft: `6px solid ${colors.azul}`,
				};
		}
	}

	function getIcon() {
		switch (severity) {
			case 'error':
				return <BsFillExclamationCircleFill size={20} color={colors.vermelhoDark} />; //'fa fa-exclamation-circle'
			case 'warn':
				return <BsFillExclamationDiamondFill size={20} color={colors.amarelo} />; //'fa fa-exclamation-triangle'
			case 'success':
				return <BsCheckCircle size={20} color={colors.verde} />; //'fa fa-check-circle'
			default:
				return <BsInfoCircleFill size={20} color={colors.azul} />; //'fa fa-info-circle'
		}
	}

	return (
		<div className={className} style={getDivStyle()}>
			<div style={getStyle()}>
				<div style={{ display: 'flex' }}>
					<span style={{ marginRight: '10px', alignItems: 'center', display: 'flex' }}>{getIcon()}</span>
					<span style={{ flex: '1' }}>{text}</span>
				</div>
			</div>
		</div>
	);
}

Message.propTypes = {
	/** Identificador do componente */
	id: propTypes.string,
	/** Derfine o componente como invisível */
	hidden: propTypes.bool,
	/** Nome da classe do componente */
	className: propTypes.string,
	/** Estilo do tipo do componente */
	severity: propTypes.oneOf(['info', 'success', 'warn', 'error']),
	/** Texto do componente */
	text: propTypes.any,
	/** Usado para ignorar o padding externo do componente */
	ignorePadding: propTypes.bool,
};
