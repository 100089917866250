import { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Menu } from 'primereact/menu';
import { Status, infoStatusNfe } from '../../Util/constantes';
import NotasHistorico from '../NotasHistorico';
import { Button, NenhumRegistroEncontrado, If, Paginacao } from '../../../../../../components';
import { recursos, permissoes, formatarMonetario, usuarioPossuiPermissao, colors } from '../../../../../../Common';
import { imprimirEspelhoDANFE, imprimirXMLRejeicaoNfe } from '../../Requests';

const styleButton = {
	borderRadius: '50%',
	padding: '5px',
	width: '30px',
	height: '30px',
	margin: '2px',
};

function TabelaNFe(props) {
  const {
    registros,
    sortField,
    sortOrder,
    totalRecords,
    rows,
    page,
    onPageChange,
    isMobile,
    setSortField,
    setSortOrder,
    editarNotaFiscal,
    removerNotaFiscal,
    imprimirDANFE,
    baixarXMLNotaFiscal,
    imprimirCorrecao,
    baixarXMLCorrecao,
  } = props;
  const [modalHistoricoVisible, setModalHistoricoVisible] = useState(false);
  const [registroSelecionado, setRegistroSelecionado] = useState(null);
  const [podeEditar, setPodeEditar] = useState(usuarioPossuiPermissao(recursos.VENDAS_NOTAS, permissoes.EDITAR));
  const [podeExcluir, setPodeExcluir] = useState(usuarioPossuiPermissao(recursos.VENDAS_NOTAS, permissoes.EXCLUIR));
  const [podeVisualizarPedido, setPodeVisualizarPedido] = useState(
    usuarioPossuiPermissao(recursos.VENDAS_PEDIDOS, permissoes.VISUALIZAR)
  );
  const itensMenu = montarItensMenu();
  const menu = useRef(null);

	function atualizarOrdenacao(e) {
		setSortField(e.sortField);
		setSortOrder(e.sortOrder);
	}

	function renderEmissaoField(elemento) {
		if (elemento.criadoEm) return <span>{format(parseISO(elemento.criadoEm, new Date()), 'dd/MM/yyyy')}</span>;
		return '';
	}

	function renderNumeroField(elemento) {
		return (
			<div title={elemento.serie && elemento.nro ? `Série ${elemento.serie} / Número ${elemento.nro}` : null}>
				<span>{elemento.serie || '-'}</span>/
				<span
					title={!elemento.nro ? 'O número da nota é gerados automaticamente pelo sistema' : 'Número da nota fiscal'}
					style={{ fontWeight: 'bolder' }}
				>
					{elemento.nro || '-'}
				</span>
			</div>
		);
	}

	function renderClienteField(elemento) {
		return (
			<span
				title={elemento.destNome}
				style={{
					maxHeight: '60px',
					overflow: 'hidden',
					display: 'flex',
					wordBreak: 'break-word',
					maxWidth: props.isMobile ? '70%' : '100%',
					textAlign: props.isMobile ? 'end' : 'start',
				}}
			>
				{elemento.destNome}
			</span>
		);
	}

	function renderStatusField(elemento) {
		if (!elemento.status) {
			return null;
		}

		return (
			<span
				title={infoStatusNfe[elemento.status].description}
				style={{
					backgroundColor: infoStatusNfe[elemento.status].lightColor,
					color: infoStatusNfe[elemento.status].strongColor,
					fontWeight: 'bold',
					fontSize: '13px',
					borderRadius: '20px',
					display: 'flex',
					height: '1.5rem',
					width: '7rem',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<span>{infoStatusNfe[elemento.status].name}</span>
			</span>
		);
	}

	function renderValorField(elemento) {
		const valor = formatarMonetario(elemento.valor);
		return <span title={valor}>{valor}</span>;
	}

	function renderVenda(elemento) {
		if (elemento.vendaNumero) {
			return (
				<span
					style={{ fontSize: '12px' }}
					className="link_to"
					title={elemento.vendaNumero && 'Nota fiscal gerada através da venda número ' + elemento.vendaNumero}
				>
					<Link to={`/pedido_venda/cadastro/${elemento.vendaId}`}>
						{elemento.vendaNumero && `Pedido ${elemento.vendaNumero}`}
					</Link>
				</span>
			);
		}
		return <span>—</span>;
	}

	function renderAcoesField(elemento) {
		let titleEditar = 'Editar';
		let titleExcluir = 'Excluir';
		let titleBtnOpcoes = 'Opções';
		let disableBtnEditar = false;
		let disableBtnExcluir = false;
		let disableBtnOpcoes = false;

		if (!podeExcluir) {
			titleExcluir = 'Você não possui permissão para executar essa ação';
		}

		if (
			elemento.status === Status.CANCELADA ||
			elemento.status === Status.TRANSMITIDA ||
			elemento.status === Status.CORRIGIDA ||
			elemento.status === Status.DENEGADA
		) {
			titleExcluir = 'Você só pode excluir uma nota fiscal caso ela ainda não foi transmitida';
			disableBtnExcluir = true;
		}

		if (elemento.status === Status.AGUARDANDO_AUTORIZACAO) {
			titleBtnOpcoes = 'Aguarde até que a nota seja transmitida';
			titleExcluir = 'Aguarde até que a nota seja transmitida';
			disableBtnExcluir = true;
			disableBtnOpcoes = false;
		}

		if (elemento.status === Status.REJEITADA) {
			titleBtnOpcoes = 'Nota fiscal rejeitada';
			disableBtnOpcoes = false;
		}
		if (elemento.status === Status.NAO_ENVIADA) {
			titleBtnOpcoes = 'Nota fiscal não transmitida';
			disableBtnOpcoes = false;
		}

		if (elemento.status === Status.DENEGADA) {
			titleBtnOpcoes = 'Nota fiscal denegada';
			disableBtnOpcoes = false;
		}

		if (!podeEditar) {
			disableBtnEditar = true;
		}

		if (!podeExcluir) {
			disableBtnExcluir = true;
		}

		return (
			<div>
				<Button
					style={styleButton}
					className="p-button p-button-primary"
					icon="fa fa-pencil"
					title={titleEditar}
					disabled={disableBtnEditar}
					onClick={() => editarNotaFiscal(elemento)}
				/>
				<Button
					style={styleButton}
					className="p-button p-button-danger"
					icon="fa fa-trash"
					title={titleExcluir}
					disabled={disableBtnExcluir}
					onClick={() => removerNotaFiscal(elemento)}
				/>
				<Button
					className="p-button-secondary"
					icon="fa fa-ellipsis-v"
					style={styleButton}
					title={titleBtnOpcoes}
					disabled={disableBtnOpcoes}
					aria-controls="popup_menu"
					aria-haspopup={true}
					onClick={(event) => {
						setRegistroSelecionado(elemento);
						menu.current.toggle(event);
					}}
				/>
			</div>
		);
	}

	function montarItensMenu() {
		let itens = [];

		if (registroSelecionado) {
			itens.push({
				label: 'Histórico',
				icon: 'fa fa-history',
				command: () => visualizarHistorico(),
			});

			itens.push({
				separator: true,
				visible: true,
			});

			if (
				registroSelecionado.status === Status.TRANSMITIDA ||
				registroSelecionado.status === Status.CANCELADA ||
				registroSelecionado.status === Status.CORRIGIDA
			) {
				itens.push({
					label: 'Imprimir DANF-e',
					icon: 'fa fa-print',
					command: () => imprimirDANFE(registroSelecionado),
				});
				itens.push({
					label: 'Baixar XML da NF-e',
					icon: 'fa fa-download',
					command: () => baixarXMLNotaFiscal(registroSelecionado),
				});
				if (registroSelecionado.status === Status.CORRIGIDA) {
					itens.push({
						label: 'Imprimir correção',
						icon: 'fa fa-print',
						command: () => imprimirCorrecao(registroSelecionado),
					});
					itens.push({
						label: 'Baixar XML correção',
						icon: 'fa fa-download',
						command: () => baixarXMLCorrecao(registroSelecionado),
					});
				}
			} else if (registroSelecionado.status == Status.NAO_ENVIADA || registroSelecionado.status == Status.REJEITADA) {
				itens.push({
					label: 'Visualizar espelho',
					icon: 'fa fa-file-text-o',
					command: () => visualizarEspelho(registroSelecionado),
				});
				if (registroSelecionado.status == Status.REJEITADA) {
					itens.push({
						label: 'Baixar XML rejeição',
						icon: 'fa fa-download',
						command: () => baixarXmlRejeicao(registroSelecionado),
					});
				}
			}
		}
		return itens;
	}

	function visualizarHistorico() {
		setModalHistoricoVisible(true);
	}

	function visualizarEspelho(registroSelecionado) {
		imprimirEspelhoDANFE(registroSelecionado.id, ({ data: file }) => {
			let arquivo = new Blob([file], { type: 'application/pdf' });
			let arquivoURL = URL.createObjectURL(arquivo);
			let danfe = window.open(arquivoURL);
			danfe.onload = function () {
				setTimeout(() => {
					danfe.document.title = 'Espelho NF-e';
				}, 250);
			};
		});
	}

	async function baixarXmlRejeicao(registroSelecionado) {
		await imprimirXMLRejeicaoNfe(
			registroSelecionado.id,
			({ data: xml }) => {
				let blob = new Blob([xml], { type: 'text/plain;charset=utf-8' });
				saveAs(blob, `${registroSelecionado.chave ?? registroSelecionado.id}.xml`);
			},
			({ response }) => {
				notify(response.data.details[0], ToastTypes.ERROR, 8);
			}
		);
	}

	return (
		<div style={{ width: !isMobile ? '100%' : '99%' }}>
			<Menu model={itensMenu} popup={true} style={{ width: '200px' }} ref={(elemento) => (menu.current = elemento)} />
			<DataTable
				className="table"
				value={registros}
				responsive
				sortField={sortField}
				sortOrder={sortOrder}
				onSort={atualizarOrdenacao}
				emptyMessage={<NenhumRegistroEncontrado message="Nenhuma nota fiscal encontrada" />}
			>
				<Column
					className="step-listagem-order"
					header="Série/N°"
					field="nro"
					sortable={true}
					body={renderNumeroField}
					style={{ color: colors.cinzaDark, width: '100px' }}
				/>
				<Column
					header="Data"
					field="criadoEm"
					sortable={true}
					body={renderEmissaoField}
					style={{ color: colors.cinzaDark, width: '100px' }}
				/>
				<Column
					header="Cliente"
					field="destNome"
					sortable={true}
					body={renderClienteField}
					style={{ color: colors.cinzaDark }}
				/>
				<Column
					header="Pedido de venda"
					field="pedido"
					sortable={false}
					body={renderVenda}
					style={{ color: colors.cinzaDark, width: '200px' }}
				/>
				<Column
					header="Status"
					field="status"
					sortable={true}
					body={renderStatusField}
					style={{ color: colors.cinzaDark, width: '130px' }}
				/>
				<Column
					header="Valor"
					field="valor"
					sortable={true}
					body={renderValorField}
					style={{
						color: colors.cinzaDark,
						width: '116px',
						textOverflow: 'ellipsis',
						overflow: 'hidden',
						fontWeight: 'bold',
					}}
				/>
				<Column className="step-listagem-acoes" header="Ações" body={renderAcoesField} style={{ width: '130px' }} />
			</DataTable>
			<Paginacao totalElements={totalRecords} rows={rows} page={page} onPageChange={onPageChange} />
			<If test={modalHistoricoVisible}>
				<NotasHistorico
					idNota={registroSelecionado && registroSelecionado.id}
					visible={modalHistoricoVisible}
					onHide={() => setModalHistoricoVisible(false)}
				/>
			</If>
		</div>
	);
}

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
});

export default connect(mapStateToProps)(TabelaNFe);
