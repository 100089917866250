import { useFormikContext } from 'formik';

import {
	ButtonCancelar,
	ButtonExcluir,
	ButtonNovo,
	ButtonSalvar,
	estadosBotaoCancelar,
	estadosBotaoNovo,
	estadosBotaoSalvar,
} from 'components';

import { useContextCondicaoPagamento } from 'views/cadastros/financas/CondicaoPagamento/Context';
import { atualizarUrl, metodosAtualizarUrl, validarFormulario, voltarParaAPesquisa } from 'views/Util';
import { confirmarExclusao } from 'views/Util/ExclusaoDeRegistros';
import { CADASTRO_URL, INITIAL_VALUES, PESQUISA_URL } from '../../../Util/constantes';
import { createCondicaoPagamento, deleteCondicaoPagamento, updateCondicaoPagamento } from '../../../Requests';
import { converterCondicaoPagamentoParaApi } from '../../../Util/condicoesPagamentoConverter';

function ActionButtons({ isModal, hideModal, isEntrada }) {
	const { values, dirty, resetForm, handleSubmit, validateForm, handleReset } = useFormikContext();
	const { informacoesPermissoes, history } = useContextCondicaoPagamento();

	const estadoBotaoSalvar = !dirty && isModal && values.id ? estadosBotaoSalvar.CONFIRMAR : estadosBotaoSalvar.SALVAR;
	const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;

	function handleClickNovo() {
		if (dirty) {
			handleClickSalvar(onNovo);
		} else {
			onNovo();
		}
	}

	async function onNovo() {
		atualizarUrl(history, CADASTRO_URL, null, metodosAtualizarUrl.POP);
		resetForm({ values: INITIAL_VALUES });
	}

	function handleClickExcluir() {
		confirmarExclusao(deleteRegistro);
	}

	async function handleClickSalvar(novoOnSuccess) {
		handleSubmit();

		if (await validarFormulario({ validateForm, values })) {
			if (values.id) {
				updateRegistro(values, novoOnSuccess);
			} else {
				createRegistro(values, novoOnSuccess);
			}
		}
	}

	function handleClickCancelar() {
		if (dirty) {
			handleReset();
		} else if (isModal) {
			closeModal();
		} else {
			voltarParaAPesquisa(history, PESQUISA_URL);
		}
	}

	function closeModal(dados = null) {
		if (dados) {
			hideModal(dados);
		} else {
			hideModal();
		}
	}

	async function deleteRegistro() {
		await deleteCondicaoPagamento(values.id, () => {
			resetForm();
			voltarParaAPesquisa(history, PESQUISA_URL);
		});
	}

	async function updateRegistro(dadosFormulario, novoOnSuccess) {
		await updateCondicaoPagamento(converterCondicaoPagamentoParaApi(dadosFormulario), () => {
			if (typeof novoOnSuccess === 'function') {
				novoOnSuccess();
			} else {
				resetForm({ values: values });
			}
		});
	}

	async function createRegistro(dadosFormulario, novoOnSuccess) {
		await createCondicaoPagamento(converterCondicaoPagamentoParaApi(dadosFormulario), ({ data: condicao }) => {
			if (typeof novoOnSuccess === 'function') {
				novoOnSuccess();
			} else {
				resetForm({ values: { ...values, id: condicao.id } });
				if (!isModal) {
					atualizarUrl(history, CADASTRO_URL, condicao.id, metodosAtualizarUrl.POP);
				} else {
					closeModal({ ...values, id: condicao.id });
				}
			}
		});
	}

	return (
		<>
			<ButtonCancelar
				hidden={isModal && values.id !== '' && !dirty}
				estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
				onClick={handleClickCancelar}
				{...informacoesPermissoes}
			/>
			<ButtonSalvar
				estadoBotao={estadoBotaoSalvar}
				disabled={!dirty && estadoBotaoSalvar === estadosBotaoSalvar.SALVAR}
				onClick={handleClickSalvar}
				{...informacoesPermissoes}
			/>
			<ButtonNovo
				estadoBotao={estadoBotaoNovo}
				hidden={(!dirty && !values.id) || isModal}
				onClick={handleClickNovo}
				{...informacoesPermissoes}
			/>
			<ButtonExcluir
				hidden={!values.id || isModal}
				onClick={handleClickExcluir}
				{...informacoesPermissoes}
				disabled={isEntrada}
			/>
		</>
	);
}

export { ActionButtons };
