import { buscarDadosLoginLocalStorage, modulos, usuarioPossuiModulo } from '../../../../Common';
import { buscarDescricaoTamanhoArquivo } from '../../../../Common/ManipulacaoDeString';

export function converterPlanoParaFormulario(planos) {
	let result = planos.map((plano) => {
		if (!plano.utilizado) {
			plano.utilizado = {};
		}
		plano.utilizado =
			buscarDadosLoginLocalStorage()?.organizacao.aplicacao === 'DOCS_DIGITAIS'
				? converterUtilizadoParaFormuarioDOCsDigitais(plano)
				: converterUtilizadoParaFormuarioMsysGestor(plano);
		plano.labels =
			buscarDadosLoginLocalStorage()?.organizacao.aplicacao === 'DOCS_DIGITAIS'
				? buscarDescricoesPlanoDOCsDigitais(plano)
				: buscarDescricoesPlanoMsysGestor(plano);
		return plano;
	});
	result = result.sort((a, b) => a.valor - b.valor);
	return result;
}

function buscarDescricoesPlanoDOCsDigitais(plano) {
	let result = [];

	if (plano.ilimitado) {
		result.push('Documentos ilimitados');
		result.push('Armazenamento ilimitado');
		result.push('Usuários ilimitados');
		result.push('Cadastro de pessoas ilimitado');
		result.push('Cadastro de produtos ilimitado');
		result.push(`Suporte via chat`);
	} else {
		if (plano.documentosMensais === 999999999) {
			result.push('Documentos ilimitados');
		} else {
			result.push(`${plano.documentosMensais} documentos ${plano.tipo === 'GRATUITO' ? '' : 'por mês'}`);
		}
		if (Number.parseInt(plano.armazenamento) === Number.parseInt('100000000000000000')) {
			result.push('Armazenamento ilimitado');
		} else {
			result.push(`${buscarDescricaoTamanhoArquivo(plano.armazenamento)} de armazenamento`);
		}
		if (plano.usuarios === 999999999) {
			result.push('Usuários ilimitados');
		} else {
			result.push(`${plano.usuarios} ${plano.usuarios === 1 ? 'usuário' : 'usuários'}`);
		}

		if (plano.cadastrosPessoas === 999999999) {
			result.push('Cadastro de pessoas ilimitado');
		} else {
			result.push(`${plano.cadastrosPessoas} cadastros de pessoas`);
		}

		if (plano.cadastrosProdutos === 999999999) {
			result.push('Cadastro de produtos ilimitado');
		} else {
			result.push(`${plano.cadastrosProdutos} cadastros de produtos`);
		}

		if (plano.suporteChat) {
			result.push(`Suporte via chat`);
		}
	}
	return result;
}

function buscarDescricoesPlanoMsysGestor(plano) {
	let result = [];

	if (plano.ilimitado) {
		result.push('Usuários ilimitados');
		result.push('Cadastro de pessoas ilimitado');
		result.push('Cadastro de produtos ilimitado');
		result.push('Notas de entrada ilimitadas');
		result.push('Orçamentos ilimitados');
		result.push('Pedidos de venda ilimitados');
		result.push('NF-es ilimitadas');
		result.push('NFC-es ilimitadas');
		result.push('MDF-es ilimitados');
		result.push('Ordens de serviço ilimitadas');
		result.push('NFS-es ilimitadas');
		result.push('Contas financeiras ilimitadas');
		result.push('Boletos ilimitados');
		if (usuarioPossuiModulo(modulos.DOCUMENTOS)) {
			result.push('Documentos ilimitados');
		}
		result.push('Armazenamento ilimitado');
		result.push(`Suporte via chat`);
	} else {
		if (plano.usuarios === 999999999) {
			result.push('Usuários ilimitados');
		} else {
			result.push(`${plano.usuarios} ${plano.usuarios === 1 ? 'usuário' : 'usuários'}`);
		}
		if (plano.cadastrosPessoas === 999999999) {
			result.push('Cadastro de pessoas ilimitado');
		} else {
			result.push(`${plano.cadastrosPessoas} cadastros de pessoas`);
		}
		if (plano.cadastrosProdutos === 999999999) {
			result.push('Cadastro de produtos ilimitado');
		} else {
			result.push(`${plano.cadastrosProdutos} cadastros de produtos`);
		}
		if (plano.comprasMensais === 999999999) {
			result.push('Notas de entrada ilimitadas');
		} else {
			result.push(`${plano.comprasMensais} notas de entrada ${plano.tipo === 'GRATUITO' ? '' : 'por mês'}`);
		}
		if (plano.orcamentosMensais === 999999999) {
			result.push('Orçamentos ilimitados');
		} else {
			result.push(`${plano.orcamentosMensais} orçamentos ${plano.tipo === 'GRATUITO' ? '' : 'por mês'}`);
		}
		if (plano.pedidosMensais === 999999999) {
			result.push('Pedidos de venda ilimitados');
		} else {
			result.push(`${plano.pedidosMensais} pedidos de venda ${plano.tipo === 'GRATUITO' ? '' : 'por mês'}`);
		}
		if (plano.nfesMensais === 999999999) {
			result.push('NF-es ilimitadas');
		} else {
			result.push(`${plano.nfesMensais} NF-es ${plano.tipo === 'GRATUITO' ? '' : 'por mês'}`);
		}

		if (plano.mdfesMensais === 999999999) {
			result.push('MDF-es ilimitados');
		} else {
			result.push(`${plano.mdfesMensais} MDF-es ${plano.tipo === 'GRATUITO' ? '' : 'por mês'}`);
		}

		if (plano.nfcesMensais === 999999999) {
			result.push('NFC-es ilimitadas');
		} else {
			result.push(`${plano.nfcesMensais} NFC-es ${plano.tipo === 'GRATUITO' ? '' : 'por mês'}`);
		}

		if (plano.ordensMensais === 999999999) {
			result.push('Ordens de serviço ilimitadas');
		} else {
			result.push(`${plano.ordensMensais} ordens de serviço ${plano.tipo === 'GRATUITO' ? '' : 'por mês'}`);
		}
		if (plano.nfsesMensais === 999999999) {
			result.push('NFS-es ilimitadas');
		} else {
			result.push(`${plano.nfsesMensais} NFS-es ${plano.tipo === 'GRATUITO' ? '' : 'por mês'}`);
		}

		if (plano.contasFinanceiras === 999999999) {
			result.push('Contas financeiras ilimitadas');
		} else {
			result.push(
				`${plano.contasFinanceiras} ${plano.contasFinanceiras === 1 ? 'conta financeira' : 'contas financeiras'}`
			);
		}

		if (plano.boletosMensais === 999999999) {
			result.push('Boletos ilimitados');
		} else {
			result.push(`${plano.boletosMensais} boletos ${plano.tipo === 'GRATUITO' ? '' : 'por mês'} `);
		}
		if (usuarioPossuiModulo(modulos.DOCUMENTOS)) {
			if (plano.documentosMensais === 999999999) {
				result.push('Documentos ilimitados');
			} else {
				result.push(`${plano.documentosMensais} documentos ${plano.tipo === 'GRATUITO' ? '' : 'por mês'}`);
			}
		}
		if (Number.parseInt(plano.armazenamento) === Number.parseInt('100000000000000000')) {
			result.push('Armazenamento ilimitado');
		} else {
			result.push(`${buscarDescricaoTamanhoArquivo(plano.armazenamento)} de armazenamento`);
		}
		if (plano.suporteChat) {
			result.push(`Suporte via chat`);
		}
	}
	return result;
}

function converterUtilizadoParaFormuarioDOCsDigitais(plano) {
	plano.utilizado.labels = [];

	if (plano.utilizado) {
		if (plano.ilimitado) {
			plano.utilizado.labels.push(`${plano.utilizado.documentosMensais} documentos neste mês`);
			plano.utilizado.labels.push(`${buscarDescricaoTamanhoArquivo(plano.utilizado.armazenamento)} de armazenamento`);
			plano.utilizado.labels.push(`${plano.utilizado.usuarios} usuário(s)`);
			plano.utilizado.labels.push(
				`${plano.utilizado.cadastrosPessoas} ${
					plano.utilizado.cadastrosPessoas === 1 ? 'cadastro de pessoa' : 'cadastros de pessoas'
				}`
			);
			plano.utilizado.labels.push(
				`${plano.utilizado.cadastrosProdutos} ${
					plano.utilizado.cadastrosProdutos === 1 ? 'cadastro de produto' : 'cadastros de produtos'
				}`
			);
		} else {
			if (plano.documentosMensais === 999999999) {
				plano.utilizado.labels.push(
					plano.utilizado.documentosMensais === 1
						? `${plano.utilizado.documentosMensais} documento`
						: `${plano.utilizado.documentosMensais} documentos`
				);
			} else {
				plano.utilizado.labels.push(
					`${plano.utilizado.documentosMensais} de ${plano.documentosMensais} ${
						plano.documentosMensais === 1 ? 'documento' : 'documentos'
					} `
				);
			}
			if (plano.armazenamento === Number.parseInt('100000000000000000')) {
				plano.utilizado.labels.push(`${buscarDescricaoTamanhoArquivo(plano.utilizado.armazenamento)} utilizado`);
			} else {
				plano.utilizado.labels.push(
					`${buscarDescricaoTamanhoArquivo(plano.utilizado.armazenamento)} de ${buscarDescricaoTamanhoArquivo(
						plano.armazenamento
					)} utilizado`
				);
			}
			if (plano.usuarios === 999999999) {
				plano.utilizado.labels.push(
					plano.utilizado.usuarios === 1
						? `${plano.utilizado.usuarios} usuário`
						: `${plano.utilizado.usuarios} usuários`
				);
			} else {
				plano.utilizado.labels.push(
					`${plano.utilizado.usuarios} de ${plano.usuarios} ${plano.usuarios === 1 ? 'usuário' : 'usuários'} `
				);
			}

			if (plano.cadastrosPessoas === 999999999) {
				plano.utilizado.labels.push(
					plano.utilizado.cadastrosPessoas === 1
						? `${plano.utilizado.cadastrosPessoas} cadastro de pessoa`
						: `${plano.utilizado.cadastrosPessoas} cadastros de pessoas`
				);
			} else {
				plano.utilizado.labels.push(
					`${plano.utilizado.cadastrosPessoas} de ${plano.cadastrosPessoas} ${
						plano.cadastrosPessoas === 1 ? 'cadastro de pessoa' : 'cadastros de pessoas'
					} `
				);
			}
			if (plano.cadastrosProdutos === 999999999) {
				plano.utilizado.labels.push(
					plano.utilizado.cadastrosProdutos === 1
						? `${plano.utilizado.cadastrosProdutos} cadastro de produto`
						: `${plano.utilizado.cadastrosProdutos} cadastros de produtos`
				);
			} else {
				plano.utilizado.labels.push(
					`${plano.utilizado.cadastrosProdutos} de ${plano.cadastrosProdutos} ${
						plano.cadastrosProdutos === 1 ? 'cadastro de produto' : 'cadastros de produtos'
					} `
				);
			}
		}
	}
	return plano.utilizado;
}

function converterUtilizadoParaFormuarioMsysGestor(plano) {
	plano.utilizado.labels = [];

	if (plano.utilizado) {
		if (plano.ilimitado) {
			plano.utilizado.labels.push(`${plano.utilizado.usuarios} usuário(s)`);
			plano.utilizado.labels.push(
				`${plano.utilizado.cadastrosPessoas} ${
					plano.utilizado.cadastrosPessoas === 1 ? 'cadastro de pessoa' : 'cadastros de pessoas'
				}`
			);
			plano.utilizado.labels.push(
				`${plano.utilizado.cadastrosProdutos} ${
					plano.utilizado.cadastrosProdutos === 1 ? 'cadastro de produto' : 'cadastros de produtos'
				}`
			);
			plano.utilizado.labels.push(
				`${plano.utilizado.comprasMensais} ${
					plano.utilizado.comprasMensais === 1 ? 'nota de entrada' : 'notas de entrada'
				} neste mês`
			);
			plano.utilizado.labels.push(
				`${plano.utilizado.orcamentosMensais} ${
					plano.utilizado.orcamentosMensais === 1 ? 'orçamento' : 'orçamentos'
				} neste mês`
			);
			plano.utilizado.labels.push(
				`${plano.utilizado.pedidosMensais} ${
					plano.utilizado.pedidosMensais === 1 ? 'pedido de venda' : 'pedidos de venda'
				} neste mês`
			);
			plano.utilizado.labels.push(
				`${plano.utilizado.nfesMensais} ${plano.utilizado.nfesMensais === 1 ? 'NF-e' : 'NF-es'} neste mês`
			);
			plano.utilizado.labels.push(
				`${plano.utilizado.nfcesMensais} ${plano.utilizado.nfcesMensais === 1 ? 'NFC-e' : 'NFC-es'} neste mês`
			);
			plano.utilizado.labels.push(
				`${plano.utilizado.mdfesMensais} ${plano.utilizado.mdfesMensais === 1 ? 'MDF-e ' : 'MDF-es'} neste mês`
			);
			plano.utilizado.labels.push(
				`${plano.utilizado.ctesMensais} ${plano.utilizado.ctesMensais === 1 ? 'CT-e ' : 'CT-es'} neste mês`
			);
			plano.utilizado.labels.push(
				`${plano.utilizado.nfsesMensais} ${plano.utilizado.nfsesMensais === 1 ? 'NFS-e' : 'NFS-es'} neste mês`
			);
			plano.utilizado.labels.push(
				`${plano.utilizado.ordensMensais} ${
					plano.utilizado.ordensMensais === 1 ? 'ordem de serviço' : 'ordens de serviço'
				} neste mês`
			);

			plano.utilizado.labels.push(
				`${plano.utilizado.contasFinanceiras} ${
					plano.utilizado.contasFinanceiras === 1 ? 'conta financeira' : 'contas financeiras'
				}`
			);
			plano.utilizado.labels.push(
				`${plano.utilizado.boletosMensais} ${plano.utilizado.boletosMensais === 1 ? 'boleto' : 'boletos'} neste mês`
			);
			if (usuarioPossuiModulo(modulos.DOCUMENTOS)) {
				plano.utilizado.labels.push(`${plano.utilizado.documentosMensais} documentos neste mês`);
			}
			plano.utilizado.labels.push(`${buscarDescricaoTamanhoArquivo(plano.utilizado.armazenamento)} de armazenamento`);
		} else {
			if (plano.usuarios === 999999999) {
				plano.utilizado.labels.push(
					plano.utilizado.usuarios === 1
						? `${plano.utilizado.usuarios} usuário`
						: `${plano.utilizado.usuarios} usuários`
				);
			} else {
				plano.utilizado.labels.push(
					`${plano.utilizado.usuarios} de ${plano.usuarios} ${plano.usuarios === 1 ? 'usuário' : 'usuários'} `
				);
			}
			if (plano.cadastrosPessoas === 999999999) {
				plano.utilizado.labels.push(
					plano.utilizado.cadastrosPessoas === 1
						? `${plano.utilizado.cadastrosPessoas} cadastro de pessoa`
						: `${plano.utilizado.cadastrosPessoas} cadastros de pessoas`
				);
			} else {
				plano.utilizado.labels.push(
					`${plano.utilizado.cadastrosPessoas} de ${plano.cadastrosPessoas} ${
						plano.cadastrosPessoas === 1 ? 'cadastro de pessoa' : 'cadastros de pessoas'
					} `
				);
			}
			if (plano.cadastrosProdutos === 999999999) {
				plano.utilizado.labels.push(
					plano.utilizado.cadastrosProdutos === 1
						? `${plano.utilizado.cadastrosProdutos} cadastro de produto`
						: `${plano.utilizado.cadastrosProdutos} cadastros de produtos`
				);
			} else {
				plano.utilizado.labels.push(
					`${plano.utilizado.cadastrosProdutos} de ${plano.cadastrosProdutos} ${
						plano.cadastrosProdutos === 1 ? 'cadastro de produto' : 'cadastros de produtos'
					} `
				);
			}
			if (plano.comprasMensais === 999999999) {
				plano.utilizado.labels.push(
					plano.utilizado.comprasMensais === 1
						? `${plano.utilizado.comprasMensais} nota de entrada`
						: `${plano.utilizado.comprasMensais} notas de entrada`
				);
			} else {
				plano.utilizado.labels.push(
					`${plano.utilizado.comprasMensais} de ${plano.comprasMensais} ${
						plano.comprasMensais === 1 ? 'nota de entrada' : 'notas de entrada'
					} `
				);
			}
			if (plano.orcamentosMensais === 999999999) {
				plano.utilizado.labels.push(
					plano.utilizado.orcamentosMensais === 1
						? `${plano.utilizado.orcamentosMensais} orçamento`
						: `${plano.utilizado.orcamentosMensais} orçamentos`
				);
			} else {
				plano.utilizado.labels.push(
					`${plano.utilizado.orcamentosMensais} de ${plano.orcamentosMensais} ${
						plano.orcamentosMensais === 1 ? 'orçamento' : 'orçamentos'
					} `
				);
			}
			if (plano.pedidosMensais === 999999999) {
				plano.utilizado.labels.push(
					plano.utilizado.pedidosMensais === 1
						? `${plano.utilizado.pedidosMensais} pedido de venda`
						: `${plano.utilizado.pedidosMensais} pedidos de venda`
				);
			} else {
				plano.utilizado.labels.push(
					`${plano.utilizado.pedidosMensais} de ${plano.orcamentosMensais} ${
						plano.pedidosMensais === 1 ? 'pedido de venda' : 'pedidos de venda'
					} `
				);
			}
			if (plano.nfesMensais === 999999999) {
				plano.utilizado.labels.push(
					plano.utilizado.nfesMensais === 1
						? `${plano.utilizado.nfesMensais} NF-e`
						: `${plano.utilizado.nfesMensais} NF-es`
				);
			} else {
				plano.utilizado.labels.push(
					`${plano.utilizado.nfesMensais} de ${plano.nfesMensais} ${plano.nfesMensais === 1 ? 'NF-e' : 'NF-es'} `
				);
			}
			if (plano.mdfesMensais === 999999999) {
				plano.utilizado.labels.push(
					plano.utilizado.mdfesMensais === 1
						? `${plano.utilizado.mdfesMensais} MDF-e`
						: `${plano.utilizado.mdfesMensais} MDF-es`
				);
			} else {
				plano.utilizado.labels.push(
					`${plano.utilizado.mdfesMensais} de ${plano.mdfesMensais} ${plano.mdfesMensais === 1 ? 'MDF-e' : 'MDF-es'} `
				);
			}
			if (plano.nfcesMensais === 999999999) {
				plano.utilizado.labels.push(
					plano.utilizado.nfcesMensais === 1
						? `${plano.utilizado.nfcesMensais} NFC-e`
						: `${plano.utilizado.nfcesMensais} NFC-es`
				);
			} else {
				plano.utilizado.labels.push(
					`${plano.utilizado.nfcesMensais} de ${plano.nfcesMensais} ${plano.nfcesMensais === 1 ? 'NFC-e' : 'NFC-es'} `
				);
			}
			if (plano.ordensMensais === 999999999) {
				plano.utilizado.labels.push(
					plano.utilizado.ordensMensais === 1
						? `${plano.utilizado.ordensMensais} ordem de serviço`
						: `${plano.utilizado.ordensMensais} ordens de serviço`
				);
			} else {
				plano.utilizado.labels.push(
					`${plano.utilizado.ordensMensais} de ${plano.ordensMensais} ${
						plano.ordensMensais === 1 ? 'ordem de serviço' : 'ordens de serviço'
					} `
				);
			}
			if (plano.nfsesMensais === 999999999) {
				plano.utilizado.labels.push(
					plano.utilizado.nfsesMensais === 1
						? `${plano.utilizado.nfsesMensais} NFS-e`
						: `${plano.utilizado.nfsesMensais} NFS-es`
				);
			} else {
				plano.utilizado.labels.push(
					`${plano.utilizado.nfsesMensais} de ${plano.nfsesMensais} ${plano.nfsesMensais === 1 ? 'NFS-e' : 'NFS-es'} `
				);
			}
			if (plano.contasFinanceiras === 999999999) {
				plano.utilizado.labels.push(
					plano.utilizado.contasFinanceiras === 1
						? `${plano.utilizado.contasFinanceiras} conta financeira`
						: `${plano.utilizado.contasFinanceiras} contas financeiras`
				);
			} else {
				plano.utilizado.labels.push(
					`${plano.utilizado.contasFinanceiras} de ${plano.contasFinanceiras} ${
						plano.contasFinanceiras === 1 ? 'conta financeira' : 'contas financeiras'
					} `
				);
			}
			if (plano.boletosMensais === 999999999) {
				plano.utilizado.labels.push(
					plano.utilizado.boletosMensais === 1
						? `${plano.utilizado.boletosMensais} boleto`
						: `${plano.utilizado.boletosMensais} boletos`
				);
			} else {
				plano.utilizado.labels.push(
					`${plano.utilizado.boletosMensais} de ${plano.boletosMensais} ${
						plano.boletosMensais === 1 ? 'boleto' : 'boletos'
					} `
				);
			}
			if (usuarioPossuiModulo(modulos.DOCUMENTOS)) {
				if (plano.documentosMensais === 999999999) {
					plano.utilizado.labels.push(
						plano.utilizado.documentosMensais === 1
							? `${plano.utilizado.documentosMensais} documento`
							: `${plano.utilizado.documentosMensais} documentos`
					);
				} else {
					plano.utilizado.labels.push(
						`${plano.utilizado.documentosMensais} de ${plano.documentosMensais} ${
							plano.documentosMensais === 1 ? 'documento' : 'documentos'
						} `
					);
				}
			}
			if (plano.armazenamento === Number.parseInt('100000000000000000')) {
				plano.utilizado.labels.push(`${buscarDescricaoTamanhoArquivo(plano.utilizado.armazenamento)} utilizado`);
			} else {
				plano.utilizado.labels.push(
					`${buscarDescricaoTamanhoArquivo(plano.utilizado.armazenamento)} de ${buscarDescricaoTamanhoArquivo(
						plano.armazenamento
					)} utilizado`
				);
			}
		}
	}
	return plano.utilizado;
}
