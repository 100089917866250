import { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import Col from '../../Col';
import { removerCaracteresInvalidosRsql } from '../../../Common/Rsql';
import { buscarDadosLoginLocalStorage, colors } from 'Common';

export default function InputSearch(props) {
	const {
		placeholder,
		name,
		className,
		value,
		disabled,
		sm,
		md,
		lg,
		xl,
		id,
		removerEComercial = true,
		styleContainer,
	} = props;

	const [time, setTime] = useState(null);
	const [firstRender, setFirstRender] = useState(true);
	const [dadosLogin, setDadosLogin] = useState(buscarDadosLoginLocalStorage());

	useEffect(() => {
		setFirstRender(false);
	}, []);

	useEffect(() => {
		if (!dadosLogin.filialConectada?.parametrosGerais?.pesquisaInteracao && !firstRender) {
			setTime(setTimeout(() => props.onPesquisar(), 500));
		}
	}, [value]);

	function onKeyDown(e) {
		if (props.onKeyDown) props.onKeyDown(e);
		else if (e.key === 'Enter') {
			clearTimeout(time);
			props.onPesquisar();
		}
	}

	function onClickPesquisar() {
		clearTimeout(time);
		props.onPesquisar();
	}

	function handleChangePesquisa(e) {
		clearTimeout(time);
		props.onChange(removerCaracteresInvalidosRsql(e.target.value, removerEComercial));
	}

	async function limparEPesquisar() {
		clearTimeout(time);
		await props.onChange('');
		props.onPesquisar();
	}

	return (
		<>
			<Col sm={sm} md={md} lg={lg} xl={xl}>
				<div className="p-inputgroup" style={styleContainer}>
					<InputText
						id={id}
						type="text"
						className={className}
						placeholder={placeholder}
						name={name}
						style={{
							width: '100%',
							marginRight: '-36px',
							height: '32px',
						}}
						onKeyDown={onKeyDown}
						value={value}
						disabled={disabled}
						onChange={handleChangePesquisa}
					/>
					<Button
						type="button"
						className="p-button-secondary"
						style={{
							backgroundColor: colors.brancoTransparente,
							border: 'none',
							visibility: !value ? 'hidden' : 'inherit',
							zIndex: 1,
						}}
						icon="fa fa-times"
						onClick={limparEPesquisar}
					/>
					<Button
						type="button"
						icon="fa fa-search"
						disabled={disabled}
						onClick={onClickPesquisar}
						style={{
							boxShadow: 'none',
							zIndex: 1,
							height: '32px',
						}}
					/>
				</div>
			</Col>
		</>
	);
}
InputSearch.propTypes = {
	/** Placeholder da pesquisa */
	placeholder: propTypes.string,
	/** Nome do campo de pesquisa */
	name: propTypes.string,
	/** Classificacao da pesquisa */
	className: propTypes.string,
	/** Evento disparado ao teclar */
	onKeyDown: propTypes.func,
	/** Valor do componente */
	value: propTypes.any,
	/** Evento onChange*/
	onChange: propTypes.func,
	/** Evento clicar no pesquisa */
	onPesquisar: propTypes.func,
	/** Componente desabilitado */
	disabled: propTypes.bool,
	/** Tamanho do campo em small devices*/
	sm: propTypes.string,
	/** Tamanho do campo em medium devices*/
	md: propTypes.string,
	/** Tamanho do campo em large devices*/
	lg: propTypes.string,
	/** Tamanho do campo em extra large devices*/
	xl: propTypes.string,
	/**Define se o componente está carregando */
	loading: propTypes.bool,
	styleContainer: propTypes.object,
};
InputSearch.defaultProps = {
	placeholder: 'Digite algo para pesquisar...',
	sm: '12',
	md: '7',
	lg: '6',
	xl: '5',
};
