import { colors, keyFilterConsultaRsql } from 'Common';
import { Dropdown, Grid, If, InputDate, InputField, Message } from 'components';
import { isBefore, parseISO } from 'date-fns';
import { Field, useFormikContext } from 'formik';
import { useContextTabelaPreco } from 'views/cadastros/vendas/TabelaPreco/Context';
import {
	TABELA_PRECO_PROMOCIONAL_OPTIONS,
	TABELA_PRECO_SITUACAO_OPTIONS,
} from 'views/cadastros/vendas/TabelaPreco/Util/constantes';

function HeaderTabelaPreco() {
	const { values, setFieldValue } = useFormikContext();
	const { informacoesPermissoes, utilizaTabelaPrecoPromocional, isHeaderEditing } = useContextTabelaPreco();

	function onChangeField(value, field) {
		isHeaderEditing.current = true;

		if (field === 'situacao') {
			if (values?.situacao === 'INATIVO' && isBefore(parseISO(values?.dataVencimento), new Date())) {
				setFieldValue(`dataVencimento`, null);
			}
		}

		setFieldValue(`${field}`, value);
	}

	function createTextMessage() {
		return 'Para adicionar produtos, salve primeiro a tabela de preço.';
	}

	return (
		<>
			<div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '1rem' }}>
				<div
					style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '0px' }}
					title={!values.codigo ? 'O código é gerado automaticamente pelo sistema' : null}
				>
					<div>Código</div>
					<div style={{ fontSize: '24px', color: colors.principal, fontWeight: 'bold' }}>{values.codigo || '-'}</div>
				</div>
			</div>

			<Grid className="step-tabela-preco-campos-principais">
				<Field
					id="input-tabela_preco-nome"
					sm="12"
					md="12"
					lg="12"
					xl="12"
					name="nome"
					label="Nome"
					component={InputField}
					keyfilter={keyFilterConsultaRsql}
					value={values.nome}
					onChange={(e) => onChangeField(e.target?.value, 'nome')}
					size={80}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					component={Dropdown}
					label="Tabela promocional"
					name="tabelaPromocional"
					helpMessage={
						utilizaTabelaPrecoPromocional ? null : 'Parâmetro "Utiliza tabela de preço promocional" desativado'
					}
					options={TABELA_PRECO_PROMOCIONAL_OPTIONS}
					onChange={(e) => onChangeField(e.value, 'tabelaPromocional')}
					value={values?.tabelaPromocional}
					disabled={!utilizaTabelaPrecoPromocional}
					showClear={false}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					component={InputDate}
					label="Vencimento"
					name="dataVencimento"
					value={values?.dataVencimento}
					onChange={(e) => onChangeField(e.target?.value, 'dataVencimento')}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					component={Dropdown}
					label="Situação"
					name="situacao"
					options={TABELA_PRECO_SITUACAO_OPTIONS}
					onChange={(e) => onChangeField(e.value, 'situacao')}
					value={values?.situacao}
					showClear={false}
					{...informacoesPermissoes}
				/>
			</Grid>
			<If test={!values.id}>
				<Message severity="warn" text={createTextMessage()} />
			</If>
		</>
	);
}

export { HeaderTabelaPreco };
