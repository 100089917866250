import { colors } from 'Common';
import { tipoCampos } from 'components';

export const CADASTRO_URL = '/condicoes_pagamento/cadastro';
export const PESQUISA_URL = '/condicoes_pagamento';

export const CONDICAO_PAGAMENTO_MOVIMENTACAO = {
	ENTRADA: 'ENTRADA',
	SAIDA: 'SAIDA',
};

export const CONDICAO_PAGAMENTO_MOVIMENTACAO_OPTIONS = [
	{ label: 'Entrada', value: CONDICAO_PAGAMENTO_MOVIMENTACAO.ENTRADA },
	{ label: 'Saída', value: CONDICAO_PAGAMENTO_MOVIMENTACAO.SAIDA },
];

export const CONDICAO_PAGAMENTO_SITUACAO = {
	ATIVO: 'ATIVO',
	INATIVO: 'INATIVO',
};

export const CONDICAO_PAGAMENTO_SITUACAO_OPTIONS = [
	{ label: 'Ativo', value: CONDICAO_PAGAMENTO_SITUACAO.ATIVO },
	{ label: 'Inativo', value: CONDICAO_PAGAMENTO_SITUACAO.INATIVO },
];

export const CONDICAO_PAGAMENTO_TIPO = {
	A_VISTA: 'A_VISTA',
	A_PRAZO: 'A_PRAZO',
	SEM_PAGAMENTO: 'SEM_PAGAMENTO',
};

export const CONDICAO_PAGAMENTO_TIPO_OPTIONS = [
	{ label: 'À vista', value: CONDICAO_PAGAMENTO_TIPO.A_VISTA },
	{ label: 'À prazo', value: CONDICAO_PAGAMENTO_TIPO.A_PRAZO },
	{ label: 'Sem pagamento', value: CONDICAO_PAGAMENTO_TIPO.SEM_PAGAMENTO },
];

export const OPTIONS_FILTRO_AVANCADO = [
	{ label: 'Descrição', name: 'descricao', type: tipoCampos.STRING },
	{
		label: 'Situação',
		name: 'situacao',
		type: tipoCampos.SELECT,
		optionSelect: CONDICAO_PAGAMENTO_SITUACAO_OPTIONS,
	},
	{
		label: 'Tipo',
		name: 'tipo',
		type: tipoCampos.SELECT,
		optionSelect: CONDICAO_PAGAMENTO_TIPO_OPTIONS,
	},
];

export const CONDICAO_PAGAMENTO_COMPENSACAO_TIPO = {
	EMISSAO: 'EMISSAO',
	VENCIMENTO: 'VENCIMENTO',
};

export const CONDICAO_PAGAMENTO_COMPENSACAO_TIPO_OPTIONS = [
	{ label: 'Emissão', value: CONDICAO_PAGAMENTO_COMPENSACAO_TIPO.EMISSAO },
	{ label: 'Vencimento', value: CONDICAO_PAGAMENTO_COMPENSACAO_TIPO.VENCIMENTO },
];

export const CONDICAO_PAGAMENTO_TAXA_TIPO = {
	PERCENTUAL: 'PERCENTUAL',
	VALOR: 'VALOR',
};

export const CONDICAO_PAGAMENTO_TAXA_TIPO_OPTIONS = [
	{ label: 'Percentual', value: CONDICAO_PAGAMENTO_TAXA_TIPO.PERCENTUAL },
	{ label: 'Valor', value: CONDICAO_PAGAMENTO_TAXA_TIPO.VALOR },
];

export const COLORS_SITUACAO = {
	bgAtivo: colors.verdeLight,
	textAtivo: colors.verde,
	bgInativo: colors.vermelhoLight,
	textInativo: colors.vermelho,
};

export const COLORS_INDICACAO_MOVIMENTO = {
	bgEntrada: colors.azulLight,
	textEntrada: colors.azul,
	bgSaida: colors.vermelhoLight,
	textSaida: colors.vermelho,
};

export const QTD_PARCELAS_OPTIONS = [
	{ label: '1x', value: 1 },
	{ label: '2x', value: 2 },
	{ label: '3x', value: 3 },
	{ label: '4x', value: 4 },
	{ label: '5x', value: 5 },
	{ label: '6x', value: 6 },
	{ label: '7x', value: 7 },
	{ label: '8x', value: 8 },
	{ label: '9x', value: 9 },
	{ label: '10x', value: 10 },
	{ label: '11x', value: 11 },
	{ label: '12x', value: 12 },
	{ label: '13x', value: 13 },
	{ label: '14x', value: 14 },
	{ label: '15x', value: 15 },
	{ label: '16x', value: 16 },
	{ label: '17x', value: 17 },
	{ label: '18x', value: 18 },
	{ label: '19x', value: 19 },
	{ label: '20x', value: 20 },
	{ label: '21x', value: 21 },
	{ label: '22x', value: 22 },
	{ label: '23x', value: 23 },
	{ label: '24x', value: 24 },
	{ label: '25x', value: 25 },
	{ label: '26x', value: 26 },
	{ label: '27x', value: 27 },
	{ label: '28x', value: 28 },
	{ label: '29x', value: 29 },
	{ label: '30x', value: 30 },
	{ label: '31x', value: 31 },
	{ label: '32x', value: 32 },
	{ label: '33x', value: 33 },
	{ label: '34x', value: 34 },
	{ label: '35x', value: 35 },
	{ label: '36x', value: 36 },
	{ label: '37x', value: 37 },
	{ label: '38x', value: 38 },
	{ label: '39x', value: 39 },
	{ label: '40x', value: 40 },
	{ label: '41x', value: 41 },
	{ label: '42x', value: 42 },
	{ label: '43x', value: 43 },
	{ label: '44x', value: 44 },
	{ label: '45x', value: 45 },
	{ label: '46x', value: 46 },
	{ label: '47x', value: 47 },
	{ label: '48x', value: 48 },
];

export const INITIAL_VALUES = {
	id: null,
	descricao: null,
	tipo: CONDICAO_PAGAMENTO_TIPO.A_PRAZO,
	movimentacao: CONDICAO_PAGAMENTO_MOVIMENTACAO.SAIDA,
	parcelaMinima: 1,
	parcelaPadrao: 1,
	parcelaMaxima: 48,
	situacao: CONDICAO_PAGAMENTO_SITUACAO.ATIVO,
	favorita: false,
	formaPagamento: null,
	compensacaoAutomatica: false,
	compensacaoTipo: CONDICAO_PAGAMENTO_COMPENSACAO_TIPO.VENCIMENTO,
	compensacaoNumeroDias: 0,
	taxaTipoAplicado: CONDICAO_PAGAMENTO_TAXA_TIPO.PERCENTUAL,
	taxas: [],
};
