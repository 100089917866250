import { colors } from 'Common';

export const Financas = {
	cores: {
		vermelho: colors.vermelho,
		vermelhoClaro: colors.vermelhoLight,
		verde: colors.verde,
		verdeClaro: colors.verdeLight,
		azul: colors.principal,
		preto: colors.brancoTransparente,
		cinza: colors.cinza,
		amarelo: colors.amarelo,
		amareloClaro: colors.amareloLight,
	},
};

export const Vendas = {
	cores: {
		vermelho: colors.vermelho,
		vermelhoClaro: colors.vermelhoLight,
		verde: colors.verde,
		verdeClaro: colors.verdeLight,
		azul: colors.principal,
		preto: colors.brancoTransparente,
		cinza: colors.cinza,
		branco: colors.cinzaLight,
	},
};

export const Compras = {
	cores: {
		vermelho: colors.vermelho,
		vermelhoClaro: colors.vermelhoLight,
		verde: colors.verde,
		verdeClaro: colors.verdeLight,
		azul: colors.principal,
		preto: colors.brancoTransparente,
		cinza: colors.cinza,
		branco: colors.cinzaLight,
	},
};

export const Documentos = {
	cores: {
		vermelho: colors.vermelho,
		vermelhoClaro: colors.vermelhoLight,
		verde: colors.verde,
		verdeClaro: colors.verdeLight,
		azul: colors.principal,
		preto: colors.brancoTransparente,
		cinza: colors.cinza,
	},
	corStatus: {
		assinados: '#36a536',
		rejeitados: colors.vermelho,
		aguardandoAssinaturas: colors.azul,
		expirados: '#B6531C',
	},
};

export const Estoque = {
	cores: {
		vermelho: colors.vermelho,
		vermelhoClaro: colors.vermelhoLight,
		verde: colors.verde,
		verdeClaro: colors.verdeLight,
		azul: colors.principal,
		preto: colors.brancoTransparente,
		cinza: colors.cinza,
	},
};
