import { useDrag } from 'react-dnd';

import { colors } from 'Common';

import {
	buscarAlinhamentoTextoEtiqueta,
	converterFontSizeEmPixel,
	converterMilimetrosEmPixel,
	isCampoPesoOuQuantidade,
	isCampoTextoLivre,
	isCampoValor,
	isCodigoBarra,
} from 'views/cadastros/produtos/Etiquetas/components/configuracao/Form/Util/functions';
import { DRAG_AND_DROP_TYPES } from 'views/cadastros/produtos/Etiquetas/components/configuracao/Form/Util/constantes';

import codigoBarraSvg from '../../../../../../Util/Imagens/barcode.svg';

function CampoEtiqueta({ campos, indexCampo, escalaVisualizacao, indexCampoSelecionado, setIndexCampoSelecionado }) {
	const isCampoSelecionado = indexCampo === indexCampoSelecionado;

	const posicaoCampo = {
		posicaoHorizontal: converterMilimetrosEmPixel(campos.posicaoHorizontal, escalaVisualizacao),
		posicaoVertical: converterMilimetrosEmPixel(campos.posicaoVertical, escalaVisualizacao),
	};

	const [{ isDragging }, dragRef] = useDrag(
		() => ({
			type: DRAG_AND_DROP_TYPES.FIELDS_ETIQUETA,
			item: {
				posicaoHorizontal: campos.posicaoHorizontal,
				posicaoVertical: campos.posicaoVertical,
				indexCampo: indexCampo,
			},
			collect: (monitor) => ({ isDragging: !!monitor.isDragging() }),
		}),
		[indexCampo, campos.posicaoHorizontal, campos.posicaoVertical]
	);

	function validarCampoPreview() {
		if (isCodigoBarra(campos.campo?.registro?.nome)) {
			return (
				<img
					alt="preview campo etiqueta"
					style={{
						width: `${converterMilimetrosEmPixel(campos.largura, escalaVisualizacao)}px`,
						height: `${converterMilimetrosEmPixel(campos.altura, escalaVisualizacao)}px`,
					}}
					src={codigoBarraSvg}
				/>
			);
		} else if (isCampoTextoLivre(campos.campo?.registro?.nome)) {
			return <span>{campos.textoLivre}</span>;
		} else if (isCampoValor(campos.campo?.registro?.nome)) {
			return <span>R$0,00</span>;
		} else if (isCampoPesoOuQuantidade(campos.campo?.registro?.nome)) {
			return <span>0,00</span>;
		} else {
			return <span>{campos.campo?.label}</span>;
		}
	}

	function onSelectField() {
		if (isCampoSelecionado) {
			setIndexCampoSelecionado(null);
		} else {
			setIndexCampoSelecionado(indexCampo);
		}
	}

	if (isDragging) {
		return <div ref={dragRef} />;
	}

	return (
		<div
			ref={dragRef}
			type={DRAG_AND_DROP_TYPES.FIELDS_ETIQUETA}
			onClick={onSelectField}
			style={{
				position: 'absolute',
				border: `1px solid ${isCampoSelecionado ? colors.principal : '#b3b3b3'}`,
				overflow: 'hidden',
				background: colors.branco,

				fontSize: converterFontSizeEmPixel(campos.tamanhoFonte, escalaVisualizacao),
				fontWeight: campos.negrito && 'bold',
				fontStyle: campos.italico && 'italic',
				textDecoration: campos.sublinhado && 'underline',

				display: 'flex',
				justifyContent: buscarAlinhamentoTextoEtiqueta(campos.alinhamento),

				left: posicaoCampo.posicaoHorizontal,
				top: posicaoCampo.posicaoVertical,

				width: converterMilimetrosEmPixel(campos.largura, escalaVisualizacao),
				height: converterMilimetrosEmPixel(campos.altura, escalaVisualizacao),
				transform: `rotate(${campos.rotacao}deg)`,
			}}
		>
			{validarCampoPreview()}
		</div>
	);
}

export { CampoEtiqueta };
