import { colors } from 'Common';
import { tipoCampos } from '../../../../components/PesquisaAvancada/Util/constantes';

export const optionsFiltroAvancado = [
	{ label: 'Número', name: 'numero', type: tipoCampos.STRING },
	{ label: 'Série', name: 'serie', type: tipoCampos.STRING },
	{ label: 'Emissão', name: 'dataHoraEmissao', type: tipoCampos.DATE },
	{ label: 'Nome emitente', name: 'emitenteNome', type: tipoCampos.STRING },
	{ label: 'CPF/CNPJ', name: 'emitenteCpfCnpj', type: tipoCampos.STRING },
	{
		label: 'Status da NF-e',
		name: 'situacaoNfe',
		type: tipoCampos.SELECT,
		optionSelect: [
			{ label: 'Transmitida', value: 'TRANSMITIDA' },
			{ label: 'Cancelada', value: 'CANCELADA' },
			{ label: 'Denegada', value: 'DENEGADA' },
		],
	},
];

export const NotasEntrada = {
	cores: {
		verde: colors.verde,
		verdeClaro: colors.verdeLight,
		azul: colors.principal,
		preto: colors.brancoTransparente,
		cinza: colors.cinza,
	},
};

export const ColorsCard = {
	SEM_MANIFESTACAO: {
		lightColor: colors.amareloLight,
		strongColor: colors.amarelo,
	},
	CIENCIA_OPERACAO: {
		lightColor: colors.azulLight,
		strongColor: colors.azul,
	},
	CONFIRMACAO_OPERACAO: {
		lightColor: colors.verdeLight,
		strongColor: colors.verde,
	},
	DESCONHECIMENTO_OPERACAO: {
		lightColor: colors.cinzaLight,
		strongColor: colors.cinzaDark,
	},
	OPERACAO_NAO_REALIZADA: {
		lightColor: colors.vermelhoLight,
		strongColor: colors.vermelho,
	},
};

export const OperacaoStatus = {
	SEMMANIFESTACAO: 'SEM_MANIFESTACAO',
	CIENCIA: 'CIENCIA_OPERACAO',
	CONFIRMADA: 'CONFIRMACAO_OPERACAO',
	DESCONHECIMENTO: 'DESCONHECIMENTO_OPERACAO',
	NAOREALIZADA: 'OPERACAO_NAO_REALIZADA',
};

export const ColorsSituacao = {
	bgSemManifestacao: colors.amareloLight,
	textSemManifestacao: colors.amarelo,
	bgCiencia: colors.azulLight,
	textCiencia: colors.cinzaDark,
	bgConfirmada: colors.verdeLight,
	textConfirmada: colors.verde,
	bgDesconhecimento: '#E0E0E0',
	textDesconhecimento: '#000000',
	bgNaoRealizada: colors.vermelhoLight,
	textNaoRealizada: colors.vermelho,
};

export const OptionsManifestacao = [
	{ label: 'Ciência da operação', value: 'CIENCIA_DA_OPERACAO' },
	{ label: 'Confirmação da operação', value: 'CONFIRMACAO_DA_OPERACAO' },
	{ label: 'Desconhecimento da operação', value: 'DESCONHECIMENTO_DA_OPERACAO' },
	{ label: 'Operação não realizada', value: 'OPERACAO_NAO_REALIZADA' },
];

export const TipoManifestacao = {
	CIENCIA_DA_OPERACAO: 'CIENCIA_DA_OPERACAO',
	CONFIRMACAO_DA_OPERACAO: 'CONFIRMACAO_DA_OPERACAO',
	DESCONHECIMENTO_DA_OPERACAO: 'DESCONHECIMENTO_DA_OPERACAO',
	OPERACAO_NAO_REALIZADA: 'OPERACAO_NAO_REALIZADA',
	SEM_MANIFESTACAO: 'SEM_MANIFESTACAO',
};

export const SituacaoNfe = {
	TRANSMITIDA: 'TRANSMITIDA',
	CANCELADA: 'CANCELADA',
	DENEGADA: 'DENEGADA',
};
