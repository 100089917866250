import { endOfMonth, formatISO, startOfMonth } from 'date-fns';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Menu } from 'primereact/menu';
import { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { atualizarUrl } from '../../Util';
import DocumentoHistorico from './Form/components/DocumentoHistorico';
import ModalEnviarParaAssinatura from './Form/modal/ModalEnviarParaAssinatura';
import ModalVerDetalhesDocumento from './Form/modal/ModalVerDetalhesDocumento';
import {
	asyncArquivarDocumento,
	asyncBaixarDocumentoComAssinaturas,
	asyncBaixarDocumentoOriginal,
	asyncDesarquivarDocumento,
	asyncExcluirDocumento,
	asyncGetDocumento,
	asyncGetPesquisaDocumentos,
	asyncGetTotalizadores,
	asyncRegerarBaixarDocumentoComAssinaturas,
} from './Requests';
import { optionsFiltroAvancado, statusDocumento } from './Util/constantes';
import {
	aplicarEstiloArquivado,
	buscarMensagemExcluirDocumento,
	renderDataCriacao,
	renderStatus,
} from './Util/funcoesList';

import {
	baixarArquivo,
	buscarConfiguracaoUsuario,
	colors,
	configuracoesUsuario,
	construirUrl,
	formatarParaPesquisarTiposEnumerados,
	manterApenasNumeros,
	permissoes,
	recursos,
	replaceCaracteresEspeciais,
	salvarConfiguracaoUsuario,
	services,
	usuarioPossuiPermissao,
} from 'Common';

import {
	Button,
	ButtonEditarTable,
	ButtonNovo,
	Col,
	DateInterval,
	DescricaoFiltroAvancado,
	Form,
	FormActions,
	FormContent,
	Grid,
	If,
	InputSearch,
	NenhumRegistroEncontrado,
	Paginacao,
	PesquisaAvancada,
	ToastTypes,
	Tutorial,
	confirm,
	notify,
	tutorialStepsListagens,
} from 'components';
import { useUpdateEffect } from 'react-use';
import { useContextPesquisa } from 'views/Util/Context/ContextPesquisa';
import { CardStatusAssinaturaEletronica } from './components/CardStatus';

const buttonTableStyle = {
	borderRadius: '50%',
	padding: '5px',
	width: '30px',
	height: '30px',
	marginLeft: '2.5px',
	marginRight: '2.5px',
	border: 'none',
	color: colors.cinzaDark,
};

function AssinaturaEletronica(props) {
	const { isMobile, location, history } = props;

	const {
		valorPesquisa,
		setValorPesquisa,
		interval,
		setInterval,
		sortField,
		setSortField,
		sortOrder,
		setSortOrder,
		page,
		setPage,
		rows,
		setRows,
		filtroAvancado,
		setFiltroAvancado,
		descricaoFiltroAvancado,
		setDescricaoFiltroAvancado,
		exibirBloqueadas,
		setExibirBloqueadas,
	} = useContextPesquisa();

	const [podeInserir, setPodeInserir] = useState(
		usuarioPossuiPermissao(recursos.DOCUMENTOS_ASSINATURA, permissoes.INSERIR)
	);
	const [podeExcluir, setPodeExcluir] = useState(
		usuarioPossuiPermissao(recursos.DOCUMENTOS_ASSINATURA, permissoes.EXCLUIR)
	);
	const [podeEditar, setPodeEditar] = useState(
		usuarioPossuiPermissao(recursos.DOCUMENTOS_ASSINATURA, permissoes.EDITAR)
	);
	const [deveExibirTutorial, setDeveExibirTutorial] = useState(
		buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS)
	);
	const [limparFiltroPesquisaAvancada, setLimparFiltroPesquisaAvancada] = useState(false);
	const [registroSelecionado, setRegistroSelecionado] = useState(null);
	const [modalDocumentoHistoricoVisible, setModalDocumentoHistoricoVisible] = useState(false);
	const [documentosSelecionados, setDocumentosSelecionados] = useState([]);
	const [tutorialVisible, setTutorialVisible] = useState(false);
	const [valorCard, setValorCard] = useState('');
	const [mostrarModalVerDetalhesDocumento, setMostrarModalVerDetalhesDocumento] = useState(false);
	const [documentoSelecionado, setDocumentoSelecionado] = useState(null);
	const [modalEnviarDocumentoVisible, setModalEnviarDocumentoVisible] = useState(false);
	const [signatarioEspecifico, setSignatarioEspecifico] = useState(null);
	const [cards, setCards] = useState({
		aguardandoAssinaturas: 0,
		assinados: 0,
		rejeitados: 0,
		expirados: 0,
	});
	const [cardSelected, setCardSelected] = useState(null);
	const [registros, setRegistros] = useState([]);
	const [filtroData, setFiltroData] = useState(
		`criadoEm>=${formatISO(interval.dataInicial, {
			representation: 'date',
		})};criadoEm<=${formatISO(interval.dataFinal, {
			representation: 'date',
		})}`
	);
	const [totalElements, setTotalElements] = useState(0);
	const [firstRender, setFirstRender] = useState(true);

	const menuOpcoes = useRef(null);

	const acoesStyle = !isMobile && { textAlign: 'end' };

	const pesquisarCallback = useCallback(() => {
		if (!firstRender) {
			pesquisar();
		}
	});

	useEffect(() => {
		pesquisar();

		const { state } = props.location;

		if (state && state.pesquisaPadrao) {
			setValorPesquisa(state.pesquisaPadrao);
		}

		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS, false, null, false);
		}

		setTimeout(() => {
			document.getElementById('AssinaturaInputSearch')?.focus();
		}, 500);
	}, []);

	useUpdateEffect(() => {
		pesquisarCallback();
	}, [exibirBloqueadas, page, rows, sortOrder, sortField, filtroAvancado, interval, cardSelected]);

	function buscarFiltro() {
		const valorTiposEnumerados = formatarParaPesquisarTiposEnumerados(valorPesquisa.replaceAll('&', ''));
		const currentDate = new Date();
		const startOfTheMonth = startOfMonth(currentDate);
		const endOfTheMonth = endOfMonth(currentDate);

		let filtroRSQL = String('?query=(')
			.concat(`id=contains="${valorPesquisa.replaceAll('&', '%26')}",`)
			.concat(`identificacao=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)
			.concat(`pessoa.nome=contains="*${valorPesquisa.replaceAll('&', '*')}*",`)
			.concat(
				`pessoa.pessoaFisica.cpf=="${manterApenasNumeros(
					replaceCaracteresEspeciais(valorPesquisa.replaceAll('&', '%26'))
				)}",`
			)
			.concat(
				`pessoa.pessoaJuridica.cnpj=="${manterApenasNumeros(
					replaceCaracteresEspeciais(valorPesquisa.replaceAll('&', '%26'))
				)}",`
			)
			.concat(`status=contains="*${valorTiposEnumerados}*",`)
			.concat(`hash256=contains="*${valorTiposEnumerados}*")`);

		if (filtroData) {
			filtroRSQL = filtroRSQL.concat(`;(${filtroData})`);
		} else {
			filtroRSQL = filtroRSQL.concat(
				`;(${`criadoEm>=${formatISO(startOfTheMonth, {
					representation: 'date',
				})};criadoEm<=${formatISO(endOfTheMonth, {
					representation: 'date',
				})}`})`
			);
		}
		if (filtroAvancado) {
			filtroRSQL = filtroRSQL.concat(`;(${filtroAvancado})`);
		}
		if (!exibirBloqueadas) {
			filtroRSQL = filtroRSQL.concat(`;arquivado==${false}`);
		}
		if (valorCard) {
			filtroRSQL = filtroRSQL.concat(`;(${valorCard})`);
		}

		return filtroRSQL;
	}

	function asyncExcluirRegistro() {
		confirm('Atenção', buscarMensagemExcluirDocumento(registroSelecionado), () => {
			asyncExcluirDocumento(registroSelecionado.id, () => {
				notify('Documento excluído', ToastTypes.SUCCESS);
				pesquisar(false);
			});
		});
	}

	function excluirSelecionados(documentos) {
		confirm('Confirmação', 'Tem certeza que deseja excluir estes documentos?', async () => {
			let error = false;
			for (const documento of documentos) {
				await asyncExcluirDocumento(documento.id, null, () => {
					error = true;
				});
			}
			if (!error) {
				notify('Documentos excluídos', ToastTypes.SUCCESS);
			}
			limparRegistrosSelecionados();
			pesquisar();
		});
	}

	function pesquisar(exibirLoading) {
		const filtro = buscarFiltro();
		let campoOrdenacao = sortField;
		let sentidoOrdenacao = sortOrder;
		if (firstRender) {
			setSortField('criadoEm');
			setSortOrder(-1);
			campoOrdenacao = 'criadoEm';
			sentidoOrdenacao = -1;
		}
		const url = construirUrl(
			`${services.GESTOR}/v1/documentos/resumo`,
			filtro,
			rows,
			page,
			sentidoOrdenacao > 0 ? `${campoOrdenacao},asc` : `${campoOrdenacao},desc`
		);

		asyncGetTotalizadores(interval, exibirBloqueadas, ({ data: totais }) => {
			setCards(totais);
		});

		asyncGetPesquisaDocumentos(
			url,
			({ data: documentos }) => {
				setRegistros(documentos.content);
				setTotalElements(documentos.totalElements);
				setFirstRender(false);
			},
			null,
			exibirLoading
		);
	}

	async function buscarDocumento(documentoId) {
		await asyncGetDocumento(documentoId, ({ data: documento }) => {
			setDocumentoSelecionado(documento);
			setMostrarModalVerDetalhesDocumento(true);
		});
	}

	function renderAssinados(row) {
		let assinados = row
			? aplicarEstiloArquivado(row.arquivado, `${row.qtdAssinaturasRealizadas} de ${row.qtdSignatarios}`, isMobile)
			: aplicarEstiloArquivado(row.arquivado, '0 de 0', isMobile);

		if (
			[
				statusDocumento.AGUARDANDO_ASSINATURAS,
				statusDocumento.ASSINADO,
				statusDocumento.REJEITADO,
				statusDocumento.EXPIRADO,
			].includes(row.status)
		) {
			return (
				<span title={assinados} style={{ display: 'inline-block' }}>
					<span
						title="Clique para ver os detalhes"
						className="link_to"
						style={{ color: colors.principal }}
						onClick={() => buscarDocumento(row.id)}
					>
						{assinados}
					</span>
				</span>
			);
		}
	}

	function onPesquisar() {
		pesquisarCallback();
	}

	function onPageChange(e) {
		setPage(e.page);
		setRows(e.rows);
	}

	function onSort(element) {
		setSortField(element.sortField);
		setSortOrder(element.sortOrder);
	}

	function onEditar(row) {
		atualizarUrl(history, `/documentos/assinatura/cadastro/${row.id}`);
	}

	function onExcluir() {
		asyncExcluirRegistro();
	}

	function renderOpcoes(registroSelecionado) {
		const acoesDoBotao = buscarAcoes(registroSelecionado);
		return (
			<div style={{ display: 'flex' }}>
				<ButtonEditarTable onClick={() => onEditar(registroSelecionado)} />
				<Button
					color="secondary"
					style={buttonTableStyle}
					icon="fa fa-ellipsis-v"
					size="icon"
					disabled={Boolean(acoesDoBotao.length === 0)}
					title={Boolean(acoesDoBotao.length === 0) ? 'Nenhuma operação possível para este documento' : null}
					onClick={(event) => {
						menuOpcoes.current.toggle(event);
						setRegistroSelecionado(registroSelecionado);
					}}
				/>
			</div>
		);
	}

	function buscarAcoes(registroSelecionado) {
		let itens = [];

		if (registroSelecionado && registroSelecionado.status !== statusDocumento.PENDENTE) {
			itens.push({
				label: 'Visualizar arquivo original',
				icon: 'fa fa-download',
				disabled: !podeEditar,
				command: () => baixarDocumento(registroSelecionado.id),
			});
		}

		if (registroSelecionado && registroSelecionado.status === statusDocumento.ASSINADO) {
			itens.push({
				label: 'Visualizar arquivo assinado',
				icon: 'fa fa-download',
				disabled: !podeEditar,
				command: () => baixarDocumentoAssinado(registroSelecionado.id),
			});
		}

		itens.push({
			label: 'Histórico',
			icon: 'fa fa-history',
			command: () => setModalDocumentoHistoricoVisible(true),
		});

		if (registroSelecionado && registroSelecionado.arquivado && podeEditar) {
			itens.push({
				label: 'Desarquivar',
				icon: 'fa fa-archive',
				disabled: !podeEditar,
				command: () => desarquivarDocumento(),
			});
		}

		if (registroSelecionado && !registroSelecionado.arquivado && podeEditar) {
			itens.push({
				label: 'Arquivar',
				icon: 'fa fa-archive',
				disabled: !podeEditar,
				command: () => arquivarDocumento(),
			});
		}

		if (registroSelecionado && registroSelecionado.status !== statusDocumento.ASSINADO && podeExcluir) {
			itens.push({
				label: 'Excluir',
				icon: 'fa fa-trash',
				command: () => onExcluir(),
			});
		}

		return itens;
	}

	function baixarDocumento(documentoId) {
		asyncBaixarDocumentoOriginal(documentoId, ({ data: file }) => {
			baixarArquivo(file, 'Documento original.pdf');
		});
	}

	function baixarDocumentoAssinado(documentoId) {
		asyncBaixarDocumentoComAssinaturas(documentoId, ({ data: file }) => {
			baixarArquivo(file, 'Documento assinado.pdf');
		}).catch((error) => {
			regerarbaixarDocumentoAssinado(documentoId);
		});
	}

	function regerarbaixarDocumentoAssinado(documentoId) {
		asyncRegerarBaixarDocumentoComAssinaturas(documentoId, ({ data: file }) => {
			baixarArquivo(file, 'Documento assinado.pdf');
		});
	}

	function arquivarDocumento() {
		const message = `Tem certeza que deseja arquivar este documento? Um documento arquivado não aparecerá mais na listagem, para visualizá-los marque o item "Exibir documentos arquivados" na listagem.`;
		confirm('Atenção', message, () => {
			asyncArquivarDocumento(
				registroSelecionado.id,
				() => {
					pesquisar(false);
				},
				null
			);
		});
	}

	function desarquivarDocumento() {
		const message = 'Tem certeza que deseja desarquivar este documento?';
		confirm('Atenção', message, () => {
			asyncDesarquivarDocumento(
				registroSelecionado.id,
				() => {
					pesquisar(false);
				},
				null
			);
		});
	}

	async function arquivarSelecionados(documentos) {
		confirm(
			'Tem certeza que deseja arquivar estes documentos?',
			'Um documento arquivado não aparecerá mais na listagem, para visualizá-los marque o item "Exibir documentos arquivados" na listagem.',
			async () => {
				const promises = documentos.map(
					(documento) =>
						new Promise((resolve, reject) => {
							asyncArquivarDocumento(
								documento.id,
								() => {
									resolve();
								},
								() => {
									reject(error);
								},
								null,
								false
							);
						})
				);

				await Promise.all(promises);
				pesquisar(false);
				limparRegistrosSelecionados();
				notify('Documentos arquivados com sucesso');
			}
		);
	}

	async function desarquivarSelecionados(documentos) {
		confirm('Confirmação', 'Tem certeza que deseja desarquivar este(s) documento(s)?', async () => {
			const promises = documentos.map(
				(documento) =>
					new Promise((resolve, reject) => {
						asyncDesarquivarDocumento(
							documento.id,
							() => {
								resolve();
							},
							() => {
								reject(error);
							},
							null,
							false
						);
					})
			);

			await Promise.all(promises);
			pesquisar(false);
			limparRegistrosSelecionados();
			notify('Documentos desarquivados com sucesso');
		});
	}

	function limparRegistrosSelecionados() {
		setDocumentosSelecionados([]);
	}

	function onPesquisarFiltroAvancado(e) {
		setFiltroAvancado(e);
		setLimparFiltroPesquisaAvancada(false);
	}

	function onChangeCheckboxexibirBloqueadas(e) {
		limparRegistrosSelecionados();
		setExibirBloqueadas(e.checked);
	}

	function getHeader() {
		return (
			<Grid justifyBetween>
				<Col col="5" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', minHeight: '50px' }}>
					<span style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
						<Checkbox
							inputId="exibirBloqueadas"
							checked={exibirBloqueadas}
							name="exibirBloqueadas"
							onChange={onChangeCheckboxexibirBloqueadas}
						/>
						<label htmlFor="exibirBloqueadas" className="p-checkbox-label">
							Exibir documentos arquivados
						</label>
					</span>
				</Col>
				<Col col="7" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', minHeight: '50px' }}>
					<If test={documentosSelecionados.length > 0}>
						<Button
							className="p-button-primary"
							type="button"
							label="Arquivar"
							icon="fa fa-archive"
							disabled={!podeEditar}
							title={!podeEditar ? 'Você não possui permissão para executar essa ação' : ''}
							onClick={() => arquivarSelecionados(documentosSelecionados)}
							style={{ marginRight: '6px' }}
						/>
						<Button
							className="p-button-primary"
							type="button"
							hidden={exibirBloqueadas === false}
							disabled={!podeEditar}
							title={!podeEditar ? 'Você não possui permissão para executar essa ação' : ''}
							label="Desarquivar"
							icon="fa fa-archive"
							onClick={() => desarquivarSelecionados(documentosSelecionados)}
						/>
					</If>
				</Col>
			</Grid>
		);
	}

	function handleChangeInterval(interval) {
		setInterval(interval);
		setFiltroData(
			`criadoEm>=${formatISO(interval.dataInicial, {
				representation: 'date',
			})};criadoEm<=${formatISO(interval.dataFinal, {
				representation: 'date',
			})}`
		);
	}

	return (
		<>
			<Menu model={buscarAcoes(registroSelecionado)} popup={true} style={{ minWidth: '230px' }} ref={menuOpcoes} />
			<Tutorial
				steps={tutorialStepsListagens}
				showSkipButton
				continuous
				disableScrolling
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>
			<Form header="Assinatura de documentos">
				<FormActions>
					<ButtonNovo
						label="Nova assinatura"
						className="step-listagem-novo"
						onClick={() => {
							history.push('assinatura/cadastro');
						}}
						podeInserir={podeInserir}
					/>
				</FormActions>

				<FormContent>
					<Grid justifyCenter verticalAlignCenter>
						<span style={{ padding: '12px' }}>
							<DateInterval onChange={handleChangeInterval} interval={interval} />
						</span>
						<InputSearch
							className="step-listagem-input-search"
							onPesquisar={() => onPesquisar()}
							value={valorPesquisa}
							onChange={(value) => setValorPesquisa(value)}
							removerEComercial={false}
							id="AssinaturaInputSearch"
						/>
						<span style={{ padding: '12px' }}>
							<PesquisaAvancada
								optionsFiltros={optionsFiltroAvancado}
								onPesquisarClick={onPesquisarFiltroAvancado}
								onChangeFiltroRsql={(rsql) => setFiltroAvancado(rsql)}
								onChangeDescricaoFiltro={(e) => setDescricaoFiltroAvancado(e)}
								limparFiltro={limparFiltroPesquisaAvancada}
							/>
						</span>
					</Grid>
					<CardStatusAssinaturaEletronica
						cards={cards}
						cardSelected={cardSelected}
						setCardSelected={setCardSelected}
						setValorCard={setValorCard}
						setPage={setPage}
						isMobile={isMobile}
					/>
					<DescricaoFiltroAvancado texto={descricaoFiltroAvancado} />
					<DataTable
						className={'table table-assinaturas'}
						selectionMode="checkbox"
						responsive
						value={registros}
						sortField={sortField}
						sortOrder={sortOrder}
						selection={documentosSelecionados}
						header={getHeader()}
						onSort={onSort}
						onSelectionChange={(e) => setDocumentosSelecionados(e.value)}
						emptyMessage={<NenhumRegistroEncontrado />}
					>
						<Column header={isMobile ? 'Seleção' : ''} selectionMode="multiple" style={{ width: '45px' }} />
						<Column
							field="identificacao"
							header={isMobile ? 'Identific.' : 'Identificação'}
							sortable={true}
							body={(row) => aplicarEstiloArquivado(row.arquivado, row.identificacao, isMobile)}
							style={{ overflow: 'hidden' }}
						/>
						<Column
							field="pessoaNome"
							body={(row) => aplicarEstiloArquivado(row.arquivado, row.pessoaNome, isMobile)}
							header="Pessoa"
							sortable={true}
							style={{ overflow: 'hidden' }}
						/>
						<Column field="criadoEm" header="Data de criação" sortable={true} body={(row) => renderDataCriacao(row)} />
						<Column
							field="assinados"
							header={isMobile ? 'Ass.' : 'Assinaturas'}
							body={(row) => renderAssinados(row)}
							sortable={false}
						/>
						<Column
							field="status"
							header="Status"
							style={{ width: '220px' }}
							body={(row) => renderStatus(row)}
							sortable={true}
						/>
						<Column
							className="step-listagem-acoes"
							body={(row) => renderOpcoes(row)}
							header="Ações"
							style={{ width: '100px', ...acoesStyle }}
						/>
					</DataTable>
					<Paginacao totalElements={totalElements} rows={rows} page={page} onPageChange={(e) => onPageChange(e)} />
					<If test={documentoSelecionado && mostrarModalVerDetalhesDocumento}>
						<ModalVerDetalhesDocumento
							visible={mostrarModalVerDetalhesDocumento}
							onHide={() => setMostrarModalVerDetalhesDocumento(false)}
							documento={documentoSelecionado}
							isMobile={isMobile}
							onClickEnviarEmailSignatarioEspecifico={(signatario) => {
								setModalEnviarDocumentoVisible(true), setSignatarioEspecifico(signatario);
							}}
						/>
					</If>
					<If test={documentoSelecionado && modalEnviarDocumentoVisible}>
						<ModalEnviarParaAssinatura
							modalReenviarEmail={true}
							visible={modalEnviarDocumentoVisible}
							onHide={() => setModalEnviarDocumentoVisible(false)}
							idDocumento={documentoSelecionado && documentoSelecionado.id}
							arquivo={documentoSelecionado && documentoSelecionado.arquivo}
							signatarioEspecifico={signatarioEspecifico}
							onDocumentoEnviado={() => {
								setSignatarioEspecifico(null), setModalEnviarDocumentoVisible(false);
							}}
							emailsSignatarios={signatarioEspecifico && [signatarioEspecifico.email]}
							documentoSelecionado={documentoSelecionado}
						/>
					</If>
				</FormContent>
			</Form>
			<If test={modalDocumentoHistoricoVisible && registroSelecionado.id}>
				<DocumentoHistorico
					idDocumento={registroSelecionado && registroSelecionado.id}
					visible={modalDocumentoHistoricoVisible}
					onHide={() => {
						setModalDocumentoHistoricoVisible(false), setRegistroSelecionado(null);
					}}
				/>
			</If>
		</>
	);
}

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
});

export default connect(mapStateToProps)(AssinaturaEletronica);
