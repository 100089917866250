import { colors } from 'Common';
import propTypes from 'prop-types';
import { AiFillCloseCircle } from 'react-icons/ai';

const styleCardContent = {
	borderRadius: '5px',
	cursor: 'pointer',
	padding: '7px',
	height: '100%',
};

const styleCloseIcon = {
	float: 'right',
	margin: '-15px -15px 0px -15px',
	fontSize: '20px',
	backgroundColor: 'white',
	borderRadius: '50%',
	color: colors.principal,
};

const styleValue = {
	fontSize: '22px',
	fontWeight: 'bold',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	textAlign: 'end',
	paddingBottom: '0px',
};

function CardTotalizadorListagem(props) {
	const { cores, title, value = 0, icon = 0, onSelect, name, selected, helpMessage, titleFiltro } = props;

	const titleFiltrar = !selected ? titleFiltro : `Clique para limpar o filtro`;

	function getContentStyle() {
		if (selected) {
			return {
				...styleCardContent,
				border: `2px solid ${colors.principal}`,
				boxShadow: '2px 4px 10px 0px #999999',
				margin: '-2px',
			};
		}
		return styleCardContent;
	}

	return (
		<div className="p-card p-total-list-cards" onClick={() => onSelect(name)} style={getContentStyle()}>
			{selected && <AiFillCloseCircle style={styleCloseIcon} />}
			<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} title={titleFiltrar}>
				<div>
					<img src={icon} alt="imagem" height={25} />
				</div>
				<div>
					<div>
						<span style={{ color: colors.cinzaDark, fontSize: '14px' }} title={helpMessage}>
							{title}
						</span>
					</div>
					<div>
						<div style={{ ...styleValue, color: cores.strongColor }}>{value}</div>
					</div>
				</div>
			</div>
		</div>
	);
}

CardTotalizadorListagem.propTypes = {
	name: propTypes.string.isRequired,
};

export default CardTotalizadorListagem;
