import { tipoCampos } from 'components/PesquisaAvancada/Util/constantes';

export const optionsFiltroAvancado = [
	{ label: 'Código', name: 'codigo', type: tipoCampos.INTEGER },
	{ label: 'Referência (SKU)', name: 'sku', type: tipoCampos.STRING },
	{ label: 'Código de barras', name: 'codigoBarras', type: tipoCampos.INTEGER },
	{ label: 'Nome', name: 'nome', type: tipoCampos.STRING },
	{
		label: 'Situação',
		name: 'situacao',
		type: tipoCampos.SELECT,
		optionSelect: [
			{ label: 'Ativo', value: 'ATIVO' },
			{ label: 'Inativo', value: 'INATIVO' },
		],
	},
	{
		label: 'Tipo',
		name: 'tipo',
		type: tipoCampos.SELECT,
		optionSelect: [
			{ label: '00 – Mercadoria para revenda', value: 'PRODUTO' },
			{ label: '01 – Matéria-prima', value: 'MATERIAPRIMA' },
			{ label: '02 – Embalagem', value: 'EMBALAGEM' },
			{ label: '03 – Produto em processo', value: 'PROCESSO' },
			{ label: '04 – Produto acabado', value: 'ACABADO' },
			{ label: '05 – Subproduto', value: 'SUBPRODUTO' },
			{ label: '06 – Produto intermediário', value: 'INTERMEDIARIO' },
			{ label: '07 – Material de uso e Consumo', value: 'CONSUMO' },
			{ label: '08 – Ativo imobilizado', value: 'ATIVO' },
			{ label: '09 – Serviços', value: 'SERVICO' },
			{ label: '10 – Outros insumos', value: 'INSUMO' },
			{ label: '99 – Outras', value: 'OUTRA' },
		],
	},
	{
		label: 'Controlar estoque',
		name: 'controlarEstoque',
		type: tipoCampos.SELECT,
		optionSelect: [
			{ label: 'Sim', value: 'true' },
			{ label: 'Não', value: 'false' },
		],
	},
	{ label: 'Preço', name: 'preco', type: tipoCampos.DECIMAL },
];
