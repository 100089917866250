import { colors } from 'Common';
import { tipoCampos } from 'components/PesquisaAvancada/Util/constantes';

export const SITUACAO_OPTIONS = [
	{ label: 'Ativo', value: 'ATIVO' },
	{ label: 'Inativo', value: 'INATIVO' },
];

export const OPTIONS_FILTRO_AVANCADO = [
	{ label: 'Descrição', name: 'descricao', type: tipoCampos.STRING },
	{ label: 'Valor', name: 'valor', type: tipoCampos.DECIMAL },
	{
		label: 'Situação',
		name: 'situacao',
		type: tipoCampos.SELECT,
		optionSelect: SITUACAO_OPTIONS,
	},
];

export const COLORS = {
	bgAtivo: colors.verdeLight,
	textAtivo: colors.verde,
	bgInativo: colors.vermelhoLight,
	textInativo: colors.vermelho,
};

export const STYLE_BACKGROUND = {
	borderRadius: '20px',
	padding: '0.2rem 1.1rem',
};

export const INITIAL_VALUES = {
	id: null,
	descricao: '',
	valor: 0,
	favorito: false,
	situacao: 'ATIVO',
};

export const CADASTRO_URL = 'transporte/componentes_valores_prestacao/cadastro';
export const PESQUISA_URL = 'transporte/componentes_valores_prestacao';
