import { Field, useFormikContext, withFormik } from 'formik';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import {
	buscarConfiguracaoUsuario,
	colors,
	configuracoesUsuario,
	estadosCadastro,
	mensagensDeValidacao,
	permissoes,
	recursos,
	removerCaracteres,
	salvarConfiguracaoUsuario,
	services,
	usuarioPossuiPermissao,
	validarUUID,
} from 'Common';
import {
	Dropdown,
	Form,
	FormActions,
	FormContent,
	Grid,
	If,
	InputField,
	Prompt,
	SingleSelectPessoa,
	TextArea,
	ToastTypes,
	Tutorial,
	notify,
	tutorialStepsObjetoServico,
	SingleSelectMarca,
} from 'components';

import { atualizarUrl, metodosAtualizarUrl } from 'views/Util';
import { TipoPessoa } from 'views/cadastros/Pessoas/Util/constantes';
import { asyncGetObjetoDeServico } from '../Request';
import { buscarEstadoCadastro } from '../Util/function';
import { converterObjetoDeServicoParaFormulario, converterParaDuplicar } from '../Util/ObjetoservicoConverter';
import { situacaoOptions, tipoOptions } from '../Util/constantes';
import ActionButtons from './components/ActionButtons';
import EletronicoFields from './components/EletronicoFields';
import VeiculoFields from './components/VeiculoFields';
import { asyncGetModelosDeServico } from '../../Modelo/Request';
import ModalModeloServico from '../../Modelo/Modal';

const initialValue = {
	id: null,
	codigo: null,
	nome: '',
	cliente: null,
	situacao: 'ATIVO',
	tipo: 'VEICULO',
	marca: null,
	modelo: null,
	anoFabricacao: null,
	observacao: null,
	veiculo: {
		placa: '',
		chassi: '',
		renavam: '',
		quilometragem: 0,
	},
	eletronico: {
		serie: '',
	},
};

function ObjetoDeServicoFormView({
	match,
	location,
	history,
	values,
	dirty,
	setFieldValue,
	resetForm,
	isModal,
	hideModal,
	isMobile,
	cliente,
}) {
	const { setValues } = useFormikContext();
	const [tutorialVisible, setTutorialVisible] = useState(false);
	const [disabledModelo, setDisabledModelo] = useState(true);
	const [optionsModelosPorMarca, setOptionsModelosPorMarca] = useState([]);
	const [modalModeloVisible, setModalModeloVisible] = useState(false);

	const podeInserir = usuarioPossuiPermissao(recursos.SERVICOS, permissoes.INSERIR);
	const podeEditar = usuarioPossuiPermissao(recursos.SERVICOS, permissoes.EDITAR);
	const podeExcluir = usuarioPossuiPermissao(recursos.SERVICOS, permissoes.EXCLUIR);
	const informacoesPermissoes = {
		estadoCadastro: buscarEstadoCadastro(values.id),
		podeInserir: podeInserir,
		podeEditar: podeEditar,
		podeExcluir: podeExcluir,
	};

	useEffect(() => {
		const deveExibirTutorial = buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_OBJETO_DE_SERVICO);
		const idURL = match?.params.id;
		const objetoDeServicoIdParaDuplicar = location?.state?.objetoDeServicoId;

		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_OBJETO_DE_SERVICO, false, null, false);
		}

		if (objetoDeServicoIdParaDuplicar) {
			duplicarObjetoDeServico(objetoDeServicoIdParaDuplicar);
		} else if (validarUUID(idURL)) {
			getObjetoDeServico(idURL);
		}

		setTimeout(() => {
			document.getElementById('InputNomeObjetoDeServico').focus();
		}, 300);
	}, []);

	useEffect(() => {
		if (informacoesPermissoes.estadoCadastro === estadosCadastro.EDICAO) {
			if (values.marca && informacoesPermissoes.podeEditar) {
				setDisabledModelo(false);
			}
		} else if (values.marca && informacoesPermissoes.podeInserir) {
			setDisabledModelo(false);
		}

		if (!values.marca) {
			setDisabledModelo(true);
		}

		if (values.marca?.registro?.modelos?.length > 0) {
			const modelos = values.marca?.registro?.modelos
				?.map((modelo) => ({
					label: modelo.nome,
					registro: modelo,
					value: modelo.id,
				}))
				.filter((modelo) => modelo.registro.situacao === 'ATIVO');
			setOptionsModelosPorMarca(modelos);
		} else {
			setOptionsModelosPorMarca([]);
		}
	}, [values.marca]);

	function duplicarObjetoDeServico(objetoDeServicoId) {
		asyncGetObjetoDeServico(objetoDeServicoId, ({ data: objetoDeServico }) => {
			atualizarUrl(history, '/servicos/objeto/cadastro', null, metodosAtualizarUrl.POP);
			const objetoDeServicoFormulario = converterObjetoDeServicoParaFormulario(objetoDeServico);

			setValues(converterParaDuplicar(objetoDeServicoFormulario));
		});
	}

	function getObjetoDeServico(idURL) {
		asyncGetObjetoDeServico(
			idURL,
			({ data: modelo }) => {
				const ObjetoDeServicoConvertido = converterObjetoDeServicoParaFormulario(modelo);

				getModelosPorMarca(ObjetoDeServicoConvertido);
				resetForm({ values: ObjetoDeServicoConvertido });
			},
			(error) => {
				notify('Erro ao buscar registro', ToastTypes.ERROR);
				console.error(error);
			}
		);
	}

	async function getModelosPorMarca(objetoDeServico) {
		if (!objetoDeServico.marca) {
			setOptionsModelosPorMarca([]);
			return;
		}

		const url = `${services.GESTOR}/v1/servicos/modelo/resumo?query=marca.id==%22${objetoDeServico.marca?.value}%22;situacao==%22ATIVO%22&size=500&page=0&sort=nome,asc`;
		await asyncGetModelosDeServico(url, async ({ data: modelosApi }) => {
			const modelos = await modelosApi?.content
				?.map((modelo) => ({
					label: modelo.nome,
					registro: modelo,
					value: modelo.id,
				}))
				.filter((modelo) => modelo.registro.situacao === 'ATIVO');
			setOptionsModelosPorMarca(modelos);
		});
	}

	function onChangeTipo(event) {
		setFieldValue('veiculo', {
			placa: '',
			chassi: '',
			renavam: '',
			quilometragem: 0,
		});

		setFieldValue('eletronico', {
			serie: '',
		});

		setFieldValue('tipo', event?.value);
	}

	function onChangeMarca(event) {
		setFieldValue('marca', event);

		setFieldValue('modelo', null);
	}

	function onChangeModelo(event) {
		if (event.value) {
			setFieldValue('modelo', event);
		} else {
			setFieldValue('modelo', null);
		}
	}

	function setarNovoModeloDoModal(modeloNovo) {
		if (modeloNovo?.id) {
			const modelo = {
				label: modeloNovo.nome,
				registro: modeloNovo,
				value: modeloNovo.id,
			};
			setOptionsModelosPorMarca([modelo, ...optionsModelosPorMarca]);
			setFieldValue('modelo', modelo);
		}
		setModalModeloVisible(false);
	}

	return (
		<>
			<Prompt dirty={dirty} isModal={isModal} />
			<Tutorial
				steps={tutorialStepsObjetoServico}
				showSkipButton
				continuous
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>
			<Form header="Cadastro objeto de serviço" isModal={isModal} className="card-default screen-max-width">
				<FormActions className="screen-max-width">
					<ActionButtons
						history={history}
						isModal={isModal}
						hideModal={hideModal}
						informacoesPermissoes={informacoesPermissoes}
					/>
				</FormActions>
				<FormContent>
					<Grid>
						<Field
							sm="12"
							md="5"
							lg="5"
							xl="5"
							id="InputNomeObjetoDeServico"
							component={InputField}
							label="Nome"
							obrigatorio
							name="nome"
							onBlur={() => {
								if (values.nome) {
									setFieldValue('nome', values.nome.trim());
								}
							}}
							onChange={(e) => setFieldValue('nome', removerCaracteres(e.target?.value, ['─']))}
							value={values.nome}
							size={120}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="5"
							xl="5"
							component={SingleSelectPessoa}
							label="Proprietário"
							obrigatorio
							name="cliente"
							value={values.cliente}
							tipoPessoa={TipoPessoa.CLIENTE}
							onChange={(event) => setFieldValue('cliente', event)}
							disabled={!!cliente}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="3"
							lg="2"
							xl="2"
							component={Dropdown}
							label="Situação"
							obrigatorio
							name="situacao"
							value={values.situacao}
							onChange={(event) => setFieldValue('situacao', event?.value)}
							options={situacaoOptions}
							showClear={false}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="3"
							lg="3"
							xl="2"
							component={Dropdown}
							label="Tipo"
							obrigatorio
							name="tipo"
							value={values.tipo}
							onChange={(event) => onChangeTipo(event)}
							options={tipoOptions}
							showClear={false}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="4"
							xl="5"
							component={SingleSelectMarca}
							marcaComModelos
							label="Marca"
							name="marca"
							value={values.marca}
							onChange={(event) => onChangeMarca(event)}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="5"
							lg="5"
							xl="5"
							component={Dropdown}
							label="Modelo"
							name="modelo"
							disabled={disabledModelo}
							value={values.modelo}
							options={optionsModelosPorMarca}
							onChange={(event) => onChangeModelo(event)}
							titleBotao="Adicionar novo modelo"
							botaoIcon="fa fa-plus"
							styleButton={{ backgroundColor: 'transparent', color: colors.principal, border: 'none' }}
							esconderBotao={false}
							desabilitarBotao={disabledModelo}
							onClickModal={() => setModalModeloVisible(true)}
							{...informacoesPermissoes}
						/>
						<Field
							sm="3"
							md={values.tipo === 'VEICULO' ? '2' : '3'}
							lg={values.tipo === 'VEICULO' ? '2' : '3'}
							xl={values.tipo === 'VEICULO' ? '2' : '3'}
							component={InputField}
							type="number"
							label="Ano fabricação"
							name="anoFabricacao"
							onChange={(e) => setFieldValue('anoFabricacao', e.target?.value)}
							value={values.anoFabricacao}
							{...informacoesPermissoes}
						/>
						<If test={values.tipo === 'VEICULO'}>
							<VeiculoFields informacoesPermissoes={informacoesPermissoes} />
						</If>
						<If test={values.tipo === 'ELETRONICO'}>
							<EletronicoFields informacoesPermissoes={informacoesPermissoes} />
						</If>
						<Field
							sm="12"
							md={values.tipo === 'OUTROS' ? '9' : '12'}
							lg={values.tipo === 'OUTROS' ? '9' : '12'}
							xl={values.tipo === 'OUTROS' ? '9' : '12'}
							component={TextArea}
							placeholder="Escreva informações que sejam importantes para você e seu cliente"
							label="Observação"
							name="observacao"
							maxLength={4096}
							rows={isMobile ? 4 : values.tipo === 'OUTROS' ? 1 : 2}
							value={values.observacao}
							onChange={(event) => setFieldValue('observacao', event.target.value)}
							{...informacoesPermissoes}
						/>
					</Grid>
				</FormContent>
			</Form>

			<If test={modalModeloVisible}>
				<ModalModeloServico
					visible={modalModeloVisible}
					marcaJaCadastrada={values.marca}
					onHide={(modeloNovo) => setarNovoModeloDoModal(modeloNovo)}
				/>
			</If>
		</>
	);
}

const ObjetoDeServicoForm = withFormik({
	enableReinitialize: true,
	validateOnChange: true,
	validateOnBlur: false,

	mapPropsToValues(props) {
		if (props.cliente) {
			return { ...initialValue, cliente: props.cliente };
		}
		return initialValue;
	},

	validate(values) {
		const errors = {};

		if (!values.nome) {
			errors.nome = mensagensDeValidacao.OBRIGATORIO;
		}

		if (!values.cliente) {
			errors.cliente = mensagensDeValidacao.OBRIGATORIO;
		}

		if (!values.situacao) {
			errors.situacao = mensagensDeValidacao.OBRIGATORIO;
		}

		if (!values.tipo) {
			errors.tipo = mensagensDeValidacao.OBRIGATORIO;
		}

		if (values.marca?.registro?.situacao === 'INATIVO') {
			errors.marca = 'Marca selecionada está INATIVA';
		}

		if (values.modelo?.registro?.situacao === 'INATIVO') {
			errors.modelo = 'Modelo selecionado está INATIVO';
		}

		if (values.modelo && values.marca?.registro?.modelos?.includes(values.modelo?.value)) {
			errors.modelo = 'Modelo selecionado não pertence à marca';
		}

		return errors;
	},

	handleSubmit: () => {},
})(ObjetoDeServicoFormView);

function mapStateToProps(state) {
	return {
		isMobile: state.dispositivo.isMobile,
	};
}

export default connect(mapStateToProps)(ObjetoDeServicoForm);
