import {
	buscarDadosLoginLocalStorage,
	calcularImpostosProduto,
	colors,
	copiarObjeto,
	formatarDecimais,
	formatarMonetarioDecimais,
	gerarUUID,
	getSafeValue,
	removerCaracteres,
	removerCaracteresInvalidosRsql,
	services,
	TiposPercentualValor,
} from 'Common';
import {
	Button,
	If,
	InputDouble,
	InputSelectPercentualOrValor,
	NenhumRegistroEncontrado,
	SingleSelectProduto,
	TextArea,
} from 'components';
import { asyncGetOpcoesSelect } from 'components/select/SingleSelect/Requests';
import { NOVO_PRODUTO_VENDA, moduloUtilizado } from 'components/Produtos/Util/constantes';
import { styleButton, styleColumn } from 'components/Produtos/Util/util';
import { useFormikContext } from 'formik';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useCallback, useEffect, useRef, useState } from 'react';
import { CiDiscount1, CiWarning } from 'react-icons/ci';
import { aplicarPercentual } from 'views/Util';
import { TooltipQuantidade } from '../TooltipQuantidade';
import { TooltipSubtotal } from '../TooltipSubtotal';
import { TooltipValorUnitario } from '../TooltipValorUnitario';

function ListaProdutos({
	isMobile,
	isTablet,
	disabled,
	informacoesPermissoes,
	setVisibleModalProduto,
	setIndexModalProduto,
	setAtualizarProdutos,
	adicionarNovoProduto,
	onChangeProduto,
	onChangeProdutos,
	utilizaTabelaPreco,
	operacaoFiscalHeader,
	modulo,
	existeParcelaRecebida,
	montarProduto,
	tabelaPreco,
	pagamentos,
	calcularValorVenda,
	calcularPercentualAdicionalTabelaPreco,
	recalcularTodosOsProdutosComTabelaPreco,
	setRecalcularTodosOsProdutosComTabelaPreco,
	descontoMaximoSuperior,
}) {
	const { values, errors, setFieldValue } = useFormikContext();

	const panelDetalhesProduto = useRef(null);

	const [indexAtualizarProduto, setIndexAtualizarProduto] = useState(null);
	const [currentProductIndex, setCurrentProductIndex] = useState(null);
	const [observacaoItem, setObservacaoItem] = useState('');

	const { filialConectada } = buscarDadosLoginLocalStorage();
	const decimaisQtd = filialConectada?.parametrosCadastros?.decimaisQtd ?? 3;
	const decimaisPreco = filialConectada?.parametrosCadastros?.decimaisPreco ?? 2;
	const utilizaTabelaPrecoAcrescimoOuDescontoPorCondicao =
		filialConectada?.parametrosVendas?.utilizaTabelaPrecoAcrescimoOuDescontoPorCondicao;
	const utilizaTabelaPrecoDescontoQuantidade = filialConectada?.parametrosVendas?.utilizaTabelaPrecoDescontoQuantidade;

	const calcularImpostosProdutoLocal = useCallback(async (produto) => {
		if (modulo !== moduloUtilizado.ORCAMENTO && produto) {
			await calcularImpostosProduto(produto);
		}

		return null;
	});

	const atualizarProduto = useCallback(async (index) => {
		const produto = await recalcularValoresProduto(index);

		setFieldValue(`produtos[${index}]`, produto);
		onChangeProduto(index, produto);
	});

	useEffect(() => {
		if (indexAtualizarProduto !== null) {
			atualizarProduto(indexAtualizarProduto);
			setIndexAtualizarProduto(null);
		}
	}, [indexAtualizarProduto]);

	useEffect(() => {
		if (recalcularTodosOsProdutosComTabelaPreco) {
			const promises = values.produtos?.map((_, index) => recalcularValoresProduto(index, true));
			Promise.all(promises).then((produtos) => {
				setFieldValue(`produtos`, produtos);
				onChangeProdutos(produtos);
				setRecalcularTodosOsProdutosComTabelaPreco(false);
			});
		}
	}, [recalcularTodosOsProdutosComTabelaPreco]);

	async function recalcularValoresProduto(index, alterarValor = false) {
		const produto = copiarObjeto(values.produtos[index]);

		if (produto.produto) {
			if (utilizaTabelaPreco) {
				const deveAlterarValorAutomaticamente =
					!produto.alterouValorManualmente || produto.alterouQuantidade || alterarValor;
				const adicionalTabelaPreco = await calcularPercentualAdicionalTabelaPreco(produto.quantidade);
				produto.adicionalTabelaPreco = { percentual: adicionalTabelaPreco.percentual, tipo: adicionalTabelaPreco.tipo };

				if (deveAlterarValorAutomaticamente) {
					produto.valor = parseFloat(
						calcularValorVenda(produto.valorOriginal, produto.adicionalTabelaPreco).toFixed(decimaisPreco)
					);

					produto.alterouValorManualmente = false;
					produto.alterouQuantidade = false;
					produto.valorAntesAlteracao = null;
				}
			}

			produto.baseCalculo = parseFloat(
				(
					getSafeValue(produto.quantidade) * getSafeValue(produto.valor) +
					getSafeValue(produto.frete) +
					getSafeValue(produto.seguro) +
					getSafeValue(produto.acessorias) -
					getSafeValue(produto.desconto)
				).toFixed(2)
			);

			calcularImpostosProdutoLocal(produto);

			produto.subtotal = parseFloat(
				(
					getSafeValue(produto.baseCalculo) +
					getSafeValue(produto.valorIcmsSt) +
					getSafeValue(produto.tributos?.icms?.valorIcmsSt) +
					getSafeValue(produto.tributos?.ipi?.valorIpi)
				).toFixed(2)
			);

			produto.comissaoValor = produto.comissaoPercentual
				? parseFloat((produto.subtotal * (produto.comissaoPercentual / 100)).toFixed(2))
				: 0;

			return produto;
		}

		return produto;
	}

	function onKeyDownEditProduto(options, value) {
		const pesquisaCodigo = removerCaracteres(value, ['.']);
		const pesquisaFormatado = removerCaracteresInvalidosRsql(value);
		const urlPesquisa = `${services.GESTOR}/v1/produtos?query=(codigo=contains="*${pesquisaCodigo}*",nome=contains="*${pesquisaFormatado}*",codigo=contains="*${pesquisaFormatado}*",sku=contains="*${pesquisaFormatado}*",codigoBarras=contains="${pesquisaFormatado}");tipo!="SERVICO";situacao=="ATIVO"&page=0&size=50&sort=codigo`;
		asyncGetOpcoesSelect(urlPesquisa, ({ data: registros }) => {
			if (registros.content.length === 1) {
				const valueProduto = {
					value: registros.content[0].id,
					registro: registros.content[0],
					label: `${registros.content[0].codigo} - ${registros.content[0].nome}`,
				};
				onChangeFieldProduto(options, valueProduto);
			}
		});
	}

	function renderEditProduto(options) {
		return (
			<SingleSelectProduto
				id={`select-produto-${options.rowIndex}`}
				colStyle={{ padding: '0px', maxWidth: isMobile || isTablet ? '80%' : null }}
				url={`${services.GESTOR}/v1/produtos`}
				label=""
				name="produto"
				onChange={(event) => {
					options.editorCallback(event);
					onChangeFieldProduto(options, event);
				}}
				value={options.value}
				placeholder="Selecione o produto"
				filtroAdicionalUrl={
					utilizaTabelaPreco ? `;tipo!="SERVICO";tabelaPreco.id==${tabelaPreco?.value}` : ';tipo!="SERVICO"'
				}
				disabled={disabled || (utilizaTabelaPreco && !tabelaPreco?.value)}
				resolveBugDataTable
				esconderBotao
				isClearable={false}
				onKeyDown={(event, value) => {
					if (event.key === 'Enter') {
						onKeyDownEditProduto(options, value);
					}
				}}
				{...informacoesPermissoes}
			/>
		);
	}

	function renderEditQuantidade(options) {
		return (
			<InputDouble
				colStyle={{ padding: '0px', maxWidth: isMobile || isTablet ? '80%' : null }}
				label=""
				size={13}
				name="quantidade"
				value={options.value}
				disabled={disabled}
				onChange={(event) => onChangeField(options, event.target.value ?? 0)}
				decimalScale={decimaisQtd}
				allowNegative={false}
				errors={errors?.quantidade}
				{...informacoesPermissoes}
			/>
		);
	}

	function renderEditValorUnitario(options) {
		return (
			<InputDouble
				colStyle={{ padding: '0px', maxWidth: isMobile || isTablet ? '80%' : null }}
				label=""
				name="valor"
				value={options.value}
				onChange={(event) => onChangeField(options, event.target.value ?? 0)}
				size={13}
				prefix="R$ "
				allowNegative={false}
				errors={errors?.valor}
				touched={!!errors?.valor}
				disabled={
					disabled ||
					(utilizaTabelaPreco &&
						!tabelaPreco?.registro?.editarPrecoAbaixoValorTabela &&
						!tabelaPreco?.registro?.editarPrecoAcimaValorTabela)
				}
				decimalScale={decimaisPreco}
				{...informacoesPermissoes}
			/>
		);
	}

	function renderEditDesconto(options) {
		return (
			<InputSelectPercentualOrValor
				colStyle={{ padding: '0px', maxWidth: isMobile || isTablet ? '80%' : null }}
				label=""
				name="desconto"
				value={values?.produtos[options.rowIndex]?.desconto}
				onChange={(tipo, value, percentual) => onChangeField(options, value, percentual, tipo)}
				size={13}
				prefix="R$ "
				allowNegative={false}
				errors={errors?.desconto}
				touched={!!errors?.desconto}
				disabled={disabled}
				decimalScale={decimaisPreco}
				informacoesPermissoes={informacoesPermissoes}
			/>
		);
	}

	function onChangeField(options, value, percentual, tipo = TiposPercentualValor.VALOR) {
		if (tipo === TiposPercentualValor.PERCENTUAL) {
			const subtotal = parseFloat((options.rowData.quantidade * options.rowData.valorOriginal).toFixed(2));
			options.editorCallback(aplicarPercentual(subtotal, percentual));
		} else {
			options.editorCallback(value);
		}
	}

	async function onChangeFieldProduto(options, value) {
		if (value) {
			const produto = await montarProduto({ ...values.produtos[options.rowIndex], produto: value }, value);

			if (value && options.rowIndex === values.produtos.length - 1) {
				setFieldValue('produtos', [
					...values.produtos,
					{
						...copiarObjeto(NOVO_PRODUTO_VENDA),
						id: gerarUUID(),
						item: values.produtos.length + 1,
						operacaoFiscal: operacaoFiscalHeader ?? null,
					},
				]);
			}
			setFieldValue(`produtos[${options.rowIndex}]`, produto);

			setTimeout(() => {
				setAtualizarProdutos(true);

				if (!options.value && value) {
					const elementHtmlProduto = document
						.getElementById('id-produtos-listagem')
						?.getElementsByTagName('table')[0]
						?.getElementsByTagName('tr')
						[values.produtos.length + 1]?.getElementsByClassName('id-produtos-listagem-campo-produto')[0];
					elementHtmlProduto?.click();
				}
			}, 200);
		}
	}

	async function onChangeColumn(e) {
		if (!disabled) {
			const produto = e.newRowData;
			if (produto) {
				if (descontoMaximoSuperior && descontoMaximoSuperior > produto.descontoMaximoPercentual) {
					produto.descontoMaximoVendedor = descontoMaximoSuperior;
					produto.descontoMaximoPercentual = descontoMaximoSuperior;
				}

				if (utilizaTabelaPreco) {
					if (
						(tabelaPreco?.registro?.editarPrecoAbaixoValorTabela ||
							tabelaPreco?.registro?.editarPrecoAcimaValorTabela) &&
						e.rowData.valor !== produto.valor
					) {
						produto.alterouValorManualmente = true;
						produto.valorAntesAlteracao = e.rowData.valor;
					} else if (utilizaTabelaPrecoDescontoQuantidade && e.rowData.quantidade !== produto.quantidade) {
						produto.alterouQuantidade = true;
					}
				}

				setFieldValue(`produtos[${e.rowIndex}]`, produto);
				setIndexAtualizarProduto(e.rowIndex);
			}
		}
	}

	function renderAcoesField(row, options) {
		function handleEditProduto() {
			setVisibleModalProduto(true);
			setIndexModalProduto(options.rowIndex);
		}

		function handleDeleteProduto() {
			const produtos = copiarObjeto(values.produtos);

			produtos.splice(options.rowIndex, 1);

			for (let i = 0; i < produtos.length; i++) {
				produtos[i].item = i + 1;
			}

			setFieldValue(`produtos`, produtos);
			setAtualizarProdutos(true);
		}

		function handleShowOverlay(e, rowIndex) {
			document
				.getElementById('overlayPanel-detalhes')
				?.getElementsByClassName('p-overlaypanel-close p-link')[0]
				?.click();

			setCurrentProductIndex(rowIndex);
			setObservacaoItem(values.produtos[rowIndex]?.descricao);
			panelDetalhesProduto.current?.toggle(e);
		}

		return (
			<div>
				<Button
					style={styleButton}
					className="p-button p-button-primary"
					icon="fa fa-file-text-o"
					title="Observações do item"
					onClick={(e) => handleShowOverlay(e, options.rowIndex)}
					disabled={!row.produto}
				/>
				<Button
					style={styleButton}
					className="p-button p-button-primary"
					icon="fa fa-pencil"
					title="Acessar produto"
					onClick={handleEditProduto}
					disabled={!row.produto}
				/>
				<Button
					style={styleButton}
					className="p-button p-button-danger"
					icon="fa fa-trash"
					title="Excluir produto"
					disabled={disabled || existeParcelaRecebida || (options.rowIndex === 0 && values.produtos.length === 1)}
					onClick={handleDeleteProduto}
				/>
			</div>
		);
	}

	async function onExitLastCell(event) {
		if (event.rowIndex < values.produtos.length - 1) {
			onChangeColumn(event);
			const elementHtmlProduto = document
				.getElementById('id-produtos-listagem')
				?.getElementsByTagName('table')[0]
				?.getElementsByTagName('tr')
				[event.rowIndex + 2]?.getElementsByClassName('id-produtos-listagem-campo-produto')[0];
			elementHtmlProduto?.click();
		} else if (event.rowIndex === values.produtos.length - 1 && event?.newRowData?.produto) {
			await onChangeColumn(event);
			adicionarNovoProduto();
		}
	}

	function renderLabelProduto(row, options) {
		const color = errors[options.rowIndex]?.produto ? colors.vermelho : colors.cinzaText;

		return (
			<div title={errors[options.rowIndex]?.produto} style={{ color: color, display: 'flex', alignItems: 'center' }}>
				{errors[options.rowIndex] && (
					<CiWarning style={{ width: '18px', height: '18px', marginRight: '8px', color: colors.vermelho }} />
				)}
				{row.produto?.label ?? ' - '}
				{row.utilizouTabelaPromocional && (
					<CiDiscount1
						style={{ width: '18px', height: '18px', marginLeft: '8px', color: colors.verdeButton }}
						title={
							row.produto.registro?.tabelaPreco
								? `Produto presente na tabela promocional: ${row.produto.registro?.tabelaPreco?.codigo} - ${row.produto.registro?.tabelaPreco?.nome}`
								: 'Produto presente em tabela promocional'
						}
					/>
				)}
			</div>
		);
	}

	function renderLabelQuantidade(row, options) {
		const color = errors[options.rowIndex]?.quantidade ? colors.vermelho : colors.cinzaText;
		return (
			<>
				<TooltipQuantidade
					indexProduto={options.rowIndex}
					produto={row}
					disabled={disabled}
					quantidadeSaida={row.quantidade}
				/>
				<div
					className={`id-quantidade-produto-listagem-${options.rowIndex}`}
					title={errors[options.rowIndex]?.quantidade}
					style={{ color: color }}
				>
					{formatarDecimais(row.quantidade, decimaisQtd)}
				</div>
			</>
		);
	}

	function renderLabelValorUnitario(row, options) {
		const color = errors[options.rowIndex]?.valor ? colors.vermelho : colors.cinzaText;
		return (
			<>
				<If
					test={
						utilizaTabelaPreco &&
						(utilizaTabelaPrecoDescontoQuantidade || utilizaTabelaPrecoAcrescimoOuDescontoPorCondicao)
					}
				>
					<TooltipValorUnitario
						indexProduto={options.rowIndex}
						produto={row}
						disabled={disabled}
						tabelaPreco={tabelaPreco}
						pagamentos={pagamentos}
						utilizaTabelaPreco={utilizaTabelaPreco}
						utilizaTabelaPrecoDescontoQuantidade={utilizaTabelaPrecoDescontoQuantidade}
						utilizaTabelaPrecoAcrescimoOuDescontoPorCondicao={utilizaTabelaPrecoAcrescimoOuDescontoPorCondicao}
					/>
				</If>
				<div
					className={`id-valor-unitario-produto-listagem-${options.rowIndex}`}
					title={errors[options.rowIndex]?.valor}
					style={{ color: color }}
				>
					{formatarMonetarioDecimais(row.valor, decimaisPreco)}
				</div>
			</>
		);
	}

	function renderLabelDesconto(row, options) {
		const descontoMaximoValor = row.descontoMaximoPercentual
			? row.quantidade * row.valor * (row.descontoMaximoPercentual / 100)
			: 0;
		const color =
			errors[options.rowIndex]?.desconto || (descontoMaximoValor && row.produto && row.desconto > descontoMaximoValor)
				? colors.vermelho
				: colors.cinzaText;
		const title =
			row.descontoMaximoPercentual && row.desconto > descontoMaximoValor
				? `Desconto máximo permitido: R$${parseFloat(descontoMaximoValor).toFixed(2).replace('.', ',')}`
				: errors[options.rowIndex]?.desconto;

		return (
			<div title={title} style={{ color: color }}>
				{formatarMonetarioDecimais(row.desconto, decimaisPreco)}
			</div>
		);
	}

	function renderLabelSubtotal(row, options) {
		return (
			<>
				<TooltipSubtotal indexProduto={options.rowIndex} produto={row} disabled={disabled} />
				<div className={`id-subtotal-produto-listagem-${options.rowIndex}`}>
					{formatarMonetarioDecimais(row.subtotal, decimaisPreco)}
				</div>
			</>
		);
	}

	function renderDetalheField() {
		if (currentProductIndex === null) {
			return null;
		}

		const campo = `produtos[${currentProductIndex}].descricao`;
		function onBlurDetalheField() {
			setFieldValue(campo, observacaoItem);
			setAtualizarProdutos(true);
			setObservacaoItem('');
		}

		return (
			<TextArea
				label="Observação"
				name={campo}
				value={observacaoItem}
				onBlur={onBlurDetalheField}
				onChange={(e) => {
					setObservacaoItem(e.target.value);
				}}
				disabled={disabled}
				{...informacoesPermissoes}
			/>
		);
	}

	return (
		<>
			<DataTable
				id="id-produtos-listagem"
				className="table"
				rowClassName="table-row"
				cellClassName="table-row-cell"
				responsive
				editMode="cell"
				value={values?.produtos}
				style={{
					width: '100%',
					overflowY: values.produtos?.length > 6 ? 'scroll' : 'hidden',
					overflowX: 'hidden',
					maxHeight: isMobile ? '36.4rem' : '20.2rem',
				}}
				emptyMessage={<NenhumRegistroEncontrado />}
			>
				<Column
					header="Item"
					field="item"
					body={(row) => row.item}
					style={{
						...styleColumn,
						width: '40px',
					}}
				/>
				<Column
					bodyClassName="id-produtos-listagem-campo-produto"
					header="Produto"
					field="produto"
					body={(row, options) => renderLabelProduto(row, options)}
					editor={(options) => renderEditProduto(options)}
					style={{
						...styleColumn,
					}}
				/>
				<Column
					header="Qtde."
					field="quantidade"
					body={(row, options) => renderLabelQuantidade(row, options)}
					editor={(options) => renderEditQuantidade(options)}
					onCellEditComplete={onChangeColumn}
					style={{
						...styleColumn,
						width: '120px',
					}}
				/>
				<Column
					header="Valor unit."
					field="valor"
					body={(row, options) => renderLabelValorUnitario(row, options)}
					editor={(options) => renderEditValorUnitario(options)}
					onCellEditComplete={onChangeColumn}
					style={{
						...styleColumn,
						width: '140px',
					}}
				/>
				<Column
					header="Desconto"
					field="desconto"
					body={(row, options) => renderLabelDesconto(row, options)}
					editor={(options) => renderEditDesconto(options)}
					onCellEditComplete={(event) => {
						onExitLastCell(event);
					}}
					style={{
						...styleColumn,
						width: '140px',
					}}
				/>
				<Column
					header="Total produto"
					field="subtotal"
					body={(row, options) => renderLabelSubtotal(row, options)}
					style={{
						...styleColumn,
						width: '120px',
					}}
				/>
				<Column
					header="Ações"
					field="acoes"
					body={(row, options) => renderAcoesField(row, options)}
					style={{
						...styleColumn,
						width: '130px',
					}}
				/>
			</DataTable>
			<OverlayPanel
				ref={panelDetalhesProduto}
				id="overlayPanel-detalhes"
				style={{
					width: isMobile ? '90%' : '100%',
					maxWidth: '30.5rem',
					marginLeft: isMobile ? '1rem' : null,
					marginTop: '0px',
				}}
				onHide={() => setCurrentProductIndex(null)}
				showCloseIcon
			>
				{renderDetalheField()}
			</OverlayPanel>
		</>
	);
}

export { ListaProdutos };
