import { colors, copiarObjeto, removerElementoPorId } from 'Common';
import { ButtonEditarTable, ButtonExcluirTable, If, Modal, NenhumRegistroEncontrado } from 'components';
import { format, isValid } from 'date-fns';
import { useFormikContext, withFormik } from 'formik';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useState } from 'react';
import { ActionButtons } from './ActionButtons';
import ModalFormConfirmacaoPadrao from './ModalFormConfirmacaoPadrao';
import { INITIAL_VALUES } from './Util/constantes';

function ModalConfirmacaoPadraoView({ visible, onHide, handleSalvarConfirmacaoPadrao }) {
	const { values, dirty, initialValues, resetForm, setFieldValue } = useFormikContext();
	const [registroSelecionado, setRegistroSelecionado] = useState(null);
	const [isVisibleFormModalConfirmacao, setIsVisibleFormModalConfirmacao] = useState(false);

	function atualizarRegistro(dadosFormulario) {
		const dadosAtualizados = copiarObjeto(values.confirmacaoPadrao);

		if (dadosFormulario.id !== null) {
			const index = dadosAtualizados.findIndex((obj) => obj.id === dadosFormulario.id);

			if (index !== -1) {
				dadosAtualizados[index] = dadosFormulario;
			}
		} else if (dadosFormulario.tempId !== null) {
			const index = dadosAtualizados.findIndex((obj) => obj.tempId === dadosFormulario.tempId);

			if (index !== -1) {
				dadosAtualizados[index] = dadosFormulario;
			}
		}

		setFieldValue('confirmacaoPadrao', dadosAtualizados);
		setRegistroSelecionado(null);
		setIsVisibleFormModalConfirmacao(false);
	}

	function inserirRegistro(dadosFormulario) {
		const dadosAtualizados = [...(values.confirmacaoPadrao ?? []), dadosFormulario];
		setFieldValue('confirmacaoPadrao', dadosAtualizados);
		setRegistroSelecionado(null);
		setIsVisibleFormModalConfirmacao(false);
	}

	function excluirRegistro(dadosFormulario) {
		const dadosAtualizados = removerElementoPorId(values.confirmacaoPadrao, dadosFormulario, 'id');
		setRegistroSelecionado(null);
		setFieldValue('confirmacaoPadrao', dadosAtualizados);
	}

	function onEditarRegistro(row) {
		setRegistroSelecionado(row);
		setIsVisibleFormModalConfirmacao(true);
	}

	function renderRespostaEsperada(row) {
		if (!row.respostaEsperada) {
			return <p style={{ color: colors.vermelho }}>Nenhum valor informado</p>;
		}

		if (row.respostaTipo === 'SIM_NAO') {
			if (row.respostaEsperada === 'true') {
				return 'Sim';
			} else if (row.respostaEsperada === 'false') {
				return 'Não';
			}
		}

		if (row.respostaTipo === 'DATA' && isValid(new Date(row.respostaEsperada))) {
			return format(new Date(row.respostaEsperada), 'dd/MM/yyyy');
		}

		return row.respostaEsperada;
	}

	function renderOpcoes(row) {
		return (
			<div>
				<ButtonEditarTable onClick={() => onEditarRegistro(row)} />
				<ButtonExcluirTable onClick={() => excluirRegistro(row)} />
			</div>
		);
	}

	function novaConfirmacao() {
		setIsVisibleFormModalConfirmacao(true);
	}

	function cancelar() {
		if (dirty) {
			resetForm({ values: initialValues });
		} else {
			onHide();
		}
	}

	function salvar() {
		handleSalvarConfirmacaoPadrao(values.confirmacaoPadrao);
	}

	return (
		<>
			<Modal header="Cadastro de confirmação para assinatura" visible={visible} onHide={onHide}>
				<ActionButtons novaConfirmacao={novaConfirmacao} cancelar={cancelar} salvar={salvar} />
				<DataTable
					className="table"
					responsive
					value={values.confirmacaoPadrao}
					emptyMessage={<NenhumRegistroEncontrado message="Nenhuma confirmação encontrada" />}
				>
					<Column field="pergunta" header="Pergunta para confirmação" sortable />
					<Column
						field="respostaEsperada"
						header="Resposta esperada"
						body={(row) => renderRespostaEsperada(row)}
						sortable
					/>
					<Column body={(row) => renderOpcoes(row)} header="Ações" style={{ width: '7em' }} />
				</DataTable>
			</Modal>
			<If test={isVisibleFormModalConfirmacao}>
				<ModalFormConfirmacaoPadrao
					visible={isVisibleFormModalConfirmacao}
					registroSelecionado={registroSelecionado}
					onNovoClick={() => setRegistroSelecionado(null)}
					onHide={() => setIsVisibleFormModalConfirmacao(false)}
					excluirRegistro={(e) => excluirRegistro(e)}
					inserirRegistro={(e) => inserirRegistro(e)}
					atualizarRegistro={(e) => atualizarRegistro(e)}
				/>
			</If>
		</>
	);
}

const ModalConfirmacaoPadrao = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues(props) {
		if (props.values) {
			return { confirmacaoPadrao: props.values };
		}
		return INITIAL_VALUES;
	},

	handleSubmit: () => {},
})(ModalConfirmacaoPadraoView);

export { ModalConfirmacaoPadrao };
