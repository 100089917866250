import { AiFillCloseCircle } from 'react-icons/ai';
import If from '../../If';
import { colors } from 'Common';

function CardModulo({ title, subtitle, selected, onSelect, value, disabled }) {
	const divStyle = {
		borderRadius: '5px',
		border: '3px solid #c0c0c0',
		padding: '15px',
		height: '100%',
		cursor: 'pointer',
	};

	const divStyleSelected = {
		borderRadius: '5px',
		border: '4px solid rgb(0, 96, 149)',
		padding: '15px',
		height: '100%',
		cursor: 'pointer',
	};

	const divStyleDisabled = {
		opacity: '0.5',
		cursor: 'default',
	};

	const titleStyle = {
		color: 'rgb(49 49 49)',
		fontSize: '20px',
		display: 'flex',
		justifyContent: 'center',
		margin: '5px',
		textAlign: 'center',
	};

	const subtitleStyle = {
		color: 'rgb(123, 123, 123)',
		fontWeight: '600',
		margin: '5px',
		display: 'flex',
		justifyContent: 'center',
		textAlign: 'center',
	};

	const styleCloseIcon = {
		float: 'right',
		margin: '-30px -30px 0px -10px',
		fontSize: '25px',
		backgroundColor: 'white',
		color: colors.principal,
	};

	let style = selected ? divStyleSelected : divStyle;
	style = disabled ? { ...style, ...divStyleDisabled } : style;

	return (
		<div style={style} onClick={() => !disabled && onSelect && onSelect(value)}>
			<If test={selected}>
				<AiFillCloseCircle style={styleCloseIcon} />
			</If>
			<p style={titleStyle}>{title}</p>
			<p style={subtitleStyle}>{subtitle}</p>
		</div>
	);
}

export default CardModulo;
