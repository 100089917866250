import { useState } from 'react';
import { Modal, Grid } from 'components';
import './Styles/index.css';
import * as ajudasContaBancaria from '../../../Util/ajudasContaBancaria';
import { linkDocumentacao } from '../../../Util/ajudasContaBancaria';
import { selecionarBancoCorrespondenteDados } from '../../../Util/functions';
import { colors } from 'Common';

const styleDivs = {
	display: 'grid',
	alignItems: 'start',
	gridTemplateColumns: '150px 1fr',
	margin: '8px 0',
	marginBottom: '16px',
};

const styleTitle = {
	fontWeight: 'bold',
	justifySelf: 'flex-end',
	marginRight: '16px',
};

const styleInsideDiv = {
	display: 'flex',
};

const styleExample = {
	color: colors.principal,
	fontWeight: 'bold',
};

const styleLink = {
	color: colors.principal,
	fontWeight: 'bold',
	cursor: 'pointer',

	display: 'flex',
	flexDirection: 'row-reverse',
	margin: '16px 0 0 0',
};

function ModalAjudaContaBancaria(props) {
	const { visible, onHide, bancoSelecionado } = props;

	const [ajudaSelecionada] = useState(selecionarBancoCorrespondenteDados(bancoSelecionado, ajudasContaBancaria));

	function ItensAjuda() {
		let arr = Object.values(ajudaSelecionada);

		return (
			<div>
				{arr.map((item, index) => {
					return (
						<div style={styleDivs} key={`${item.titulo} - ${index}`}>
							<p style={styleTitle}>{item.titulo ? `${item.titulo}:` : ''}</p>
							<div>
								<p>{item.descricao ?? ''}</p>
								<div style={styleInsideDiv}>
									<p style={styleExample}>{item.exemplo ?? ''}</p>
								</div>
							</div>
						</div>
					);
				})}
				<div style={styleLink}>
					<a href={linkDocumentacao[bancoSelecionado?.value]} target="_blank">
						Documentação de preenchimento
					</a>
				</div>
			</div>
		);
	}

	return (
		<>
			<Modal header={`Exemplo de preenchimento - ${ajudaSelecionada.NOME}`} visible={visible} onHide={onHide}>
				<Grid style={{ display: 'block', padding: '8px' }}>
					<ItensAjuda />
				</Grid>
			</Modal>
		</>
	);
}

export default ModalAjudaContaBancaria;
