import { useEffect, useState } from 'react';
import { asyncBuscarStatusPix } from './Requests';
import { format } from 'date-fns';
import { colors, formatos, inserirMascara } from 'Common';
import { Badge, Button, Divider, If, Modal } from 'components';
import { ModalRecebimentoPix } from 'components/ModalRecebimentoPix';

function ModalDetalhesPix({ visible, onHide, container, lancamentoId, pixId, valor, openModalCobranca }) {
	const cores = {
		txtPendente: colors.principal,
		bgPendente: colors.azulLight,
		txtRecebido: colors.verde,
		bgRecebido: colors.verdeLight,
		txtExpirado: colors.vermelho,
		bgExpirado: colors.vermelhoLight,
	};
	const backgroundColor = {
		padding: '0.3rem 3rem 0.3rem 3rem ',
		borderRadius: '20px',
	};

	const styleDescription = {
		fontSize: '1.5rem',
		paddingTop: '0.5rem',
		paddingBottom: '0.5rem',
	};

	const [dadosPix, setDadosPix] = useState(null);
	const [modalCobrancaPixVisible, setModalCobrancaPixVisible] = useState(false);

	useEffect(() => {
		if (openModalCobranca) {
			setModalCobrancaPixVisible(true);
		}
		asyncBuscarStatusPix(pixId, ({ data: pix }) => {
			setDadosPix(pix);
		});
	}, []);

	function onHideModalCobranca(pixId) {
		asyncBuscarStatusPix(pixId, ({ data: pix }) => {
			setDadosPix(pix);
			setModalCobrancaPixVisible(false);
		});
	}

	return (
		<>
			<Modal
				header="Detalhes - Pix"
				styleModal={{ maxWidth: '500px', minHeight: '430px' }}
				visible={visible}
				onHide={onHide}
				container={container}
			>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						flexDirection: 'column',
						alignItems: 'center',
						paddingTop: '1rem',
						paddingBottom: '1rem',
					}}
				>
					{dadosPix?.status === 'RECEBIDO'
						? Badge(cores.txtRecebido, cores.bgRecebido, 'Recebido', backgroundColor, styleDescription)
						: dadosPix?.status === 'EXPIRADO'
							? Badge(cores.txtExpirado, cores.bgExpirado, 'Expirado', backgroundColor, styleDescription)
							: Badge(cores.txtPendente, cores.bgPendente, 'Pendente', backgroundColor, styleDescription)}
				</div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'start',
						flexDirection: 'column',
						alignItems: 'start',
					}}
				>
					<label
						style={{
							fontSize: '1rem',
							paddingTop: '0.5rem',
						}}
					>
						<label
							style={{
								fontWeight: '600',
							}}
						>
							Emissão:
						</label>
						{` ${dadosPix?.emissao ? format(new Date(dadosPix?.emissao), 'dd/MM/yyyy HH:mm:ss') : ''}`}
					</label>
					<label
						style={{
							fontSize: '1rem',
							paddingTop: '0.5rem',
						}}
					>
						<label
							style={{
								fontWeight: '600',
							}}
						>
							Conta:
						</label>
						{` ${dadosPix?.conta?.nome}`}
					</label>
					<label
						style={{
							fontSize: '1.5rem',
							paddingTop: '0.5rem',
							paddingBottom: '0.5rem',
							color:
								dadosPix?.status === 'RECEBIDO'
									? cores.txtRecebido
									: dadosPix?.status === 'EXPIRADO'
										? cores.txtExpirado
										: cores.txtPendente,
						}}
					>
						<b>{`Valor: R$ ${valor?.toFixed(2).split('.').join(',')}`}</b>
					</label>
					<Divider
						styleContainer={{
							marginLeft: '0rem',
						}}
					/>
					<If test={dadosPix?.status === 'RECEBIDO'}>
						<label
							style={{
								fontSize: '1rem',
								paddingTop: '2.0rem',
							}}
						>
							<label
								style={{
									fontWeight: '600',
								}}
							>
								Identificador:
							</label>
							{` ${dadosPix?.pagamentoId}`}
						</label>
						<label
							style={{
								fontSize: '1rem',
								paddingTop: '0.5rem',
							}}
						>
							<label
								style={{
									fontWeight: '600',
								}}
							>
								Data pagamento:
							</label>
							{` ${dadosPix?.dataPagamento ? format(new Date(dadosPix?.dataPagamento), 'dd/MM/yyyy HH:mm:ss') : ''}`}
						</label>
						<label
							style={{
								fontSize: '1rem',
								paddingTop: '0.5rem',
							}}
						>
							<label
								style={{
									fontWeight: '600',
								}}
							>
								Pagador:
							</label>
							{` ${
								dadosPix?.pagador?.length === 11
									? inserirMascara(dadosPix?.pagador, formatos.CPF)
									: inserirMascara(dadosPix?.pagador, formatos.CNPJ)
							}`}
						</label>
						<label
							style={{
								fontSize: '1rem',
								paddingTop: '0.5rem',
								fontWeight: '600',
							}}
						>
							{`Valor pago: R$ ${dadosPix?.valorPago?.toFixed(2).split('.').join(',')}`}
						</label>
					</If>
				</div>
				<If test={dadosPix?.status !== 'RECEBIDO'}>
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							flexDirection: 'column',
							alignItems: 'center',
							paddingTop: '0.5rem',
						}}
					>
						<label
							style={{
								fontSize: '1.2rem',
								paddingTop: '3.0rem',
								paddingBottom: '0.5rem',
								color: '#A9A9A9',
							}}
						>
							<b>{`Sem informações do pagamento`}</b>
						</label>
						<Button
							style={{
								marginTop: '3.2rem',
							}}
							className="step-listagem-novo"
							label={'Receber cobrança'}
							color="success"
							disabled={dadosPix?.status === 'EXPIRADO' && dadosPix?.tipo === 'COBRANCA'}
							icon="pi pi-qrcode"
							onClick={() => {
								setModalCobrancaPixVisible(true);
							}}
						/>
					</div>
				</If>
			</Modal>
			<If test={modalCobrancaPixVisible}>
				<ModalRecebimentoPix
					valor={valor}
					visible={modalCobrancaPixVisible}
					lancamentoId={lancamentoId}
					id={pixId}
					onHide={onHideModalCobranca}
					container={container}
					hideBackground={true}
				/>
			</If>
		</>
	);
}

export { ModalDetalhesPix };
