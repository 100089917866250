import { Component } from 'react';
import FormGroup from '../../../components/FormGroup/index';
import Card from '../../../components/cards/Card';
import { Checkbox } from 'primereact/checkbox';
import logo from '../../../layout/Images/logo_msys.svg';
import { mensagensDeValidacao } from '../../../Common/Constantes/mensagens';
import InputField from '../../../components/input/InputField';
import { fazerLogin, buscarDadosLoginLocalStorage } from '../../../Common/Autenticacao';
import { cardStyle, imgStyle, errorStyle, subTitleStyle, contentStyle } from '../Styles';
import { withFormik, Field } from 'formik';
import * as Yup from 'yup';
import { prompt } from '../../../components/Toast';
import { asyncRecuperarSenha } from './Requests';
import Col from '../../../components/Col';
import Button from '../../../components/Button';
import { withRouter } from 'react-router';
import { tratarMensagemErroLogin } from './Util';
import autoBind from 'react-autobind';
import Grid from '../../../components/Grid';
import { validarFormulario } from '../../Util';
import If from '../../../components/If';
import AutoProgressBar from '../../../components/Loading/AutoProgressBar';
import { Link } from 'react-router-dom';
import { buscarItemLocalStorage, removerItemLocalStorage } from '../../../Common/LocalStorage';
import { DADOSLOCALSTORAGE } from '../../../Common/Constantes/localStorage';
import { colors } from 'Common';

export class Login extends Component {
	constructor(props) {
		super(props);
		autoBind(this);

		this.state = {
			mensagemErro: '',
		};
	}

	componentDidMount() {
		const dadosLogin = buscarDadosLoginLocalStorage();
		if (dadosLogin) {
			this.props.setFieldValue('email', dadosLogin.email);

			if (dadosLogin.manterConectado) {
				this.props.setFieldValue('senha', dadosLogin.senha);
				setTimeout(() => this.fazerLogin(), 5);
			}
		}

		setTimeout(() => {
			document.getElementById('LoginInputEmail')?.focus();
		}, 500);
	}

	checarAplicacao() {
		let isDev = window.location.href.includes('local');

		if (window.location.href.includes('docsdigitais') && !isDev) {
			return '/registro?modulos=DOCUMENTOS';
		} else {
			return '/registro';
		}
	}

	async fazerLogin() {
		const { handleSubmit, values } = this.props;

		handleSubmit();
		if (await validarFormulario(this.props)) {
			await fazerLogin(
				values.email.toLowerCase(),
				values.senha,
				values.manterConectado,
				() => {
					const ultimaRota = buscarItemLocalStorage(DADOSLOCALSTORAGE.ultimaRotaNaoAutenticada);

					if (ultimaRota) {
						removerItemLocalStorage(DADOSLOCALSTORAGE.ultimaRotaNaoAutenticada);
						this.props.history.push(ultimaRota);
					} else {
						this.props.history.push('/');
					}
				},
				(e) => {
					this.setState({
						mensagemErro: tratarMensagemErroLogin(e.response),
					});
				},
				false
			);
		}
	}

	esqueciMinhaSenhaClick(e) {
		e.preventDefault();
		prompt(
			'Confirme seu e-mail',
			'Insira seu endereço de e-mail abaixo e enviaremos um link com as instruções.',
			this.props.values.email,
			async (evt, value) => {
				await asyncRecuperarSenha(value);
			}
		);
	}

	onKeyDownEmail(e) {
		if (e.key === 'Enter') {
			document.getElementById('input_senha').focus();
		}
	}

	onKeyDownSenha(e) {
		if (e.key === 'Enter') {
			this.fazerLogin();
		}
	}

	render() {
		const { values, setFieldValue } = this.props;

		return (
			<>
				<AutoProgressBar />
				<div style={contentStyle}>
					<Card style={cardStyle}>
						<If test={this.state.mensagemErro}>
							<div style={errorStyle}>
								<center>
									<h4
										style={{ margin: 0, color: '#751111' }}
										dangerouslySetInnerHTML={{ __html: this.state.mensagemErro }}
									></h4>
								</center>
							</div>
						</If>
						<div className="right-side p-g-12 p-lg-12" style={{ justifyContent: 'center', display: 'flex' }}>
							<img alt="Logo Microsys" style={imgStyle} src={logo} />
						</div>
						<div className="left-side p-g-12 p-lg-12">
							<h3 style={subTitleStyle}>Bem-vindo, por favor informe seus dados de acesso.</h3>

							<div className="content-section implementation">
								<FormGroup>
									<Grid>
										<Field
											id="LoginInputEmail"
											component={InputField}
											placeholder="E-mail"
											name="email"
											onKeyDown={(e) => this.onKeyDownEmail(e)}
										/>
										<Field
											id="input_senha"
											component={InputField}
											type="password"
											name="senha"
											placeholder="Senha"
											onKeyDown={(e) => this.onKeyDownSenha(e)}
										/>
										<Col style={{ paddingTop: '0px', paddingBottom: '0px' }}>
											<a
												href="²"
												onClick={this.esqueciMinhaSenhaClick}
												style={{ fontSize: '13px', color: colors.principal }}
											>
												Esqueci minha senha
											</a>
										</Col>
										<Col>
											<Checkbox
												name="manterConhectado"
												inputId="manterConhectado"
												checked={values.manterConectado}
												onChange={(e) => setFieldValue('manterConectado', e.checked)}
											/>
											<label htmlFor="manterConhectado" className="p-checkbox-label" style={{ fontWeight: 400 }}>
												Manter-me conectado
											</label>
										</Col>
									</Grid>
									<Button
										label="Entrar"
										style={{
											marginTop: '25px',
											height: '40px',
											width: '100%',
										}}
										onClick={this.fazerLogin}
									/>
									<div style={{ textAlign: 'center', marginTop: '8px', paddingBottom: '18px' }}>
										<span>Não possui conta?</span>
										<Link to={this.checarAplicacao}> Criar conta</Link>
									</div>
								</FormGroup>
							</div>
						</div>
					</Card>
				</div>
			</>
		);
	}
}

Login = withFormik({
	validateOnBlur: false,
	mapPropsToValues() {
		const dados = buscarDadosLoginLocalStorage();
		return {
			email: dados ? dados.email : '',
			senha: dados && dados.manterConectado ? dados.senha : '',
			manterConectado: dados ? dados.manterConectado : true,
		};
	},

	validationSchema: Yup.object().shape({
		email: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		senha: Yup.string()
			.nullable()
			.required(mensagensDeValidacao.OBRIGATORIO)
			.test('len', 'A senha deve ter no mínimo 8 caracteres', (val) => val && val.length >= 8),
	}),
	handleSubmit: () => {},
})(Login);

export default withRouter(Login);
