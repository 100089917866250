import { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import Col from '../../Col';
import {
	buscarDisabledDeAcordoComAsPermissoes,
	buscarHiddenDeAcordoComAsPermissoes,
	buscarTitleCampoDeAcordoComAsPermissoes,
} from '../../../Common/Autorizacao/ManipulacaoDeComponentes';
import { renderizarValidacao } from '../../../Common/TratamentoDeErro/validacoesDeCampos';
import { colors } from 'Common';

export function InternalTextArea(props) {
	const {
		name,
		placeholder,
		className,
		value,
		id,
		rows,
		cols,
		colId,
		sm,
		md,
		lg,
		xl,
		col,
		podeInserir,
		podeEditar,
		estadoCadastro,
		title,
		disabled,
		errors,
		touched,
		podeVisualizar,
		readOnly,
		maxLength,
		autoFocus,
		minLength,
		style,
		useOnBlurPerformatico = false,
	} = props;

	const [inputValue, setInputValue] = useState(null);

	const internalStyle = {
		fontFamily: 'Open Sans, Helvetica Neue, sans-serif',
		resize: 'none',
		width: '100%',
		fontSize: '14px',
		color: colors.cinzaDark,
		background: colors.branco,
		padding: '0.429em',
		border: '1px solid #ced4da',
		borderColor: errors && touched ? colors.vermelho : '#ced4da',
		appearance: 'none',
		borderRadius: '3px',
	};

	const desabilitar = buscarDisabledDeAcordoComAsPermissoes(podeInserir, podeEditar, estadoCadastro, disabled);

	useEffect(() => {
		if (useOnBlurPerformatico) {
			setInputValue(value);
		}
	}, [value]);

	function getColStyle() {
		if (buscarHiddenDeAcordoComAsPermissoes(props.podeVisualizar, props.hidden))
			return { display: 'none', ...props.colStyle };
		return props.colStyle;
	}

	function modificarOnChangeParaNull(event) {
		return {
			target: {
				value: null,
				name: event.target.name,
			},
		};
	}

	function onChange(event) {
		if (!event.target.value || event.target.value.length <= props.size) {
			if (event.target.value === '') {
				props.onChange(modificarOnChangeParaNull(event));
			} else if (useOnBlurPerformatico) {
				setInputValue(event.target.value);
			} else {
				props.onChange(event);
			}
		}
	}

	function getValue() {
		if (useOnBlurPerformatico) {
			return inputValue || value || '';
		} else {
			return props.value ? props.value : '';
		}
	}

	function montarLabel() {
		const { obrigatorio, label } = props;
		if (obrigatorio) {
			return (
				<label>
					{label} <b style={{ fontSize: '18px', lineHeight: '5px' }}> *</b>
				</label>
			);
		}
		return <label> {label} </label>;
	}

	function onBlur(e) {
		if (useOnBlurPerformatico) {
			props.onBlur(inputValue);
		} else {
			typeof props.onBlur === 'function' ? props.onBlur(e) : null;
		}
	}

	return (
		<Col id={colId} col={col} sm={sm} md={md} lg={lg} xl={xl} style={getColStyle()}>
			<label title={props.helpMessage}>{montarLabel()}</label>
			<textarea
				title={buscarTitleCampoDeAcordoComAsPermissoes(podeVisualizar, podeInserir, podeEditar, estadoCadastro, title)}
				onChange={onChange}
				disabled={desabilitar}
				name={name}
				rows={rows}
				cols={cols}
				style={{ ...internalStyle, ...style }}
				placeholder={placeholder}
				className={desabilitar ? className + ' p-disabled' : className}
				value={getValue()}
				onBlur={onBlur}
				id={id}
				readOnly={readOnly}
				maxLength={maxLength}
				minLength={minLength}
				autoFocus={autoFocus}
			/>
			{renderizarValidacao(errors, touched)}
		</Col>
	);
}

InternalTextArea.defaultProps = {
	value: '',
	autoResize: false,
	rows: 5,
	cols: 30,
	podeVisualizar: true,
	size: 9999999,
};

InternalTextArea.propTypes = {
	/** Estilo dO componente*/
	style: propTypes.object,
	/** Especifica o tipo do campo */
	type: propTypes.string,
	/** Label do componente */
	label: propTypes.string,
	/** Nome do componente */
	name: propTypes.string,
	/** Placeholder do componente */
	placeholder: propTypes.string,
	/** Nome da classe do componente */
	className: propTypes.string,
	/** Identificador do componente */
	id: propTypes.string,
	/** Valor do componente do componente */
	value: propTypes.any,
	/** Especifica os erros de validação que o componente possui (geralmente vindos do Yup)*/
	errors: propTypes.oneOfType([propTypes.string, propTypes.bool]),
	/** Especifica se o componente foi 'tocado'*/
	touched: propTypes.bool,
	/** Evento executado ao sair do campo*/
	onBlur: propTypes.any,
	/** Tamanho do campo em small devices*/
	sm: propTypes.string,
	/** Tamanho do campo em medium devices*/
	md: propTypes.string,
	/** Tamanho do campo em large devices*/
	lg: propTypes.string,
	/** Tamanho do campo em extra large devices*/
	xl: propTypes.string,
	/** Estado em que o cadastro se encontra*/
	estadoCadastro: propTypes.string,
	/** Diz se o usuário possui permissão de visualizar*/
	podeVisualizar: propTypes.bool,
	/** Diz se o usuário possui permissão de editar*/
	podeEditar: propTypes.bool,
	/** Diz se o usuário possui permissão de excluir*/
	podeInserir: propTypes.bool,
	/** Title do componente*/
	title: propTypes.string,
	/** Id da coluna*/
	colId: propTypes.string,
	/** Esconde o componente*/
	hidden: propTypes.bool,
	readOnly: propTypes.bool,
};
