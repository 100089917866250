import { useState, useEffect, useRef } from 'react';
import { Chart } from 'chart.js';
import { Card } from 'primereact/card';
import { asyncValorEstoquePorPeriodo } from './Requests';
import { ProgressBar } from 'primereact/progressbar';
import { formatarMonetario } from '../../../../../../../Common/Mascara';
import { format, parse } from 'date-fns';
import FalhaAoCarregarAsInformacoes from '../../../FalhaAoCarregarAsInformacoes';
import { Estoque } from '../../../../../../Util/constantes';
import usePrevious from '../../../../../../../Common/Hooks/usePrevious';
import useStateCallback from '../../../../../../../Common/Hooks/useStateCallback';
import { colors } from 'Common';

const cardHeader = {
	padding: '1rem 0px 0px 1rem',
	display: 'flex',
	justifyContent: 'space-between',
};

let graficoBalancoDiario;

export default function GraficoValorEstoquePorPeriodo(props) {
	const { dataInicial, dataFinal, atualizarGraficoNovamente, isMobile, dataAtualizacaoMenuLateral } = props;

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [data, setData] = useState([]);
	const [valorEstoque, setValorEstoque] = useStateCallback([]);
	const [valorEstoqueTotal, setValorEstoqueTotal] = useState(0);
	const graficoDeValorEstoquePorDia = useRef();
	const afterFirstRenderRef = useRef(false);
	const prevProps = usePrevious(props);

	useEffect(() => {
		carregarInformacoes();

		return () => {
			if (typeof graficoBalancoDiario !== 'undefined') graficoBalancoDiario.destroy();
		};
	}, [atualizarGraficoNovamente]);

	useEffect(() => {
		if (afterFirstRenderRef) {
			if (prevProps?.dataInicial !== dataInicial || prevProps?.dataFinal !== dataFinal) {
				carregarInformacoes();
			}
			if (prevProps?.atualizarGraficoNovamente !== atualizarGraficoNovamente) {
				carregarInformacoes(false);
			}
		} else afterFirstRenderRef = true;
	}, [dataInicial, dataFinal]);

	useEffect(() => {
		asyncValorEstoquePorPeriodo(
			dataInicial,
			dataFinal,
			({ data: valorEstoquePeriodo }) => {
				let dataRequest = [];
				let valorEstoqueRequest = [];

				valorEstoquePeriodo.forEach((element) => {
					dataRequest.push(element.data);
					valorEstoqueRequest.push(element.valorEstoque);
				});
				setData(dataRequest);
				setValorEstoque(valorEstoqueRequest, setValorEstoqueTotal(valorEstoqueRequest.pop()));
				setLoading(false);
				setError(false);
			},
			() => {
				setLoading(false);
				setError(true);
			}
		);
	}, [loading, dataAtualizacaoMenuLateral]);

	useEffect(() => {
		buildChart();
	}, [valorEstoque]);

	function carregarInformacoes() {
		setLoading(true);
		setError(false);
	}

	function buildChart() {
		if (!graficoDeValorEstoquePorDia.current) {
			return;
		}
		const myChartRef = graficoDeValorEstoquePorDia.current.getContext('2d');

		if (typeof graficoBalancoDiario !== 'undefined') graficoBalancoDiario.destroy();

		graficoBalancoDiario = new Chart(myChartRef, {
			type: 'line',
			data: {
				labels: data,
				datasets: [
					{
						label: 'Valor do estoque no dia',
						data: valorEstoque,
						borderColor: Estoque.cores.verde,
						backgroundColor: Estoque.cores.preto,
					},
				],
			},
			options: {
				responsive: true,
				maintainAspectRatio: false,
				aspectRatio: 1,
				animation: {
					animateScale: true,
					animateRotate: true,
				},
				plugins: {
					datalabels: {
						color: colors.brancoTransparente,
					},
					legend: {
						display: false,
						position: 'bottom',
						labels: {
							usePointStyle: true,
						},
					},
					tooltip: {
						callbacks: {
							title: (tooltipItem) => {
								return `Data: ${format(parse(tooltipItem[0].label, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')}`;
							},
							afterTitle: (tooltipItem) => {
								return `Valor: ${formatarMonetario(tooltipItem[0].raw)}`;
							},
							label: (tooltipItem) => {
								const label = tooltipItem.dataset.label;
								const valor = formatarMonetario(tooltipItem.raw);
								return `${label}: ${valor}`;
							},
						},
					},
				},
				scales: {
					x: {
						ticks: {
							callback: function (value) {
								return format(parse(this.getLabelForValue(value), 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy');
							},
						},
					},
					y: {
						beginAtZero: true,
						ticks: {
							callback: (value) => formatarMonetario(value),
						},
					},
				},
			},
		});
	}

	function getHeaderCard() {
		return (
			<div style={cardHeader}>
				<label
					title="Lista o valor do estoque em cada dia. Passando o mouse por cima dos pontos do gráfico é possível visualizar o valor no dia"
					style={{ fontSize: '17px', color: colors.cinzaDark }}
				>
					{isMobile ? 'Valor do estoque por período' : 'Valor do estoque ao longo do período'}
				</label>
			</div>
		);
	}

	if (loading) {
		return (
			<Card header={getHeaderCard()} style={{ borderRadius: '5px', padding: '0px 1rem 1rem 1rem' }}>
				<ProgressBar mode="indeterminate" style={{ height: '3px' }} />
			</Card>
		);
	} else if (error) {
		return (
			<Card header={getHeaderCard()} style={{ borderRadius: '5px', height: '100%', padding: '0px 1rem 1rem 1rem' }}>
				<FalhaAoCarregarAsInformacoes />
			</Card>
		);
	} else {
		return (
			<Card className="card-canvas-dashboard-financas" header={getHeaderCard()} style={{ borderRadius: '5px' }}>
				<div>
					<canvas id="graficoDeValorEstoquePorDia" ref={graficoDeValorEstoquePorDia} style={{ maxWidth: '100%' }} />
				</div>

				<div
					style={{
						display: 'flex',
						justifyContent: 'flex-end',
						alignItems: 'center',
						padding: '7px',
						fontWeight: 'bold',
					}}
				>
					Valor atual do estoque: &nbsp;
					<span style={{ fontSize: '17px', color: `${Estoque.cores.azul}` }}>
						{formatarMonetario(valorEstoqueTotal)}
					</span>
				</div>
			</Card>
		);
	}
}
