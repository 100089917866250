import { tipoCampos } from 'components';
import { format, formatISO } from 'date-fns';
import { getMunicipioTomador } from './funtions';
import { colors, gerarUUID } from 'Common';

export const COLORS = {
	PENDENTE: {
		lightColor: colors.azulLight,
		strongColor: colors.azul,
	},
	FINALIZADO: {
		lightColor: colors.verdeLight,
		strongColor: colors.verde,
	},
	CANCELADO: {
		lightColor: colors.vermelhoLight,
		strongColor: colors.vermelho,
	},
	EXPIRADO: {
		lightColor: '#e0e0e0',
		strongColor: '#000000de',
	},
};

export const CLASSNAME_TABVIEW_COLOR = {
	PENDENTE: { class: 'tab-view-os-pendente', color: colors.azul },
	FINALIZADO: { class: 'tab-view-os-finalizado', color: colors.verde },
	CANCELADO: { class: 'tab-view-os-cancelado', color: colors.vermelho },
};

export const SELECTABLE_CARDS = {
	PENDENTE: 'PENDENTE',
	FINALIZADO: 'FINALIZADO',
	CANCELADO: 'CANCELADO',
};

export const SITUACAO_ORDEM_SERVICO = {
	PENDENTE: 'PENDENTE',
	FINALIZADO: 'FINALIZADO',
	CANCELADO: 'CANCELADO',
};

export const ORDEM_SERVICO_ORCAMENTO_SITUACAO = {
	NAO_ENVIADO: 'NAO_ENVIADO',
	AGUARDANDO_APROVACAO: 'AGUARDANDO_APROVACAO',
	APROVADO: 'APROVADO',
	REJEITADO: 'REJEITADO',
};

export const CONDICOES_PAGAMENTO = {
	A_VISTA: 'A_VISTA',
	A_PRAZO: 'A_PRAZO',
	SEM_PAGAMENTO: 'SEM_PAGAMENTO',
};

export const OPTIONS_CONDICAO_PAGAMENTO = [
	{ label: 'À vista', value: CONDICOES_PAGAMENTO.A_VISTA },
	{ label: 'A prazo', value: CONDICOES_PAGAMENTO.A_PRAZO },
	{ label: 'Sem pagamento', value: CONDICOES_PAGAMENTO.SEM_PAGAMENTO },
];

export const OPTIONS_FORMA_PAGAMENTO = [
	{ label: 'Dinheiro', value: 'DINHEIRO' },
	{ label: 'Cheque', value: 'CHEQUE' },
	{ label: 'Cartão de crédito', value: 'CARTAO_CREDITO' },
	{ label: 'Cartão de débito', value: 'CARTAO_DEBITO' },
	{ label: 'Crédito na loja', value: 'CREDITO_LOJA' },
	{ label: 'Vale alimentação', value: 'VALE_ALIMENTACAO' },
	{ label: 'Vale refeição', value: 'VALE_REFEICAO' },
	{ label: 'Vale presente', value: 'VALE_PRESENTE' },
	{ label: 'Vale combustível', value: 'VALE_COMBUSTIVEL' },
	{ label: 'Boleto bancário', value: 'BOLETO_BANCARIO' },
	{ label: 'Sem Pagamento', value: 'SEM_PAGAMENTO' },
	{ label: 'Depósito bancário', value: 'DEPOSITO_BANCARIO' },
	{ label: 'Pix - Pagamento instantâneo', value: 'PAGAMENTO_INSTANTANEO_PIX' },
	{
		label: 'Transferência bancária, Carteira digital',
		value: 'TRANFERENCIA_BANCARIA_CARTEIRA_DIGITAL',
	},
	{
		label: 'Programa de fidelidade, Cashback, Crédito virtual',
		value: 'PROGRAMA_FIDELIDADE_CASHBACK_CREDITO_VIRTUAL',
	},
];

export const CONTA_RECEBER_STATUS = {
	NAO_RECEBIDA: 'NAO_RECEBIDA',
	PARCIALMENTE_RECEBIDA: 'PARCIALMENTE_RECEBIDA',
	RECEBIDA: 'RECEBIDA',
};

export const OPTIONS_FILTRO_AVANCADO_ORDEM_SERVICO = [
	{ label: 'Número', name: 'numero', type: tipoCampos.INTEGER },
	{
		label: 'Situação',
		name: 'situacao',
		type: tipoCampos.SELECT,
		optionSelect: [
			{ label: 'Pendente', value: 'PENDENTE' },
			{ label: 'Finalizado', value: 'FINALIZADO' },
			{ label: 'Cancelado', value: 'CANCELADO' },
		],
	},
	{ label: 'Valor total', name: 'valorTotal', type: tipoCampos.DECIMAL },
];

export const DEFAULT_TOTAIS = {
	valorTotal: 0,
	totalServicos: {
		id: null,
		valorBruto: 0,
		tipoDesconto: 'VALOR',
		desconto: 0,
		valorLiquido: 0,
		baseIss: 0,
		valorIss: 0,
		baseIr: 0,
		valorIr: 0,
		baseCsll: 0,
		valorCsll: 0,
		baseInss: 0,
		valorInss: 0,
		basePis: 0,
		valorPis: 0,
		baseCofins: 0,
		valorCofins: 0,
	},
	totalProdutos: {
		id: null,
		valorBruto: 0,
		frete: 0,
		seguro: 0,
		acessorias: 0,
		tipoDesconto: 'VALOR',
		desconto: 0,
		valorLiquido: 0,
		baseIcms: 0,
		valorIcms: 0,
		baseSt: 0,
		valorSt: 0,
		baseIpi: 0,
		valorIpi: 0,
		basePis: 0,
		valorPis: 0,
		baseCofins: 0,
		valorCofins: 0,
	},
};

export const CAMPOS_TOTAIS = {
	DESCONTO: 'desconto',
	SEGURO: 'seguro',
	ACESSORIAS: 'acessorias',
	FRETE: 'frete',
};

export const ORIGEM_PRODUTO = [
	{
		label: '0 - Nacional, exceto as indicadas nos código 3, 4, 5 e 8',
		value: 'NACIONAL',
	},
	{
		label: '1 - Estrangeira - Importação direta, exceto a indicada no código 6',
		value: 'ESTRANGEIRA',
	},
	{
		label: '2 - Estrangeira - Adquirida no mercado interno, exceto a indicada no código 7',
		value: 'ESTRANGEIRA_ADQUIRIDA_MERCADO_INTERNO',
	},
	{
		label: '3 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 40% e inferior ou igual a 70%',
		value: 'NACIONAL_IMPORTACAO_ENTRE_40_E_70_PORCENTO',
	},
	{
		label:
			'4 - Nacional, cuja produção tenha sido feita em conformidade com os processos produtivos básicos de que tratam as legislações citadas nos Ajustes',
		value: 'NACIONAL_COM_PROCESSO_PRODUTIVO',
	},
	{
		label: '5 - Nacional, mercadoria ou bem com Conteúdo de Importação inferior ou igual a 40%',
		value: 'NACIONAL_IMPORTACAO_INFERIOR_40_PORCENTO',
	},
	{
		label: '6 - Estrangeira - Importação direta, sem similar nacional, constante em lista da CAMEX e gás natural',
		value: 'ESTRANGEIRA_IMPORTACAO_DIRETA',
	},
	{
		label:
			'7 - Estrangeira - Adquirida no mercado interno, sem similar nacional, constante em lista da CAMEX e gás natural',
		value: 'ESTRANGEIRA_ADQUIR_MERCADO_INTERNO_SEM_SIMILAR_NAC',
	},
	{
		label: '8 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 70%',
		value: 'NACIONAL_IMPORTACAO_SUPERIOR_70_PORCENTO',
	},
];

export const HELP_MESSAGES = {
	valor: 'Valor pago. Este valor será debitado na conta selecionada no campo "Conta"',
	descricao: 'Breve descrição da movimentação',
	data: 'Data em que a movimentação ocorreu',
	conta:
		'Conta de destino do valor da movimentação. Ao salvar, a movimentação irá gerar um débito na conta selecionada',
	categoria: 'Categoria financeira da ordem. Útil para visualização das receitas por categoria no dashboard financeiro',
	pessoa: 'Pessoa que originou a movimentação',
	observacao: 'Observações gerais da movimentação',
};

export const INITIAL_VALUE = {
	id: null,
	numero: null,
	situacao: 'PENDENTE',
	cliente: null,
	dataAbertura: format(new Date(), 'yyyy-MM-dd'),
	horaAbertura: format(new Date(), 'HH:mm'),
	dataFechamento: null,
	horaFechamento: null,
	objetoServico: null,
	operacaoFiscal: null,
	veiculoKmAtual: null,
	tecnico: null,
	problemaRelatado: null,
	servicos: [],
	produtos: [],
	nfse: null,
	nfe: null,
	situacaoOrcamento: ORDEM_SERVICO_ORCAMENTO_SITUACAO.NAO_ENVIADO,
	totalServicos: {
		valorBruto: 0,
		tipoDesconto: 'VALOR',
		desconto: 0,
		valorLiquido: 0,
		baseIss: 0,
		valorIss: 0,
		baseIr: 0,
		valorIr: 0,
		baseCsll: 0,
		valorCsll: 0,
		baseInss: 0,
		valorInss: 0,
		basePis: 0,
		valorPis: 0,
		baseCofins: 0,
		valorCofins: 0,
	},
	totalProdutos: {
		valorBruto: 0,
		frete: 0,
		seguro: 0,
		acessorias: 0,
		tipoDesconto: 'VALOR',
		desconto: 0,
		valorLiquido: 0,
		baseIcms: 0,
		valorIcms: 0,
		baseSt: 0,
		valorSt: 0,
		baseIpi: 0,
		valorIpi: 0,
		basePis: 0,
		valorPis: 0,
		baseCofins: 0,
		valorCofins: 0,
	},
	valorTotal: 0,
	pagamentos: [
		{
			id: null,
			tempKey: gerarUUID(),
			sequencial: 1,
			condicaoPagamento: null,
			conta: null,
			formaPagamento: null,
			categoria: null,
			quantidadeParcelas: 0,
			valor: 0,
			valorRecebido: 0,
			parcelas: [],
		},
	],
	informacoesComplementares: {
		vendedor: null,
		setor: null,
		servico: {
			informacoesComplementares: null,
			observacaoInterna: null,
			municipio: getMunicipioTomador(),
		},
		produto: {
			informacoesComplementares: null,
			observacaoInterna: null,
		},
	},
};

export const INITIAL_COMPARTILHAR_POR_EMAIL = {
	id: null,
	idOrdem: null,
	data: formatISO(new Date()),
	valor: null,
	multa: 0,
	juros: 0,
	desconto: 0,
	conta: null,
};

export const INITIAL_RECEBIMENTO = {
	id: null,
	idOrdem: null,
	data: formatISO(new Date()),
	valor: null,
	multa: 0,
	juros: 0,
	desconto: 0,
	conta: null,
};

export const TAB_ITENS = {
	SERVICOS: 0,
	PRODUTOS: 1,
};
