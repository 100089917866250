import { colors, formatarMonetario, mensagensDeValidacao } from 'Common';
import Button from 'components/Button';
import ButtonCancelar, { estadosBotaoCancelar } from 'components/Button/ButtonCancelar';
import Divider from 'components/Divider';
import Form from 'components/Form';
import FormActions from 'components/FormActions';
import FormContent from 'components/FormContent';
import Grid from 'components/Grid';
import Modal from 'components/Modal';
import Dropdown from 'components/select/Dropdown';
import { Field, useFormikContext } from 'formik';
import { useState } from 'react';
import { connect } from 'react-redux';
import { TabViewParcelas } from '../TabViewParcelas';

function ModalParcelasImpl({
	idOrigem,
	values,
	visible,
	onHide,
	indexPagamento,
	urls,
	disabledFields,
	optionsQuantidadeParcelas,
	informacoesPermissoes,
	calculateValorParcelas,
	onChangeQuantidadeParcelas,
	onChangeVencimentoParcelas,
	onChangeValorParcelas,
	onBlurValorParcelas,
	onChangeFormaPagamento,
	onChangeConta,
	onChangeDescricao,
	onBlurDescricaoParcelas,
	onRemoveParcela,
	hideTabFinanceiro,
	financeiroTipo,
	isMobile,
	isTablet,
	isLessHd,
	classNameTabView,
	color,
	favoritos,
}) {
	const { errors, setFieldValue, setErrors } = useFormikContext();

	const [initialParcelas] = useState(values);
	const [wasEdited, setWasEdited] = useState(false);

	function handleClickCancelar() {
		if (wasEdited) {
			setFieldValue(`[${indexPagamento}]`, initialParcelas);
			setWasEdited(false);
		} else {
			onHide();
		}
	}

	async function handleClickSalvar() {
		if (validate()) {
			onHide();
		}
	}

	function validate() {
		const errorsPagamentos = [];

		if (!values.quantidadeParcelas?.value) {
			errorsPagamentos[indexPagamento] = { quantidadeParcelas: mensagensDeValidacao.OBRIGATORIO };
		}

		if (errorsPagamentos.length > 0) {
			setErrors(errorsPagamentos);
			return false;
		}

		return true;
	}

	function handleChangeQuantidadeParcelas(e) {
		onChangeQuantidadeParcelas(e);
		setWasEdited(true);
	}

	return (
		<Modal
			header="Parcelas"
			visible={visible}
			onHide={onHide}
			closeOnEsc={false}
			styleModal={{
				maxWidth: isMobile || isTablet || isLessHd ? '98%' : '65%',
			}}
		>
			<Form>
				<FormActions>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							paddingBottom: '10px',
							flexWrap: 'wrap',
						}}
					>
						<ButtonCancelar
							estadoBotao={wasEdited ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
							onClick={handleClickCancelar}
						/>
						<Button
							className="p-button-success"
							label="Salvar"
							icon="fa fa-check"
							onClick={handleClickSalvar}
							disabled={disabledFields}
						/>
					</div>
				</FormActions>
				<FormContent>
					<Grid>
						<Field
							sm="6"
							md="4"
							lg="4"
							xl="4"
							component={Dropdown}
							label="Qtd. parcelas"
							name="quantidadeParcelas"
							helpMessage="Quantidade de parcelas que serão geradas"
							obrigatorio
							value={values.quantidadeParcelas}
							showClear={false}
							options={optionsQuantidadeParcelas}
							onChange={handleChangeQuantidadeParcelas}
							disabled={disabledFields}
							touched
							errors={errors[indexPagamento]?.quantidadeParcelas}
							useFormErrors={false}
							{...informacoesPermissoes}
						/>
						<div
							style={{
								fontSize: '24px',
								fontWeight: 'bold',
								color: color,
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								paddingLeft: isMobile || isTablet ? '12px' : '0px',
								marginTop: isMobile || isTablet ? '0px' : '28px',
								marginLeft: isMobile || isTablet ? '0px' : '8px',
							}}
						>
							Total a prazo: {formatarMonetario(values.valor)}
							{errors.valor && (
								<p
									style={{
										fontSize: '12px',
										color: colors.vermelho,
										margin: '0px',
										justifyContent: 'flex-end',
										display: 'flex',
									}}
								>
									{errors.valor}
								</p>
							)}
						</div>
						<Divider styleContainer={{ marginTop: '0px', marginBottom: '0px' }} />
						<TabViewParcelas
							idOrigem={idOrigem}
							values={values}
							urls={urls}
							disabledFields={disabledFields}
							calculateValorParcelas={calculateValorParcelas}
							onChangeVencimentoParcelas={onChangeVencimentoParcelas}
							onChangeValorParcelas={onChangeValorParcelas}
							onChangeFormaPagamento={onChangeFormaPagamento}
							onBlurValorParcelas={onBlurValorParcelas}
							onChangeConta={onChangeConta}
							onChangeDescricao={onChangeDescricao}
							onBlurDescricaoParcelas={onBlurDescricaoParcelas}
							onRemoveParcela={onRemoveParcela}
							setWasEdited={setWasEdited}
							isModal
							hideTabFinanceiro={hideTabFinanceiro}
							financeiroTipo={financeiroTipo}
							indexPagamento={indexPagamento}
							classNameTabView={classNameTabView}
							informacoesPermissoes={informacoesPermissoes}
							isTablet={isTablet}
							isMobile={isMobile}
							isLessHd={isLessHd}
							favoritos={favoritos}
							color={color}
						/>
					</Grid>
				</FormContent>
			</Form>
		</Modal>
	);
}

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
	isTablet: state.dispositivo.isTablet,
	isLessHd: state.dispositivo.isLessHd,
});

export const ModalParcelas = connect(mapStateToProps)(ModalParcelasImpl);
