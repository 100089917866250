import { colors } from 'Common';
import { alert } from 'components';
import Dropzone from 'react-dropzone';
import { AiOutlineCloudUpload } from 'react-icons/ai';

export default function SecaoUploadArquivo({ onAdicionar, disabled }) {
	function onUpload(files, error) {
		if (error) {
			alert(
				'Problema ao adicionar o documento',
				'Só são permitidos arquivos do tipo PDF e com tamanho máximo de 25 MB'
			);
		} else {
			onAdicionar(files[0]);
		}
	}

	return (
		<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
			<section
				className="dropzone"
				style={{
					margin: '10px',
					border: '2px dashed #A4A4A4',
					borderRadius: '10px',
					cursor: !disabled ? 'pointer' : 'not-allowed',
					opacity: !disabled ? '1' : '0.5',
				}}
			>
				<Dropzone
					multiple={false}
					accept="application/pdf"
					onDropAccepted={(e) => onUpload(e, false)}
					onDropRejected={(e) => onUpload(e, true)}
					maxSize={25000000}
					disabled={disabled}
				>
					{({ getRootProps, getInputProps }) => (
						<div
							{...getRootProps()}
							style={{
								height: '100%',
								width: '100%',
								padding: '10px',
								display: 'flex',
								justifyContent: 'center',
								flexDirection: 'column',
								alignItems: 'center',
							}}
						>
							<input {...getInputProps()} />
							<AiOutlineCloudUpload size={50} color={`${colors.principal}`} />
							<div style={{ color: colors.principal, fontSize: '16px', fontWeight: 'bold' }}>
								Arraste e solte o documento aqui
							</div>
							<div style={{ fontSize: '13px', color: '#565656' }}>
								Serão aceitos arquivos do tipo PDF com no máximo 25 MB
							</div>
						</div>
					)}
				</Dropzone>
			</section>
		</div>
	);
}
