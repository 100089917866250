import { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, withFormik } from 'formik';
import * as Yup from 'yup';

import {
	ButtonCancelar,
	estadosBotaoCancelar,
	ButtonSalvar,
	estadosBotaoSalvar,
	ButtonExcluirTable,
	Form,
	FormActions,
	FormContent,
	Grid,
	Modal,
	SingleSelectOperacaoFiscal,
	SingleSelectTributacaoEstadual,
	Divider,
	ButtonAdicionarItem,
} from 'components';
import { colors, mensagensDeValidacao } from 'Common';

import { validarFormulario } from '../../../../../../Util';

import './Styles/index.css';

const buttonAdicionarStyle = {
	background: 'none',
	border: 'none',
	fontWeight: 'bold',
	boxShadow: 'none',
	color: colors.verde,
	marginTop: '0.7rem',
	paddingLeft: '0',
};

function ModalOperacoesTributacoesView(props) {
	const {
		visible,
		onHide,
		setFieldValueForm,
		dirty,
		values,
		setFieldValue,
		tributacoesNcm,
		permissoes,
		idURL,
		isMobile,
	} = props;

	const [isTouched, setIsTouched] = useState(false);
	const [hideBackgroundNcm, setHideBackgroundNcm] = useState(false);

	const estadoBotaoCancelar = dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR;
	const bloqueiaEditarCampos = Boolean(!permissoes.podeEditar && idURL);
	const bloqueiaExclusaoVinculo =
		values.tributacoes?.length === 1 &&
		((values.tributacoes[0]?.operacaoFiscal?.value === null &&
			values.tributacoes[0]?.tributacaoEstadual?.value === null) ||
			(typeof values.tributacoes[0]?.operacaoFiscal?.value === 'undefined' &&
				typeof values.tributacoes[0]?.tributacaoEstadual?.value === 'undefined'));

	useEffect(() => {
		if (tributacoesNcm?.length) {
			setFieldValue(
				'tributacoes',
				Array.from(tributacoesNcm).sort((a, b) => {
					if (a.operacaoFiscal.label > b.operacaoFiscal.label) {
						return 1;
					}
					if (a.operacaoFiscal.label < b.operacaoFiscal.label) {
						return -1;
					}
					return 0;
				})
			);
		} else {
			setFieldValue('tributacoes', [{ label: null, value: null }]);
		}

		if (!visible) {
			props.handleReset();
		}
	}, [visible]);

	function cancelar() {
		if (estadoBotaoCancelar === estadosBotaoCancelar.VOLTAR) {
			setFieldValue('tributacoes', []);
			onHide();
		} else {
			props.handleReset();
		}
	}

	async function confirmar() {
		setIsTouched(true);

		if (await validarFormulario(props)) {
			const tributacoesNcm = values.tributacoes.filter((item) => Object.keys(item).length > 0);

			setFieldValueForm('tributacoes', tributacoesNcm);
			onHide();
		}
	}

	function addTributacao() {
		setFieldValue('tributacoes', [...values.tributacoes, {}]);
	}

	function removeTributacao(tributacao) {
		let result = [{}];
		const tributacoesNcm = values.tributacoes.filter((item) => item !== tributacao);
		if (tributacoesNcm?.length) {
			result = tributacoesNcm;
		}

		setFieldValue('tributacoes', result);

		if (!tributacoesNcm?.length) {
			setValueOperacao({}, 0);
			setValueTributacao({}, 0);
		}
	}

	function setValueOperacao(event, key) {
		const tributacao = values.tributacoes;
		values.tributacoes.forEach((value, index) => {
			if (index === key) {
				if (typeof event?.registro !== 'undefined') {
					tributacao[index] = {
						operacaoFiscal: {
							label: `${event.registro.codigo} - ${event.registro.descricao}`,
							value: event.registro.id,
						},
						situacaoOperacao: event.registro.situacao,
						tributacaoEstadual: value.tributacaoEstadual,
						situacaoTributacao: value.situacaoTributacao,
					};
				} else {
					tributacao[index] = {
						operacaoFiscal: null,
						situacaoOperacao: null,
						tributacaoEstadual: value.tributacaoEstadual,
						situacaoTributacao: value.situacaoTributacao,
					};
				}
			}
		});
		setFieldValue('tributacoes', Array.from(tributacao));
	}

	function setValueTributacao(event, key) {
		const tributacao = values.tributacoes;
		values.tributacoes.forEach((value, index) => {
			if (index === key) {
				if (typeof event?.registro !== 'undefined') {
					tributacao[index] = {
						operacaoFiscal: value.operacaoFiscal,
						situacaoOperacao: value.situacaoOperacao,
						tributacaoEstadual: {
							label: `${event.registro.codigo} - ${event.registro.descricao}`,
							value: event.registro.id,
						},
						situacaoTributacao: event.registro.situacao,
					};
				} else {
					tributacao[index] = {
						operacaoFiscal: value.operacaoFiscal,
						situacaoOperacao: value.situacaoOperacao,
						tributacaoEstadual: null,
						situacaoTributacao: null,
					};
				}
			}
		});
		setFieldValue('tributacoes', Array.from(tributacao));
	}

	return (
		<Modal
			header="Incluir tributação"
			onHide={onHide}
			visible={visible}
			styleModal={{ maxHeight: '31rem' }}
			hideBackground={hideBackgroundNcm}
		>
			<Form>
				<FormActions>
					<ButtonCancelar onClick={cancelar} estadoBotao={estadoBotaoCancelar} />
					<ButtonSalvar onClick={confirmar} estadoBotao={estadosBotaoSalvar.CONFIRMAR} disabled={!dirty} />
				</FormActions>
				<FormContent>
					<h3>Tributações a serem incluídas</h3>
					<Grid className="grid-tributacoes" style={{ overflowY: 'scroll', maxHeight: '18.2rem' }}>
						{values.tributacoes?.length
							? values.tributacoes?.map((tributacao, index) => {
									const { operacaoFiscal, tributacaoEstadual } = tributacao;
									return (
										<div
											key={`${operacaoFiscal?.value} ${tributacaoEstadual?.value} ${index}`}
											style={{ display: 'flex', width: '100%', paddingLeft: '0.5rem' }}
										>
											<Grid style={{ width: '97%' }}>
												<Field
													sm="12"
													md="6"
													lg="6"
													xl="6"
													component={SingleSelectOperacaoFiscal}
													name="operacaoFiscal"
													label="Operação fiscal"
													value={operacaoFiscal}
													index={index}
													onChange={(event) => setValueOperacao(event, index)}
													disabled={bloqueiaEditarCampos}
													errors={
														props.errors && props.errors.tributacoes?.length > 0
															? props.errors.tributacoes[index]?.operacaoFiscal
															: null
													}
													touched={isTouched}
													isMobile={isMobile}
													setHideBackground={setHideBackgroundNcm}
												/>
												<Field
													sm="12"
													md="6"
													lg="6"
													xl="6"
													component={SingleSelectTributacaoEstadual}
													name="tributacaoEstadual"
													value={tributacaoEstadual}
													index={index}
													onChange={(event) => setValueTributacao(event, index)}
													disabled={bloqueiaEditarCampos}
													errors={
														props.errors && props.errors.tributacoes?.length > 0
															? props.errors.tributacoes[index]?.tributacaoEstadual
															: null
													}
													touched={isTouched}
													isMobile={isMobile}
													setHideBackground={setHideBackgroundNcm}
												/>
												{isMobile && index !== values.tributacoes.length - 1 ? (
													<Divider
														styleLine={{ opacity: '1', color: 'rgb(177, 177, 177)' }}
														styleContainer={{
															marginBottom: '1.2rem',
														}}
													/>
												) : null}
											</Grid>
											<div
												style={{
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													paddingTop: '0.5rem',
													paddingLeft: '0.5rem',
													paddingRight: values.tributacoes?.length > 5 ? '0.5rem' : '0',
													paddingBottom:
														isTouched &&
														props.errors &&
														props.errors.tributacoes?.length > 0 &&
														props.errors.tributacoes[index]
															? '1rem'
															: '0',
													marginBottom: isMobile && index !== values.tributacoes.length - 1 ? '2.5rem' : '0',
												}}
											>
												<Field
													sm="12"
													md="1"
													lg="1"
													xl="1"
													component={ButtonExcluirTable}
													onClick={() => removeTributacao(tributacao)}
													disabled={bloqueiaEditarCampos || bloqueiaExclusaoVinculo}
												/>
											</div>
										</div>
									);
								})
							: null}
					</Grid>
					<ButtonAdicionarItem
						label="Adicionar tributação"
						style={{ ...buttonAdicionarStyle }}
						onClick={addTributacao}
						disabled={bloqueiaEditarCampos}
					/>
				</FormContent>
			</Form>
		</Modal>
	);
}

const ModalOperacoesTributacoes = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues(props) {
		if (props.tributacoesNcm?.length) {
			return {
				tributacoes: Array.from(props.tributacoesNcm).sort((a, b) => {
					if (a.operacaoFiscal.label > b.operacaoFiscal.label) {
						return 1;
					}
					if (a.operacaoFiscal.label < b.operacaoFiscal.label) {
						return -1;
					}
					return 0;
				}),
			};
		} else {
			return { tributacoes: [{ label: null, value: null }] };
		}
	},

	validate(values) {
		const errors = {
			tributacoes: [],
		};
		let errorsWithoutUndefined = [];

		values.tributacoes.forEach((value) => {
			let countOperacao = 0;
			let lastIndex = null;

			errors.tributacoes.push(undefined);

			values.tributacoes.forEach((tributacao, index) => {
				if (
					value.operacaoFiscal?.value === tributacao.operacaoFiscal?.value &&
					typeof value.operacaoFiscal?.value !== 'undefined'
				) {
					countOperacao++;
					lastIndex = index;
				}
			});
			if (countOperacao > 1) {
				errors.tributacoes[lastIndex] = {
					operacaoFiscal: 'Não é possível cadastrar a mesma operação fiscal mais de uma vez',
				};
			}
		});

		errorsWithoutUndefined = errors.tributacoes.filter((error) => typeof error !== 'undefined');

		if (errorsWithoutUndefined?.length > 0) {
			return errors;
		} else {
			return {};
		}
	},

	validationSchema: Yup.object().shape({
		tributacoes: Yup.array().of(
			Yup.object().shape({
				operacaoFiscal: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
				tributacaoEstadual: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
			})
		),
	}),
	handleSubmit: () => {},
})(ModalOperacoesTributacoesView);

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
});

export default withRouter(connect(mapStateToProps)(ModalOperacoesTributacoes));
