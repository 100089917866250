/* eslint-disable react/no-array-index-key */
import { colors, formatarMonetario, mensagensDeValidacao } from 'Common';
import {
	Button,
	ButtonCancelar,
	Col,
	Divider,
	Dropdown,
	Form,
	FormActions,
	FormContent,
	Grid,
	Modal,
	estadosBotaoCancelar,
} from 'components';
import { isValid, parseISO } from 'date-fns';
import { Field, useFormikContext, withFormik } from 'formik';
import { validarFormulario } from '../../../../../../../../Util';
import { useContextNFCe } from '../../../../../Context';
import { quantidadeParcelasDropdown } from '../../../../../Util/constantes';
import Parcelas from './components/Parcelas';

function ModalParcelasView(props) {
	const {
		onHide,
		visible,
		setFieldValueModalFinalizacao,
		indexPagamento,
		dirty,
		isMobile,
		isTablet,
		isFullScreen,
		disabled,
		generateParcelas,
		favoritosLocal,
	} = props;

	const { informacoesPermissoes } = useContextNFCe();
	const { values, errors, setFieldValue, resetForm } = useFormikContext();

	function cancelar() {
		if (dirty) {
			resetForm({ values: props.initialValues });
		} else {
			onHide();
		}
	}

	async function salvar() {
		await props.handleSubmit();

		if (await validarFormulario(props)) {
			setFieldValueModalFinalizacao(`pagamentos[${indexPagamento}]`, values);
			onHide();
		}
	}

	function onChangeParcelas(parcelas) {
		setFieldValue(`parcelas`, parcelas);
	}

	function handleChangeQuantidadeParcelas(e) {
		setFieldValue(`quantidadeParcelas`, e.value);
		onChangeParcelas(generateParcelas({ qtdParcelas: e.value, favoritos: { ...favoritosLocal } }));
	}

	return (
		<Modal
			header="Parcelamento"
			onHide={onHide}
			visible={visible}
			styleModal={{
				minWidth: '60%',
				maxWidth: '920px',
				height: 'auto',
			}}
			container={isFullScreen ? document.getElementsByClassName('layout-fullscreen')[0] : null}
		>
			<Form className="modal-parcelas-nfce">
				<FormActions>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							paddingBottom: '10px',
							flexWrap: 'wrap',
						}}
					>
						<ButtonCancelar
							estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
							onClick={() => cancelar()}
						/>
						<Button
							className="p-button-success"
							label="Salvar"
							icon="fa fa-check"
							disabled={!dirty}
							onClick={() => salvar()}
						/>
					</div>
				</FormActions>
				<FormContent>
					<Grid>
						<Field
							xs="6"
							sm="6"
							md="4"
							lg="4"
							xl="4"
							component={Dropdown}
							label="Qtd. parcelas"
							name="quantidadeParcelas"
							helpMessage="Quantidade de parcelas que serão geradas para esta venda"
							obrigatorio
							value={values.quantidadeParcelas}
							showClear={false}
							options={quantidadeParcelasDropdown(values.minParcelas, values.maxParcelas)}
							onChange={handleChangeQuantidadeParcelas}
							disabled={disabled}
							menuPortalTarget={isFullScreen ? document.getElementsByClassName('layout-fullscreen')[0] : null}
							{...informacoesPermissoes}
						/>
						<div
							style={{
								fontSize: '24px',
								fontWeight: 'bold',
								color: colors.principal,
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								paddingLeft: isMobile || isTablet ? '12px' : '0px',
								marginTop: isMobile || isTablet ? '0px' : '28px',
								marginLeft: isMobile || isTablet ? '0px' : '8px',
							}}
						>
							Total a prazo: {formatarMonetario(values.valor)}
							{errors.valor && (
								<p
									style={{
										fontSize: '12px',
										color: colors.vermelho,
										margin: '0px',
										justifyContent: 'flex-end',
										display: 'flex',
									}}
								>
									{errors.valor}
								</p>
							)}
						</div>
						<Divider styleContainer={{ marginTop: '0px', marginBottom: '0px' }} />
						<Col>
							{values.parcelas.map((parcela, index) => (
								<Parcelas
									key={index}
									index={index}
									isMobile={isMobile}
									labelParcela={`${index + 1}ª `}
									parcela={parcela}
									exibirLabels={index === 0}
									errors={errors.parcelas?.length > 0 ? errors?.parcelas[index] : null}
									disabled={disabled}
									isFullScreen={isFullScreen}
								/>
							))}
						</Col>
					</Grid>
				</FormContent>
			</Form>
		</Modal>
	);
}

const ModalParcelas = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: true,

	mapPropsToValues(props) {
		return props.pagamento;
	},

	validate(values) {
		const errors = {};
		const errorsParcelas = [];
		let valorTotalParcelas = 0;

		if (values.parcelas?.length > 0) {
			values.parcelas.forEach((parcela, index) => {
				valorTotalParcelas += parcela.valor;

				let errorParcela = null;
				if (parcela.valor <= 0) {
					errorParcela = { ...errorParcela, valor: 'Valor inválido' };
				}

				if (!parcela.vencimento || (parcela.vencimento && !isValid(parseISO(parcela.vencimento)))) {
					errorParcela = { ...errorParcela, vencimento: mensagensDeValidacao.DATA_INVALIDA };
				}

				if (!parcela.formaPagamento) {
					errorParcela = { ...errorParcela, formaPagamento: mensagensDeValidacao.OBRIGATORIO };
				}

				if (!parcela.conta) {
					errorParcela = { ...errorParcela, conta: mensagensDeValidacao.OBRIGATORIO };
				}

				if (errorParcela !== null) {
					errorsParcelas[index] = errorParcela;
				}
			});

			if (parseFloat(valorTotalParcelas.toFixed(2)) > parseFloat(values.valor.toFixed(2))) {
				errors.valor = 'Valor das parcelas é maior que o valor da NFC-e';
			}

			if (parseFloat(valorTotalParcelas.toFixed(2)) < parseFloat(values.valor.toFixed(2))) {
				errors.valor = 'Valor das parcelas é menor que o valor da NFC-e';
			}
		}

		if (errorsParcelas.length > 0) {
			errors.parcelas = errorsParcelas;
		}

		return errors;
	},

	handleSubmit: () => {},
})(ModalParcelasView);

export default ModalParcelas;
