import { colors } from 'Common';

export default function TituloCard(props) {
	const tituloStyle = {
		color: colors.cinzaDark,
		fontSize: '17px',
		paddingLeft: '5px',
		paddingRight: '15px',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		...props.style,
	};

	return (
		<div style={{ display: 'flex', justifyContent: 'space-between' }}>
			<div style={tituloStyle} title={props.helpMessage}>
				{props.title}
			</div>
		</div>
	);
}
