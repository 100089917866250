import { useEffect, useState } from 'react';
import { Field, useFormikContext, withFormik } from 'formik';
import { connect } from 'react-redux';
import * as Yup from 'yup';

import {
	Form,
	FormActions,
	Prompt,
	FormContent,
	Grid,
	Col,
	Divider,
	InputSelectPercentualOrValor,
	ModalLoadingTransmissao,
	Message,
	If,
	Tutorial,
	tutorialStepsNotaFiscalServico,
	tipos as TIPOS_DESCONTO,
	useRecalculatePagamentos,
	confirm,
} from 'components';
import {
	buscarConfiguracaoUsuario,
	buscarDadosLoginLocalStorage,
	configuracoesUsuario,
	copiarObjeto,
	estadosCadastro,
	gerarUUID,
	salvarConfiguracaoUsuario,
	validarUUID,
} from 'Common';

import { atualizarUrl, metodosAtualizarUrl } from 'views/Util';
import { INITIAL_VALUE_PAGAMENTO } from 'components/Pagamentos/Util/constantes';
import { CONDICAO_PAGAMENTO_TIPO } from 'views/cadastros/financas/CondicaoPagamento/Util/constantes';
import { useGenerateParcelas } from 'components/Pagamentos/Hooks/useGenerateParcelas';
import { validatePagamentos } from 'components/Pagamentos/Util/validations';

import { validateServicos } from 'components/Servicos/Utils/validations';
import { NOVO_SERVICO } from 'components/Servicos/Utils';
import { useContextNFSe } from '../Context';
import Header from './components/Header';
import Body from './components/Body';
import TabsServicos from './components/TabsServicos';
import HeaderTitulo from './components/HeaderTitulo';
import ActionButtons from './components/ActionButtons';
import Numero from './components/Numero';
import { MessagesErrors, Situacao, SituacaoNFSE } from '../Util/constantes';
import { novaNotaServico } from './Util/constantes';
import {
	recalcularTotais,
	calcularDescontoServicos,
	converterValueParaSelect,
	montarCalcularImpostosServicoNFSe,
} from './Util/functions';
import { converterToForm } from './Util/NFSeConverter';
import { ModalCompartilharPorEmail } from './components/ModalCompartilharPorEmail';
import { ModalCompartilharPorWhatsapp } from './components/ModalCompartilharPorWhatsapp';

import {
	asyncBuscarSetorResponsavel,
	asyncBuscarTabelaPreco,
	asyncGetCategoria,
	asyncGetCondicaoPagamentoFavorita,
	asyncGetConta,
	asyncGetFormaPagamentoDinheiro,
	asyncGetNfse,
	asyncGetOperacoesFiscais,
	asyncGetVendedor,
} from '../Requests';

function NFSeForm(props) {
	const { dirty, values, errors, submitCount, setFieldValue, resetForm } = useFormikContext();
	const { isMobile } = props;
	const {
		podeInserir,
		podeEditar,
		podeExcluir,
		setColor,
		disableFields,
		setDisableFields,
		setActiveTab,
		isFirstRender,
		setIsFirstRender,
		exibirLoadingTransmissao,
		setClienteUsaPrecoAtacado,
		setUfId,
		setMunicipioId,
		setFunctionsForm,
		showMessageTransmissao,
		setShowMessageTransmissao,
		setShowMessageParametrosFiscais,
		showMessageParametrosFiscais,
		atualizarTotal,
		setAtualizarTotal,
		errosTransmissao,
		setErrosTransmissao,
		setModalEnviarPorWhatsappVisible,
		modalEnviarPorWhatsappVisible,
		setModalEnviarPorEmailVisible,
		modalEnviarPorEmailVisible,
		favoritos,
		setFavoritos,
	} = useContextNFSe();

	const dadosLocais = buscarDadosLoginLocalStorage();
	const statusfinalNFSe = [Situacao.CANCELADO, Situacao.TRANSMITIDO].includes(values.situacao);
	const desabilitarBotaoTransmitir =
		dirty || statusfinalNFSe || !podeEditar || !organizacaoPodeTransmitirNfse() || !values.id;

	const [tutorialVisible, setTutorialVisible] = useState(false);
	const [deveExibirTutorial, setDeveExibirTutorial] = useState(
		buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_NFSE)
	);

	const [dadosBase, setDadosBase] = useState(copiarObjeto(novaNotaServico));
	const [mensagemParametroFiscal, setMensagemParametroFiscal] = useState('');

	const informacoesPermissoes = {
		podeInserir: podeInserir,
		podeEditar: podeEditar && (values.situacao === Situacao.PENDENTE || values.situacao === Situacao.REJEITADO),
		podeExcluir: podeExcluir && (values.situacao === Situacao.REJEITADO || values.situacao === Situacao.PENDENTE),
		estadoCadastro: values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO,
	};
	const existePeloMenosUmServico = values.servicos?.some((servico) => servico.servico) || false;
	const utilizaTabelaPreco = dadosLocais?.filialConectada?.parametrosVendas?.utilizaTabelaPrecoParaServico;

	const uuid = props.match.params.id;

	const [generateParcelas] = useGenerateParcelas({
		valorPagamento: values.totalizadores?.valorLiquido,
		dataBaseParcela: values.dataEmissao,
		favoritos,
	});

	useRecalculatePagamentos(
		{ valorTotal: values.totalizadores?.valorLiquido, dataBaseParcela: values.dataEmissao, favoritos },
		[values.totalizadores?.valorLiquido]
	);

	useEffect(() => {
		setIsFirstRender(false);
		setErrosTransmissao({});
	}, []);

	useEffect(async () => {
		if (validarUUID(uuid)) {
			asyncSelectRegistro(uuid);
		} else {
			resetFormWithFavoritos();
		}

		setActiveTab(0);

		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			setDeveExibirTutorial(false);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_NFSE, false, null, false);
		}

		setTimeout(() => {
			document.getElementById('NfseSingleSelectCliente')?.getElementsByTagName('input')[0]?.focus();
		}, 500);
	}, []);

	useEffect(() => {
		handleParametrosFiscais();
		handleErrosTransmissao();
	}, [values]);

	useEffect(() => {
		if (!isFirstRender) {
			setMunicipioAndUfId(values);
		}
	}, [values.informacoesComplementares.municipio]);

	useEffect(() => {
		setColor(SituacaoNFSE[values.situacao]);
		if (values.situacao === Situacao.TRANSMITIDO || values.situacao === Situacao.CANCELADO) {
			setDisableFields(true);
		} else {
			setDisableFields(false);
		}
	}, [values.situacao]);

	useEffect(() => {
		if (atualizarTotal) {
			const newTotais = recalcularTotais(values);
			setFieldValue('totalizadores', newTotais);
			setAtualizarTotal(false);
		}
	}, [atualizarTotal]);

	useEffect(() => {
		montarERecalcularTodosOsServicos();
	}, [values?.informacoesComplementares?.municipio]);

	useEffect(() => {
		if (Object.keys(errors)?.length > 0) {
			if (errors?.informacoesComplementares?.vendedor || errors?.informacoesComplementares?.setor) {
				setActiveTab(2);
			} else if (errors?.pagamentos) {
				setActiveTab(1);
			}
		}
	}, [errors, submitCount]);

	async function montarERecalcularTodosOsServicos() {
		if (dirty) {
			const { servicos } = values;
			const municipioId = values?.informacoesComplementares?.municipio?.value;
			await values.servicos.forEach(async (servico, index) => {
				if (servicos[index].servico) {
					servicos[index] = await montarCalcularImpostosServicoNFSe(municipioId, servicos[index]);
				}
				if (index === servicos.length - 1) {
					setFieldValue('servicos', servicos);
					setTimeout(() => {
						setAtualizarTotal(true);
					}, 250);
				}
			});
		}
	}

	function setMunicipioAndUfId(values) {
		const { municipio } = values.informacoesComplementares;
		if (municipio?.value) {
			setMunicipioId(municipio?.value);
		}
		setUfId(municipio?.registro?.estado?.id ?? municipio?.registro?.ufId);
	}

	function organizacaoPodeTransmitirNfse() {
		if (dadosLocais?.filialConectada?.parametrosFiscalNFse) {
			if (
				!dadosLocais?.filialConectada?.parametrosFiscalCertificado ||
				!dadosLocais.filialConectada.parametrosFiscalNFse.serie
			) {
				return false;
			}
		}
		return true;
	}

	function updateStatesContext(values) {
		setClienteUsaPrecoAtacado(values.tomador?.registro?.configPrecoPraticado === 'PRECO_ATACADO');
		setFunctionsForm(startFunctionsForm());
		setMunicipioAndUfId(values);
	}

	function startFunctionsForm() {
		return {
			handleChangeInput: (event) => {
				setFieldValue(event.target.name, event.target.value);
			},
			handleChangeSelect: (field, value) => {
				setFieldValue(field, {
					value: value.value,
					label: value.label,
					registro: value.registro,
				});
			},
			updateStatesContext: (values) => updateStatesContext(values),
		};
	}

	async function asyncSelectRegistro(idNota) {
		await asyncGetNfse(idNota, async (nota) => {
			const { setores } = await asyncBuscarSetorResponsavel();
			let nfseComSetor = await { ...copiarObjeto(nota.data) };
			setores?.forEach((setor) => {
				if (nota.data.setorId === setor.id) {
					nfseComSetor = { ...nfseComSetor, setor: setor };
				}
			});

			const valoresNfse = converterToForm(nfseComSetor);

			setDadosBase(copiarObjeto(valoresNfse));
			atualizarUrl(props.history, `/nfses/cadastro/${idNota}`, null, metodosAtualizarUrl.POP);
			setIsFirstRender(false);
			updateStatesContext(valoresNfse);
			resetForm({ values: valoresNfse });
			fetchFavoritosPagamentos();
		});
	}

	function getSetor() {
		const dadosLogin = buscarDadosLoginLocalStorage();
		return dadosLogin?.setores[0] ?? null;
	}

	async function buscarTabelaPreco(dados, onSuccess) {
		function onSuccessWrapper(request) {
			const arrayServicos = values.servicos.filter((item) => item.servico);
			if (
				!isFirstRender &&
				values.informacoesComplementares?.tabelaPreco &&
				arrayServicos?.length > 0 &&
				values.informacoesComplementares?.tabelaPreco?.value !== request?.data?.id
			) {
				confirm(
					'Confirmação',
					'Nova tabela de preço reconhecida. Ao atualizá-la os serviços serão removidos. Deseja atualizar a tabela de preço?',
					() => {
						setFieldValue('servicos', [{ ...NOVO_SERVICO, id: gerarUUID() }]);
						onSuccess(request);
					}
				);
			} else if (typeof onSuccess === 'function') {
				onSuccess(request);
			}
		}

		if (utilizaTabelaPreco) {
			await asyncBuscarTabelaPreco(dados, onSuccessWrapper);
		}
	}

	async function fetchFavoritosPagamentos(callback) {
		const pagamentos = [
			{
				...INITIAL_VALUE_PAGAMENTO,
				tempKey: gerarUUID(),
				sequencial: 1,
				conta: null,
				categoria: null,
				condicaoPagamento: null,
				formaPagamento: null,
			},
		];

		const favoritosToContextState = {};

		const promises = [
			asyncGetConta(({ data: response }) => {
				const contaFavorita = converterValueParaSelect(response.content[0]);
				pagamentos[0].conta = contaFavorita;
				favoritosToContextState.conta = contaFavorita;
			}),
			asyncGetCategoria(({ data: response }) => {
				const categoria = converterValueParaSelect(response.content[0]);
				pagamentos[0].categoria = categoria;
				favoritosToContextState.categoria = categoria;
			}),
			asyncGetCondicaoPagamentoFavorita(async ({ data: response }) => {
				const condicaoPagamentoFavorita = converterValueParaSelect(response.content[0]);
				const isAPrazo = condicaoPagamentoFavorita?.registro?.tipo === CONDICAO_PAGAMENTO_TIPO.A_PRAZO;
				const parcelaPadraoCondicaoPagamento =
					condicaoPagamentoFavorita?.registro?.parcelaPadrao ?? condicaoPagamentoFavorita?.registro?.parcelaMinima ?? 1;

				pagamentos[0].condicaoPagamento = condicaoPagamentoFavorita;
				favoritosToContextState.condicaoPagamento = condicaoPagamentoFavorita;

				function setFormaPagamento(formaPagamento) {
					const formaPagamentoConvertida = converterValueParaSelect(formaPagamento);
					pagamentos[0].formaPagamento = formaPagamentoConvertida;
					favoritosToContextState.formaPagamento = formaPagamentoConvertida;

					if (formaPagamento.conta) {
						const contaConvertida = converterValueParaSelect(formaPagamento.conta);
						pagamentos[0].conta = contaConvertida;
						favoritosToContextState.conta = contaConvertida;
					}

					if (formaPagamento.categoria) {
						const categoriaConvertida = converterValueParaSelect(formaPagamento.categoria);
						pagamentos[0].categoria = categoriaConvertida;
						favoritosToContextState.categoria = categoriaConvertida;
					}
				}

				if (condicaoPagamentoFavorita.registro.formaPagamento) {
					setFormaPagamento(condicaoPagamentoFavorita.registro.formaPagamento);
				} else {
					await asyncGetFormaPagamentoDinheiro(({ data: response }) => {
						setFormaPagamento(response.content[0]);
					});
				}

				if (isAPrazo) {
					pagamentos[0].quantidadeParcelas = {
						value: parcelaPadraoCondicaoPagamento,
						label: `${parcelaPadraoCondicaoPagamento}x`,
					};
					pagamentos[0].parcelas = generateParcelas({
						qtdParcelas: parcelaPadraoCondicaoPagamento,
						favoritos: favoritosToContextState,
					});
				}
			}),
		];

		await Promise.all(promises).then(() => {
			setFavoritos(favoritosToContextState);

			if (typeof callback === 'function') {
				callback(pagamentos);
			}
		});
	}

	async function resetFormWithFavoritos(data = values) {
		const favoritos = {
			operacaoFiscal: null,
			informacoesComplementares: {
				...data.informacoesComplementares,
				vendedor: null,
				informacoesComplementares: null,
				observacaoInterna: null,
				numeroRPS: null,
				loteRPS: null,
				protocolo: null,
				setor: converterValueParaSelect(getSetor()),
			},
			pagamentos: [],
		};

		await asyncGetVendedor(({ data: response }) => {
			const indexFavorito =
				Math.max(
					response.content?.findIndex((vendedor) => vendedor.favorito === true),
					0
				) ?? 0;
			const vendedor = response.content[indexFavorito];

			const labelVendedor = vendedor ? `${vendedor.codigo} - ${vendedor.nome}` : null;
			favoritos.informacoesComplementares.vendedor = converterValueParaSelect(vendedor, labelVendedor);
		});

		const promises = [
			asyncGetOperacoesFiscais({ favoritaSaida: true }, ({ data: response }) => {
				const operacaoFiscal = response.content[0];
				favoritos.operacaoFiscal = converterValueParaSelect(
					operacaoFiscal,
					`${operacaoFiscal.codigo} - ${operacaoFiscal.descricao}`
				);
			}),
			buscarTabelaPreco(
				{ vendedorId: favoritos.informacoesComplementares?.vendedor?.value },
				({ data: tabelaPrecoApi }) => {
					const tabelaPrecoGet = {
						label: `${tabelaPrecoApi.codigo} - ${tabelaPrecoApi.nome}`,
						registro: tabelaPrecoApi,
						value: tabelaPrecoApi.id,
					};
					favoritos.informacoesComplementares = {
						...favoritos.informacoesComplementares,
						tabelaPreco: tabelaPrecoGet,
					};
				}
			),
			fetchFavoritosPagamentos((favoritosPagamentos) => {
				favoritos.pagamentos = favoritosPagamentos;
			}),
		];

		await Promise.all(promises).then(() => {
			if (dadosLocais.filialConectada.parametrosServicos.padraoMunicipioPrestacao === 'PRESTADOR') {
				favoritos.informacoesComplementares.municipio = converterValueParaSelect(
					dadosLocais.filialConectada.endereco.municipio
				);
			}

			favoritos.prestador = dadosLocais.filialConectada;

			resetForm({ values: { ...data, ...favoritos } });
			updateStatesContext(data);
		});
	}

	function onBlurValorDesconto(tipo, value, percentual) {
		if (!isFirstRender && value !== values.totalizadores?.desconto) {
			const desconto = {
				tipo: tipo,
				total: tipo === TIPOS_DESCONTO.PERCENTUAL ? percentual : value,
			};
			const calculatedData = calcularDescontoServicos({ ...values, ...values.totalizadores }, desconto);

			setFieldValue('servicos', calculatedData.servicos);
			setFieldValue('totalizadores.desconto', calculatedData.totalDesconto);
		}
	}

	function ErrosTransmissao() {
		if (showMessageTransmissao) {
			return <Message severity="warn" text={montarMensagemTransmissao()} />;
		}

		return null;
	}

	function ErrosParametrosFiscais() {
		if (showMessageParametrosFiscais) {
			return <Message severity="error" text={montarMensagemParametroFiscal()} />;
		}

		return null;
	}

	function montarMensagemTransmissao() {
		const mensagens = [];

		if (values.erros.length > 0) {
			values.erros.forEach((error) => {
				mensagens.push(<p key={error.id}>{error.mensagem}</p>);
			});
		}

		if (Object.keys(errosTransmissao).length > 0) {
			Object.keys(errosTransmissao).forEach((key) => {
				const errorMessage = `Validação interna - ${errosTransmissao[key]}`;
				mensagens.push(<p key={key}>{errorMessage}</p>);
			});
		}

		return mensagens;
	}

	function montarMensagemParametroFiscal() {
		return (
			<span>
				{`${mensagemParametroFiscal} `}
				<b
					style={{ cursor: 'pointer' }}
					onClick={() =>
						props.history.push({
							pathname: '/configuracoes_gerais',
							state: { configurarSerieNfse: true },
						})
					}
				>
					Clique aqui para configurar
				</b>
			</span>
		);
	}

	function handleErrosTransmissao() {
		if (Object.keys(errosTransmissao).length > 0) {
			setShowMessageTransmissao(true);
			Object.keys(errosTransmissao).forEach(() => {});
		}

		if (values.erros.length > 0) {
			setShowMessageTransmissao(true);
		}

		if (!Object.keys(errosTransmissao).length > 0 && !values.erros.length > 0) {
			setShowMessageTransmissao(false);
		}
	}

	function handleParametrosFiscais() {
		if (dadosLocais?.filialConectada?.parametrosFiscalNFSe) {
			if (!dadosLocais?.filialConectada?.parametrosFiscalCertificado) {
				setMensagemParametroFiscal('Filial sem certificado digital configurado para transmissão de NFS-e.');
				setShowMessageParametrosFiscais(true);
			} else if (
				!dadosLocais.filialConectada.parametrosFiscalNFSe?.numeroRps ||
				!dadosLocais.filialConectada.parametrosFiscalNFSe?.numeroInicial ||
				!dadosLocais.filialConectada.parametrosFiscalNFSe?.loteRps
			) {
				setMensagemParametroFiscal('Número de RPS, lote ou número inicial inválido para transmissão de NFS-e.');
				setShowMessageParametrosFiscais(true);
			}
		}
	}

	function buscarMunicipioIdPessoa(pessoa) {
		let municipioId = null;
		if (pessoa && pessoa.registro?.endereco) {
			municipioId = pessoa.registro?.endereco?.municipio?.value;
		} else if (pessoa) {
			const indexEnderecoFavorito = pessoa.registro?.enderecos?.findIndex((endereco) => endereco.favorito);
			if (indexEnderecoFavorito >= 0) {
				municipioId = pessoa.registro?.enderecos[indexEnderecoFavorito]?.municipio?.id;
			} else if (indexEnderecoFavorito === -1 && pessoa.registro?.enderecos?.length > 0) {
				municipioId = pessoa.registro?.enderecos[0]?.municipio?.id;
			}
		}

		return municipioId;
	}

	return (
		<>
			<Prompt dirty={dirty} />
			<Tutorial
				steps={tutorialStepsNotaFiscalServico}
				showSkipButton
				continuous
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>
			<ModalLoadingTransmissao visible={exibirLoadingTransmissao} message="Transmitindo NFS-e..." />
			<Form header={<HeaderTitulo {...props} />} className="card-default screen-max-width">
				<FormActions className="screen-max-width">
					<ActionButtons
						dadosBase={dadosBase}
						dadosLocais={dadosLocais}
						initialValues={novaNotaServico}
						informacoesPermissoes={informacoesPermissoes}
						desabilitarBotaoTransmitir={desabilitarBotaoTransmitir}
						asyncSelectRegistro={asyncSelectRegistro}
						resetFormWithFavoritos={resetFormWithFavoritos}
						{...props}
					/>
				</FormActions>
				<FormContent>
					<Grid justifyBetween verticalAlignCenter>
						<ErrosTransmissao />
						<ErrosParametrosFiscais />
						<Numero isMobile={isMobile} values={values} history={props.history} />
					</Grid>
					<Grid>
						<Col>
							<Header
								informacoesPermissoes={informacoesPermissoes}
								buscarTabelaPreco={buscarTabelaPreco}
								operacaoFiscal={values?.operacaoFiscal}
								buscarMunicipioIdPessoa={buscarMunicipioIdPessoa}
							/>
							<Divider
								label="Serviços"
								styleContainer={{ margin: '1rem 0' }}
								styleLine={{ opacity: '1', color: 'rgb(177, 177, 177)' }}
							/>
							<Col style={{ padding: 0, margin: 0 }}>
								<Body informacoesPermissoes={informacoesPermissoes} buscarMunicipioIdPessoa={buscarMunicipioIdPessoa} />
							</Col>
							<Divider
								styleContainer={{ margin: '1rem 0', padding: 0 }}
								styleLine={{ opacity: '1', color: 'rgb(177, 177, 177)' }}
							/>
							<Col style={{ padding: 0, margin: 0 }}>
								<Field
									sm="6"
									md="3"
									lg="3"
									xl="3"
									component={InputSelectPercentualOrValor}
									size={14}
									label="Desconto"
									name="descontoNFSe"
									value={values.totalizadores.desconto}
									onBlur={(tipo, value, percentual) => onBlurValorDesconto(tipo, value, percentual)}
									placeholder="0,00"
									allowNegative={false}
									disabled={disableFields || !existePeloMenosUmServico}
									informacoesPermissoes={informacoesPermissoes}
									colStyle={{ padding: 0, margin: 0 }}
								/>
							</Col>
							<Col style={{ padding: 0, margin: 0 }}>
								<TabsServicos
									informacoesPermissoes={informacoesPermissoes}
									utilizaTabelaPreco={utilizaTabelaPreco}
									buscarTabelaPreco={buscarTabelaPreco}
									buscarMunicipioIdPessoa={buscarMunicipioIdPessoa}
								/>
							</Col>
						</Col>
					</Grid>
				</FormContent>
			</Form>
			<If test={modalEnviarPorEmailVisible}>
				<ModalCompartilharPorEmail
					idNFSe={values.id}
					idPessoa={values.tomador?.registro?.idTomador}
					numero={values.numero}
					email={values.tomador?.registro?.email}
					visible={modalEnviarPorEmailVisible}
					onHide={() => setModalEnviarPorEmailVisible(false)}
				/>
			</If>
			<If test={modalEnviarPorWhatsappVisible}>
				<ModalCompartilharPorWhatsapp
					idNFSe={values.id}
					idPessoa={values.tomador?.registro?.idTomador}
					nomeCliente={values.tomador?.registro?.nome}
					numero={values.numero}
					telefone={values.tomador?.registro?.endereco?.telefone}
					visible={modalEnviarPorWhatsappVisible}
					onHide={() => setModalEnviarPorWhatsappVisible(false)}
				/>
			</If>
		</>
	);
}

const NFSeView = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues() {
		return novaNotaServico;
	},

	validationSchema: Yup.object().shape({
		tomador: Yup.object().nullable().required(MessagesErrors.CAMPO_OBRIGATORIO),
		operacaoFiscal: Yup.object().nullable().required(MessagesErrors.CAMPO_OBRIGATORIO),
		dataEmissao: Yup.string().nullable().required(MessagesErrors.CAMPO_OBRIGATORIO),
	}),

	validate: (values) => {
		const errors = {};
		const errorsServicos = validateServicos(values.servicos, values.informacoesComplementares?.tabelaPreco);
		const errorsPagamentos = validatePagamentos(values.pagamentos, values.totalizadores?.valorLiquido);

		if (!values.informacoesComplementares.vendedor) {
			errors.informacoesComplementares = {
				...errors.informacoesComplementares,
				vendedor: MessagesErrors.CAMPO_OBRIGATORIO,
			};
		}

		if (errorsServicos.length > 0) {
			errors.servicos = errorsServicos;
		}

		if (errorsPagamentos.length > 0) {
			errors.pagamentos = errorsPagamentos;
		}

		return errors;
	},

	handleSubmit: () => {},
})(NFSeForm);

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
	isTablet: state.dispositivo.isTablet,
});

export default connect(mapStateToProps)(NFSeView);
