import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { IoMdLock, IoMdUnlock } from 'react-icons/io';
import { format, parse } from 'date-fns';

import {
	buscarConfiguracaoUsuario,
	buscarDadosLoginLocalStorage,
	colors,
	configuracoesUsuario,
	construirUrl,
	permissoes,
	recursos,
	removerElemento,
	replaceCaracteresEspeciais,
	salvarConfiguracaoUsuario,
	services,
	usuarioPossuiPermissao,
} from 'Common';
import {
	Badge,
	Button,
	ButtonEditarTable,
	ButtonExcluirTable,
	ButtonNovo,
	Col,
	DescricaoFiltroAvancado,
	Form,
	FormActions,
	FormContent,
	Grid,
	If,
	InputSearch,
	NenhumRegistroEncontrado,
	Paginacao,
	PesquisaAvancada,
	Tutorial,
	tutorialStepsListagens,
	confirm,
	Message,
	ModalHistorico,
} from 'components';

import { formatarDataParaPesquisa } from 'Common/ManipulacaoDeString';
import { confirmarExclusao } from 'views/Util/ExclusaoDeRegistros';
import { useContextPesquisa } from 'views/Util/Context/ContextPesquisa';
import { deleteFechamento, readFechamentos, updateFechamento, updateFechamentosEmLote } from './Requests';
import { COLORS, OPTIONS_FILTRO_AVANCADO, SITUACAO, STYLE_BACKGROUND, TIPO } from './Util/constantes';
import { ModalGestaoFechamento } from './components/ModalGestaoFechamento';
import { ModalAlteracaoEmLote } from './components/ModalAlteracaoEmLote';
import { converterLoteRegistrosParaApi, converterRegistroParaApi } from './Util/gestaoFechamentoConverter';

function GestaoFechamentoImpl({ history }) {
	const {
		valorPesquisa,
		setValorPesquisa,
		page,
		setPage,
		rows,
		setRows,
		sortOrder,
		setSortOrder,
		sortField,
		setSortField,
		filtroAvancado,
		filtroData,
		setFiltroAvancado,
		descricaoFiltroAvancado,
		setDescricaoFiltroAvancado,
		isMobile,
		interval,
	} = useContextPesquisa();

	const [registros, setRegistros] = useState([]);
	const [totalElements, setTotalElements] = useState(0);
	const [tutorialVisible, setTutorialVisible] = useState(false);
	const [firstRender, setFirstRender] = useState(true);
	const [visibleModalGestaoFechamento, setVisibleModalGestaoFechamento] = useState(false);
	const [visibleModalAlteracaoEmLote, setVisibleModalAlteracaoEmLote] = useState(false);
	const [registroSelecionado, setRegistroSelecionado] = useState(null);
	const [registrosSelecionados, setRegistrosSelecionados] = useState([]);
	const [mensagemParametroControladoria, setMensagemParametroControladoria] = useState(null);
	const [showMessageParametrosControladoria, setShowMessageParametrosControladoria] = useState(false);
	const [visibleModalHistorico, setVisibleModalHistorico] = useState(false);

	const { filialConectada } = buscarDadosLoginLocalStorage();
	const deveExibirTutorial = buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS);
	const podeInserir = usuarioPossuiPermissao(recursos.CONTROLADORIA_GESTAO_FECHAMENTO, permissoes.INSERIR);
	const podeEditar = usuarioPossuiPermissao(recursos.CONTROLADORIA_GESTAO_FECHAMENTO, permissoes.EDITAR);
	const podeExcluir = usuarioPossuiPermissao(recursos.CONTROLADORIA_GESTAO_FECHAMENTO, permissoes.EXCLUIR);

	const sortFieldLocal = sortField?.length > 0 ? sortField : 'dataInicial';

	const pesquisarCallback = useCallback(() => {
		if (!firstRender) {
			pesquisar();
		}
	});

	useEffect(() => {
		handleParametrosControladoria();
		pesquisar();
		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS, false, null, false);
		}

		setTimeout(() => {
			document.getElementById('input-search-gestao-fechamento').focus();
		}, 500);
	}, []);

	useEffect(() => {
		handleParametrosControladoria();
		pesquisarCallback();
	}, [interval, page, rows, sortField, sortOrder, sortFieldLocal, filtroAvancado]);

	useEffect(() => {
		setPage(0);
	}, [valorPesquisa]);

	async function pesquisar() {
		const filtro = getFilter();
		const url = construirUrl(
			`${services.GESTOR}/v1/controladoria/gestao_fechamento/resumo`,
			filtro,
			rows,
			page,
			sortOrder > 0 ? `${sortFieldLocal},asc` : `${sortFieldLocal},desc`
		);

		await readFechamentos(url, ({ data }) => {
			setRegistros(data.content);
			setTotalElements(data.totalElements);
			setFirstRender(false);
		});
	}

	async function onPesquisarFiltroAvancado(filtro) {
		setFiltroAvancado(filtro);
	}

	function handleParametrosControladoria() {
		if (!filialConectada?.parametrosControladoria.usaGestaoFechamento) {
			setMensagemParametroControladoria('Filial não configurada para gerir fechamentos.');
			setShowMessageParametrosControladoria(true);
		}
	}

	function ErrorsParametrosControladoria() {
		function montarMensagemParametroControladoria() {
			return (
				<span>
					{`${mensagemParametroControladoria} `}
					<b
						style={{ cursor: 'pointer' }}
						onClick={() =>
							history.push({
								pathname: '/configuracoes_gerais',
								state: { configurarGestaoFechamentoControladoria: true },
							})
						}
					>
						Clique aqui para configurar
					</b>
				</span>
			);
		}
		return <Message severity="error" text={montarMensagemParametroControladoria()} />;
	}

	function onSort(event) {
		setSortOrder(event.sortOrder);
		setSortField(event.sortField);
	}

	function handlePageChange(event) {
		setRows(event.rows);
		setPage(event.page);
	}

	function getFilter() {
		const replacedValorPesquisa = replaceCaracteresEspeciais(valorPesquisa);
		const replacedPesquisa = formatarDataParaPesquisa(replacedValorPesquisa);
		let filter = String('?query=(')
			.concat(`dataInicial=contains="*${replacedPesquisa}*",`)
			.concat(`dataFinal=contains="*${replacedPesquisa}*",`)
			.concat(`tipo=contains="*${replacedPesquisa}*",`)
			.concat(`situacao=contains="*${replacedPesquisa}*")`);
		if (filtroData) {
			filter = filter.concat(`;(${filtroData})`);
		}
		if (filtroAvancado) {
			filter = filter.concat(`;(${filtroAvancado})`);
		}

		return filter;
	}

	function renderSituacao(row) {
		const styleDescription = {
			margin: row.situacao === 'ABERTO' ? '3px 16px' : '3px 10px',
		};
		switch (row.situacao) {
			case 'ABERTO': {
				return Badge(COLORS.textAtivo, COLORS.bgAtivo, 'Aberto', STYLE_BACKGROUND, styleDescription);
			}
			case 'FECHADO': {
				return Badge(COLORS.textInativo, COLORS.bgInativo, 'Fechado', STYLE_BACKGROUND, styleDescription);
			}
			default:
				return row.situacao;
		}
	}

	function renderOpcoes(row) {
		return (
			<div style={{ display: 'flex' }}>
				<Button
					className={row.situacao === SITUACAO.ABERTO ? 'p-button p-button-warning' : 'p-button p-button-success'}
					style={{
						borderRadius: '50%',
						padding: '5px',
						width: '30px',
						height: '30px',
						marginRight: '3px',
						background: `${row.situacao === SITUACAO.ABERTO ? colors.amareloButton : colors.verdeButton} !important`,
						borderColor: `${row.situacao === SITUACAO.ABERTO ? colors.amareloButton : colors.verdeButton} !important`,
						color: colors.branco,
					}}
					title={row.situacao === SITUACAO.ABERTO ? 'Fechar' : 'Abrir'}
					icon={row.situacao === SITUACAO.ABERTO ? <IoMdLock /> : <IoMdUnlock />}
					onClick={() => handleClickFecharAbrirRegistro(row)}
				/>
				<ButtonEditarTable onClick={() => handleClickEditar(row)} disabled={!podeEditar} />
				<ButtonExcluirTable
					onClick={() => handleClickExcluir(row)}
					podeExcluir={podeExcluir}
					disabled={row.situacao === SITUACAO.FECHADO}
				/>
			</div>
		);
	}

	function handleClickEditar(row) {
		setRegistroSelecionado(row);
		setVisibleModalGestaoFechamento(true);
	}

	function handleClickFecharAbrirRegistro(row) {
		const updatedRow = { ...row };
		if (updatedRow.situacao === SITUACAO.ABERTO) {
			updatedRow.situacao = SITUACAO.FECHADO;
		} else if (updatedRow.situacao === SITUACAO.FECHADO) {
			updatedRow.situacao = SITUACAO.ABERTO;
		}
		enviarRegistro(updatedRow);
	}

	function enviarRegistro(row) {
		const registro = converterRegistroParaApi(row);
		updateFechamento(registro, () => {
			pesquisar();
		});
	}

	function handleClickExcluir(row) {
		confirmarExclusao(() => deleteRegistro(row));
	}

	async function deleteRegistro(registro) {
		await deleteFechamento(registro.id, () => {
			setRegistros(removerElemento(registros, registro));
			setTotalElements(totalElements - 1);
		});
	}

	function getTipoValue(value) {
		return value === TIPO.GERAL ? 'Geral' : 'Indivídual';
	}

	async function alterarSelecionados(registros, acao) {
		confirm(
			'Confirmação',
			'Confirmar alteração?',
			async () => {
				const fechamentos = registros.map((registro) => registro.id);
				return new Promise((resolve, reject) => {
					updateFechamentosEmLote(
						converterLoteRegistrosParaApi(fechamentos, acao),
						() => {
							resolve();
							setRegistrosSelecionados([]);
							pesquisar();
						},
						(error) => {
							reject(error);
						},
						null,
						false
					);
				});
			},
			() => {},
			'Sim',
			'Não'
		);
	}

	return (
		<>
			<Tutorial
				steps={tutorialStepsListagens}
				showSkipButton
				continuous
				disableScrolling
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>
			<Form header="Gestão de fechamento">
				<FormActions>
					<ButtonNovo
						className="step-listagem-novo"
						label="Novo fechamento"
						onClick={() => {
							setVisibleModalGestaoFechamento(true);
						}}
						podeInserir={podeInserir}
					/>
					<Button
						label="Alteração em lote"
						onClick={() => {
							setVisibleModalAlteracaoEmLote(true);
						}}
						className="p-button-success"
						icon="fa fa-pencil"
						style={{ margin: '5px' }}
					/>
					<Button
						label="Histórico"
						onClick={() => {
							setVisibleModalHistorico(true);
						}}
						className="p-button-primary"
						icon="fa fa-history"
						style={{ margin: '5px' }}
					/>
				</FormActions>
				<If test={showMessageParametrosControladoria}>
					<ErrorsParametrosControladoria />
				</If>
				<FormContent>
					<Grid justifyCenter verticalAlignCenter>
						<InputSearch
							id="input-search-gestao-fechamento"
							className="step-listagem-input-search"
							onPesquisar={() => pesquisar(0)}
							value={valorPesquisa}
							onChange={(value) => setValorPesquisa(value)}
						/>
						<Col sm="12" md="4" lg="3" xl="3" className="step-listagem-filtro-avancado">
							<PesquisaAvancada
								className="step-listagem-filtro-avancado"
								optionsFiltros={OPTIONS_FILTRO_AVANCADO}
								onPesquisarClick={onPesquisarFiltroAvancado}
								onChangeFiltroRsql={setFiltroAvancado}
								onChangeDescricaoFiltro={setDescricaoFiltroAvancado}
							/>
						</Col>
					</Grid>
					<DescricaoFiltroAvancado texto={descricaoFiltroAvancado} />
					<DataTable
						selectionMode="checkbox"
						onSelectionChange={(e) => setRegistrosSelecionados(e.value)}
						selection={registrosSelecionados}
						className="table"
						rowClassName="table-row"
						cellClassName="table-row-cell"
						responsive
						value={registros}
						sortField={sortFieldLocal}
						sortOrder={sortOrder}
						onSort={onSort}
						emptyMessage={<NenhumRegistroEncontrado />}
						header={
							<div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
								<span>
									<FormActions>
										<Button
											label="Abrir registros"
											className="p-button-success"
											icon={<IoMdUnlock size={20} style={{ marginRight: '0.5rem' }} />}
											style={{ margin: '5px' }}
											disabled={!registrosSelecionados.length > 0}
											onClick={() => alterarSelecionados(registrosSelecionados, SITUACAO.ABERTO)}
										/>
										<Button
											label="Fechar registros"
											className="p-button-warning"
											icon={<IoMdLock size={20} style={{ marginRight: '0.5rem' }} />}
											style={{
												magin: '5px',
												background: `${colors.amareloButton} !important`,
												borderColor: `${colors.amareloButton} !important`,
												color: colors.branco,
											}}
											disabled={!registrosSelecionados.length > 0}
											onClick={() => alterarSelecionados(registrosSelecionados, SITUACAO.FECHADO)}
										/>
									</FormActions>
								</span>
							</div>
						}
					>
						<Column header={isMobile ? 'Seleção' : ''} selectionMode="multiple" style={{ width: '45px' }} />
						<Column
							field="dataInicial"
							header="Data início"
							body={(row) => format(parse(row.dataInicial, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')}
							sortable
							style={{ width: '25%' }}
						/>
						<Column
							field="dataFinal"
							header="Data fim"
							body={(row) => format(parse(row.dataFinal, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')}
							sortable
							style={{ width: '25%' }}
						/>
						<Column
							field="tipo"
							header="Tipo"
							body={(row) => getTipoValue(row.tipo)}
							sortable
							style={{ width: '25%' }}
						/>
						<Column field="situacao" header="Situação" body={renderSituacao} sortable style={{ width: '25%' }} />
						<Column className="step-listagem-acoes" header="Ações" body={renderOpcoes} style={{ width: '7em' }} />
					</DataTable>
					<Paginacao totalElements={totalElements} rows={rows} page={page} onPageChange={handlePageChange} />
				</FormContent>
			</Form>
			<If test={visibleModalGestaoFechamento}>
				<ModalGestaoFechamento
					visible={visibleModalGestaoFechamento}
					registroSelecionado={registroSelecionado}
					onHide={() => {
						setVisibleModalGestaoFechamento(false);
						setRegistroSelecionado(null);
						pesquisar();
					}}
				/>
			</If>
			<If test={visibleModalAlteracaoEmLote}>
				<ModalAlteracaoEmLote
					visible={visibleModalAlteracaoEmLote}
					onHide={() => {
						setVisibleModalAlteracaoEmLote(false);
						pesquisar();
					}}
				/>
			</If>
			<If test={visibleModalHistorico}>
				<ModalHistorico
					header="Histórico do cadastro"
					visible={visibleModalHistorico}
					onHide={() => setVisibleModalHistorico(false)}
					mapping="fechamentos"
					url={`${services.GESTOR}/v1/controladoria/gestao_fechamento/historico`}
				/>
			</If>
		</>
	);
}

function mapStateToProps(state) {
	return {
		isMobile: state.dispositivo.isMobile,
	};
}

export const GestaoFechamento = connect(mapStateToProps)(GestaoFechamentoImpl);
