/* eslint-disable no-param-reassign */
import { format, parse } from 'date-fns';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Menu } from 'primereact/menu';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
	buscarDadosLoginLocalStorage,
	colors,
	formatarMonetario,
	permissoes,
	recursos,
	usePrevious,
	usuarioPossuiPermissao,
} from '../../../../../../Common';
import { Button, If, NenhumRegistroEncontrado, Paginacao, ToastTypes, notify } from '../../../../../../components';
import { moduloUtilizado } from '../../../../../../components/header/Util/constantes';
import { atualizarUrl } from '../../../../../Util';
import { TypeErrors } from '../../Form/Util/constantes';
import { asyncEstornarPedido, asyncFinalizarPedido } from '../../Requests';
import { SITUACAO_DEVOLUCAO, Status, colorsStatus as StatusTable, Tipo } from '../../Util/constantes';
import ModalCancelarVendaForm from '../ModalCancelarVenda';
import VendasHistorico from '../VendasHistorico';

const styleButton = {
	borderRadius: '50%',
	padding: '5px',
	width: '30px',
	height: '30px',
	margin: '2px',
};

const makeStatus = (strongColor, lightColor, title, description = '') => {
	if (description === 'Cancelada') {
		description = 'Cancelado';
	}

	if (description === 'Expirada') {
		description = 'Expirado';
	}

	return (
		<span
			style={{
				backgroundColor: lightColor,
				color: strongColor,
				fontWeight: 'bold',
				fontSize: '13px',
				borderRadius: '20px',
				display: 'flex',
				height: '1.5rem',
				width: '7rem',
				alignItems: 'center',
				justifyContent: 'center',
			}}
			title={title || description}
		>
			<span>{description}</span>
		</span>
	);
};

function TabelaVendasOrcamentos(props) {
	const {
		history,
		onPesquisar,
		setSortField,
		setSortOrder,
		isMobile,
		onPrint,
		onGenerateSale,
		onReject,
		registros,
		totalRecords,
		rows,
		page,
		onPageChange,
	} = props;

	const [podeInserir] = useState(isPedidoVenda() ? usuarioPossuiPermissao(recursos.VENDAS_PEDIDOS, permissoes.INSERIR) : usuarioPossuiPermissao(recursos.VENDAS_ORCAMENTOS, permissoes.INSERIR));
	const [podeEditar] = useState(isPedidoVenda() ? usuarioPossuiPermissao(recursos.VENDAS_PEDIDOS, permissoes.EDITAR) : usuarioPossuiPermissao(recursos.VENDAS_ORCAMENTOS, permissoes.EDITAR));
	const [podeExcluir] = useState(isPedidoVenda() ? usuarioPossuiPermissao(recursos.VENDAS_PEDIDOS, permissoes.EXCLUIR) : usuarioPossuiPermissao(recursos.VENDAS_ORCAMENTOS, permissoes.EXCLUIR));
	const [registroSelecionado, setRegistroSelecionado] = useState(null);
	const [modalHistoricoVisible, setModalHistoricoVisible] = useState(false);
	const [visibleCancelModal, setVisibleCancelModal] = useState(false);
	const [menu, setMenu] = useState(null);
	const prevProps = usePrevious(props);

	const urlVendaOuOrcamento = isPedidoVenda() ? 'pedidos_venda' : 'orcamentos';
	const vendaOuOrcamento = isPedidoVenda() ? moduloUtilizado.PEDIDO_VENDA : moduloUtilizado.ORCAMENTO;

	function isPedidoVenda() {
		return history.location.pathname === '/pedido_venda';
	}

	useEffect(() => {
		if (prevProps?.registros !== registros) {
			setVisibleCancelModal(false);
		}
	}, [prevProps]);

	function atualizarOrdenacao(event) {
		setSortField(event.sortField);
		setSortOrder(event.sortOrder);
	}

	function renderEmissaoField(element) {
		return <span>{format(parse(element.emissao, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')}</span>;
	}

	function renderNumeroField(element) {
		return <span title={element.numero}>{element.numero}</span>;
	}

	function renderClienteField(element) {
		return (
			<span
				title={element.clienteNome}
				style={{
					maxHeight: '60px',
					overflow: 'hidden',
					display: 'flex',
					wordBreak: 'break-word',
					maxWidth: props.isMobile ? '70%' : '100%',
					textAlign: props.isMobile ? 'end' : 'start',
				}}
			>
				{element.clienteNome ? element.clienteNome : '—'}
			</span>
		);
	}

	function renderStatusField(element) {
		let status = '';
		let lightColor = '';
		let strongColor = '';
		let paddingSize = '';
		let title = null;

		if (element.status === Status.PENDENTE) {
			status = 'Pendente';
			lightColor = StatusTable.PENDENTE.lightColor;
			strongColor = StatusTable.PENDENTE.strongColor;
			paddingSize = '0.2rem 1.3rem';
		} else if (element.status === Status.FINALIZADO) {
			if (element.situacaoDevolucao === SITUACAO_DEVOLUCAO.PARCIALMENTE_DEVOLVIDO) {
				status = 'Parc. Devolvido';
				lightColor = StatusTable.PARCIALMENTE_DEVOLVIDO.lightColor;
				strongColor = StatusTable.PARCIALMENTE_DEVOLVIDO.strongColor;
				paddingSize = '0.2rem 1.2rem';
				title = 'Pedido parcialmente devolvido';
			} else if (element.situacaoDevolucao === SITUACAO_DEVOLUCAO.DEVOLVIDO) {
				status = 'Devolvido';
				lightColor = StatusTable.DEVOLVIDO.lightColor;
				strongColor = StatusTable.DEVOLVIDO.strongColor;
				paddingSize = '0.2rem 1.3rem';
				title = 'Pedido completamente devolvido';
			} else {
				status = 'Finalizado';
				lightColor = StatusTable.FINALIZADO.lightColor;
				strongColor = StatusTable.FINALIZADO.strongColor;
				paddingSize = '0.2rem 1.2rem';
			}
		} else if (element.status === Status.CANCELADA) {
			status = 'Cancelada';
			lightColor = StatusTable.CANCELADA.lightColor;
			strongColor = StatusTable.CANCELADA.strongColor;
			paddingSize = '0.2rem 1.2rem';
		} else if (element.status === Status.EXPIRADA) {
			status = 'Expirada';
			lightColor = StatusTable.EXPIRADA.lightColor;
			strongColor = StatusTable.EXPIRADA.strongColor;
			paddingSize = '0.2rem 1.3rem';
		}
		return makeStatus(strongColor, lightColor, title, status, paddingSize);
	}

	function renderValorField(element) {
		const valor = formatarMonetario(element.totalLiquido);
		return (
			<span title={valor} style={{ color: colors.cinzaDark }}>
				{valor}
			</span>
		);
	}

	function renderValorAReceberField(element) {
		const valor = formatarMonetario(element.valorAReceber);
		if (valor !== formatarMonetario(0)) {
			return (
				<span title={valor} style={{ fontWeight: 'bold', color: colors.cinzaDark }}>
					{valor}
				</span>
			);
		}
		return <span>—</span>;
	}

	function renderAcoesField(element) {
		const titleEditar = 'Editar';
		let titleExcluir = 'Excluir';
		const disableBtnEditar = false;
		let disableBtnExcluir = false;

		if (element?.status === 'CANCELADA' || element?.status === 'FINALIZADO') {
			titleExcluir = 'Você não pode excluir uma venda cancelada';
			disableBtnExcluir = true;
		}

		if (!podeExcluir) {
			titleExcluir = 'Você não possui permissão para executar essa ação';
			disableBtnExcluir = true;
		}

		if (element.nfe && element.nfe.id) {
			titleExcluir = 'Você não pode excluir uma venda com nota fiscal';
			disableBtnExcluir = true;
		}

		return (
			<div>
				<Button
					style={styleButton}
					className="p-button p-button-primary"
					icon="fa fa-pencil"
					title={titleEditar}
					disabled={disableBtnEditar}
					onClick={() => props.onEditItem(element)}
				/>
				<Button
					style={styleButton}
					className="p-button p-button-danger"
					icon="fa fa-trash"
					title={titleExcluir}
					disabled={disableBtnExcluir}
					onClick={() => props.onRemoveItem(element)}
				/>
				<Button
					title="Opções"
					className="p-button-secondary step-listagem-acoes"
					icon="fa fa-ellipsis-v"
					style={styleButton}
					aria-controls="popup_menu"
					aria-haspopup
					onClick={(event) => {
						setRegistroSelecionado(element);
						menu.toggle(event);
					}}
				/>
			</div>
		);
	}

	function renderNotaFiscal(element) {
		if (element.nfe && element.nfe.id) {
			const { numero } = element.nfe;
			const { serie } = element.nfe;

			return (
				<span
					onClick={() => acessarNfe(element.nfe)}
					style={{ fontSize: '12px', color: colors.principal }}
					className="link_to"
					title={
						!numero || numero === 0
							? `Acessar nota fiscal (ainda não transmitida)`
							: `Acessar nota fiscal número ${numero}`
					}
				>
					NF-e {numero || '-'}/{serie || '?'}
				</span>
			);
		} else if (element.nfce && element.nfce.id) {
			const { numero } = element.nfce;
			const { serie } = element.nfce;

			return (
				<span
					onClick={() => acessarNfce(element.nfce.id)}
					style={{ fontSize: '12px', color: colors.principal }}
					className="link_to"
					title={
						!numero || numero === 0
							? `Acessar nota fiscal de consumidor (ainda não transmitida)`
							: `Acessar nota fiscal de consumidor número ${numero}`
					}
				>
					NFC-e {numero || '-'}/{serie || '?'}
				</span>
			);
		}
		return <span>—</span>;
	}

	function renderPedidoVenda(element) {
		if (element.pedido && element.pedido.id) {
			const { numero } = element.pedido;

			return (
				<span
					onClick={() => acessarPedido(element.pedido.id)}
					style={{ fontSize: '12px', color: colors.principal }}
					className="link_to"
					title={`Acessar pedido de venda número ${numero}`}
				>
					Pedido {numero || '-'}
				</span>
			);
		}
		return <span>—</span>;
	}

	function acessarNfe(nfe) {
		atualizarUrl(history, `/nfes/cadastro/${nfe.id}`);
	}

	function acessarNfce(nfeId) {
		atualizarUrl(history, `/nfces/cadastro/${nfeId}`);
	}

	function acessarPedido(vendaId) {
		atualizarUrl(history, `/pedido_venda/cadastro/${vendaId}`);
	}

	function duplicar(vendaId) {
		history.push({
			pathname: `/${isPedidoVenda() ? 'pedido_venda' : 'orcamento'}/cadastro/${vendaId}`,
			state: { vendaId },
		});
	}

	function gerarNfe(venda) {
		try {
			if (venda.temProdutoInativo) {
				throw new Error(TypeErrors.PRODUTO_INATIVO);
			}
			atualizarUrl(props.history, `/nfes/importar_pedido/${venda.id}`);
		} catch (error) {
			if (error.message === TypeErrors.PRODUTO_INATIVO) {
				notify('Processo cancelado, orçamento contém produtos inativos!', ToastTypes.ERROR);
			} else {
				console.error(error);
			}
		}
	}

	function gerarNfce(venda) {
		atualizarUrl(history, `/nfces/importar_pedido/${venda.id}`);
	}

	function finalizarPedido(venda) {
		try {
			if (venda.temProdutoInativo) {
				throw new Error(TypeErrors.PRODUTO_INATIVO);
			}
			asyncFinalizarPedido(venda.id, () => {
				onPesquisar();
			});
		} catch (error) {
			if (error.message === TypeErrors.PRODUTO_INATIVO) {
				notify('Processo cancelado, orçamento contém produtos inativos!', ToastTypes.ERROR);
			} else {
				console.error(error);
			}
		}
	}

	function estornarPedido(vendaId) {
		asyncEstornarPedido(vendaId, () => {
			onPesquisar();
		});
	}

	function montarItensMenu() {
		const itens = [];

		if (registroSelecionado) {
			itens.push({
				label: 'Duplicar',
				icon: 'fa fa-copy',
				command: () => duplicar(registroSelecionado.id),
			});
			if (registroSelecionado.tipo === Tipo.ORCAMENTO) {
				itens.push({
					label: 'Imprimir',
					icon: 'fa fa-print',
					command: () => onPrint(registroSelecionado),
				});
				itens.push({
					label: 'Histórico',
					icon: 'fa fa-history',
					command: () => visualizarHistorico(),
				});

				if (registroSelecionado.status === Status.PENDENTE || registroSelecionado.status === Status.EXPIRADA) {
					itens.push({
						separator: true,
						visible: true,
					});
				}

				if (				
					registroSelecionado.status !== Status.CANCELADA &&
					registroSelecionado.status !== Status.FINALIZADO
				) {				
					itens.push({
						label: 'Gerar pedido',
						icon: 'fa fa-dollar',
						command: () => onGenerateSale(registroSelecionado),
						disabled: !usuarioPossuiPermissao(recursos.VENDAS_PEDIDOS, permissoes.INSERIR)
					});
					itens.push({
						label: 'Cancelar orçamento',
						icon: 'fa fa-times',
						command: () => visualizarCancelamento(),
						disabled: !podeEditar,
					});
				}
			} else if (registroSelecionado.tipo === Tipo.PEDIDO_VENDA) {
				itens.push({
					label: 'Imprimir',
					icon: 'fa fa-print',
					command: () => onPrint(registroSelecionado),
				});
				itens.push({
					label: 'Histórico',
					icon: 'fa fa-history',
					command: () => visualizarHistorico(),
				});

				if (registroSelecionado.status !== Status.CANCELADA) {
					itens.push({
						separator: true,
						visible: true,
					});
				}

				if (
					!registroSelecionado.nfe &&
					!registroSelecionado.nfce &&
					registroSelecionado.status !== Status.CANCELADA
				) {
					itens.push({
						label: registroSelecionado.tipo === 'PEDIDO_VENDA' ? 'Cancelar pedido' : 'Cancelar orcamento',
						icon: 'fa fa-times',
						command: () => visualizarCancelamento(),
						disabled: !(isPedidoVenda() ? usuarioPossuiPermissao(recursos.VENDAS_PEDIDOS, permissoes.CANCELAR) : podeEditar),
					});					

					if (registroSelecionado.status !== Status.FINALIZADO) {
						itens.push({
							label: 'Finalizar pedido',
							icon: 'fa fa-check',
							command: () => {
								finalizarPedido(registroSelecionado);
							},
							disabled: !usuarioPossuiPermissao(recursos.VENDAS_PEDIDOS, permissoes.FINALIZAR),
						});
					}else{
						itens.push({
							label: 'Estornar pedido',
							icon: 'fa fa-reply',
							command: () => {
								estornarPedido(registroSelecionado.id);
							},
							disabled: !usuarioPossuiPermissao(recursos.VENDAS_PEDIDOS, permissoes.ESTORNAR),
						});
					}	
				}
				if (podeInserir && registroSelecionado.nfce) {
					itens.push({
						label: 'Acessar NFC-e',
						icon: 'fa fa-link',
						command: () => {
							acessarNfce(registroSelecionado.nfce.id);
						},
					});
				} else if (podeInserir && registroSelecionado.nfe) {
					itens.push({
						label: 'Acessar NF-e',
						icon: 'fa fa-link',
						command: () => {
							acessarNfe(registroSelecionado.nfe);
						},
					});
				} else if (registroSelecionado.status !== Status.CANCELADA) {
					itens.push({
						label: 'Gerar NF-e',
						icon: 'fa fa-file-text-o',
						command: () => {
							gerarNfe(registroSelecionado);
						},
					});
					if (
						buscarDadosLoginLocalStorage()?.filialConectada?.parametrosFiscalNFCe &&
						buscarDadosLoginLocalStorage().filialConectada.parametrosFiscalNFCe.serieNfce
					) {
						itens.push({
							label: 'Gerar NFC-e',
							icon: 'fa fa-file-text-o',
							command: () => {
								gerarNfce(registroSelecionado);
							},
						});
					}
				}				
			} else if (registroSelecionado.status === Status.CANCELADA) {
				itens.push({
					label: 'Imprimir',
					icon: 'fa fa-print',
					command: () => onPrint(registroSelecionado),
				});
				itens.push({
					label: 'Histórico',
					icon: 'fa fa-history',
					command: () => visualizarHistorico(),
				});
			}
		}
		return itens;
	}

	function handleSubmit(values, registroSelecionado) {
		onReject({
			...registroSelecionado,
			motivoCancelamento: values,
		});
		setVisibleCancelModal(false);
	}

	function visualizarCancelamento() {
		setVisibleCancelModal(true);
	}

	function visualizarHistorico() {
		setModalHistoricoVisible(true);
	}

	return (
		<div style={{ width: !isMobile ? '100%' : '99%' }}>
			<Menu model={montarItensMenu()} popup ref={(elemento) => setMenu(elemento)} />
			<DataTable
				className="table"
				value={registros}
				responsive
				sortField={props.sortField}
				sortOrder={props.sortOrder}
				onSort={atualizarOrdenacao}
				emptyMessage={<NenhumRegistroEncontrado />}
			>
				<Column
					header="N°"
					className="step-listagem-order"
					field="numero"
					sortable
					body={renderNumeroField}
					style={{ color: colors.cinzaDark, width: '60px' }}
				/>
				<Column
					header="Data"
					field="emissao"
					sortable
					body={renderEmissaoField}
					style={{ color: colors.cinzaDark, width: '100px' }}
				/>
				<Column
					header="Cliente"
					field="clienteNome"
					sortable
					body={renderClienteField}
					style={{ color: colors.cinzaDark }}
				/>
				{isPedidoVenda() ? (
					<Column
						header="NF-e/NFC-e"
						field="nfe"
						body={renderNotaFiscal}
						style={{
							color: colors.cinzaDark,
							maxWidth: '100px',
						}}
					/>
				) : (
					<Column
						header="Pedido de venda"
						field="pedido"
						body={renderPedidoVenda}
						style={{
							color: colors.cinzaDark,
							maxWidth: '125px',
						}}
					/>
				)}
				<Column
					header="Status"
					field="status"
					sortable
					body={renderStatusField}
					style={{ color: colors.cinzaDark, width: '150px' }}
				/>
				<Column
					header="Valor"
					field="totalLiquido"
					sortable
					body={renderValorField}
					style={{
						color: colors.cinzaDark,
						width: '116px',
						textOverflow: 'ellipsis',
						overflow: 'hidden',
						fontWeight: 'bold',
					}}
				/>
				{isPedidoVenda() ? (
					<Column
						header="A receber"
						field="valorAReceber"
						body={renderValorAReceberField}
						style={{
							color: colors.cinzaDark,
							width: '120px',
							textOverflow: 'ellipsis',
							overflow: 'hidden',
						}}
					/>
				) : null}
				<Column className="step-listagem-acoes" header="Ações" body={renderAcoesField} style={{ width: '130px' }} />
			</DataTable>
			<Paginacao totalElements={totalRecords} rows={rows} page={page} onPageChange={onPageChange} />
			<If test={modalHistoricoVisible}>
				<VendasHistorico
					status={registroSelecionado && registroSelecionado.status}
					idVenda={registroSelecionado && registroSelecionado.id}
					visible={modalHistoricoVisible}
					urlVendaOuOrcamento={urlVendaOuOrcamento}
					onHide={() => setModalHistoricoVisible(false)}
					vendaOuOrcamento={vendaOuOrcamento}
				/>
			</If>
			<If test={visibleCancelModal}>
				<ModalCancelarVendaForm
					valuesVenda={registroSelecionado}
					visible={visibleCancelModal}
					onHide={() => setVisibleCancelModal(false)}
					urlVendaOuOrcamento={urlVendaOuOrcamento}
					handleSubmitForm={(values) => handleSubmit(values, registroSelecionado)}
					resetFormForm={() => {
						onPesquisar();
					}}
				/>
			</If>
		</div>
	);
}

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
});

export default connect(mapStateToProps)(TabelaVendasOrcamentos);
