import { useState } from 'react';
import { Field, useFormikContext, withFormik } from 'formik';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import {
	ButtonAdicionarItem,
	ButtonEditarTable,
	ButtonExcluirTable,
	Col,
	confirm,
	Form,
	FormActions,
	FormContent,
	Grid,
	If,
	InputDate,
	InputField,
	Message,
	Modal,
	ModalLoadingTransmissao,
	NenhumRegistroEncontrado,
} from 'components';
import { colors, gerarUUID } from 'Common';

import { CTE_SITUACAO } from 'views/transporte/CTe/Util/constantes';
import { validarFormulario } from 'views/Util';
import { ActionButtons } from './components/ActionButtons';
import { ModalDadosCorrecao } from './components/ModalDadosCorrecao';
import {
	CTE_CARTA_CORRECAO_CAMPOS,
	CTE_CARTA_CORRECAO_GRUPO,
	CTE_CARTA_CORRECAO_INITIAL_VALUES,
} from './Utils/constantes';
import { transmitirCTeCartaCorrecao } from './Requests';
import { converterCorrecaoCteParaApi } from './Utils/converterCorrecaoCte';

function ModalCorrecaoImpl({ visible, onHide, cte, fetchRegistro }) {
	const { values, setFieldValue, handleSubmit, validateForm, errors } = useFormikContext();

	const [visibleModalDadosCorrecao, setVisibleModalDadosCorrecao] = useState(false);
	const [mostrarLoadingTransmissao, setMostrarLoadingTransmissao] = useState(false);
	const [registroSelecionado, setRegistroSelecionado] = useState(null);

	const cteFinalizado = cte?.situacao === CTE_SITUACAO.TRANSMITIDO;
	const correcaoFinalizada = !!values.id;

	function renderGrupo(row) {
		const label = CTE_CARTA_CORRECAO_GRUPO[String(row.grupo).toUpperCase()];
		return <div style={{ overflow: 'hidden', overflowWrap: 'break-word' }}>{label}</div>;
	}

	function renderCampo(row) {
		const grupo = CTE_CARTA_CORRECAO_CAMPOS[String(row?.grupo).toUpperCase()];
		const label = grupo ? grupo[String(row?.campo).toUpperCase()] : '';
		return <div style={{ overflow: 'hidden', overflowWrap: 'break-word' }}>{label}</div>;
	}

	function renderValor(row) {
		return <div style={{ overflow: 'hidden', overflowWrap: 'break-word' }}>{row.valor}</div>;
	}

	function renderOpcoes(row) {
		return (
			<div>
				<ButtonEditarTable title="Editar" onClick={() => handleEditarRegistroTable(row)} />
				<ButtonExcluirTable
					title="Excluir"
					onClick={() => handleDeletarRegistroTable(row)}
					disabled={!cteFinalizado || correcaoFinalizada}
				/>
			</div>
		);
	}

	function handleEditarRegistroTable(row) {
		setRegistroSelecionado(row);
		setVisibleModalDadosCorrecao(true);
	}

	function handleDeletarRegistroTable(row) {
		confirm('Confirmação de exclusão', 'Deseja excluir o registro?', () => {
			removerRegistro(row);
		});
	}

	async function removerRegistro(row) {
		const itens = values.correcoes || [];
		const updatedItens = await itens
			.filter((item) => (item.tempId ? item.tempId !== row.tempId : item.id !== row.id))
			.map((item, index) => ({ ...item, item: index + 1 }));

		setFieldValue('correcoes', updatedItens);
	}

	function handleSalvarDadosCorrecao(event) {
		const itens = values.correcoes || [];
		let updatedItens;

		if (registroSelecionado) {
			const registroId = registroSelecionado.id || registroSelecionado.tempId;

			updatedItens = itens.map((item) => {
				const itemId = item.id || item.tempId;
				if (itemId === registroId) {
					return event;
				}
				return item;
			});
		} else {
			updatedItens = [
				...itens,
				{ ...event, tempId: gerarUUID(), item: values.correcoes?.length ? values.correcoes.length + 1 : 1 },
			];
		}

		setFieldValue('correcoes', updatedItens);
		setRegistroSelecionado(null);
		setVisibleModalDadosCorrecao(false);
	}

	async function handleSalvarCartaCorrecao() {
		handleSubmit();

		if (await validarFormulario({ validateForm, values })) {
			const correcao = await converterCorrecaoCteParaApi(values);
			setMostrarLoadingTransmissao(true);

			transmitirCTeCartaCorrecao(
				cte.id,
				correcao,
				() => {
					setMostrarLoadingTransmissao(false);
					fetchRegistro(cte.id);
					onHide();
				},
				() => {
					setMostrarLoadingTransmissao(false);
				}
			);
		}
	}

	function montarInformacaoMessage() {
		return (
			<>
				<div style={{ marginBottom: '7px' }}>
					A Carta de Correção é disciplinada pelo Art. 58-B do CONVÊNIO/SINIEF 06/89 e pode ser utilizada para
					regularização de erro ocorrido na emissão de documento fiscal, desde que o erro não esteja relacionado com:
				</div>
				<div>
					I - As variáveis que determinam o valor do imposto tais como: base de cálculo, alíquota, diferença de preço,
					quantidade, valor da prestação;
				</div>
				<div>
					II - A correção de dados cadastrais que implique mudança do emitente, tomador, remetente ou do destinatário;
				</div>
				<div>III - A data de emissão ou de saída.</div>
			</>
		);
	}

	return (
		<>
			<ModalLoadingTransmissao visible={mostrarLoadingTransmissao} message="Transmitindo carta de correção..." />
			<Modal
				header="Corrigir CT-e"
				visible={visible}
				onHide={onHide}
				styleModal={{ maxWidth: '1024px' }}
				closeOnEsc={false}
			>
				<Form>
					<FormActions>
						<Col style={{ paddingLeft: '0px' }}>
							<ActionButtons onHide={onHide} onHandleSave={handleSalvarCartaCorrecao} />
						</Col>
					</FormActions>
					<FormContent>
						<If test={!values.id}>
							<Message style={{ marginBottom: '7px' }} severity="info" text={montarInformacaoMessage()} />
						</If>
						<If test={errors.correcoes}>
							<Message style={{ marginBottom: '7px' }} text={errors.correcoes} severity="error" />
						</If>
						<Grid>
							<Field
								sm="12"
								md="4"
								lg="4"
								xl="4"
								component={InputField}
								label="Sequencial"
								name="numeroSequencial"
								value={values.numeroSequencial}
								disabled
							/>
							<Field
								sm="12"
								md="4"
								lg="4"
								xl="4"
								component={InputDate}
								label="Emissão"
								name="emissao"
								value={values.emissao}
								disabled
							/>
							<Field
								sm="12"
								md="4"
								lg="4"
								xl="4"
								component={InputField}
								label="Protocolo"
								name="protocolo"
								value={values.protocolo}
								disabled
							/>
						</Grid>
						<DataTable
							className="table"
							responsive
							value={values.correcoes || []}
							emptyMessage={<NenhumRegistroEncontrado />}
						>
							<Column
								field="grupo"
								header="Grupo"
								body={(e) => renderGrupo(e)}
								style={{ overflow: 'hidden', overflowWrap: 'break-word' }}
							/>
							<Column
								field="campo"
								header="Campo"
								body={(e) => renderCampo(e)}
								style={{ overflow: 'hidden', overflowWrap: 'break-word' }}
							/>
							<Column
								field="valor"
								header="Novo valor"
								body={(e) => renderValor(e)}
								style={{ overflow: 'hidden', overflowWrap: 'break-word' }}
							/>
							<Column
								className="step-listagem-acoes"
								header="Ações"
								body={(e) => renderOpcoes(e)}
								style={{ width: '100px' }}
							/>
						</DataTable>
						<ButtonAdicionarItem
							label="Adicionar campo para alteração"
							style={{
								color: colors.azul,
							}}
							onClick={() => {
								setVisibleModalDadosCorrecao(true);
							}}
							tabIndex={-1}
							disabled={!cteFinalizado || correcaoFinalizada}
						/>
					</FormContent>
				</Form>
				<If test={visibleModalDadosCorrecao}>
					<ModalDadosCorrecao
						visible={visibleModalDadosCorrecao}
						registroSelecionado={registroSelecionado}
						onHide={() => {
							setVisibleModalDadosCorrecao(false);
							setRegistroSelecionado(null);
						}}
						onSave={handleSalvarDadosCorrecao}
						correcaoFinalizada={correcaoFinalizada}
						itens={values.correcoes}
					/>
				</If>
			</Modal>
		</>
	);
}

const ModalCorrecao = withFormik({
	enableReinitialize: true,
	validateOnBlur: false,
	validateOnChange: false,

	mapPropsToValues({ registroSelecionado }) {
		if (registroSelecionado) {
			return registroSelecionado;
		} else {
			return CTE_CARTA_CORRECAO_INITIAL_VALUES;
		}
	},

	validate(values) {
		const errors = {};

		if (!values.correcoes) {
			errors.correcoes = 'É necessário incluir pelo menos 1 item alterado para transmitir a carta de correção';
		}

		return errors;
	},

	handleSubmit: () => {},
})(ModalCorrecaoImpl);

export { ModalCorrecao };
