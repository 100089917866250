import { useState } from 'react';
import { format, parseISO } from 'date-fns';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Menu } from 'primereact/menu';

import { Badge, Button, If, NenhumRegistroEncontrado, Paginacao } from 'components';
import { baixarArquivo, colors, formatarMonetario, permissoes, recursos, usuarioPossuiPermissao } from 'Common';

import { atualizarUrl, metodosAtualizarUrl } from 'views/Util';
import { Colors, Situacao, SituacaoNFSE } from '../../Util/constantes';
import { useContextNFSe } from '../../Context';
import NotasHistorico from '../NotasHistorico';
import { imprimirDANFSE, imprimirXMLNfse } from '../../Requests';

const styleButton = {
	borderRadius: '50%',
	padding: '5px',
	width: '30px',
	height: '30px',
	margin: '2px',
};

export function TabelaNFSe(props) {
	const {
		registros,
		totalRecords,
		onEdit,
		onRemove,
		onPageChange,
		page,
		rows,
		sortField,
		sortOrder,
		setSortField,
		setSortOrder,
		isMobile,
		history,
	} = props;
	const { podeExcluir } = useContextNFSe();

	const [registroSelecionado, setRegistroSelecionado] = useState({});
	const [modalHistoricoVisible, setModalHistoricoVisible] = useState(false);
	const [permissaoOrdemServico] = useState(
		usuarioPossuiPermissao(recursos.SERVICOS_ORDEM_SERVICO, permissoes.VISUALIZAR)
	);

	const [menu, setMenu] = useState(null);

	function montarItensMenu() {
		const itens = [];

		itens.push({
			label: 'Histórico',
			icon: 'fa fa-history',
			command: () => setModalHistoricoVisible(true),
		});
		if (registroSelecionado.situacao === Situacao.TRANSMITIDO || registroSelecionado.situacao === Situacao.CANCELADO) {
			itens.push({
				separator: true,
				visible: true,
			});
			itens.push({
				label: 'Imprimir DANFS-e',
				icon: 'fa fa-print',
				command: () => imprimirNotaFiscal(),
			});
			itens.push({
				label: 'Baixar XML',
				icon: 'fa fa-download',
				command: () => baixarXMLNotaFiscal(),
			});
		}

		return itens;
	}

	async function baixarXMLNotaFiscal() {
		await imprimirXMLNfse(registroSelecionado.id, ({ data: file }) => {
			baixarArquivo(file, 'Nota fiscal de serviço (NFS-e)', 'application/xml');
		});
	}

	async function imprimirNotaFiscal() {
		await imprimirDANFSE(registroSelecionado.id, async ({ data: file }) => {
			const arquivo = new Blob([file], { type: 'application/pdf' });
			const arquivoURL = URL.createObjectURL(arquivo);
			const danfe = window.open(arquivoURL);
			danfe.onload = () => {
				setTimeout(() => {
					danfe.document.title = 'Nota fiscal de serviço (NFS-e).pdf';
				}, 250);
			};
		});
	}

	function atualizarOrdenacao(e) {
		setSortField(e.sortField);
		setSortOrder(e.sortOrder);
	}

	function renderAcoes(row) {
		const titleEditar = 'Editar';
		let titleExcluir = 'Excluir';
		const disableBtnEditar = false;
		let disableBtnExcluir = false;

		if (row?.situacao === 'CANCELADO') {
			titleExcluir = 'Você não pode excluir uma NFS-e cancelada';
			disableBtnExcluir = true;
		}
		if (row?.situacao === 'TRANSMITIDO') {
			titleExcluir = 'Você não pode excluir uma NFS-e transmitida';
			disableBtnExcluir = true;
		}

		if (!podeExcluir) {
			titleExcluir = 'Você não possui permissão para executar essa ação';
			disableBtnExcluir = true;
		}

		return (
			<div>
				<Button
					style={styleButton}
					className="p-button p-button-primary"
					icon="fa fa-pencil"
					title={titleEditar}
					disabled={disableBtnEditar}
					onClick={() => onEdit(row)}
				/>
				<Button
					style={styleButton}
					className="p-button p-button-danger"
					icon="fa fa-trash"
					title={titleExcluir}
					disabled={disableBtnExcluir}
					onClick={() => onRemove(row)}
				/>
				<Button
					title="Opções"
					className="p-button-secondary step-listagem-acoes"
					icon="fa fa-ellipsis-v"
					style={styleButton}
					aria-controls="popup_menu"
					aria-haspopup
					onClick={(event) => {
						setRegistroSelecionado(row);
						menu.toggle(event);
					}}
				/>
			</div>
		);
	}

	function renderFieldNumero(row) {
		return <span>{row.numero ?? '-'}</span>;
	}

	function renderFieldData(row) {
		const label = format(parseISO(row.emissao), 'dd/MM/yyyy');
		return <span title={label}>{label}</span>;
	}

	function renderFieldCliente(row) {
		const label = `${row.tomador.codigo} - ${row.tomador.nome}`;
		return (
			<span
				title={label}
				style={{
					display: 'flex',
					wordBreak: 'break-word',
					maxWidth: props.isMobile ? '70%' : '100%',
					textAlign: props.isMobile ? 'end' : 'start',
				}}
			>
				{label}
			</span>
		);
	}

	function renderFielOrdemServico(row) {
		if (row.ordemServico?.numero && permissaoOrdemServico) {
			return (
				<span
					onClick={() =>
						atualizarUrl(history, `/ordem_servico/cadastro/${row.ordemServico?.id}`, null, metodosAtualizarUrl.PUSH)
					}
					style={{ fontSize: '12px', color: colors.principal }}
					className="link_to"
					title={
						!row.ordemServico?.numero || row.ordemServico?.numero === 0
							? `Ordem`
							: `Acessar ordem de serviço número ${row.ordemServico?.numero}`
					}
				>
					{`Ordem ${row.ordemServico?.numero}`}
				</span>
			);
		} else if (row.ordemServico?.numero && !permissaoOrdemServico) {
			return <span>{`Ordem ${row.ordemServico?.numero}`}</span>;
		} else {
			return <span>—</span>;
		}
	}

	function renderFieldSituacao(row) {
		const styleBadge = {
			fontWeight: 'bold',
			fontSize: '13px',
			borderRadius: '20px',
			display: 'flex',
			height: '1.5rem',
			width: '7rem',
			alignItems: 'center',
			justifyContent: 'center',
		};

		switch (row.situacao) {
			case SituacaoNFSE.PENDENTE.enumerator.toUpperCase(): {
				return Badge(Colors.PENDENTE.strongColor, Colors.PENDENTE.lightColor, 'Não enviada', styleBadge);
			}
			case SituacaoNFSE.AGUARDANDO.enumerator.toUpperCase(): {
				return Badge(Colors.AGUARDANDO.strongColor, Colors.AGUARDANDO.lightColor, 'Aguardando', styleBadge);
			}
			case SituacaoNFSE.TRANSMITIDO.enumerator.toUpperCase(): {
				return Badge(Colors.TRANSMITIDO.strongColor, Colors.TRANSMITIDO.lightColor, 'Transmitida', styleBadge);
			}
			case SituacaoNFSE.REJEITADO.enumerator.toUpperCase(): {
				return Badge(Colors.REJEITADO.strongColor, Colors.REJEITADO.lightColor, 'Rejeitada', styleBadge);
			}
			case SituacaoNFSE.CANCELADO.enumerator.toUpperCase(): {
				return Badge(Colors.CANCELADO.strongColor, Colors.CANCELADO.lightColor, 'Cancelada', styleBadge);
			}
			default:
				return row.situacao;
		}
	}

	function renderFieldValor(row) {
		const label = formatarMonetario(row.total);
		return <span title={label}>{label}</span>;
	}

	return (
		<div style={{ width: !isMobile ? '100%' : '99%' }}>
			<Menu model={montarItensMenu()} popup ref={(elemento) => setMenu(elemento)} />
			<DataTable
				className="table"
				value={registros}
				responsive
				sortField={sortField}
				sortOrder={sortOrder}
				onSort={atualizarOrdenacao}
				emptyMessage={<NenhumRegistroEncontrado />}
			>
				<Column
					header="Número/NFS-e"
					className="step-listagem-order"
					field="numero"
					sortable
					body={renderFieldNumero}
					style={{ color: colors.cinzaDark, width: '100px' }}
				/>
				<Column
					header="Data"
					field="emissao"
					sortable
					body={renderFieldData}
					style={{ color: colors.cinzaDark, width: '100px' }}
				/>
				<Column
					header="Cliente"
					field="tomadorNome"
					sortable
					body={renderFieldCliente}
					style={{ color: colors.cinzaDark }}
				/>
				<Column
					header="Ordem de serviço"
					field="ordemServicoNumero"
					body={renderFielOrdemServico}
					style={{ color: colors.cinzaDark, width: '200px' }}
				/>
				<Column
					header="Situação"
					field="situacao"
					sortable
					body={renderFieldSituacao}
					style={{ color: colors.cinzaDark, width: '130px' }}
				/>
				<Column
					header="Valor"
					field="total"
					sortable
					body={renderFieldValor}
					style={{
						color: colors.cinzaDark,
						width: '116px',
						textOverflow: 'ellipsis',
						overflow: 'hidden',
						fontWeight: 'bold',
					}}
				/>
				<Column
					header="Ações"
					className="step-listagem-acoes"
					body={renderAcoes}
					style={{
						width: '130px',
					}}
				/>
			</DataTable>
			<Paginacao totalElements={totalRecords} rows={rows} page={page} onPageChange={onPageChange} />
			<If test={modalHistoricoVisible}>
				<NotasHistorico
					idNota={registroSelecionado && registroSelecionado.id}
					visible={modalHistoricoVisible}
					onHide={() => setModalHistoricoVisible(false)}
				/>
			</If>
		</div>
	);
}
