import { colors, mensagensDeValidacao } from 'Common';
import {
	ButtonCancelar,
	ButtonExcluirTable,
	ButtonSalvar,
	Col,
	Form,
	FormActions,
	FormContent,
	Grid,
	InputDate,
	Modal,
	ModalLoadingTransmissao,
	ToastTypes,
	estadosBotaoCancelar,
	notify,
} from 'components';
import { isAfter, parseISO } from 'date-fns';
import { Field, withFormik } from 'formik';
import { FileUpload } from 'primereact/fileupload';
import { useEffect, useState } from 'react';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { validarFormulario } from 'views/Util';
import {
	asyncAdicionarArquivosAtualizarNcm,
	asyncAdicionarArquivosIbptax,
	asyncGetDadosAtualizarIbptax,
	asyncProcessarIbptaxEmVigor,
	asyncUpdateDadosAtualizarIbptax,
} from './Requests';
import {
	converterAtualizarIbptaxParaApi,
	converterAtualizarIbptaxParaFormluario,
} from './Utils/converterAtualizarIbptax';

const initialvalue = {
	id: null,
	dataVigencia: null,
	arquivos: [],
};

function ModalAtualizarIbptaxView(props) {
	const { visible, setMostrarModalAtualizarIbptax, setFieldValue, values, errors, dirty, resetForm, handleSubmit } =
		props;
	const [adicionouArquivo, setAdicionouArquivo] = useState(false);
	const [exibirLoading, setExibirLoading] = useState(false);
	const [quantidadeArquivosProcessados, setQuantidadeArquivosProcessados] = useState(0);

	useEffect(() => {
		asyncGetDadosAtualizarIbptax(({ data: dadosIbptax }) => {
			const dados = converterAtualizarIbptaxParaFormluario(dadosIbptax);
			resetForm({ values: dados });
		});
	}, []);

	function onHide() {
		setMostrarModalAtualizarIbptax(false);
	}

	function onUpload(upload) {
		setFieldValue('arquivos', upload?.files);
		setAdicionouArquivo(true);
	}

	function onRemove(fileRemoved) {
		const arquivos = values.arquivos?.filter(
			(file) => file?.name !== fileRemoved?.name && file?.size !== fileRemoved?.size
		);
		setFieldValue('arquivos', arquivos);
	}

	function formatarTamanhoArquivo(size) {
		return `${(size / 1024).toFixed(1)} KB`;
	}

	function cancelar() {
		return dirty ? resetForm() : onHide();
	}

	async function salvar() {
		handleSubmit();
		if (await validarFormulario(props)) {
			const ibptax = converterAtualizarIbptaxParaApi(values);
			setExibirLoading(true);

			await asyncUpdateDadosAtualizarIbptax(
				ibptax,
				async () => {
					if (values.arquivos?.length > 0) {
						await asyncAdicionarArquivosAtualizarNcm(values.arquivos[0]);

						let i;
						for (i = 0; i < values.arquivos.length; i++) {
							setQuantidadeArquivosProcessados((quantidadeArquivosProcessados) => quantidadeArquivosProcessados + 1);
							asyncAdicionarArquivosIbptax(values.arquivos[i]);
						}

						if (isAfter(new Date(), parseISO(values.dataVigencia))) {
							await new Promise((resolve, reject) => {
								asyncProcessarIbptaxEmVigor(resolve, reject);
							});
						}

						resetForm({ values: values });
						setAdicionouArquivo(false);
						setExibirLoading(false);
					}
				},
				() => {
					setExibirLoading(false);
				},
				false
			);
		} else {
			!values.arquivos?.length && notify('Nenhum arquivo informado', ToastTypes.ERROR);
			props.errors?.versaoDiferente && notify('As versão dos arquivos são diferentes, verifique', ToastTypes.ERROR);
		}
	}

	return (
		<Modal header="Dados IBPTax" visible={visible} onHide={onHide}>
			<ModalLoadingTransmissao
				visible={exibirLoading}
				message={`Processando arquivos IBPTax (${quantidadeArquivosProcessados} de ${values.arquivos?.length ?? 0})`}
			/>
			<Form className="card-default screen-max-width">
				<FormActions className="screen-max-width">
					<ButtonCancelar
						estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
						onClick={cancelar}
					/>
					<ButtonSalvar disabled={!dirty} onClick={salvar} />
				</FormActions>
				<FormContent>
					<Grid>
						<Field
							sm="12"
							md="12"
							lg="4"
							xl="4"
							component={InputDate}
							label="Data início vigência"
							name="dataVigencia"
							obrigatorio
							useFormErrors={false}
							errors={errors.dataVigencia}
							useFormTouched={false}
							touched={!!errors.dataVigencia}
							onChange={(element) => {
								setFieldValue('dataVigencia', element.target.value);
							}}
							value={values.dataVigencia}
						/>
						<Col sm={12} md={12} lg={12} xl={12} style={{ padding: 0 }}>
							<section
								className="dropzone"
								style={{
									margin: '10px',
									border: '2px dashed #A4A4A4',
									borderRadius: '10px',
								}}
							>
								<FileUpload
									name="arquivos"
									multiple
									auto
									customUpload
									uploadHandler={onUpload}
									invalidFileSizeMessageSummary=" "
									invalidFileSizeMessageDetail=" "
									chooseOptions={{
										label: 'Adicionar arquivos',
										icon: false,
										className: 'custom-choose-options-file-upload',
									}}
									onRemove={({ file }) => onRemove(file)}
									itemTemplate={(file, props) => (
										<div style={{ display: 'flex', width: '100%' }}>
											<div style={{ flex: '1', display: 'flex' }}>{`${file.name}  (${formatarTamanhoArquivo(
												file.size
											)})`}</div>
											{adicionouArquivo ? <ButtonExcluirTable title={null} onClick={props.onRemove} /> : null}
										</div>
									)}
									emptyTemplate={
										values.arquivos?.length > 0 ? (
											values.arquivos?.map((arquivo) => (
												<div
													key={`${arquivo.name}_${arquivo.size}`}
													style={{ display: 'flex', width: '100%', padding: '0.5rem 1rem' }}
												>
													<div style={{ flex: '1', display: 'flex' }}>
														{`${arquivo.name} (${formatarTamanhoArquivo(arquivo.size)})`}
													</div>
												</div>
											))
										) : (
											<div
												style={{
													height: '100%',
													width: '100%',
													padding: '10px',
													display: 'flex',
													justifyContent: 'center',
													flexDirection: 'column',
													alignItems: 'center',
													opacity: '1',
												}}
											>
												<AiOutlineCloudUpload size={50} color={`${colors.principal}`} />
												<div
													style={{
														color: colors.principal,
														fontSize: '16px',
														fontWeight: 'bold',
													}}
												>
													Ou arraste e solte o documento aqui
												</div>
											</div>
										)
									}
								/>
							</section>
						</Col>
					</Grid>
				</FormContent>
			</Form>
		</Modal>
	);
}

const ModalAtualizarIbptax = withFormik({
	enableReinitialize: true,
	validateOnChange: true,
	validateOnBlur: false,

	mapPropsToValues() {
		return initialvalue;
	},

	validate(values) {
		const errors = {};

		if (!values.dataVigencia) {
			errors.dataVigencia = mensagensDeValidacao.OBRIGATORIO;
		}
		if (!values.arquivos?.length) {
			errors.arquivos = mensagensDeValidacao.OBRIGATORIO;
		}

		if (values.arquivos) {
			const arquivoVersoes = values.arquivos?.map((arquivo) => arquivo.name?.substring(14, 20));
			const isVersaoDiferente = arquivoVersoes?.some((versao) => versao !== arquivoVersoes[0]);

			if (isVersaoDiferente) {
				errors.versaoDiferente = true;
			}
		}

		return errors;
	},

	handleSubmit: () => {},
})(ModalAtualizarIbptaxView);

export { ModalAtualizarIbptax };
