import { colors } from 'Common';
import { tipoCampos } from 'components';

export const optionsFiltroAvancado = [
	{ label: 'Número', name: 'numero', type: tipoCampos.INTEGER },
	{ label: 'Data', name: 'emissao', type: tipoCampos.DATE },
	{ label: 'Cliente', name: 'tomadorNome', type: tipoCampos.STRING },
	{ label: 'OrdemServico', name: 'ordemServicoNumero', type: tipoCampos.STRING },
	{
		label: 'Situação',
		name: 'situacao',
		type: tipoCampos.SELECT,
		optionSelect: [
			{ label: 'Pendente', value: 'PENDENTE' },
			{ label: 'Aguardando', value: 'AGUARDANDO' },
			{ label: 'Transmitido', value: 'TRANSMITIDO' },
			{ label: 'Rejeitado', value: 'REJEITADO' },
			{ label: 'Cancelado', value: 'CANCELADO' },
		],
	},
	{ label: 'Valor', name: 'total', type: tipoCampos.INTEGER },
];

export const ClassNameTabViewColor = {
	PENDENTE: 'tab-view-nfse-pendente',
	AGUARDANDO: 'tab-view-nfse-aguardando',
	TRANSMITIDO: 'tab-view-nfse-transmitido',
	REJEITADO: 'tab-view-nfse-rejeitado',
	CANCELADO: 'tab-view-nfse-cancelado',
};

export const SelectableCards = {
	PENDENTE: 'PENDENTE',
	AGUARDANDO: 'AGUARDANDO',
	TRANSMITIDO: 'TRANSMITIDO',
	REJEITADO: 'REJEITADO',
	CANCELADO: 'CANCELADO',
};

export const Colors = {
	PENDENTE: {
		lightColor: colors.azulLight,
		strongColor: colors.azul,
	},
	AGUARDANDO: {
		lightColor: colors.cinzaBackground,
		strongColor: colors.cinzaDark,
	},
	TRANSMITIDO: {
		lightColor: colors.verdeLight,
		strongColor: colors.verde,
	},
	REJEITADO: {
		lightColor: colors.amareloLight,
		strongColor: colors.amarelo,
	},
	CANCELADO: {
		lightColor: colors.vermelhoLight,
		strongColor: colors.vermelho,
	},
};

export const SituacaoNFSE = {
	PENDENTE: {
		enumerator: 'PENDENTE',
		name: 'Não enviada',
		description: 'Nota fiscal ainda não foi transmitida para o Sefaz',
		lightColor: colors.azulLight,
		strongColor: colors.azul,
	},
	AGUARDANDO: {
		enumerator: 'AGUARDANDO',
		name: 'Aguardando',
		description: 'A nota fiscal está sendo processada pelo Sefaz',
		lightColor: colors.cinzaBackground,
		strongColor: colors.cinzaDark,
	},
	TRANSMITIDO: {
		enumerator: 'TRANSMITIDO',
		name: 'Transmitida',
		description: 'Nota fiscal transmitida com sucesso',
		lightColor: colors.verdeLight,
		strongColor: colors.verde,
	},
	REJEITADO: {
		enumerator: 'REJEITADO',
		name: 'Rejeitada',
		description: 'Nota fiscal rejeitada',
		lightColor: colors.amareloLight,
		strongColor: colors.amarelo,
	},
	CANCELADO: {
		enumerator: 'CANCELADO',
		name: 'Cancelada',
		description: 'Nota fiscal foi cancelada',
		lightColor: colors.vermelhoLight,
		strongColor: colors.vermelho,
	},
};

export const Situacao = {
	PENDENTE: 'PENDENTE',
	AGUARDANDO: 'AGUARDANDO',
	TRANSMITIDO: 'TRANSMITIDO',
	REJEITADO: 'REJEITADO',
	CANCELADO: 'CANCELADO',
};

export const SituacaoNFSECards = {
	PENDENTE: 'PENDENTE',
	AGUARDANDO: 'AGUARDANDO',
	TRANSMITIDO: 'TRANSMITIDO',
	REJEITADO: 'REJEITADO',
	CANCELADO: 'CANCELADO',
};

export const IndPres = {
	NAO_SE_APLICA: 'NAO_SE_APLICA',
	OPERACAO_PRESENCIAL: 'OPERACAO_PRESENCIAL',
	OPERACAO_PELA_INTERNET: 'OPERACAO_PELA_INTERNET',
	OPERACAO_POR_TELEATENDIMENTO: 'OPERACAO_POR_TELEATENDIMENTO',
	OPERACAO_PRESENCIAL_FORA_DO_ESTABELECIMENTO: 'OPERACAO_PRESENCIAL_FORA_DO_ESTABELECIMENTO',
	OUTROS: 'OUTROS',
};

export const CADASTROURL = '/nfses/cadastro';
export const PESQUISAURL = '/nfses';

export const MessagesErrors = {
	OBRIGATORIO: 'Obrigatório',
	CAMPO_OBRIGATORIO: 'Campo obrigatório',
};
