import { colors } from 'Common';
import { Modal } from 'components';

const containerStyle = { width: '100%', marginTop: '1.5rem' };
const titleStyle = { fontWeight: 'bold', color: colors.principal, fontSize: '16px' };
const textStyle = { marginTop: '14px', paddingLeft: '24px' };

function ModalAjudaTabelaPreco({ visible, onHide }) {
	return (
		<Modal
			header="Informações importantes e funcionamento da tabela de preço"
			visible={visible}
			onHide={onHide}
			closeOnOverlayClick
			closeOnEsc
		>
			<div style={{ width: '100%', margin: '1rem 0rem' }}>
				<div style={containerStyle}>
					<h2>
						<span style={titleStyle}>Prioridade do uso da tabela de preço</span>
						<span style={{ marginLeft: '4px', fontSize: '12px' }}>(em ordem decrescente)</span>
					</h2>

					<ol>
						<li>Cliente</li>
						<li>Região</li>
						<li>Vendedor</li>
						<li>Filial</li>
					</ol>
				</div>

				<div style={containerStyle}>
					<h2 style={titleStyle}>Forma de aplicação do desconto/acréscimo</h2>

					<p style={textStyle}>
						É possível adicionar descontos ou acréscimos automatizados de acordo com as parametrizações adicionais da
						tabela de preço, presentes nas configurações gerais. Sendo elas:
					</p>

					<ul>
						<li>Utiliza acréscimo ou desconto por condição na tabela de preço</li>
						<li>Utiliza desconto por quantidade na tabela de preço</li>
					</ul>

					<p style={{ ...textStyle, marginTop: '24px' }}>
						De acordo com o que foi configurado, a formação de preço considera a seguinte ordem de aplicação:
					</p>

					<ol>
						<li>
							Preço de venda na tabela de preço, caso o produto estiver em uma tabela promocional, o preço da tabela
							promocional tem prioridade.
						</li>
						<li>Aplica o acréscimo/desconto por condição e o desconto por quantidade informada</li>
					</ol>

					<p style={textStyle}>
						{`Após a formação do preço de venda, os valores podem ser alterados aplicando descontos ou acréscimos
						diretamente pelos módulos de venda. Sendo que, o desconto aplicado pode ser limitado pelo campo "Desconto máximo" da
						tabela de preço.`}
					</p>

					<p style={textStyle}>
						{`Por fim, em relação ao campo "Desconto máximo", o desconto aplicado é sempre o menor percentual entre a tabela de preço e o vendedor. Já no campo de "Comissão %", o percentual aplicado a tabela de preço tem prioridade`}
					</p>
				</div>
			</div>
		</Modal>
	);
}

export { ModalAjudaTabelaPreco };
