import { colors } from 'Common';

export const SERVICOS_MODULOS = {
	ORDEM_SERVICO: 'ORDEM_SERVICO',
	NFSE: 'NFSE',
};

export const OPTIONS_SELECT = [
	{
		label: 'Ordem de serviço',
		value: SERVICOS_MODULOS.ORDEM_SERVICO,
	},
	{
		label: 'NFS-e',
		value: SERVICOS_MODULOS.NFSE,
	},
];

export const SERVICOS_CORES = {
	vermelho: colors.vermelho,
	vermelhoClaro: colors.vermelhoLight,
	verde: colors.verde,
	verdeClaro: colors.verdeLight,
	azul: colors.principal,
	preto: colors.brancoTransparente,
	cinza: colors.cinza,
	branco: colors.cinzaLight,
};
