import { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Checkbox } from 'primereact/checkbox';
import { Menu } from 'primereact/menu';
import { Icon } from '@iconify/react';

import {
	DescricaoFiltroAvancado,
	NenhumRegistroEncontrado,
	PesquisaAvancada,
	Tutorial,
	tutorialStepsListagens,
	ButtonEditarTable,
	ButtonExcluirTable,
	Badge,
	Col,
	Form,
	FormActions,
	ButtonNovo,
	FormContent,
	Grid,
	InputSearch,
	Button,
	If,
	Paginacao,
} from 'components';

import {
	usuarioPossuiPermissao,
	permissoes,
	recursos,
	removerCaracteres,
	construirUrl,
	services,
	removerElemento,
	buscarConfiguracaoUsuario,
	configuracoesUsuario,
	salvarConfiguracaoUsuario,
	formatarNcm,
	colors,
} from 'Common';

import { useContextPesquisa } from 'views/Util/Context/ContextPesquisa';
import { atualizarUrl } from '../../../Util';
import { confirmarExclusao } from '../../../Util/ExclusaoDeRegistros';

import { optionsFiltroAvancado } from './Util/constantes';
import { converterNcmApiParaApi } from './Util/ncmConverter';
import { asyncCreateNcmExtensao, asyncDeleteNcm, asyncGetNcm, asyncGetNcms } from './Requests';
import ModalReplicarTributacao from './components/ModalReplicarTributacao';

import './Styles/index.css';

const Colors = {
	bgAtivo: colors.verdeLight,
	textAtivo: colors.verde,
	bgInativo: colors.vermelhoLight,
	textInativo: colors.vermelho,
};

const styleButtonOptions = {
	borderRadius: '50%',
	padding: '5px',
	width: '30px',
	height: '30px',
	margin: '2px',
};

function Ncm(props) {
	const [registros, setRegistros] = useState([]);

	const {
		valorPesquisa,
		setValorPesquisa,
		sortField,
		setSortField,
		sortOrder,
		setSortOrder,
		page,
		setPage,
		rows,
		setRows,
		filtroAvancado,
		setFiltroAvancado,
		descricaoFiltroAvancado,
		setDescricaoFiltroAvancado,
		exibirBloqueadas,
		setExibirBloqueadas,
	} = useContextPesquisa();

	const [totalElements, setTotalElements] = useState(0);
	const [exibirModalReplicarTributacao, setExibirModalReplicarTributacao] = useState(false);
	const [ncmOrigemParaModal, setNcmOrigemParaModal] = useState(null);
	const [menu, setMenu] = useState(null);
	const [tutorialVisible, setTutorialVisible] = useState(false);
	const [rowSelected, setRowSelected] = useState({});
	const [firstRender, setFirstRender] = useState(true);

	const deveExibirTutorial = buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS);
	const podeInserir = usuarioPossuiPermissao(recursos.TRIBUTACAO, permissoes.INSERIR);
	const podeExcluir = usuarioPossuiPermissao(recursos.TRIBUTACAO, permissoes.EXCLUIR);

	const sortFieldLocal = sortField?.length > 0 ? sortField : 'codigoNcm.codigo';
	const itensMenu = montarItensMenu();

	const pesquisarCallback = useCallback(() => {
		if (!firstRender) {
			pesquisar();
		}
	});

	useEffect(() => {
		pesquisar();

		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS, false, null, false);
		}

		setTimeout(() => {
			if (document.getElementById('InputSearchNcmListagem')) {
				document.getElementById('InputSearchNcmListagem').focus();
			}
		}, 500);
	}, []);

	useEffect(() => {
		pesquisarCallback();
	}, [exibirBloqueadas, page, rows, sortOrder, sortFieldLocal, filtroAvancado]);

	function buscarFiltro() {
		const pesquisaCodigo = removerCaracteres(valorPesquisa, ['.']);

		let result = `?query=(codigoNcm.codigo=contains="*${pesquisaCodigo}*",descricao=contains="*${valorPesquisa}*",situacao=contains="*${valorPesquisa}*")`;

		if (filtroAvancado) {
			result += `;${filtroAvancado}`;
		}

		return result;
	}

	async function pesquisar() {
		let filtro = buscarFiltro();
		filtro += String(`${exibirBloqueadas ? '' : ';situacao=="ATIVO"'}`);

		const url = construirUrl(
			`${services.GESTOR}/v1/tributacoes/ncm/resumo`,
			filtro,
			rows,
			page,
			sortOrder > 0 ? `${sortFieldLocal},asc` : `${sortFieldLocal},desc`
		);

		await asyncGetNcms(url, ({ data: tributacao }) => {
			setRegistros(tributacao.content);
			setTotalElements(tributacao.totalElements);
			setFirstRender(false);

			if (!exibirBloqueadas && (filtroAvancado === 'situacao=="INATIVO"' || filtroAvancado === 'situacao!="ATIVO"')) {
				setExibirBloqueadas(true);
			}
		});
	}

	async function onPesquisarFiltroAvancado(filtro) {
		setFiltroAvancado(filtro);
	}

	function onChangeCheckboxExibirInativas(element) {
		setExibirBloqueadas(element.checked);
	}

	function aplicarEstiloInativa(row, field) {
		if (row.situacao === 'INATIVO') {
			return (
				<span
					style={{
						fontStyle: 'italic',
						opacity: '0.7',
						display: 'flex',
						wordBreak: 'break-word',
						maxWidth: props.isMobile ? '70%' : '100%',
						textAlign: props.isMobile ? 'end' : 'start',
					}}
				>
					{field}
				</span>
			);
		}
		return (
			<span
				style={{
					display: 'flex',
					wordBreak: 'break-word',
					maxWidth: props.isMobile ? '70%' : '100%',
					textAlign: props.isMobile ? 'end' : 'start',
				}}
			>
				{field}
			</span>
		);
	}

	function onEditar(row) {
		atualizarUrl(props.history, '/tributacoes/ncm/cadastro', row.id);
	}

	function onExcluir(row) {
		confirmarExclusao(() => asyncExcluirRegistro(row));
	}

	async function asyncExcluirRegistro(registro) {
		await asyncDeleteNcm(registro.id, () => {
			setRegistros(removerElemento(registros, registro));
			setTotalElements(totalElements - 1);
		});
	}

	function renderOpcoes(row) {
		return (
			<div style={{ display: 'flex' }}>
				<ButtonEditarTable onClick={() => onEditar(row)} />
				<ButtonExcluirTable onClick={() => onExcluir(row)} podeExcluir={podeExcluir} />
				<Button
					className="p-button-secondary"
					icon="fa fa-ellipsis-v"
					style={styleButtonOptions}
					title="Opções"
					disabled={row.situacao === 'INATIVO' || !podeInserir}
					aria-controls="popup_menu"
					aria-haspopup
					onClick={(event) => {
						setRowSelected(row);
						menu.toggle(event);
					}}
				/>
			</div>
		);
	}

	function montarItensMenu() {
		const itens = [];
		itens.push({
			label: 'Gerar NCM com extenção',
			icon: 'pi pi-copy',
			command: () => {
				gerarNcmComExtenção(rowSelected);
			},
		});
		itens.push({
			label: 'Replicar tributação com está NCM',
			icon: 'pi pi-sitemap',
			command: () => {
				setExibirModalReplicarTributacao(true);
				setNcmOrigemParaModal({ label: rowSelected.codigoNcm?.codigo, value: rowSelected.id });
			},
		});
		return itens;
	}

	async function gerarNcmComExtenção(row) {
		await asyncGetNcm(row.id, async ({ data: ncm }) => {
			const ncmConvertida = converterNcmApiParaApi(ncm);
			await asyncCreateNcmExtensao(ncmConvertida, ({ data: ncmWithExtensao }) => {
				atualizarUrl(props.history, '/tributacoes/ncm/cadastro', ncmWithExtensao.id);
			});
		});
	}

	function onPageChange(event) {
		setRows(event.rows);
		setPage(event.page);
	}

	function onSort(event) {
		setSortOrder(event.sortOrder);
		setSortField(event.sortField);
	}

	function renderSituacao(row) {
		const styleBackground = {
			borderRadius: '20px',
			padding: '0.2rem 1.1rem',
		};

		const styleDescription = {
			fontStyle: row.situacao === 'INATIVO' ? 'italic' : '',
			opacity: row.situacao === 'INATIVO' ? '0.7' : '',
			margin: row.situacao === 'ATIVO' ? '3px 16px' : '3px 10px',
		};

		switch (row.situacao) {
			case 'ATIVO': {
				return Badge(Colors.textAtivo, Colors.bgAtivo, 'Ativo', styleBackground, styleDescription);
			}
			case 'INATIVO': {
				return Badge(Colors.textInativo, Colors.bgInativo, 'Inativo', styleBackground, styleDescription);
			}
			default:
				return row.situacao;
		}
	}

	return (
		<>
			<Tutorial
				steps={tutorialStepsListagens}
				showSkipButton
				continuous
				disableScrolling
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>
			<Form header="NCM">
				<FormActions>
					<ButtonNovo
						className="step-listagem-novo"
						label="Nova NCM"
						onClick={() => {
							props.history.push('/tributacoes/ncm/cadastro');
						}}
						podeInserir={podeInserir}
					/>
					<Button
						label="Replicar tributação"
						className="p-button-success"
						icon={<Icon icon="carbon:ibm-data-replication" style={{ marginRight: '0.5rem' }} />}
						onClick={() => setExibirModalReplicarTributacao(true)}
						style={{ margin: '5px' }}
					/>
				</FormActions>
				<FormContent>
					<Grid justifyCenter>
						<InputSearch
							id="InputSearchNcmListagem"
							className="step-listagem-input-search"
							onPesquisar={() => pesquisar(0)}
							value={valorPesquisa}
							onChange={(value) => setValorPesquisa(value)}
						/>
						<Col sm="12" md="4" lg="3" xl="3" className="step-listagem-filtro-avancado">
							<PesquisaAvancada
								className="step-listagem-filtro-avancado"
								optionsFiltros={optionsFiltroAvancado}
								onPesquisarClick={onPesquisarFiltroAvancado}
								onChangeFiltroRsql={(rsql) => setFiltroAvancado(rsql)}
								onChangeDescricaoFiltro={(descricao) => setDescricaoFiltroAvancado(descricao)}
							/>
						</Col>
					</Grid>
					<DescricaoFiltroAvancado texto={descricaoFiltroAvancado} />
					<Menu model={itensMenu} popup style={{ width: '200px' }} ref={(elemento) => setMenu(elemento)} />
					<DataTable
						className="table"
						rowClassName="table-row"
						cellClassName="table-row-cell"
						responsive
						value={registros}
						sortField={sortFieldLocal}
						sortOrder={sortOrder}
						onSort={onSort}
						emptyMessage={<NenhumRegistroEncontrado />}
						header={
							<span style={{ display: 'flex', justifyContent: 'flex-end' }}>
								<Checkbox
									name="exibirBloqueadas"
									inputId="exibirBloqueadas"
									checked={exibirBloqueadas}
									onChange={onChangeCheckboxExibirInativas}
								/>
								<label htmlFor="exibirBloqueadas" className="p-checkbox-label">
									Exibir tributações inativas
								</label>
							</span>
						}
					>
						<Column
							className="step-listagem-order"
							field="codigoNcm.codigo"
							header="NCM"
							body={(row) =>
								aplicarEstiloInativa(
									row,
									row?.extensao && row?.extensao !== null
										? `${formatarNcm(row.codigoNcm?.codigo)}-${row?.extensao}`
										: formatarNcm(row.codigoNcm?.codigo)
								)
							}
							sortable
							style={{
								textOverflow: 'ellipsis',
								overflow: 'hidden',
								width: '130px',
							}}
						/>
						<Column
							field="descricao"
							header="Descrição"
							body={(row) => aplicarEstiloInativa(row, row.descricao)}
							sortable
						/>
						<Column field="situacao" header="Status" body={renderSituacao} sortable style={{ width: '15rem' }} />
						<Column className="step-listagem-acoes" body={renderOpcoes} header="Ações" style={{ width: '7em' }} />
					</DataTable>
					<Paginacao totalElements={totalElements} rows={rows} page={page} onPageChange={onPageChange} />
				</FormContent>
			</Form>
			<If test={exibirModalReplicarTributacao}>
				<ModalReplicarTributacao
					visible={exibirModalReplicarTributacao}
					onHide={() => {
						setExibirModalReplicarTributacao(false);
						setNcmOrigemParaModal(null);
					}}
					styleModal={{ maxWidth: '1200px' }}
					showCloseIcon={false}
					ncmOrigemParaModal={ncmOrigemParaModal}
					{...props}
				/>
			</If>
		</>
	);
}

function mapStateToProps(state) {
	return {
		isMobile: state.dispositivo.isMobile,
	};
}

export default connect(mapStateToProps)(Ncm);
