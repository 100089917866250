import { Field, withFormik } from 'formik';
import { Sidebar } from 'primereact/sidebar';
import propTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';
import { useContextPesquisa } from 'views/Util/Context/ContextPesquisa';
import { removerElementoPorId } from '../../Common/Array';
import usePrevious from '../../Common/Hooks/usePrevious';
import { removerCaracteres } from '../../Common/ManipulacaoDeString';
import { caracteresEspeciaisRSQL } from '../../Common/ManipulacaoDeString/consts';
import NormalButton from '../../components/Button';
import CampoPersonalizado from '../../components/CampoPersonalizado';
import Paper from '../../components/Paper';
import Col from '../Col';
import Grid from '../Grid';
import If from '../If';
import Dropdown from '../select/Dropdown';
import './Styles/index.css';
import { buscarDescricaoFiltro, buscarOpcoes, buscarOptionsSelect, convertToRSQL } from './Util/funcoes.js';
import { colors } from 'Common';

function PesquisaAvancada(props) {
	const { filtros, setFiltros, filtroSelecionado, setFiltroSelecionado } = useContextPesquisa();
	const {
		onChangeDescricaoFiltro,
		limparFiltro,
		menuPortalTarget = document.body,
		label = 'Pesquisa avançada',
		titleButton = 'Pesquisa avançada',
		styleButton,
		onOpen,
		disabledButton = false,
		forceClearFilter,
		setForceClearFilter,
	} = props;
	const [visible, setVisible] = useState(false);
	const prevProps = usePrevious(props);
	const prevFiltros = usePrevious(filtros);

	useEffect(() => {
		if (visible) {
			setTimeout(() => {
				document.getElementById('PesquisaAvancadaDropdownAddFiltro')?.getElementsByTagName('input')[0]?.focus();
			}, 500);
		}
	}, [visible]);

	useEffect(() => {
		if (filtros !== prevFiltros && onChangeDescricaoFiltro) {
			onChangeDescricaoFiltro(buscarDescricaoFiltro(filtros));
		}

		if (prevProps?.limparFiltro !== limparFiltro && limparFiltro) {
			onLimparClick();
		}
	}, [prevProps, prevFiltros]);

	useEffect(() => {
		if (forceClearFilter) {
			onLimparClick();

			setForceClearFilter(false);
		}
	}, [forceClearFilter]);

	function renderizarFiltros() {
		return (
			filtros &&
			filtros.map((filtro, index) => {
				return renderSelectOperacoes(filtro, index);
			})
		);
	}

	function renderSelectOperacoes(filtro, index) {
		return (
			<div key={index} style={{ padding: '7px 0px', borderBottom: '1px solid #9999', borderTop: '1px solid #9999' }}>
				{montarFiltroHeader(filtro)}
				<Field
					col="12"
					component={Dropdown}
					label="Condição"
					colStyle={{ padding: '0px' }}
					renderizarFiltros
					name={filtro.name}
					value={filtro.operacao}
					options={buscarOpcoes(filtro)}
					onChange={(operacaoSelecionada) => {
						adicionarOperacaoAoFiltro(operacaoSelecionada, filtro.name);
					}}
					menuPortalTarget={menuPortalTarget}
				/>
				{filtro.operacao && renderizarCampoFiltro(filtro)}
			</div>
		);
	}

	function adicionarFiltro(filtro) {
		setFiltros([...filtros, { ...filtro, value: '' }]);
		setFiltroSelecionado(filtro);
		props.setFieldValue('filtro', filtro);
	}

	function removerFiltro(el) {
		const novaLista = removerElementoPorId(filtros, el, 'name');
		setFiltros(novaLista);
	}

	function montarFiltroHeader(filtro) {
		return (
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					marginBottom: '8px',
				}}
			>
				<span style={{ fontSize: '15px', fontWeight: 'bold' }}>{filtro.label}</span>
				<span style={{ cursor: 'pointer' }} onClick={() => removerFiltro(filtro)}>
					<MdClose size="20px" />
				</span>
			</div>
		);
	}

	function adicionarOperacaoAoFiltro(operacaoSelecionada, valueFiltro) {
		var novosFiltros = filtros.map(function (filtro) {
			if (filtro.name === valueFiltro) {
				filtro.operacao = operacaoSelecionada.value;
				filtro.operacaoLabel = operacaoSelecionada.label;
				filtro.value = '';
			}
			return filtro;
		});
		setFiltros(novosFiltros);
	}

	function acionarInputAoFiltro(e) {
		var novosFiltros = filtros.map((filtro) => {
			if (filtro.name === e.id) {
				if (filtro.type === 'decimal' || filtro.type === 'integer' || filtro.type === 'date') {
					filtro.value = e.value;
				} else if (filtro.type === 'cpfCnpj') {
					filtro.value = removerCaracteres(e.value || '', [...caracteresEspeciaisRSQL, '.', '-', '/']);
				} else {
					filtro.value = removerCaracteres(e.value || '', caracteresEspeciaisRSQL);
				}
			}
			return filtro;
		});
		setFiltros(novosFiltros);
	}

	function acionarInputEntreInicialAoFiltro(value, filtroName) {
		var novosFiltros = filtros.map(function (filtro) {
			if (filtro.name === filtroName) {
				filtro.valueInicial = value;
			}
			return filtro;
		});
		setFiltros(novosFiltros);
	}

	function acionarInputEntreFinalAoFiltro(value, filtroName) {
		var novosFiltros = filtros.map(function (filtro) {
			if (filtro.name === filtroName) {
				filtro.valueFinal = value;
			}
			return filtro;
		});
		setFiltros(novosFiltros);
	}

	function renderizarCampoFiltro(filtro) {
		switch (filtro.operacao) {
			case 'entre':
				return inputFieldEntre(filtro);
			default:
				return inputField(filtro);
		}
	}

	function inputField(filtro) {
		return (
			<CampoPersonalizado
				sm="12"
				md="12"
				lg="12"
				xl="12"
				id={filtro.name}
				name={filtro.name}
				options={filtro.optionSelect}
				label="Valor"
				type={filtro.type}
				value={{ value: filtro.value }}
				hiddenOptionsButton
				touched
				colStyle={{ padding: '5px 0px' }}
				onChange={acionarInputAoFiltro}
				menuPortalTarget={menuPortalTarget}
			/>
		);
	}

	function inputFieldEntre(filtro) {
		return (
			<Col col="12">
				<Grid>
					<CampoPersonalizado
						col="6"
						sm="6"
						md="6"
						lg="6"
						xl="6"
						id={filtro.name}
						name={filtro.name}
						options={filtro.optionSelect}
						label="Valor inicial"
						type={filtro.type}
						value={{ value: filtro.valueInicial }}
						hiddenOptionsButton
						touched
						colStyle={{ padding: '5px 2px' }}
						onChange={(e) => acionarInputEntreInicialAoFiltro(e.value, filtro.name)}
					/>
					<CampoPersonalizado
						col="6"
						sm="6"
						md="6"
						lg="6"
						xl="6"
						id={filtro.name + 'final'}
						name={filtro.name + 'final'}
						options={filtro.optionSelect}
						label="Valor final"
						type={filtro.type}
						value={{ value: filtro.valueFinal }}
						touched
						colStyle={{ padding: '5px 0px' }}
						hiddenOptionsButton
						onChange={(e) => acionarInputEntreFinalAoFiltro(e.value, filtro.name)}
					/>
				</Grid>
			</Col>
		);
	}

	function onPesquisarClick() {
		const rsql = convertToRSQL(filtros);

		setVisible(false);

		props.onPesquisarClick(rsql);
	}

	function onLimparClick() {
		const rsql = convertToRSQL([]);

		setFiltros([]);
		setFiltroSelecionado(null);

		props.onPesquisarClick(rsql);
	}

	function handleClickPesquisaAvancada() {
		if (typeof onOpen === 'function') {
			onOpen();
		}

		setVisible((state) => !state);
	}

	return (
		<>
			<Sidebar
				style={{ width: '310px', padding: '2px', overflowY: 'scroll', height: '100%' }}
				blockScroll
				visible={visible}
				position="right"
				baseZIndex={1000000}
				onHide={() => {
					setVisible(false);
					props.onChangeFiltroRsql(convertToRSQL(filtros));
				}}
				appendTo="self"
				dismissable={false}
			>
				<Paper header="Pesquisa avançada">
					<div style={{ textAlign: 'end' }}>
						<span style={{ fontSize: '11px', color: colors.principal, cursor: 'pointer' }} onClick={onLimparClick}>
							Limpar filtros aplicados
						</span>
					</div>

					<Field
						col="12"
						component={Dropdown}
						name="adicionar_filtro"
						label="Adicionar filtro por"
						value={filtroSelecionado}
						showClear={false}
						colStyle={{ padding: '0px 0px 14px 0px' }}
						onChange={(e) => adicionarFiltro(e)}
						idInput="PesquisaAvancadaDropdownAddFiltro"
						options={buscarOptionsSelect(props.optionsFiltros, filtros)}
						menuPortalTarget={menuPortalTarget}
					/>
					{renderizarFiltros()}
					<NormalButton
						style={{ marginTop: '10px', width: '100%' }}
						label="Pesquisar"
						icon="fa fa-search"
						onClick={onPesquisarClick}
					/>
				</Paper>
			</Sidebar>
			<NormalButton
				label={label}
				icon="fa fa-filter"
				style={{ margin: '0', display: 'relative', height: '32px', ...styleButton }}
				title={titleButton}
				onClick={handleClickPesquisaAvancada}
				disabled={disabledButton}
			>
				<If test={filtros.length > 0}>
					<i className="filtro-notification" />
				</If>
			</NormalButton>
		</>
	);
}

PesquisaAvancada = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	handleSubmit: () => {},
})(PesquisaAvancada);

PesquisaAvancada.propTypes = {
	optionsFiltros: propTypes.array,
	onPesquisarClick: propTypes.func,
	onChangeFiltroRsql: propTypes.func,
	onChangeDescricaoFiltro: propTypes.func,
};

export default PesquisaAvancada;
