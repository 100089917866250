import { useState } from 'react';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import Dropzone from 'react-dropzone';

import { colors } from 'Common';

import If from '../../../../../components/If';
import { alert } from '../../../../../components/Toast';
import ModalCropImage from '../ModalCropImage';

const selecionarLogotipoStyle = {
	background: 'none',
	border: 'none',
	fontWeight: 'bold',
	boxShadow: 'none',
	color: colors.principalDark,
};

const imgStyle = {
	width: '200px',
	height: '127px',
	marginTop: '30px',
	borderRadius: '5px',
};

function LogotipoUpload({ onChange, loading, logotipo, podeEditarEmpresa }) {
	const [imagemOriginalUrl, setImagemOriginalUrl] = useState(null);
	const [modalCropImageVisible, setModalCropImageVisible] = useState(false);

	function onUploadLogotipo(files, error) {
		if (error) {
			alert('Problema ao adicionar a imagem', 'São permitidas imagens com tamanho máximo de 200KB e do tipo PNG');
		} else {
			setImagemOriginalUrl(URL.createObjectURL(files[0]));
			setModalCropImageVisible(true);
		}
	}

	function onHideModalCropImage(imagemCortada) {
		if (imagemCortada) {
			onChange(imagemCortada);
		}
		setImagemOriginalUrl(null);
		setModalCropImageVisible(false);
	}

	return (
		<>
			<div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
				<If test={loading}>
					<ProgressSpinner style={{ marginTop: '40px' }} />
				</If>
				<If test={logotipo}>
					<img style={imgStyle} src={logotipo} alt="" />
					<Dropzone
						multiple={false}
						accept="image/png"
						onDropAccepted={(e) => onUploadLogotipo(e, false)}
						onDropRejected={(e) => onUploadLogotipo(e, true)}
						maxSize={204800}
						disabled={!podeEditarEmpresa}
					>
						{({ getRootProps, getInputProps }) => (
							<div {...getRootProps()}>
								<input {...getInputProps()} />
								<Button
									label="SELECIONAR LOGOTIPO (.PNG)"
									style={{
										...selecionarLogotipoStyle,
										opacity: !podeEditarEmpresa ? '0.5' : '1',
									}}
									title={!podeEditarEmpresa ? 'Você não possui permissão para editar' : null}
								/>
							</div>
						)}
					</Dropzone>
				</If>
			</div>
			<If test={modalCropImageVisible}>
				<ModalCropImage onHide={onHideModalCropImage} visible={modalCropImageVisible} imagemUrl={imagemOriginalUrl} />
			</If>
		</>
	);
}

export default LogotipoUpload;
