import { min, parseISO } from 'date-fns';

export function ReplaceAt(string, parteAlterada, indexInicio, indexFim) {
	return string.substring(0, indexInicio) + parteAlterada + string.substring(indexFim, string.length);
}

export function addZeros(num, len) {
	let numberWithZeros = String(num);
	let counter = numberWithZeros.length;

	if (String(num).length > len) {
		return num;
	}

	while (counter < len) {
		numberWithZeros = '0' + numberWithZeros;
		counter++;
	}

	return numberWithZeros;
}

export function getSafeValue(originalValue, defaultValue = 0) {
	if (
		typeof originalValue !== 'number' ||
		isNaN(originalValue) ||
		originalValue === null ||
		originalValue === undefined
	) {
		return defaultValue;
	} else {
		return originalValue;
	}
}

export function generateOptionsQtdParcelas(minParcelas, maxParcelas) {	
	const result = [];
	if (minParcelas && maxParcelas){
		for (let i = minParcelas - 1; i < maxParcelas; i++) {
			result.push({ label: `${i + 1}x`, value: i + 1 });
		}
	}else{
		result.push({ label: `1x`, value: 1 });
	}	
	return result;
}
