import { colors } from 'Common';
import { tipoCampos } from 'components';

export const OPTIONS_FILTRO_AVANCADO_VENDEDORES = [
	{ label: 'Código', name: 'codigo', type: tipoCampos.INTEGER },
	{ label: 'Nome', name: 'nome', type: tipoCampos.STRING },
	{
		label: 'Tipo',
		name: 'tipo',
		type: tipoCampos.SELECT,
		optionSelect: [
			{ label: 'Vendedor', value: 'VENDEDOR' },
			{ label: 'Supervisor', value: 'SUPERVISOR' },
			{ label: 'Gerente', value: 'GERENTE' },
		],
	},
	{ label: 'Comissão', name: 'comissao', type: tipoCampos.DECIMAL },
	{
		label: 'Situação',
		name: 'situacao',
		type: tipoCampos.SELECT,
		optionSelect: [
			{ label: 'Ativo', value: 'ATIVO' },
			{ label: 'Inativo', value: 'INATIVO' },
		],
	},
];

export const TABELA_VENDENDORES_TIPO = {
	VENDEDOR: {
		value: 'VENDEDOR',
		label: 'Vendedor',
	},
	SUPERVISOR: {
		value: 'SUPERVISOR',
		label: 'Supervisor',
	},
	GERENTE: {
		value: 'GERENTE',
		label: 'Gerente',
	},
};

export const TABELA_VENDEDORES_SITUACAO = {
	ATIVO: 'ATIVO',
	INATIVO: 'INATIVO',
};

export const TABELA_VENDEDORES_VIEW_SITUACAO = {
	[TABELA_VENDEDORES_SITUACAO.ATIVO]: {
		name: 'Ativo',
		description: 'Vendedor ativo',
		lightColor: colors.azulLight,
		strongColor: colors.principal,
	},
	[TABELA_VENDEDORES_SITUACAO.INATIVO]: {
		name: 'Inativo',
		description: 'Vendedor inativo',
		lightColor: colors.vermelhoLight,
		strongColor: colors.vermelho,
	},
};
