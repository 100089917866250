import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { formatarDecimais, formatarMonetario, formatarMonetarioDecimais } from '../../../../../Common/Mascara';
import Button from '../../../../../components/Button';
import format from 'date-fns/format';
import { recursos, permissoes } from '../../../../../Common/Constantes/autorizacao';
import { buscarDadosLoginLocalStorage, usuarioPossuiPermissao } from '../../../../../Common/Autenticacao';
import NenhumRegistroEncontrado from '../../../../../components/NenhumRegistroEncontrado';
import { tipoMovimentacao, TipoMovimentacaoDocumento, tipoSaldo } from '../../Util/constantes';
import { Link } from 'react-router-dom';
import If from '../../../../../components/If';
import { Badge } from '../../../../../components/Badge';
import { connect } from 'react-redux';
import { Paginacao } from '../../../../../components';
import { Checkbox } from 'primereact/checkbox';
import { colors } from 'Common';

const styleButton = {
	borderRadius: '50%',
	padding: '5px',
	width: '30px',
	height: '30px',
	margin: '2px',
};

const styleButtonEfetivar = {
	borderRadius: '50%',
	paddingLeft: '4px',
	paddingTop: '2px',
	width: '30px',
	height: '30px',
	margin: '2px',
};

const Colors = {
	kBgLightGreen: colors.verdeLight,
	kTextGreen: colors.verde,
	kBgLightRed: colors.vermelhoLight,
	kTextRed: colors.vermelho,
	kBgLightBlue: colors.azulLight,
	kTextBlue: colors.azul,
};

function TabelaMovimentacoes(props) {
	const {
		registros,
		sortField,
		sortOrder,
		totalRecords,
		page,
		onPageChange,
		rows,
		setSortField,
		setSortOrder,
		isMobile,
		onEditItem,
		onRemoveItem,
		onEfetivar,
		exibirReservas,
		setExibirReservas,
	} = props;
	const podeExcluirLancamento = usuarioPossuiPermissao(recursos.ESTOQUE_MOVIMENTACOES, permissoes.EXCLUIR);
	const podeEditarLancamento = usuarioPossuiPermissao(recursos.ESTOQUE_MOVIMENTACOES, permissoes.EDITAR);
	const podeVisualizarVenda = usuarioPossuiPermissao(recursos.VENDAS_PEDIDOS, permissoes.VISUALIZAR);
	const podeVisualizarNotaEntrada = usuarioPossuiPermissao(recursos.COMPRAS_NOTA_ENTRADA, permissoes.VISUALIZAR);
	const podeVisualizarNFe = usuarioPossuiPermissao(recursos.VENDAS_NOTAS, permissoes.VISUALIZAR);
	const podeVisualizarNFCe = usuarioPossuiPermissao(recursos.VENDAS_NOTAS_CONSUMIDOR, permissoes.VISUALIZAR);
	const podeVisualizarOrdem = usuarioPossuiPermissao(recursos.SERVICOS_ORDEM_SERVICO, permissoes.VISUALIZAR);
	const decimaisQtd = buscarDadosLoginLocalStorage()?.filialConectada?.parametrosCadastros?.decimaisQtd ?? 3;
	const decimaisPreco = buscarDadosLoginLocalStorage()?.filialConectada?.parametrosCadastros?.decimaisPreco ?? 2;

	function atualizarOrdenacao(e) {
		setSortField(e.sortField);
		setSortOrder(e.sortOrder);
	}

	function renderDataField(elemento) {
		const dataFormatada = format(new Date(elemento.dataHora), 'dd/MM/yyyy HH:mm');
		return <span title={dataFormatada}>{dataFormatada}</span>;
	}

	function renderProdutoField(elemento) {
		let descricaoDocumento;
		let documentoTipo;

		if (elemento.documento?.documentoTipo === TipoMovimentacaoDocumento.NFCE) {
			documentoTipo = 'NFC-e';
		} else if (elemento.documento?.documentoTipo === TipoMovimentacaoDocumento.NFE) {
			documentoTipo = 'NF-e';
		} else if (elemento.documento?.documentoTipo === TipoMovimentacaoDocumento.NOTA_ENTRADA) {
			documentoTipo = 'Compra';
		} else if (elemento.documento?.documentoTipo === TipoMovimentacaoDocumento.ORDEM_SERVICO) {
			documentoTipo = 'Ordem';
		} else if (elemento.documento?.documentoTipo === TipoMovimentacaoDocumento.DEVOLUCAO_VENDA) {
			documentoTipo = 'Devolução da venda';
		} else {
			documentoTipo = 'Venda';
		}

		if (elemento.documento) {
			descricaoDocumento = `${documentoTipo} número ${elemento.documento.numero}`;
			if (elemento.documento.clienteNome) {
				descricaoDocumento += ` (${elemento.documento.clienteNome})`;
			}
		}

		return (
			<span
				title={elemento.produtoCodigo + ' - ' + elemento.produtoNome}
				style={{
					display: 'inline-block',
					paddingLeft: isMobile ? '20%' : '0',
					maxWidth: props.isMobile ? '80%' : '100%',
				}}
			>
				<Link to={{ pathname: `/produtos/cadastro/${elemento.produtoId}` }} title="Clique para acessar o produto">
					<div>{elemento.produtoCodigo + ' - ' + elemento.produtoNome}</div>
				</Link>
				{descricaoDocumento && (
					<div
						style={{
							fontSize: '11px',
							textOverflow: 'ellipsis',
							overflow: 'hidden',
							display: '-webkit-box',
							WebkitBoxOrient: 'vertical',
							WebkitLineClamp: 2,
							wordBreak: 'break-all',
						}}
						title={descricaoDocumento}
					>
						<If test={podeVisualizarVenda}>
							<If
								test={
									elemento.documento?.documentoTipo === TipoMovimentacaoDocumento.VENDA ||
									elemento.documento?.documentoTipo === TipoMovimentacaoDocumento.DEVOLUCAO_VENDA
								}
							>
								<Link to={{ pathname: `/pedido_venda/cadastro/${elemento.documento.id}` }}>{descricaoDocumento}</Link>
							</If>
						</If>
						<If test={podeVisualizarNFCe}>
							<If test={elemento.documento?.documentoTipo === TipoMovimentacaoDocumento.NFCE}>
								<Link to={{ pathname: `/nfces/cadastro/${elemento.documento.id}` }}>{descricaoDocumento}</Link>
							</If>
						</If>
						<If test={podeVisualizarNotaEntrada}>
							<If test={elemento.documento?.documentoTipo === TipoMovimentacaoDocumento.NOTA_ENTRADA}>
								<Link to={{ pathname: `/nota_entrada/cadastro/${elemento.documento.id}` }}>{descricaoDocumento}</Link>
							</If>
						</If>
						<If test={podeVisualizarNFe}>
							<If test={elemento.documento?.documentoTipo === TipoMovimentacaoDocumento.NFE}>
								<Link to={{ pathname: `/nfes/cadastro/${elemento.documento.id}` }}>{descricaoDocumento}</Link>
							</If>
						</If>
						<If test={podeVisualizarOrdem}>
							<If test={elemento.documento?.documentoTipo === TipoMovimentacaoDocumento.ORDEM_SERVICO}>
								<Link to={{ pathname: `/ordem_servico/cadastro/${elemento.documento.id}` }}>{descricaoDocumento}</Link>
							</If>
						</If>

						<If test={!podeVisualizarNFe}>
							<If test={elemento.documento?.documentoTipo === TipoMovimentacaoDocumento.NFE}>
								<span>{descricaoDocumento}</span>
							</If>
						</If>

						<If test={!podeVisualizarNFCe}>
							<If test={elemento.documento?.documentoTipo === TipoMovimentacaoDocumento.NFCE}>
								<span>{descricaoDocumento}</span>
							</If>
						</If>

						<If test={!podeVisualizarNotaEntrada}>
							<If test={elemento.documento?.documentoTipo === TipoMovimentacaoDocumento.NOTA_ENTRADA}>
								<span>{descricaoDocumento}</span>
							</If>
						</If>

						<If test={!podeVisualizarVenda}>
							<If
								test={
									elemento.documento?.documentoTipo === TipoMovimentacaoDocumento.VENDA ||
									elemento.documento?.documentoTipo === TipoMovimentacaoDocumento.DEVOLUCAO_VENDA
								}
							>
								<span>{descricaoDocumento}</span>
							</If>
						</If>

						<If test={!podeVisualizarOrdem}>
							<If test={elemento.documento?.documentoTipo === TipoMovimentacaoDocumento.ORDEM_SERVICO}>
								<span>{descricaoDocumento}</span>
							</If>
						</If>
					</div>
				)}
			</span>
		);
	}

	function renderTipoField(elemento) {
		const styleBackground = {
			borderRadius: '20px',
			padding:
				elemento.saldoTipo === tipoSaldo.ESTOQUE
					? elemento.tipo === 'ENTRADA'
						? '0.2rem 1.1rem'
						: '0.2rem 1.2rem'
					: '0.2rem 1.1rem',
		};

		const styleDescription = {
			margin: elemento.tipo === 'SAIDA' ? '3px 16px' : '3px 10px',
		};

		if (elemento.saldoTipo === tipoSaldo.ESTOQUE) {
			if (elemento.tipo === tipoMovimentacao.SAIDA) {
				return Badge(Colors.kTextRed, Colors.kBgLightRed, 'Saída', styleBackground, styleDescription);
			} else {
				return Badge(Colors.kTextGreen, Colors.kBgLightGreen, 'Entrada', styleBackground, styleDescription);
			}
		} else {
			if (elemento.tipo === tipoMovimentacao.ENTRADA) {
				return Badge(Colors.kTextBlue, Colors.kBgLightBlue, 'Reserva', styleBackground, styleDescription);
			}
		}
	}

	function renderQuantidadeField(elemento) {
		const quantidadeFormatado = formatarDecimais(elemento.quantidade, decimaisQtd);
		const unidadeMedida = elemento.produtoUnidadeMedidaUnidade ? ` (${elemento.produtoUnidadeMedidaUnidade})` : '';

		return (
			<span title={quantidadeFormatado} style={{ display: 'inline-flex', justifyContent: 'center' }}>
				{quantidadeFormatado}
				{unidadeMedida}
			</span>
		);
	}

	function renderCustoField(elemento) {
		if (elemento.tipo === tipoMovimentacao.ENTRADA && elemento.custo > 0) {
			const custoFormatado = formatarMonetarioDecimais(elemento.custo, decimaisPreco);
			return <span title={custoFormatado}>{custoFormatado}</span>;
		}
		return <span>—</span>;
	}

	function renderTotalField(elemento) {
		if (elemento.tipo === tipoMovimentacao.ENTRADA && elemento.custo > 0) {
			const totalFormatado = formatarMonetario(elemento.custo * elemento.quantidade);
			return <span title={totalFormatado}>{totalFormatado}</span>;
		}
		return <span>—</span>;
	}

	function renderAcoesField(elemento) {
		let titleExcluir = 'Excluir';
		let titleEditar = 'Editar';
		let titleEfetivar = 'Efetivar saída de estoque';
		let disableBtnExcluir = false;
		let disableBtnEditar = false;
		let disableBtnEfetivar = false;

		if (!podeExcluirLancamento) {
			titleExcluir = 'Você não possui permissão para executar essa ação';
			disableBtnExcluir = true;
		}

		if (!podeEditarLancamento) {
			titleExcluir = 'Você não possui permissão para executar essa ação';
			disableBtnEditar = true;
			disableBtnEfetivar = 'Você não possui permissão para executar essa ação';
			titleEfetivar = true;
		}

		if (elemento.documento && elemento.documento.documentoTipo === TipoMovimentacaoDocumento.NOTA_ENTRADA) {
			titleExcluir =
				'Esta movimentação foi gerada automaticamente a partir de uma nota de entrada e não pode ser removida.';
			titleEditar =
				'Esta movimentação foi gerada automaticamente a partir de uma nota de entrada e não pode ser editada.';
			titleEfetivar =
				'Esta movimentação foi gerada automaticamente a partir de uma nota de entrada e não pode ser editada.';
			disableBtnExcluir = true;
			disableBtnEditar = true;
			disableBtnEfetivar = true;
		} else if (elemento.documento && elemento.documento.documentoTipo === TipoMovimentacaoDocumento.NFE) {
			titleExcluir = 'Esta movimentação foi gerada automaticamente a partir de uma NF-e e não pode ser removida.';
			titleEditar = 'Esta movimentação foi gerada automaticamente a partir de uma NF-e e não pode ser editada.';
			titleEfetivar = 'Esta movimentação foi gerada automaticamente a partir de uma NF-e e não pode ser editada.';
			disableBtnExcluir = true;
			disableBtnEditar = true;
			disableBtnEfetivar = true;
		} else if (elemento.documento && elemento.documento.documentoTipo === TipoMovimentacaoDocumento.NFCE) {
			titleExcluir = 'Esta movimentação foi gerada automaticamente a partir de uma NFC-e e não pode ser removida.';
			titleEditar = 'Esta movimentação foi gerada automaticamente a partir de uma NFC-e e não pode ser editada.';
			titleEfetivar = 'Esta movimentação foi gerada automaticamente a partir de uma NFC-e e não pode ser editada.';
			disableBtnExcluir = true;
			disableBtnEditar = true;
			disableBtnEfetivar = true;
		} else if (elemento.documento && elemento.documento.documentoTipo === TipoMovimentacaoDocumento.VENDA) {
			titleExcluir = 'Esta movimentação foi gerada automaticamente a partir de uma venda e não pode ser removida.';
			titleEditar = 'Esta movimentação foi gerada automaticamente a partir de uma venda e não pode ser editada.';
			titleEfetivar = 'Esta movimentação foi gerada automaticamente a partir de uma venda e não pode ser editada.';
			disableBtnExcluir = true;
			disableBtnEditar = true;
			disableBtnEfetivar = true;
		} else if (elemento.documento && elemento.documento.documentoTipo === TipoMovimentacaoDocumento.DEVOLUCAO_VENDA) {
			titleExcluir = 'Estornar a devolução da venda.';
			titleEditar = 'Esta movimentação foi gerada automaticamente a partir de uma venda e não pode ser editada.';
			disableBtnExcluir = false;
			disableBtnEditar = true;
			disableBtnEfetivar = true;
		}

		return (
			<span>
				<Button
					style={styleButtonEfetivar}
					className="p-button p-button-secondary"
					icon="fa fa-sign-out"
					hidden={elemento.saldoTipo !== tipoSaldo.RESERVADO}
					title={titleEfetivar}
					disabled={disableBtnEfetivar}
					onClick={() => onEfetivar(elemento)}
				/>
				<Button
					style={styleButton}
					className="p-button p-button-primary"
					icon="fa fa-pencil"
					title={titleEditar}
					disabled={disableBtnEditar}
					onClick={() => onEditItem(elemento)}
				/>
				<Button
					style={{
						...styleButton,
						background:
							elemento.documento?.documentoTipo === TipoMovimentacaoDocumento.DEVOLUCAO_VENDA ? '#e2aa01' : null,
						border: elemento.documento?.documentoTipo === TipoMovimentacaoDocumento.DEVOLUCAO_VENDA ? '#e2aa01' : null,
					}}
					className="p-button p-button-danger"
					icon={
						elemento.documento?.documentoTipo === TipoMovimentacaoDocumento.DEVOLUCAO_VENDA
							? 'fa fa-reply-all'
							: 'fa fa-trash'
					}
					title={titleExcluir}
					disabled={disableBtnExcluir}
					onClick={() => onRemoveItem(elemento)}
				/>
			</span>
		);
	}

	return (
		<>
			<DataTable
				className="table"
				responsive
				value={registros}
				sortField={sortField}
				sortOrder={sortOrder}
				onSort={atualizarOrdenacao}
				emptyMessage={<NenhumRegistroEncontrado />}
				header={
					<span style={{ display: 'flex', justifyContent: 'flex-end' }}>
						<Checkbox
							name="exibirReservas"
							inputId="exibirReservas"
							checked={exibirReservas}
							onChange={() => {
								setExibirReservas(!exibirReservas);
							}}
						/>
						<label htmlFor="exibirReservas" className="p-checkbox-label">
							Exibir reservas de estoque
						</label>
					</span>
				}
			>
				<Column
					header="Data/hora"
					field="dataHora"
					sortable={true}
					body={renderDataField}
					style={{ color: colors.cinzaDark, width: '145px' }}
				/>
				<Column header="Produto" field="produto" body={renderProdutoField} style={{ width: '36rem' }} />
				<Column
					header="Tipo"
					field="tipo"
					sortable={true}
					body={renderTipoField}
					style={{ color: colors.cinzaDark, wordBreak: 'break-all', width: '200px' }}
				/>
				<Column
					header="Quantidade"
					field="quantidade"
					sortable={true}
					body={renderQuantidadeField}
					style={{ color: colors.cinzaDark }}
				/>
				<Column
					header="Custo"
					field="custo"
					sortable={true}
					body={renderCustoField}
					style={{ color: colors.cinzaDark }}
				/>
				<Column
					header="Total"
					field="total"
					body={renderTotalField}
					style={{ color: colors.cinzaDark, fontWeight: 'bold' }}
				/>
				<Column header="Ações" body={renderAcoesField} style={{ minWidth: '130px', textAlign: 'end' }} />
			</DataTable>
			<Paginacao totalElements={totalRecords} rows={rows} page={page} onPageChange={onPageChange} />
		</>
	);
}

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
});

export default connect(mapStateToProps)(TabelaMovimentacoes);
