import { useRef } from 'react';
import propTypes from 'prop-types';
import { Menu } from 'primereact/menu';
import { Field } from 'formik';
import InputField from '../input/InputField';
import InputDate from '../input/InputDate';
import InputDouble from '../input/InputDouble';
import Checkbox from '../input/Checkbox';
import { InternalDropdown } from '../select/Dropdown/components/InternalDropdown';
import Button from '../Button';
import Col from '../Col';
import { InternalTextArea } from '../TextArea/InternalTextArea';
import { mensagensDeValidacao } from '../../Common/Constantes/mensagens';
import { colors } from 'Common';

function CampoPersonalizado(props) {
	const menu = useRef(null);
	const {
		name,
		col,
		sm,
		md,
		lg,
		xl,
		onExcluirClick,
		onEditarClick,
		id,
		hiddenOptionsButton,
		estadoCadastro,
		podeEditar,
		podeInserir,
		touched,
		type,
		colStyle,
		menuPortalTarget,
	} = props;

	function getComponent() {
		switch (props.type) {
			case 'string':
				return InputField;
			case 'cpfCnpj':
				return InputField;
			case 'date':
				return InputDate;
			case 'integer':
				return InputDouble;
			case 'decimal':
				return InputDouble;
			case 'check':
				return Checkbox;
			case 'select':
				return InternalDropdown;
			case 'multiSelect':
				return InternalDropdown;
			case 'textArea':
				return InternalTextArea;
			default:
				return InputField;
		}
	}

	function buscarValorDeAcordoComComponente(event) {
		switch (props.type) {
			case 'string':
				return event.target.value;
			case 'cpfCnpj':
				return event.target.value;
			case 'date':
				return event.target.value;
			case 'decimal':
				return event.target.value;
			case 'integer':
				return event.target.value;
			case 'check':
				return event.checked;
			case 'select':
				return event.value;
			case 'multiSelect':
				return event.value;
			case 'textArea':
				return event.target.value;
			default:
				return event.value;
		}
	}

	function getValue() {
		return props.value && props.value.value;
	}

	function onChange(event) {
		const novoValor = buscarValorDeAcordoComComponente(event);
		props.onChange({ id: props.id, value: novoValor });
	}

	function getErrors() {
		const { value } = props;

		if (props.required && !value) {
			return mensagensDeValidacao.OBRIGATORIO;
		}

		if (props.required && !value.value) {
			return mensagensDeValidacao.OBRIGATORIO;
		}
	}

	function getLabel() {
		const { label, name, required } = props;
		const requiredString = required ? ' *' : '';
		return label || name + requiredString;
	}

	const optionsButton = [
		{
			label: 'Editar',
			icon: 'fa fa-pencil',
			command: () => onEditarClick(id),
		},
		{
			label: 'Excluir',
			icon: 'fa fa-trash',
			command: () => onExcluirClick(id),
		},
	];

	const buttonStyle = {
		marginTop: '17px',
		backgroundColor: colors.brancoTransparente,
	};

	const value = getValue();
	const component = getComponent();

	return (
		<Col col={col} sm={sm} md={md} lg={lg} xl={xl} style={colStyle}>
			<Field
				col="12"
				sm="12"
				md="12"
				lg="12"
				xl="12"
				component={component}
				label={getLabel()}
				size={type === 'string' ? 100 : undefined}
				name={name}
				value={value}
				checked={value}
				options={props.options}
				onChange={(event) => onChange(event)}
				colStyle={{ padding: '0px' }}
				estadoCadastro={estadoCadastro}
				podeInserir={podeInserir}
				podeEditar={podeEditar}
				errors={getErrors()}
				touched={touched}
				decimalScale={props.type === 'integer' ? 0 : 2}
				menuPortalTarget={menuPortalTarget}
			/>
			<Menu model={optionsButton} popup={true} ref={menu} />
			<Button
				color="secondary"
				icon="fa fa-ellipsis-v"
				style={buttonStyle}
				hidden={hiddenOptionsButton}
				onClick={(event) => menu.current.toggle(event)}
			/>
		</Col>
	);
}

CampoPersonalizado.propTypes = {
	/** id do componente recebido por props, esse id será exportando no onChange, onEditarClick e onExcluirClick */
	id: propTypes.string,
	/** Tipo do campo que será renderizado */
	type: propTypes.oneOf([
		'string',
		'cpfCnpj',
		'date',
		'integer',
		'check',
		'select',
		'multiSelect',
		'decimal',
		'textArea',
	]),
	/** Opções caso o componente seja select, essas opções devem ser passadas como strings simples*/
	options: propTypes.arrayOf(propTypes.object),
	/** Valor do componente de acordo com o tipo do componente */
	value: propTypes.any,
	/**Se o campo for obrigatório */
	required: propTypes.bool,
	/** Evento onChange, ele exportará o id recebido pelas props e o novo valor */
	onChange: propTypes.func,
	/** Nome do componente */
	name: propTypes.string,
	/** Label do componente */
	label: propTypes.string,
	/** Esconde botão de opções*/
	hiddenOptionsButton: propTypes.bool,
	/** Evento disparado ao clicar no ícone de excluir, esse evento exportará o id do componente que deverá ser excluído*/
	onExcluirClick: propTypes.func,
	/** Evento disparado ao clicar no ícone de editar, esse evento exportará o id do componente que deverá ser excluído*/
	onEditarClick: propTypes.func,
	/** Tamanho padrão da coluna utilizado em dispositivos muito pequenos (0 a 12) */
	col: propTypes.string,
	/** Tamanho do campo em small devices*/
	sm: propTypes.string,
	/** Tamanho do campo em medium devices*/
	md: propTypes.string,
	/** Tamanho do campo em large devices*/
	lg: propTypes.string,
	/** Tamanho do campo em extra large devices*/
	xl: propTypes.string,
};

export default CampoPersonalizado;
