import { useEffect, useRef, useState } from 'react';
import { Chart } from 'chart.js';
import { Card } from 'primereact/card';
import { buscarDespesasPorCategoria } from './Requests';
import { ProgressBar } from 'primereact/progressbar';
import { formatarMonetario } from '../../../../../../../Common/Mascara';
import 'chartjs-plugin-datalabels';
import NenhumaInformacaoEncontrada from '../../../NenhumaInformacaoEncontrada';
import FalhaAoCarregarAsInformacoes from '../../../FalhaAoCarregarAsInformacoes';
import usePrevious from '../../../../../../../Common/Hooks/usePrevious';
import { colors } from 'Common';

const cardHeader = {
	padding: '14px',
	display: 'flex',
	justifyContent: 'space-between',
};

let despesasPorCategoria;

export default function GraficoDespesasPorCategoria(props) {
	const { dataInicial, dataFinal, atualizarGraficoNovamente, isMobile } = props;

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [categorias, setCategorias] = useState([]);
	const [valorTotal, setValorTotal] = useState([]);
	const [cores, setCores] = useState([]);

	const graficoDeDespesaAgrupadoPorCategoria = useRef();
	const afterFirstRenderRef = useRef(false);
	const prevProps = usePrevious(props);

	useEffect(() => {
		carregarInformacoes();

		return () => {
			if (typeof despesasPorCategoria !== 'undefined') despesasPorCategoria.destroy();
		};
	}, [atualizarGraficoNovamente]);

	useEffect(() => {
		if (afterFirstRenderRef) {
			if (prevProps?.dataInicial !== dataInicial || prevProps?.dataFinal !== dataFinal) {
				carregarInformacoes();
			}

			if (prevProps?.atualizarGraficoNovamente !== atualizarGraficoNovamente) {
				carregarInformacoes(false);
			}
		} else afterFirstRenderRef = true;
	}, [dataInicial, dataFinal]);

	useEffect(() => {
		buscarDespesasPorCategoria(
			dataInicial,
			dataFinal,
			({ data: despesasPorCategoria }) => {
				let categoriasResponse = [];
				let coresResponse = [];
				let valorTotalResponse = [];

				despesasPorCategoria
					.filter((e) => e.valorTotal > 0)
					.forEach((elemento) => {
						categoriasResponse.push(elemento.categoria.nome);
						coresResponse.push(elemento.categoria.aparencia);
						valorTotalResponse.push(elemento.valorTotal);
					});

				setCategorias(categoriasResponse);
				setCores(coresResponse);
				setValorTotal(valorTotalResponse);
				setLoading(false);
				setError(false);
			},
			() => {
				setLoading(false);
				setError(true);
			}
		);
	}, [loading, atualizarGraficoNovamente]);

	useEffect(() => {
		buildChart();
	}, [cores]);

	function carregarInformacoes(exibirLoading = true) {
		setLoading(exibirLoading);
		setError(false);
	}

	function buildChart() {
		if (!graficoDeDespesaAgrupadoPorCategoria.current) {
			return;
		}
		const myChartRef = graficoDeDespesaAgrupadoPorCategoria.current.getContext('2d');

		if (typeof despesasPorCategoria !== 'undefined') despesasPorCategoria.destroy();
		despesasPorCategoria = new Chart(myChartRef, {
			type: 'doughnut',
			data: {
				datasets: [
					{
						data: valorTotal,
						backgroundColor: cores,
					},
				],
				labels: categorias,
			},
			options: {
				responsive: true,
				maintainAspectRatio: false,
				aspectRatio: 1.1,
				onHover: (event, chartElement) => {
					chartElement.length === 1
						? (event.native.target.style.cursor = 'pointer')
						: (event.native.target.style.cursor = 'default');
				},
				plugins: {
					datalabels: {
						color: colors.branco,
						font: {
							size: 11,
						},
						formatter: (value, ctx) => {
							let total = 0;
							let dataArr = ctx.chart.data.datasets[0].data;
							dataArr.forEach((data) => {
								total += data;
							});
							return ((value * 100) / total).toFixed(1) + '%';
						},
					},
					legend: {
						position: 'bottom',
						labels: {
							usePointStyle: true,
							pointStyle: 'circle',
						},
						onHover: (event) => {
							event.native.target.style.cursor = 'pointer';
						},
						onLeave: (event) => {
							event.native.target.style.cursor = 'default';
						},
					},
				},
				tooltips: {
					callbacks: {
						label: (tooltipItem, data) => {
							return formatarMonetario(data.datasets[0].data[tooltipItem.index]);
						},
						title: (tooltipItem, data) => {
							const label = data.labels[tooltipItem[0].index];
							let total = 0;
							let dataArr = data.datasets[0].data;
							dataArr.forEach((data) => (total += data));
							const value = dataArr[tooltipItem[0].index];
							return label + ' (' + ((value * 100) / total).toFixed(1) + '%)';
						},
					},
				},
			},
		});
	}

	function getHeaderCard() {
		return (
			<div style={cardHeader}>
				<label
					style={{ fontSize: '17px', color: colors.cinzaDark }}
					title="Lista o total de despesas realizadas e previstas de cada categoria no período informado"
				>
					Despesas por categoria
				</label>
			</div>
		);
	}

	if (loading) {
		return (
			<Card
				header={getHeaderCard()}
				style={{
					margin: isMobile ? '8px' : '0px',
					borderRadius: '5px',
					height: '100%',
				}}
			>
				<ProgressBar mode="indeterminate" style={{ height: '3px' }} />
			</Card>
		);
	} else if (error) {
		return (
			<Card
				header={getHeaderCard()}
				style={{
					margin: isMobile ? '8px' : '0px',
					borderRadius: '5px',
					height: '100%',
				}}
			>
				<FalhaAoCarregarAsInformacoes />
			</Card>
		);
	} else if (categorias.length === 0) {
		return (
			<Card
				header={getHeaderCard()}
				style={{
					margin: isMobile ? '8px' : '0px',
					borderRadius: '5px',
					height: '100%',
				}}
			>
				<NenhumaInformacaoEncontrada />
			</Card>
		);
	} else {
		return (
			<Card
				className="card-canvas-dashboard-financas"
				header={getHeaderCard()}
				style={{
					margin: isMobile ? '8px' : '0px',
					borderRadius: '5px',
					height: '100%',
				}}
			>
				<canvas id="graficoDeDespesaAgrupadoPorCategoria" ref={graficoDeDespesaAgrupadoPorCategoria} />
			</Card>
		);
	}
}
