import { Accordion, AccordionTab } from 'primereact/accordion';
import { useCallback, useEffect, useState } from 'react';
import { useUpdateEffect } from 'react-use';

import { buscarDadosLoginLocalStorage, colors, construirUrl, services } from 'Common';
import {
	Button,
	ButtonCancelar,
	Form,
	FormActions,
	FormContent,
	If,
	NenhumRegistroEncontrado,
	Paginacao,
	estadosBotaoCancelar,
} from 'components';

import { APLICACAO } from 'views/Util';
import { asyncGetNotasDeVersao } from './Request';
import { PainelNotasDeVersao } from './components/PainelNotasDeVersao';

function NotasDeVersao(props) {
	const { history } = props;
	const [registros, setRegistros] = useState([]);
	const [totalElements, setTotalElements] = useState(0);
	const [rows, setRows] = useState(10);
	const [page, setPage] = useState(0);
	const [activeIndex, setActiveIndex] = useState(0);
	const [painelNotasDeVersaoModal, setPainelNotasDeVersaoModal] = useState(false);

	const isAdmin = buscarDadosLoginLocalStorage()?.autoridade === 'ADMIN';
	const tipoAplicacao = buscarDadosLoginLocalStorage()?.organizacao?.aplicacao;
	const url = construirUrl(
		`${services.GESTOR}/v1/notas_de_versao/listar`,
		`?query=(aplicacao=contains=%22*${
			tipoAplicacao === APLICACAO.DOCS_DIGITAIS ? tipoAplicacao : APLICACAO.MSYS_GESTOR
		}*%22)`,
		rows,
		page,
		'versao,desc'
	);

	const pesquisarCallback = useCallback(() => {
		pesquisar();
	});

	useEffect(() => {
		pesquisarCallback();
	}, []);

	useUpdateEffect(() => {
		pesquisarCallback();
	}, [page, rows]);

	function cancelar() {
		history.goBack();
	}

	function onPageChange(e) {
		setPage(e.page);
		setRows(e.rows);
	}

	function pesquisar() {
		asyncGetNotasDeVersao(url, ({ data: notasDeVersao }) => {
			setRegistros(notasDeVersao?.content);
			setTotalElements(notasDeVersao.totalElements);
		});
	}

	function onHideModal() {
		setPainelNotasDeVersaoModal(false);
		pesquisarCallback();
	}

	function renderTipoVersao(row) {
		let strongColor = colors.azul;
		let lightColor = colors.azulLight;
		let texto;
		let paddingSize = '0.2rem 0.4rem';

		switch (String(row.tipo)) {
			case 'ALTERACAO':
				texto = 'Alteração';
				strongColor = colors.azul;
				lightColor = colors.azulLight;
				paddingSize = '0.2rem 0.4rem';
				break;
			case 'CORRECAO':
				texto = 'Correção';
				strongColor = '#c16b13';
				lightColor = '#fff7cd';
				paddingSize = '0.2rem 0.9rem';
				break;
			case 'NOVIDADE':
				texto = 'Novidade';
				strongColor = colors.verde;
				lightColor = colors.verdeLight;
				paddingSize = '0.2rem 0.4rem';
				break;
			default:
				texto = '';
		}
		return (
			<span
				style={{
					backgroundColor: lightColor,
					color: strongColor,
					fontWeight: 'bold',
					fontSize: '13px',
					borderRadius: '20px',
					display: 'flex',
					height: '1.2rem',
					width: '5rem',
					alignItems: 'center',
					justifyContent: 'center',
					padding: paddingSize,
				}}
			>
				<span style={{ width: '100%', textAlign: 'center' }}>{texto}</span>
			</span>
		);
	}

	return (
		<>
			<Form header="Notas de versão">
				<FormActions>
					<ButtonCancelar onClick={cancelar} estadoBotao={estadosBotaoCancelar.VOLTAR} />
					{isAdmin && (
						<Button
							label="Painel notas de versão"
							className="p-button-primary"
							icon="fa fa-plus"
							title="Painel administrativo das nota de versão"
							onClick={() => {
								setPainelNotasDeVersaoModal(true);
							}}
							disabled={!isAdmin}
							style={{ margin: '5px' }}
						/>
					)}
				</FormActions>
				<FormContent>
					<If test={!registros || registros?.length === 0}>
						<NenhumRegistroEncontrado />
					</If>
					<If test={registros?.length > 0}>
						<Accordion
							className="tab-accordion-lite"
							activeIndex={activeIndex}
							onTabChange={(event) => setActiveIndex(event.index)}
						>
							{registros?.map((registro) => (
								<AccordionTab key={registro.id} header={`${registro?.versao}`}>
									{registro?.titulos?.map((principal) => (
										<div key={principal.id} style={{ marginBottom: '8px' }}>
											<h1 style={{ fontSize: '16px', fontWeight: '500' }}>{principal.titulo}</h1>
											<div style={{ borderLeft: '1px dashed #d5d5d5' }}>
												{principal?.itens?.map((item) => (
													<div
														key={item.id}
														style={{
															display: 'flex',
															justifyContent: 'flex-start',
															alignItems: 'center',
															gap: '8px',
															marginLeft: '16px',
															marginBottom: '8px',
														}}
													>
														<div>{renderTipoVersao(item)}</div>
														<p>{item.texto}</p>
													</div>
												))}
											</div>
										</div>
									))}
								</AccordionTab>
							))}
						</Accordion>
					</If>
					<Paginacao totalElements={totalElements} rows={rows} page={page} onPageChange={(e) => onPageChange(e)} />
				</FormContent>
			</Form>
			<If test={painelNotasDeVersaoModal}>
				<PainelNotasDeVersao visible={painelNotasDeVersaoModal} onHide={() => onHideModal()} isAdmin={isAdmin} />
			</If>
		</>
	);
}

export default NotasDeVersao;
