import { useState, useEffect, useRef } from 'react';
import { asyncGetOpcoesSelect } from '../../Requests';
import { removerCaracteresInvalidosRsql } from '../../../../../Common/Rsql';
import { buscarOption, converterRegistrosParaOptions, filtrarOpcoesRepetidas } from '../../Util';
import usePrevious from '../../../../../Common/Hooks/usePrevious';
import Select from 'react-select';
import propTypes from 'prop-types';
import Col from '../../../../Col';
import { renderizarValidacao } from '../../../../../Common/TratamentoDeErro/validacoesDeCampos';
import {
	buscarDisabledDeAcordoComAsPermissoes,
	buscarHiddenDeAcordoComAsPermissoes,
} from '../../../../../Common/Autorizacao/ManipulacaoDeComponentes';
import { usuarioPossuiModulos } from '../../../../../Common/Autenticacao';
import MenuList from '../../../components/FastList';
import If from '../../../../If';
import Button from '../../../../Button';
import { colors } from 'Common';

const customStyles = {
	control: (provided, state) => ({
		...provided,
		minHeight: '32px !important',
		border:
			state.selectProps.errors && state.selectProps.touched ? `1px solid ${colors.vermelho}` : '1px solid #ced4da',
		backgroundColor: colors.branco,
		opacity: state.isDisabled ? '0.5' : '1',
		...dot(state.selectProps.errors, state.selectProps.touched),
		width: '100%',
	}),
	dropdownIndicator: (base) => ({
		...base,
		padding: 6,
	}),
	container: (styles) => ({
		...styles,
		width: '100%',
	}),
	menu: (style) => ({
		...style,
		marginTop: 0,
		marginBottom: 0,
	}),
	option: (base, state) => ({
		...base,
		backgroundColor: state.isSelected ? colors.principal : state.isFocused ? colors.principalHoverTable : colors.branco,
	}),
	menuList: (styles) => ({
		...styles,
		maxHeight: 200,
	}),
	valueContainer: (styles, state) => ({
		...styles,
		padding: '0px 5px',
		...state.selectProps.customStyles?.valueContainer,
	}),
	multiValueLabel: (styles, state) => ({
		...styles,
		wordWrap: 'break-word',
		textOverflow: 'ellipsis',
		margin: '0px 2px',
	}),
	indicatorsContainer: (styles, state) => ({
		...styles,
		marginTop: '-3px',
	}),
};
const dot = (errors, touched) => ({
	':hover': {
		border: errors && touched ? `1px solid ${colors.vermelho} !important` : `1px solid ${colors.principal} !important`,
		cursor: 'pointer',
	},
});

const buttonStyle = {
	width: '23px',
	height: '23px',
	borderRadius: '3px',
	color: colors.principal,
	backgroundColor: 'transparent',
	borderColor: 'transparent',
	fontSize: '15px',
	boxShadow: 'none',
	margin: 'unset',
	border: 'unset',
};

export default function ReactSelect(props) {
	const {
		sm,
		md,
		lg,
		xl,
		isClearable,
		errors,
		touched,
		podeInserir,
		podeEditar,
		estadoCadastro,
		disabled,
		placeholder,
		onChange,
		value,
		noOptionsMessage,
		montarLabel,
		buscarUrlPesquisa,
		podeVisualizar,
		hidden,
		colStyle,
		obrigatorio,
		label,
		utilizaFavorito,
		resetPesquisa,
		setResetPesquisa,
		esconderBotao,
		botaoIcon,
		titleBotaoNovo,
		styleButton,
		botaoColor,
		onClickModal,
		converterOptions,
	} = props;

	const [inputValue, setInputValue] = useState('');
	const [options, setOptions] = useState([]);
	const [validouModulo, setValidouModulo] = useState(true);
	const [timeOutPesquisa, setTimeOutPesquisa] = useState(null);
	const [pageSelecionada, setPageSelecionada] = useState(0);
	const [totalPages, setTotalPages] = useState(null);
	const [loading, setLoading] = useState(false);
	const [buscouTodosOsRegistros, setBuscouTodosOsRegistros] = useState(false);
	const afterFirstRenderRef = useRef(false);
	const prevProps = usePrevious(props);

	useEffect(() => {
		const { value, modulosEspecificos } = props;

		setValidouModulo(usuarioPossuiModulos(modulosEspecificos));

		if (value) {
			atribuirNovasOpcoes(value);
		}
		if (validouModulo) {
			buscarRegistros('', pageSelecionada);
		}
	}, []);

	useEffect(() => {
		if (afterFirstRenderRef.current) {
			if (value && prevProps.value !== value) {
				atribuirNovasOpcoes(value);
			}
		} else afterFirstRenderRef.current = true;
	}, []);

	useEffect(() => {
		if (resetPesquisa) {
			buscarRegistros(inputValue, pageSelecionada, true);
		}
	}, [resetPesquisa]);

	function buscarRegistros(inputValue, pageSelecionada, resetOptions) {
		setLoading(true);
		asyncGetOpcoesSelect(
			buscarUrlPesquisa(removerCaracteresInvalidosRsql(inputValue), pageSelecionada),
			({ data: registros }) => {
				let novasOptions = converterRegistrosParaOptions(registros.content, montarLabel);
				if (typeof converterOptions === 'function') {
					novasOptions = converterOptions(novasOptions);
				}
				setOptions(filtrarOpcoesRepetidas(!resetOptions ? options : [], novasOptions));
				setLoading(false);
				setPageSelecionada(pageSelecionada);
				setTotalPages(registros.totalPages);
				setBuscouTodosOsRegistros(inputValue === '' && registros.totalPages === 1);
				if (resetPesquisa) {
					setResetPesquisa(false);
				}
			},
			() => {
				setLoading(false);
				if (resetPesquisa) {
					setResetPesquisa(false);
				}
			}
		);
	}

	useEffect(() => {
		if (afterFirstRenderRef.current) {
			utilizaFavorito ? buscarFavorito() : null;
		}
	}, [options]);

	function buscarFavorito() {
		options.map((opcao) => {
			if (opcao.registro?.favorito === true) {
				onChange([opcao]);
			}
		});
	}

	function atribuirNovasOpcoes(novasOpcoes) {
		let novasOpcoesFiltradas = [];
		for (const novaOpcao of novasOpcoes) {
			if (!buscarOption(options, novaOpcao)) {
				novasOpcoesFiltradas.push(novaOpcao);
			}
		}
		if (novasOpcoesFiltradas.length > 0) {
			setOptions([...options, ...novasOpcoesFiltradas]);
		}
	}

	function onInputChange(inputValue) {
		if (inputValue && !buscouTodosOsRegistros) {
			clearTimeout(timeOutPesquisa);
			setInputValue(inputValue);
			setTimeOutPesquisa(
				setTimeout(() => {
					buscarRegistros(inputValue, 0);
				}, 500)
			);
		} else {
			setInputValue(inputValue);
		}
	}

	function onScrollToBottom() {
		if (!buscouTodosOsRegistros) {
			const page = pageSelecionada + 1;
			if (totalPages && page < totalPages) {
				buscarRegistros(inputValue, page);
			}
		}
	}

	function filterOption(element) {
		return (element.label || '').toLowerCase().includes(inputValue.toLowerCase());
	}

	function getColStyle() {
		if (buscarHiddenDeAcordoComAsPermissoes(podeVisualizar, hidden)) return { display: 'none', ...colStyle };
		return colStyle;
	}

	function montarTitulo() {
		if (obrigatorio) {
			return (
				<label title={props.helpMessage}>
					{label} <b style={{ fontSize: '18px', lineHeight: '5px' }}> *</b>
				</label>
			);
		}
		return <label title={props.helpMessage}> {label} </label>;
	}

	if (!validouModulo) return null;

	const desabilitarSelect = buscarDisabledDeAcordoComAsPermissoes(podeInserir, podeEditar, estadoCadastro, disabled);

	return (
		<Col sm={sm} md={md} lg={lg} xl={xl} style={getColStyle()}>
			{label && montarTitulo()}
			<div className="p-inputgroup">
				<Select
					isMulti
					isLoading={loading}
					styles={{ ...customStyles }}
					inputValue={inputValue}
					isClearable={isClearable}
					options={options}
					closeMenuOnSelect={true}
					onInputChange={onInputChange}
					isDisabled={desabilitarSelect}
					placeholder={placeholder}
					noOptionsMessage={noOptionsMessage}
					{...props}
					value={value}
					onChange={onChange}
					onMenuScrollToBottom={onScrollToBottom}
					filterOption={filterOption}
					components={options.length > 0 ? { MenuList } : undefined}
				/>
				<If test={!esconderBotao}>
					<Button
						icon={botaoIcon}
						title={titleBotaoNovo ? titleBotaoNovo : 'Inserir um novo registro'}
						style={{ ...buttonStyle, ...styleButton }}
						styleContentButton={{
							display: 'flex',
							alignItems: 'center',
						}}
						disabled={disabled}
						color={botaoColor ? `button-add-icon-only ${botaoColor}` : 'button-add-icon-only'}
						onClick={onClickModal}
						tabIndex={-1}
					/>
				</If>
			</div>
			{errors && renderizarValidacao(errors, touched)}
		</Col>
	);
}

ReactSelect.defaultProps = {
	noOptionsMessage: () => 'Nenhum elemento encontrado',
	loadingMessage: () => 'Por favor, aguarde...',
	placeholder: 'Clique para selecionar',
	defaultOptions: false,
	cacheOptions: true,
	menuPlacement: 'auto',
	className: 'react-select-base',
	classNamePrefix: 'reactSelect',
	botaoColor: 'primary',
	botaoIcon: 'fa fa-plus',
	closeMenuOnSelect: false,
	disabled: false,
	podeVisualizar: true,
	utilizaFavorito: false,
};

ReactSelect.propTypes = {
	/** Quando definido como true, os resultados de loadOptions() serão carregados automaticamente antes do usuário clicar para pesquisar.  */
	defaultOptions: propTypes.bool,

	/** Quando definido como true, os dados carregados serão armazenados em cache. O cache permanecerá até o valor cacheOptions sofrer alterações. */
	cacheOptions: propTypes.bool,

	/** Função executada quando o valor do input altera de estado. */
	onInputChange: propTypes.func,

	/** Função que retorna uma Promisse, que é o conjunto de opções a ser usado quando esta é resolvida. É executada assim que o componente é montado e a cada vez que o usuário filtrar a pesqusia. */
	loadOptions: propTypes.func,

	/** texto a ser exibido quando as opções estão sendo carregadas. */
	loadingMessage: propTypes.func,
	/** texto a ser exibido quando não tem nenhum item na lista */
	noOptionsMessage: propTypes.func,
};
