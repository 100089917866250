import { colors, services } from 'Common';
import { SingleSelect } from 'components';

function SelectFiliais({ value, handleChangeFilial, showLabel, resetPesquisa, setResetPesquisa, ...props }) {
	function getUrlPesquisa(pesquisa, page) {
		return `${services.GESTOR}/v1/usuarios/filiais?filter=${pesquisa}&page=${page}&size=50`;
	}

	function createLabel(row) {
		return `${row.codigo} - ${row.nomeApresentacao ?? row.nome}`;
	}

	return (
		<SingleSelect
			label={showLabel ? 'Filial' : ''}
			labelStyle={{ padding: '5px' }}
			labelSize="12px"
			buscarUrlPesquisa={getUrlPesquisa}
			montarLabel={createLabel}
			onChange={handleChangeFilial}
			isClearable={false}
			esconderBotao
			errors={props.errors}
			useFormErrors={false}
			useFormTouched={false}
			touched
			resetPesquisa={resetPesquisa}
			setResetPesquisa={setResetPesquisa}
			menuPortalTarget={document.body}
			placeholder="Digite para pesquisar"
			value={value}
			{...props}
			colStyle={{ padding: 0, margin: '4px 0 4px 0' }}
			style={{
				control: (provided) => ({
					...provided,
					backgroundColor: 'rgba(231, 236, 240, 1)',
					borderRadius: '0 !important',
					border: 'none',
					width: '100%',
					height: '40px',
					cursor: 'pointer',
				}),
				dropdownIndicator: (base) => ({
					...base,
					color: colors.cinzaDark,
				}),
			}}
		/>
	);
}

export { SelectFiliais };
