import { useCallback, useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { connect } from 'react-redux';
import {
	buscarConfiguracaoUsuario,
	colors,
	configuracoesUsuario,
	construirUrl,
	removerCaracteres,
	removerElemento,
	salvarConfiguracaoUsuario,
	services,
} from 'Common';
import {
	Grid,
	FormContent,
	FormActions,
	Form,
	ButtonNovo,
	InputSearch,
	PesquisaAvancada,
	Col,
	DescricaoFiltroAvancado,
	NenhumRegistroEncontrado,
	Checkbox,
	Paginacao,
	Badge,
	ButtonEditarTable,
	ButtonExcluirTable,
	tutorialStepsListagens,
	Tutorial,
} from 'components';

import { atualizarUrl } from 'views/Util';
import { confirmarExclusao } from 'views/Util/ExclusaoDeRegistros';
import { useContextPesquisa } from 'views/Util/Context/ContextPesquisa';
import { useContextCodigoServico } from './Context';
import { optionsFiltroAvancado } from './Util/constantes';

import { asyncDeleteCodigoServico, asyncGetCodigosServico } from './Requests';

import './Styles/index.css';

const Colors = {
	bgAtivo: colors.verdeLight,
	textAtivo: colors.verde,
	bgInativo: colors.vermelhoLight,
	textInativo: colors.vermelho,
};

function CodigoServico(props) {
	const { podeInserir, podeExcluir } = useContextCodigoServico();
	const {
		valorPesquisa,
		setValorPesquisa,
		sortField,
		setSortField,
		sortOrder,
		setSortOrder,
		page,
		setPage,
		rows,
		setRows,
		filtroAvancado,
		setFiltroAvancado,
		descricaoFiltroAvancado,
		setDescricaoFiltroAvancado,
		exibirBloqueadas,
		setExibirBloqueadas,
	} = useContextPesquisa();

	const [tutorialVisible, setTutorialVisible] = useState(false);
	const [registros, setRegistros] = useState([]);
	const [totalElements, setTotalElements] = useState(0);
	const [firstRender, setFirstRender] = useState(true);

	const deveExibirTutorial = buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS);
	const sortFieldLocal = sortField?.length > 0 ? sortField : 'codigoServico.codigo';

	const pesquisarCallback = useCallback(() => {
		if (!firstRender) {
			pesquisar();
		}
	});

	useEffect(() => {
		pesquisar();

		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS, false, null, false);
		}

		setTimeout(() => {
			if (document.getElementById('input-search-codigo-servico-listagem')) {
				document.getElementById('input-search-codigo-servico-listagem').focus();
			}
		}, 500);
	}, []);

	useEffect(() => {
		pesquisarCallback();
	}, [exibirBloqueadas, page, rows, sortOrder, sortFieldLocal, filtroAvancado]);

	async function onPesquisarFiltroAvancado(filtro) {
		setFiltroAvancado(filtro);
	}

	function onChangeCheckboxTributacoesInativas(element) {
		setExibirBloqueadas(element.checked);
	}

	function onSort(event) {
		setSortOrder(event.sortOrder);
		setSortField(event.sortField);
	}

	function onPageChange(event) {
		setRows(event.rows);
		setPage(event.page);
	}

	function buscarFiltro() {
		const pesquisaCodigo = removerCaracteres(valorPesquisa, ['.']);

		let result = `?query=(codigoServico.codigo=contains="*${pesquisaCodigo}*",descricao=contains="*${valorPesquisa}*")`;

		if (filtroAvancado) {
			result += `;${filtroAvancado}`;
		}

		return result;
	}

	async function pesquisar() {
		let filtro = buscarFiltro();
		filtro += String(`${exibirBloqueadas ? '' : ';situacao=="ATIVO"'}`);

		const url = construirUrl(
			`${services.GESTOR}/v1/tributacoes/codigo_servico/resumo`,
			filtro,
			rows,
			page,
			sortOrder > 0 ? `${sortFieldLocal},asc` : `${sortFieldLocal},desc`
		);

		await asyncGetCodigosServico(url, ({ data: tributacao }) => {
			setRegistros(tributacao.content);
			setTotalElements(tributacao.totalElements);
			setFirstRender(false);

			if (!exibirBloqueadas && (filtroAvancado === 'situacao=="INATIVO"' || filtroAvancado === 'situacao!="ATIVO"')) {
				setExibirBloqueadas(true);
			}
		});
	}

	function aplicarEstiloInativa(row, field) {
		if (row.situacao === 'INATIVO') {
			return (
				<span
					style={{
						fontStyle: 'italic',
						opacity: '0.7',
						display: 'flex',
						wordBreak: 'break-word',
						maxWidth: props.isMobile ? '70%' : '100%',
						textAlign: props.isMobile ? 'end' : 'start',
					}}
				>
					{field}
				</span>
			);
		}
		return (
			<span
				style={{
					display: 'flex',
					wordBreak: 'break-word',
					maxWidth: props.isMobile ? '70%' : '100%',
					textAlign: props.isMobile ? 'end' : 'start',
				}}
			>
				{field}
			</span>
		);
	}

	function renderSituacao(row) {
		const styleBackground = {
			borderRadius: '20px',
			padding: '0.2rem 1.1rem',
		};

		const styleDescription = {
			fontStyle: row.situacao === 'INATIVO' ? 'italic' : '',
			opacity: row.situacao === 'INATIVO' ? '0.7' : '',
			margin: row.situacao === 'ATIVO' ? '3px 16px' : '3px 10px',
		};

		switch (row.situacao) {
			case 'ATIVO': {
				return Badge(Colors.textAtivo, Colors.bgAtivo, 'Ativo', styleBackground, styleDescription);
			}
			case 'INATIVO': {
				return Badge(Colors.textInativo, Colors.bgInativo, 'Inativo', styleBackground, styleDescription);
			}
			default:
				return row.situacao;
		}
	}

	function renderOpcoes(row) {
		return (
			<div style={{ display: 'flex' }}>
				<ButtonEditarTable onClick={() => onEditar(row)} />
				<ButtonExcluirTable onClick={() => onExcluir(row)} podeExcluir={podeExcluir} />
			</div>
		);
	}

	function onEditar(row) {
		atualizarUrl(props.history, '/tributacoes/codigo_servico/cadastro', row.id);
	}

	function onExcluir(row) {
		confirmarExclusao(() => asyncExcluirRegistro(row));
	}

	async function asyncExcluirRegistro(registro) {
		await asyncDeleteCodigoServico(registro.id, () => {
			setRegistros(removerElemento(registros, registro));
			setTotalElements(totalElements - 1);
		});
	}

	return (
		<>
			<Tutorial
				steps={tutorialStepsListagens}
				showSkipButton
				continuous
				disableScrolling
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>
			<Form header="Código serviço">
				<FormActions>
					<ButtonNovo
						className="step-listagem-novo"
						label="Novo código serviço"
						onClick={() => {
							props.history.push('/tributacoes/codigo_servico/cadastro');
						}}
						podeInserir={podeInserir}
					/>
				</FormActions>
				<FormContent>
					<Grid justifyCenter>
						<InputSearch
							id="input-search-codigo-servico-listagem"
							className="step-listagem-input-search"
							onPesquisar={() => pesquisar(0)}
							value={valorPesquisa}
							onChange={(value) => setValorPesquisa(value)}
						/>
						<Col sm="12" md="4" lg="3" xl="3" className="step-listagem-filtro-avancado">
							<PesquisaAvancada
								className="step-listagem-filtro-avancado"
								optionsFiltros={optionsFiltroAvancado}
								onPesquisarClick={onPesquisarFiltroAvancado}
								onChangeFiltroRsql={(rsql) => setFiltroAvancado(rsql)}
								onChangeDescricaoFiltro={(descricao) => setDescricaoFiltroAvancado(descricao)}
							/>
						</Col>
						<DescricaoFiltroAvancado texto={descricaoFiltroAvancado} />
					</Grid>
					<DataTable
						className="table"
						rowClassName="table-row"
						cellClassName="table-row-cell"
						responsive
						value={registros}
						sortField={sortFieldLocal}
						sortOrder={sortOrder}
						onSort={onSort}
						emptyMessage={<NenhumRegistroEncontrado />}
						header={
							<div
								style={{
									display: 'flex',
									justifyContent: 'flex-end',
									alignItems: 'center',
									width: '100%',
								}}
							>
								<div>
									<Checkbox
										name="exibirBloqueadas"
										inputId="exibirBloqueadas"
										checked={exibirBloqueadas}
										onChange={onChangeCheckboxTributacoesInativas}
									/>
								</div>
								<div>
									<label htmlFor="exibirBloqueadas" className="p-checkbox-label">
										Exibir códigos inativos
									</label>
								</div>
							</div>
						}
					>
						<Column
							className="step-listagem-order"
							field="codigoServico.codigo"
							header="Código"
							body={(row) => aplicarEstiloInativa(row, row.codigoServico.codigo)}
							sortable
							style={{
								textOverflow: 'ellipsis',
								overflow: 'hidden',
								width: '130px',
							}}
						/>
						<Column
							field="descricao"
							header="Descrição"
							body={(row) => aplicarEstiloInativa(row, row.descricao)}
							sortable
						/>
						<Column field="situacao" header="Situação" body={renderSituacao} sortable style={{ width: '15rem' }} />
						<Column className="step-listagem-acoes" body={renderOpcoes} header="Ações" style={{ width: '7em' }} />
					</DataTable>
					<Paginacao totalElements={totalElements} rows={rows} page={page} onPageChange={onPageChange} />
				</FormContent>
			</Form>
		</>
	);
}

function mapStateToProps(state) {
	return {
		isMobile: state.dispositivo.isMobile,
	};
}

export default connect(mapStateToProps)(CodigoServico);
