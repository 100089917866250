import { colors } from 'Common';
import { tipoCampos } from '../../PesquisaAvancada/Util/constantes';

export const optionsFiltroAvancado = [
	{ label: 'Série', name: 'serie', type: tipoCampos.INTEGER },
	{ label: 'N. Inicial', name: 'numeroInicial', type: tipoCampos.INTEGER },
	{ label: 'N. Final', name: 'numeroFinal', type: tipoCampos.INTEGER },
	{ label: 'Data', name: 'dataInutilizacao', type: tipoCampos.DATE },
	{ label: 'Justificativa', name: 'justificativa', type: tipoCampos.STRING },
	{
		label: 'Situação',
		name: 'situacao',
		type: tipoCampos.SELECT,
		optionSelect: [
			{ label: 'Inutilizada', value: 'INUTILIZADA' },
			{ label: 'Rejeitada', value: 'REJEITADA' },
		],
	},
];

export const MessagesErrors = {
	OBRIGATORIO: 'Obrigatório',
	CAMPO_OBRIGATORIO: 'Campo obrigatório',
	CHAVE_ACESSO_INVALIDA: 'Chave de acesso inválida',
};

export const status = {
	PENDENTE: 'PENDENTE',
	REJEITADA: 'REJEITADA',
	INUTILIZADA: 'INUTILIZADA',
};

export const statusColor = {
	PENDENTE: {
		lightText: colors.azulLight,
		darkText: colors.principal,
		lightBackground: colors.azulLight,
		darkBackground: colors.principal,
	},
	REJEITADA: {
		lightText: colors.vermelhoLight,
		darkText: colors.vermelho,
		lightBackground: colors.vermelhoLight,
		darkBackground: colors.vermelho,
	},
	INUTILIZADA: {
		lightText: colors.verdeLight,
		darkText: colors.verde,
		lightBackground: colors.verdeLight,
		darkBackground: colors.verde,
	},
};

export const TipoInutilizacao = {
	NFE: 'NFE',
	NFCE: 'NFCE',
};
