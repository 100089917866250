import React from 'react';
import Modal from '../Modal';
import Grid from '../Grid';
import Lottie from 'lottie-react';

import animation from '../../App/Animation/imprimindo.json';
import { colors } from 'Common';

export function ModalLoadingImprimir({ visible, onFinish, message, container, onHide }) {
	return (
		<Modal
			visible={visible}
			showCloseIcon={false}
			styleModal={{ background: 'unset', boxShadow: 'unset', padding: '0px' }}
			container={container}
			onHide={onHide}
		>
			<Grid justifyCenter>
				<Lottie
					style={{ width: '100%', height: '200px', width: '200px' }}
					animationData={animation}
					loop={true}
					autoPlay={true}
					onLoopComplete={() => onFinish && onFinish()}
				/>
			</Grid>
			<Grid justifyCenter style={{ marginTop: '12px', fontSize: '16px', color: colors.branco }}>
				{message || 'Imprimindo nota fiscal...'}
			</Grid>
		</Modal>
	);
}
