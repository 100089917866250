import { format, parseISO } from 'date-fns';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Status, colorsStatus } from 'views/fiscal/vendas/PedidoVendasOrcamentos/Util/constantes';
import { colors, formatarMonetario, formatos, inserirMascara } from '../../../../Common';
import { atualizarUrl } from '../../../../views/Util';
import Button from '../../../Button';
import NenhumRegistroEncontrado from '../../../NenhumRegistroEncontrado';
import { ModuloImportacao } from '../../Util/Constantes';

export default function ListaPedidos(props) {
	const { setSortField, setSortOrder, sortField, sortOrder, registros, isMobile, onHide, moduloImportacao } = props;

	function renderNumeroField(elemento) {
		return (
			<span title="Número do pedido de venda" style={{ fontWeight: 'bolder' }}>
				{elemento.numero}
			</span>
		);
	}

	function renderClienteField(elemento) {
		return (
			<span
				title="Nome do cliente"
				style={{
					maxHeight: '40px',
					overflow: 'hidden',
					display: 'inline-flex',
					paddingLeft: isMobile ? '20%' : '0',
				}}
			>
				{elemento.clienteNome}
			</span>
		);
	}

	function renderCpfCnpj(elemento) {
		return (
			<span
				title={`${elemento.clienteCpf ? 'CPF' : 'CNPJ'} do cliente`}
				style={{
					maxHeight: '40px',
					overflow: 'hidden',
					display: 'inline-flex',
					wordBreak: 'break-all',
					whiteSpace: 'nowrap',
					paddingLeft: isMobile ? '20%' : '0',
				}}
			>
				{elemento.clienteCpfCnpj?.length > 11
					? inserirMascara(elemento.clienteCpfCnpj, formatos.CNPJ)
					: inserirMascara(elemento.clienteCpfCnpj, formatos.CPF)}
			</span>
		);
	}

	function renderEmissaoField(elemento) {
		if (elemento.emissao) return <span>{format(parseISO(elemento.emissao, new Date()), 'dd/MM/yyyy')}</span>;
		return '';
	}

	function renderStatusField(element) {
		let status = '';
		let lightColor = '';
		let strongColor = '';
		let paddingSize = '';

		if (element.status === Status.PENDENTE) {
			status = 'Pendente';
			lightColor = colorsStatus.PENDENTE.lightColor;
			strongColor = colorsStatus.PENDENTE.strongColor;
			paddingSize = '0.2rem 1.3rem';
		} else if (element.status === Status.FINALIZADO) {
			status = 'Finalizado';
			lightColor = colorsStatus.FINALIZADO.lightColor;
			strongColor = colorsStatus.FINALIZADO.strongColor;
			paddingSize = '0.2rem 1.2rem';
		} else if (element.status === Status.CANCELADA) {
			status = 'Cancelado';
			lightColor = colorsStatus.CANCELADA.lightColor;
			strongColor = colorsStatus.CANCELADA.strongColor;
			paddingSize = '0.2rem 1.2rem';
		} else if (element.status === Status.EXPIRADA) {
			status = 'Expirado';
			lightColor = colorsStatus.EXPIRADA.lightColor;
			strongColor = colorsStatus.EXPIRADA.strongColor;
			paddingSize = '0.2rem 1.3rem';
		}

		return (
			<span
				style={{
					backgroundColor: lightColor,
					color: strongColor,
					padding: paddingSize,
					fontWeight: 'bold',
					fontSize: '13px',
					borderRadius: '20px',
					display: 'flex',
					height: '1.5rem',
					width: '7rem',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<span>{status}</span>
			</span>
		);
	}

	function renderTotalLiquidoField(elemento) {
		const totalLiquido = formatarMonetario(elemento.totalLiquido);
		return <span title="Total Líquido do pedido de venda">{totalLiquido}</span>;
	}

	function renderAcoesField(elemento) {
		return (
			<div>
				<Button
					style={{
						borderRadius: '50%',
						padding: '5px',
						width: '30px',
						height: '30px',
						margin: '2px',
					}}
					className="p-button"
					icon="fa fa-check"
					title="Importar pedido de venda"
					onClick={() => selectOrder(elemento)}
				/>
			</div>
		);
	}

	async function selectOrder(venda) {
		switch (moduloImportacao) {
			case ModuloImportacao.NFE:
				atualizarUrl(props.history, `/nfes/importar_pedido/${venda.id}`);
				break;
			case ModuloImportacao.NFCE:
				atualizarUrl(props.history, `/nfces/importar_pedido/${venda.id}`);
				break;
			default:
				break;
		}

		onHide();
	}

	function atualizarOrdenacao(e) {
		setSortField(e.sortField);
		setSortOrder(e.sortOrder);
	}

	return (
		<DataTable
			className="table"
			value={registros}
			responsive
			sortField={sortField}
			sortOrder={sortOrder}
			onSort={atualizarOrdenacao}
			emptyMessage={<NenhumRegistroEncontrado message="Nenhuma nota fiscal encontrada" />}
			style={{
				paddingLeft: '1rem',
				overflowY: 'scroll',
				overflowX: 'hidden',
				maxHeight: '28rem',
				height: '28rem',
			}}
		>
			<Column
				header="Número"
				field="numero"
				sortable={true}
				body={renderNumeroField}
				style={{ color: colors.cinzaDark, width: '100px' }}
			/>
			<Column
				header="Cliente"
				field="clienteNome"
				sortable={true}
				sortField="cliente.nome"
				body={renderClienteField}
				style={{
					color: colors.cinzaDark,
					overflow: 'hidden',
					wordBreak: 'break-all',
				}}
			/>
			<Column
				header="CNPJ/CPF"
				field="clienteCpfCnpj"
				sortable={false}
				body={renderCpfCnpj}
				style={{ color: colors.cinzaDark }}
			/>
			<Column
				header="Emissão"
				field="emissao"
				sortable={true}
				body={renderEmissaoField}
				style={{ color: colors.cinzaDark, width: '100px' }}
			/>
			<Column
				header="Número"
				field="numero"
				sortable={true}
				body={renderStatusField}
				style={{ color: colors.cinzaDark, width: '100px' }}
			/>
			<Column
				header="Total"
				field="totalLiquido"
				sortable={true}
				body={renderTotalLiquidoField}
				style={{
					color: colors.cinzaDark,
					width: '120px',
					textOverflow: 'ellipsis',
					overflow: 'hidden',
					fontWeight: 'bold',
				}}
			/>
			<Column className="step-listagem-acoes" header="Ações" body={renderAcoesField} style={{ width: '40px' }} />
		</DataTable>
	);
}
