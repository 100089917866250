import { Field } from 'formik';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useRef, useState } from 'react';
import {
	buscarDadosLoginLocalStorage,
	colors,
	formatarDecimais,
	formatarMonetarioDecimais,
	removerCaracteres,
	removerCaracteresInvalidosRsql,
	services,
} from '../../../../../../../../../../../Common';
import {
	Button,
	Col,
	Grid,
	NenhumRegistroEncontrado,
	notify,
	ToastTypes,
} from '../../../../../../../../../../../components';
import { asyncGetOpcoesSelect } from '../../../../../../../../../../../components/select/SingleSelect/Requests';
import { InputFieldButton } from './components/InputFieldButton';
import { CiDiscount1 } from 'react-icons/ci';

function InputProduto(props) {
	const {
		produtoSelecionado,
		decimaisQtd,
		setFieldValue,
		adicionarProduto,
		initialValue,
		podeInserir,
		isFullScreen,
		informacoesPermissoes,
		id,
		sm,
		md,
		lg,
		xl,
		name,
		label,
		shortcut,
		isMobile,
		refComponenteVinculado,
		decimaisPreco,
		setarQuantidadeProdutoSelecionado,
	} = props;

	const panelDetalhes = useRef(null);

	const [produtos, setProdutos] = useState([]);
	const [selectedProduto, setSelectedProduto] = useState();

	function onKeyDownSelectProduto(event) {
		if (event.key === '*') {
			if (!isNaN(event.target.value.replace(',', '.'))) {
				setFieldValue('quantidadeSelecionada', formatarDecimais(event.target.value.replace(',', '.'), decimaisQtd));
				event.preventDefault();
				setTimeout(() => {
					event.target.value = '';
				}, 100);
			}
		} else if (event.key === 'Enter') {
			buscarProdutos(removerCaracteresInvalidosRsql(event.target.value));
		}
	}

	function buscarProdutos(pesquisa) {
		let valorPesquisa = pesquisa;
		let quantidadeBarras = 0;
		let precoBarras = 0;

		//Inicio barras com informação
		if (pesquisa && !isNaN(pesquisa) && pesquisa.toString().length > 7) {
			let configBarrasInformacao = buscarDadosLoginLocalStorage()?.filialConectada?.parametrosVendas;

			if (
				configBarrasInformacao.iniciaisBarras &&
				configBarrasInformacao.iniciaisBarras.toString().length > 0 &&
				pesquisa.toString().substring(0, configBarrasInformacao.iniciaisBarras.toString().length) ===
					configBarrasInformacao.iniciaisBarras.toString()
			) {
				let informacao = pesquisa
					.toString()
					.substring(
						configBarrasInformacao.posicaoBarras,
						configBarrasInformacao.posicaoBarras + configBarrasInformacao.tamanhoBarras
					);

				if (configBarrasInformacao.informacaoBarras === 'PESO') {
					quantidadeBarras =
						parseInt(informacao) / parseInt('10'.padEnd(1 + configBarrasInformacao.decimaisBarras, '0'));
					valorPesquisa = parseInt(
						pesquisa
							.toString()
							.substring(configBarrasInformacao.iniciaisBarras.toString().length, configBarrasInformacao.posicaoBarras)
					).toString();
				} else if (configBarrasInformacao.informacaoBarras === 'PRECO') {
					valorPesquisa = parseInt(
						pesquisa
							.toString()
							.substring(configBarrasInformacao.iniciaisBarras.toString().length, configBarrasInformacao.posicaoBarras)
					).toString();
					precoBarras = parseInt(informacao) / parseInt('10'.padEnd(1 + configBarrasInformacao.decimaisBarras, '0'));
				}
			}
		}
		//Fim barras com informação

		asyncGetOpcoesSelect(buscarUrlPesquisa(valorPesquisa), ({ data: registros }) => {
			if (registros.content.length === 1 && pesquisa && !isNaN(pesquisa)) {
				adicionarProduto(
					{ value: registros.content[0].id, registro: registros.content[0] },
					quantidadeBarras,
					precoBarras
				);
			} else if (registros.content.length > 0 || !pesquisa) {
				setProdutos(registros.content);
				panelDetalhes.current.toggle({ target: refComponenteVinculado });
				setTimeout(() => {
					document
						.getElementById('id-overlay-panel-produto')
						?.getElementsByTagName('tbody')[0]
						?.getElementsByTagName('tr')[0]
						?.focus();
				}, 200);
			} else {
				setFieldValue('produtoSelecionado', initialValue.produtoSelecionado);
				notify('Produto não encontrado', ToastTypes.ERROR);

				let layoutFullscreen = document.getElementsByClassName('layout-fullscreen');
				let alertifyNotifier = document.getElementsByClassName('alertify-notifier');

				if (isFullScreen) {
					layoutFullscreen[0].append(alertifyNotifier[0]);
				}
			}
		});
	}

	function buscarUrlPesquisa(pesquisa) {
		const pesquisaCodigo = removerCaracteres(pesquisa, ['.']);
		const pesquisaFormatado = removerCaracteresInvalidosRsql(pesquisa);
		return `${services.GESTOR}/v1/produtos?query=(codigo=contains="*${pesquisaCodigo}*",nome=contains="*${pesquisaFormatado}*",codigo=contains="*${pesquisaFormatado}*",sku=contains="*${pesquisaFormatado}*",codigoBarras=contains="${pesquisaFormatado}");tipo!="SERVICO";situacao=="ATIVO"&page=0&size=50&sort=codigo`;
	}

	function renderProdutoField(row) {
		return (
			<span style={{ paddingLeft: isMobile ? '20%' : '0', display: 'flex', alignItems: 'center' }}>
				{`${row.codigo} - ${row.nome}${row.sku ? ' [Ref:' + row.sku + ']' : ''} `}
				{row?.tabelaPreco?.tabelaPromocional && (
					<CiDiscount1
						style={{ width: '18px', height: '18px', marginLeft: '8px', color: colors.verdeButton }}
						title={
							row?.tabelaPreco
								? `Produto presente na tabela promocional: ${row?.tabelaPreco?.codigo} - ${row?.tabelaPreco?.nome}`
								: 'Produto presente em tabela promocional'
						}
					/>
				)}
			</span>
		);
	}

	function onRowSelect(event) {
		adicionarProduto({ value: event.data.id, registro: event.data });
		panelDetalhes.current.hide();
		setTimeout(() => {
			setFieldValue('produtoSelecionado', initialValue.produtoSelecionado);
			setFieldValue('quantidadeSelecionada', initialValue.quantidadeSelecionada);
			document.getElementById('id-produto-nfce')?.focus();
		}, 200);
	}

	function onChangeInput(event) {
		if (props.podeInserir) {
			setFieldValue('produtoSelecionado', event.target.value);
		}
	}

	return (
		<>
			<OverlayPanel
				id="id-input-produto-overlay-panel"
				style={{
					right: `${isMobile ? '12px' : null}`,
					width: document.getElementById('id-produto-nfce')?.offsetWidth,
					margin: `${isMobile ? '0px 8px' : null}`,
					marginTop: '0px',
				}}
				ref={panelDetalhes}
				appendTo={isFullScreen ? document.getElementsByClassName('layout-fullscreen')[0] : null}
			>
				<Grid sm={sm} md={md} lg={lg} xl={xl}>
					<DataTable
						id="id-overlay-panel-produto"
						className="table"
						rowClassName="table-row"
						cellClassName="table-row-cell"
						selectionMode="single"
						responsive
						value={produtos}
						emptyMessage={<NenhumRegistroEncontrado />}
						style={{
							maxWidth: '100%',
							width: '100%',
							maxHeight: '12rem',
							overflowY: produtos?.length > 3 ? 'scroll' : 'hidden',
							overflowX: 'hidden',
						}}
						onSelectionChange={(event) => setSelectedProduto(event)}
						selection={selectedProduto}
						onRowSelect={onRowSelect}
					>
						<Column
							field="produto"
							header="Produto"
							name="produto"
							body={(row) => renderProdutoField(row)}
							style={{
								textOverflow: 'ellipsis',
								overflow: 'hidden',
							}}
						/>
						<Column
							field="preco"
							header="Preço"
							name="preco"
							body={(row) => formatarMonetarioDecimais(row.preco, decimaisPreco)}
							style={{
								textOverflow: 'ellipsis',
								overflow: 'hidden',
								wordBreak: 'break-all',
								width: '8rem',
							}}
						/>
					</DataTable>
				</Grid>
			</OverlayPanel>
			<Field
				id={id}
				sm={sm}
				md={md}
				lg={lg}
				xl={xl}
				component={InputFieldButton}
				name={name}
				label={label}
				shortcut={shortcut}
				value={produtoSelecionado}
				onKeyDown={(e) => onKeyDownSelectProduto(e)}
				onChange={(event) => onChangeInput(event)}
				disabled={!podeInserir}
				menuPortalTarget={isFullScreen ? document.getElementsByClassName('layout-fullscreen')[0] : null}
				onPressButtonSearch={(inputValue) => buscarProdutos(removerCaracteresInvalidosRsql(inputValue))}
				{...informacoesPermissoes}
			/>
		</>
	);
}

export default InputProduto;
