import React, { Component } from 'react';
import { Field, withFormik } from 'formik';
import * as Yup from 'yup';
import InputField from '../../../components/input/InputField';
import Grid from '../../../components/Grid';
import { withRouter, Link } from 'react-router-dom';
import AutoProgressBar from '../../../components/Loading/AutoProgressBar';
import Card from '../../../components/cards/Card';
import If from '../../../components/If';
import { subTitleStyle, titleStyle } from '../Styles';
import Button from '../../../components/Button';
import autoBind from 'react-autobind';
import logo from '../../../layout/Images/logo_msys.svg';
import Dropdown from '../../../components/select/Dropdown';
import InputMask from '../../../components/input/InputMask';
import { isValidCPF, isValidCNPJ } from '@brazilian-utils/brazilian-utils';
import { buscarMascaraTelefone, manterApenasNumeros } from '../../../Common/Mascara';
import { mensagensDeValidacao } from '../../../Common/Constantes/mensagens';
import {
	modulosDocs,
	modulosGestor,
	tituloAplicacao,
	retornarAplicacao,
	validarFormulario,
	APLICACAO,
} from '../../Util';
import { asyncCriarConta } from './Requests';
import Col from '../../../components/Col';
import { converterCadastroParaApi } from './Util/cadastroConverter';
import CardModuloDetalhado from '../../../components/cards/CardModuloDetalhado';
import { validarUUID } from '../../../Common/ManipulacaoDeString';
import stringMask from 'string-mask';
import { colors } from 'Common';

const tipoPessoa = [
	{ label: 'Física', value: 'FISICA' },
	{ label: 'Jurídica', value: 'JURIDICA' },
];

const cardStyle = {
	marginTop: '8px',
	marginBottom: '20px',
	maxWidth: '450px',
	borderRadius: '5px',
};

const contentStyle = {
	height: '100%',
	justifyContent: 'center',
	alignItems: 'center',
	display: 'grid',
};

const progressBarStyle = {
	position: 'fixed',
	width: '100%',
};

const buttonSendStyle = {
	height: '40px',
	width: '100%',
};

const buttonBackStyle = {
	height: '40px',
	width: '100%',
};

const buttonContinuarStyle = {
	height: '40px',
	width: '100%',
};

const initialValue = {
	nome: null,
	email: null,
	tipo: 'FISICA',
	cpf: null,
	cnpj: null,
	telefone: null,
	modulos: [],
};

const linkPoliticaDePrivacidade = {
	cursor: 'pointer',
	color: colors.principal,
	fontSize: '13px',
};

const politicaDePrivacidade = {
	fontSize: '13px',
};

export class Cadastro extends Component {
	constructor(props) {
		super(props);
		autoBind(this);

		this.state = {
			isMobile: false,
			formularioEnviado: false,
			loading: false,
			activeIndex: 0,
			modulosPreSelecionados: [],
		};
	}

	componentDidMount() {
		this.buscarDispositivo();
		this.buscarModulosPreSelecionados();
		this.setInitialModules();

		setTimeout(() => {
			document.getElementById('CadastroInputNome')?.focus();
		}, 500);
	}

	setInitialModules() {
		this.props.setFieldValue('modulos', ['ESTOQUE', 'COMPRAS', 'VENDAS', 'FINANCAS', 'CONTROLADORIA'].sort());
	}

	buscarDispositivo() {
		const larguraInicial = window.innerWidth;
		if (larguraInicial <= 576) {
			this.setState({ isMobile: true });
		}
	}
	async buscarModulosPreSelecionados() {
		const modulosString = new URLSearchParams(this.props.location.search).getAll('modulos').toString();
		if (modulosString) {
			const modulos = modulosString.split(',');
			await this.setState({ modulosPreSelecionados: modulos });
		} else {
			await this.setState({
				modulosPreSelecionados: [],
			});
		}
	}

	async efetuarCadastro() {
		this.props.handleSubmit();
		const agenteAutorizado = new URLSearchParams(this.props.location.search).get('agenteId');

		let formValues = {};

		if (agenteAutorizado && validarUUID(agenteAutorizado)) {
			formValues = { ...this.props.values, agenteAutorizado };
		} else {
			formValues = this.props.values;
		}

		if (await validarFormulario(this.props)) {
			this.setState({ loading: true }, () => {
				asyncCriarConta(
					converterCadastroParaApi(formValues),
					() => {
						this.setState({ formularioEnviado: true });
						this.props.history.push('/login');
					},
					() => {
						this.setState({
							formularioEnviado: false,
							loading: false,
							activeIndex: 0,
						});
					}
				);
			});
		}
	}

	onSelectModulo(modulo) {
		const { values, setFieldValue } = this.props;
		const moduloJaSelecionado = values.modulos.includes(modulo);

		if (moduloJaSelecionado) {
			setFieldValue('modulos', values.modulos.filter((item) => item !== modulo).sort());
		} else {
			setFieldValue('modulos', [...values.modulos, modulo].sort());
		}
	}

	politicaDePrivacidade() {
		window.open('https://msyssolutions.inf.br/politica-de-privacidade', '_blank');
	}

	async validarModulosPreSelecionados() {
		const { modulosPreSelecionados } = this.state;
		if (modulosPreSelecionados.length === 0) {
			if (retornarAplicacao() === APLICACAO.DOCS_DIGITAIS) {
				await this.props.setFieldValue('modulos', modulosDocs());
			} else {
				await this.props.setFieldValue('modulos', modulosGestor());
			}
		} else {
			await this.props.setFieldValue('modulos', modulosPreSelecionados);
		}
		this.efetuarCadastro();
	}

	templateFormulario() {
		const { values } = this.props;
		const { formularioEnviado, isMobile, modulosPreSelecionados } = this.state;

		return (
			<>
				<div style={{ textAlign: 'center', width: '100%' }}>
					<h2 style={titleStyle}>Crie sua conta Msys {tituloAplicacao()}</h2>
					<h3 style={{ ...subTitleStyle, margin: 'unset', fontWeight: 'bold' }}>
						Preencha seus dados e receba o email de confirmação
					</h3>
				</div>

				<Col md="12" style={isMobile ? null : { marginTop: '10px' }}>
					<Field
						component={InputField}
						placeholder="Seu nome"
						name="nome"
						label="Nome"
						size={60}
						obrigatorio
						disabled={formularioEnviado}
						id="CadastroInputNome"
					/>

					<Field
						component={InputField}
						placeholder="Seu endereço de e-mail"
						name="email"
						label="Email"
						size={74}
						obrigatorio
						disabled={formularioEnviado}
					/>
					<Field
						component={Dropdown}
						label="Tipo"
						obrigatorio
						name="tipo"
						showClear={false}
						onChange={(e) => this.props.setFieldValue('tipo', e.value)}
						options={tipoPessoa}
						disabled={formularioEnviado}
					/>
					<If test={values.tipo === 'FISICA'}>
						<Field
							component={InputMask}
							mask="000.000.000-00"
							placeholder="   .   .   -  "
							label="CPF "
							size={11}
							obrigatorio
							name="cpf"
							onChange={(e) => this.props.setFieldValue('cpf', e.target.value)}
							value={values.cpf}
							disabled={formularioEnviado}
						/>
					</If>
					<If test={values.tipo === 'JURIDICA'}>
						<Field
							component={InputMask}
							mask="00.000.000/0000-00"
							placeholder="  .   .   /    -  "
							label="CNPJ"
							size={14}
							obrigatorio
							name="cnpj"
							value={values.cnpj}
							onChange={(e) => this.props.setFieldValue('cnpj', e.target.value)}
							disabled={formularioEnviado}
						/>
					</If>

					<Field
						component={InputMask}
						mask={buscarMascaraTelefone(values.telefone)}
						placeholder={'(  )      -    '}
						label="Telefone"
						obrigatorio
						name="telefone"
						size={16}
						disabled={formularioEnviado}
						onChange={(e) => this.props.setFieldValue('telefone', e.target.value)}
					/>
					<div style={{ textAlign: 'center', width: '100%', marginTop: '10px' }}>
						<span style={politicaDePrivacidade}>Ao criar a conta estou de acordo com os termos da&nbsp;</span>
						<span onClick={this.politicaDePrivacidade} style={linkPoliticaDePrivacidade}>
							política de privacidade&nbsp;
						</span>
						<span style={politicaDePrivacidade}>da Msys Solutions®</span>
					</div>

					<Grid style={{ padding: '5px', marginTop: '10px' }}>
						<Col
							style={{
								width: '100',
							}}
						>
							<Button
								color="primary"
								label={'Criar conta'}
								style={buttonContinuarStyle}
								title="Um email será enviado para definição da sua senha"
								onClick={async () => {
									if (await validarFormulario(this.props)) {
										this.validarModulosPreSelecionados();
									}
								}}
							/>
						</Col>
					</Grid>
				</Col>
			</>
		);
	}

	templateModulos() {
		const { values, errors } = this.props;
		const { formularioEnviado, loading } = this.state;

		return (
			<>
				<div style={{ textAlign: 'center', width: '100%' }}>
					<h2 style={titleStyle}>Selecione os módulos que você deseja utilizar</h2>
					<h3 style={{ ...subTitleStyle, margin: 'unset' }}>
						Não se preocupe, você poderá modificá-los após o cadastro
					</h3>
				</div>

				<Col
					md="12"
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
					}}
				>
					<Col col="12">
						<CardModuloDetalhado
							value={'COMPRAS'}
							selected={values.modulos.includes('COMPRAS')}
							onSelect={this.onSelectModulo}
							disabled={formularioEnviado}
							title="Gestão de compras"
							subtitle="Processo de compras e importação de documentos fiscais"
							content={
								<div>
									<p>
										Administre todo o seu processo de compra: importações de XML, cadastros facilitados, conversões de
										quantidade e alteração de preços através da margem de lucro. Tudo isso de forma ágil e com poucos
										cliques.
									</p>
								</div>
							}
						/>
					</Col>
					<Col col="12">
						<CardModuloDetalhado
							value={'ESTOQUE'}
							selected={values.modulos.includes('ESTOQUE')}
							onSelect={this.onSelectModulo}
							disabled={formularioEnviado}
							title="Gestão e controle de estoque"
							subtitle="Cadastro, movimentações e saldo de produtos"
							content={
								<div>
									<p>
										Organize as movimentações de entrada e saída do seu estoque e tenha os produtos certos na hora
										certa.
									</p>
								</div>
							}
						/>
					</Col>
					<Col sm="12">
						<CardModuloDetalhado
							value={'VENDAS'}
							selected={values.modulos.includes('VENDAS')}
							onSelect={this.onSelectModulo}
							disabled={formularioEnviado}
							title="Gestão de vendas"
							subtitle="Processo de venda e emissão de documentos fiscais"
							content={
								<div>
									<p>
										Gerencie seus pedidos e orçamentos de forma integrada e ágil e emita documentos fiscais sem
										complicações.
									</p>
								</div>
							}
						/>
					</Col>
					<Col col="12">
						<CardModuloDetalhado
							value={'FINANCAS'}
							selected={values.modulos.includes('FINANCAS')}
							onSelect={this.onSelectModulo}
							disabled={formularioEnviado}
							title="Gestão de finanças"
							subtitle="Controle de finanças, gestão de títulos e movimento bancário"
							content={
								<div>
									<p>
										Acompanhe de perto suas finanças pessoais ou empresariais e descomplique de vez sua vida. Controle
										contas a pagar, a receber e fluxo de caixa.
									</p>
								</div>
							}
						/>
					</Col>
					<Col col="12">
						<CardModuloDetalhado
							value={'CONTROLADORIA'}
							selected={values.modulos.includes('CONTROLADORIA')}
							onSelect={this.onSelectModulo}
							disabled={formularioEnviado}
							title="Controladoria"
							subtitle="Gere os arquivos de escrituração digital como o EFD ICMS/IPI, EFD PIS/COFINS, entre outros. Acompanhe os indicativos da sua empresa, além do controle de logs."
							content={
								<div>
									<p>Gere a escrituração fiscal digital - EFD ICMS/IPI</p>
								</div>
							}
						/>
					</Col>
					<Col col="12">
						<CardModuloDetalhado
							value={'DOCUMENTOS'}
							selected={values.modulos.includes('DOCUMENTOS')}
							onSelect={this.onSelectModulo}
							disabled={formularioEnviado}
							title="Assinatura e gestão de documentos"
							subtitle="Documentos para assinatura eletrônica ou digital e gestão de documentos"
							content={
								<div>
									<p>
										Realize o upload, armazenamento e envio de documentos para assinatura eletrônica ou digital com
										validade jurídica.
									</p>
								</div>
							}
						/>
					</Col>
					<If test={errors.modulos}>
						<Col sm="12" style={{ padding: '0px 10px' }}>
							<span style={{ color: colors.vermelho, fontSize: '12px' }}>
								Selecione ao menos um módulo para utilizar no sistema
							</span>
						</Col>
					</If>
				</Col>

				<Col md="12" style={{ paddingLeft: '13px', paddingRight: '13px' }}>
					<If test={formularioEnviado}>
						<Button
							color="success"
							label="Cadastro efetuado"
							title="Verifique seu e-mail para confirmar a criação da sua conta"
							style={buttonSendStyle}
							disabled
						/>
					</If>

					<If test={!formularioEnviado}>
						<Grid>
							<Col col="6">
								<Button
									color="secondary"
									label="Voltar"
									title="Voltar para o cadastro"
									style={buttonBackStyle}
									onClick={() => {
										this.setState({ activeIndex: 0 });
									}}
								/>
							</Col>
							<Col col="6">
								<Button
									color="primary"
									label={loading ? 'Carregando...' : 'Criar conta'}
									title={
										values.modulos.length === 0
											? 'Selecione ao menos um módulo do sistema para criar sua conta'
											: 'Clique aqui para criar sua conta'
									}
									style={buttonSendStyle}
									disabled={loading || values.modulos.length === 0}
									onClick={loading ? null : this.efetuarCadastro}
								/>
							</Col>
						</Grid>
					</If>
				</Col>
			</>
		);
	}

	template() {
		if (this.state.activeIndex !== 1) {
			return this.templateFormulario();
		} else {
			return this.templateModulos();
		}
	}

	render() {
		return (
			<>
				<AutoProgressBar style={progressBarStyle} />
				<div style={contentStyle}>
					<Card style={cardStyle}>
						<Col col="12" style={{ justifyContent: 'center', display: 'flex' }}>
							<img alt="Logo Microsys" style={{ width: '200px' }} src={logo} />
						</Col>
						<Grid style={{ paddingBottom: '18px' }}>
							{this.template()}
							<Col style={{ textAlign: 'center' }}>
								<span>Já possui conta?</span>
								<Link to="/login"> Voltar para o login</Link>
							</Col>
						</Grid>
					</Card>
				</div>
			</>
		);
	}
}

Cadastro = withFormik({
	validateOnChange: true,
	validateOnBlur: false,
	mapPropsToValues() {
		return initialValue;
	},

	validate(values) {
		let errors = {};

		if (values.tipo === 'FISICA') {
			if (!values.cpf) errors.cpf = mensagensDeValidacao.OBRIGATORIO;

			if (values.cpf && !isValidCPF(manterApenasNumeros(values.cpf))) errors.cpf = 'Digite um CPF válido.';
		}
		if (values.tipo === 'JURIDICA') {
			if (!values.cnpj) errors.cnpj = mensagensDeValidacao.OBRIGATORIO;

			if (values.cnpj && !isValidCNPJ(manterApenasNumeros(values.cnpj))) errors.cnpj = 'Digite um CNPJ válido.';
		}
		if (
			!new stringMask('(00) 00000-0000').validate(manterApenasNumeros(values.telefone)) &&
			!new stringMask('(00) 0000-0000').validate(manterApenasNumeros(values.telefone))
		) {
			errors.telefone = mensagensDeValidacao.TELEFONE_INVALIDO;
		}

		return errors;
	},

	validationSchema: Yup.object().shape({
		nome: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		email: Yup.string()
			.nullable()
			.email(mensagensDeValidacao.EMAIL_INVALIDO)
			.required(mensagensDeValidacao.OBRIGATORIO),
		telefone: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
	}),

	handleSubmit: () => {},
})(Cadastro);

export default withRouter(Cadastro);
