import { colors } from 'Common';
import { formatISO } from 'date-fns';

export const helpMessage = {
	desconto: 'Valor dos descontos quando aplicados',
	juros: 'Valor dos juros quando aplicados',
	multa: 'Valor da multa quando aplicada',
	formaPagamento: 'Forma de pagamento do recebimento.',
	conta: 'Conta de destino do valor do recebimento. Ao salvar, o recebimento irá gerar um crédito na conta selecionada',
	valor: 'Valor do recebimento',
	observacao: 'Observação do recebimento',
};

export const spanStyle = {
	display: 'flex',
	alignItems: 'center',
	margin: '0px 0px 10px 0px',
};
export const aReceberStyle = {
	fontSize: '13px',
	fontWeight: 'bold',
	color: colors.vermelho,
	margin: '0px 10px 0px 0px',
};
export const parteStyle = {
	fontSize: '13px',
	color: '#000000',
	margin: '0px 0px',
};
export const totalStyle = {
	fontSize: '20px',
	fontWeight: 'bold',
	color: '#000000',
	margin: '0px 10px 0px 0px',
};

export const INITIAL_VALUES = {
	id: null,
	idTemporario: null,
	data: formatISO(new Date()),
	desconto: 0,
	juros: 0,
	multa: 0,
	formaPagamento: null,
	conta: null,
	valor: null,
	valorAReceber: null,
	observacao: null,
	recebimentoNovoOuAlterado: true,
};
