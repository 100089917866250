import { useState, useEffect, useRef } from 'react';
import { Chart } from 'chart.js';
import { Card } from 'primereact/card';
import { buscarDocumentosArquivadosPeriodo } from './Requests';
import { ProgressBar } from 'primereact/progressbar';
import 'chartjs-plugin-datalabels';
import NenhumaInformacaoEncontrada from '../../../NenhumaInformacaoEncontrada';

import FalhaAoCarregarAsInformacoes from '../../../FalhaAoCarregarAsInformacoes';
import { ColorsCard } from '../../../../../../documentos/AssinaturaEletronica/Util/constantes';
import { connect } from 'react-redux';
import usePrevious from '../../../../../../../Common/Hooks/usePrevious';
import { colors } from 'Common';

const cardHeader = {
	padding: '14px',
	display: 'flex',
	justifyContent: 'space-between',
};

const NomeECorDocumento = {
	PENDENTE: {
		nome: 'Pendente',
		cor: ColorsCard['PENDENTE'].strongColor,
	},
	ARQUIVO_ADICIONADO: {
		nome: 'Arquivo adicionado',
		cor: ColorsCard['ARQUIVO_ADICIONADO'].strongColor,
	},
	AGUARDANDO_ASSINATURAS: {
		nome: 'Aguardando assinaturas',
		cor: ColorsCard['AGUARDANDO_ASSINATURAS'].strongColor,
	},
	ASSINADO: {
		nome: 'Assinado',
		cor: ColorsCard['ASSINADO'].strongColor,
	},
	REJEITADO: {
		nome: 'Rejeitado',
		cor: ColorsCard['REJEITADO'].strongColor,
	},
	EXPIRADO: {
		nome: 'Expirado',
		cor: ColorsCard['EXPIRADO'].strongColor,
	},
};

let documentosArquivadosPorStatus;

function GraficoDocumentosArquivadosPeriodo(props) {
	const { dataInicial, dataFinal, atualizarGraficoNovamente, isMobile, dataAtualizacaoMenuLateral } = props;

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [status, setStatus] = useState([]);
	const [corDocumentos, setCorDocumentos] = useState([]);
	const [quantidade, setQuantidade] = useState([]);
	const [documentos, setDocumentos] = useState([]);
	const graficoDeDocumentosArquivadosNoPeriodo = useRef();
	const afterFirstRenderRef = useRef(false);
	const prevProps = usePrevious(props);

	useEffect(() => {
		carregarInformacoes();

		return () => {
			if (typeof documentosArquivadosPorStatus !== 'undefined') documentosArquivadosPorStatus.destroy();
		};
	}, []);

	useEffect(() => {
		carregarInformacoes();
	}, [dataAtualizacaoMenuLateral]);

	useEffect(() => {
		if (loading) {
			buscarDocumentosArquivadosPeriodo(
				dataInicial,
				dataFinal,
				({ data: documentos }) => {
					let status = [];
					let quantidade = [];
					let corDocumentos = [];

					documentos.forEach((documento) => {
						status.push(NomeECorDocumento[documento.status].nome);
						corDocumentos.push(NomeECorDocumento[documento.status].cor);
						quantidade.push(documento.quantidade);
					});

					setStatus(status);
					setQuantidade(quantidade);
					setLoading(false);
					setDocumentos(documentos);
					setCorDocumentos(corDocumentos);
				},
				() => {
					setLoading(false);
					setError(true);
				}
			);
		}
	}, [loading, dataAtualizacaoMenuLateral]);

	useEffect(() => {
		buildChart();
	}, [corDocumentos]);

	useEffect(() => {
		if (afterFirstRenderRef) {
			if (prevProps?.dataInicial !== dataInicial || prevProps?.dataFinal !== dataFinal) {
				carregarInformacoes();
			}

			if (prevProps?.atualizarGraficoNovamente !== atualizarGraficoNovamente) {
				carregarInformacoes(false);
			}
		} else afterFirstRenderRef = true;
	}, [dataInicial, dataFinal]);

	function carregarInformacoes(exibirLoading = true) {
		setLoading(exibirLoading);
		setError(false);
	}

	function buildChart() {
		if (!graficoDeDocumentosArquivadosNoPeriodo.current) {
			return;
		}
		const myChartRef = graficoDeDocumentosArquivadosNoPeriodo.current.getContext('2d');

		if (typeof documentosArquivadosPorStatus !== 'undefined') documentosArquivadosPorStatus.destroy(); //Necessário para atualizar o gráfico sem bugs
		documentosArquivadosPorStatus = new Chart(myChartRef, {
			type: 'bar',
			data: {
				labels: status,
				datasets: [
					{
						data: quantidade,
						backgroundColor: corDocumentos,
					},
				],
			},
			options: {
				responsive: true,
				maintainAspectRatio: false,
				aspectRatio: 1.1,
				animation: {
					animateScale: true,
					animateRotate: true,
				},
				plugins: {
					datalabels: {
						color: colors.branco,
						font: {
							size: 12,
						},
						formatter: function (value, context) {
							let total = 0;
							let dataArr = context.chart.data.datasets[0].data;
							dataArr.forEach((data) => {
								total += data;
							});
							return ((value * 100) / total).toFixed(1) + '%';
						},
					},
					legend: {
						display: false,
					},
					tooltip: {
						callbacks: {
							title: (tooltipItem) => {
								const label = tooltipItem[0].label;
								let total = 0;
								let dataArr = tooltipItem[0].dataset.data;
								dataArr.forEach((data) => (total += data));
								const value = tooltipItem[0].raw;
								return label + ' (' + ((value * 100) / total).toFixed(1) + '%)';
							},
							label: (tooltipItem) => {
								const quantidade = tooltipItem.raw;
								if (quantidade === 1) {
									return ` ${quantidade} Documento`;
								}
								return ` ${quantidade} Documentos`;
							},
						},
					},
				},
				scales: {
					y: {
						beginAtZero: true,
						suggestedMax: 10,
					},
					x: {
						ticks: {
							display: !isMobile,
						},
					},
				},
			},
		});
	}

	function getHeaderCard() {
		return (
			<div style={cardHeader}>
				<label
					title="Mostra os documentos arquivados no período e agrupa por status"
					style={{ fontSize: '17px', color: colors.cinzaDark }}
				>
					Documentos arquivados no período
				</label>
			</div>
		);
	}

	if (loading) {
		return (
			<Card header={getHeaderCard()} style={{ borderRadius: '5px', height: '100%' }}>
				<ProgressBar mode="indeterminate" style={{ height: '3px' }} />
			</Card>
		);
	} else if (error) {
		return (
			<Card header={getHeaderCard()} style={{ borderRadius: '5px', height: '100%' }}>
				<FalhaAoCarregarAsInformacoes />
			</Card>
		);
	} else if (documentos.length === 0) {
		return (
			<Card header={getHeaderCard()} style={{ borderRadius: '5px', height: '100%' }}>
				<NenhumaInformacaoEncontrada />
			</Card>
		);
	} else {
		return (
			<Card header={getHeaderCard()} style={{ borderRadius: '5px', height: '100%', margin: '0px' }}>
				<canvas
					id="graficoDeDocumentosArquivadosNoPeriodo"
					ref={graficoDeDocumentosArquivadosNoPeriodo}
					style={{ maxWidth: '100%' }}
				/>
			</Card>
		);
	}
}

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
	dataAtualizacaoMenuLateral: state.menuLateral.dataAtualizacaoMenuLateral,
});

export default connect(mapStateToProps)(GraficoDocumentosArquivadosPeriodo);
