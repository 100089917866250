import { colors } from 'Common';

export const VENDAS_MODULOS = {
	ORCAMENTO: 'ORCAMENTO',
	PEDIDO_VENDA: 'PEDIDO_VENDA',
	NFE: 'NFE',
	NFCE: 'NFCE',
};

export const OPTIONS_SELECT = [
	{
		label: 'Orçamento',
		value: VENDAS_MODULOS.ORCAMENTO,
	},
	{
		label: 'Pedido de venda',
		value: VENDAS_MODULOS.PEDIDO_VENDA,
	},
	{
		label: 'NF-e',
		value: VENDAS_MODULOS.NFE,
	},
	{
		label: 'NFC-e',
		value: VENDAS_MODULOS.NFCE,
	},
];

export const VENDAS_CORES = {
	vermelho: colors.vermelho,
	vermelhoClaro: colors.vermelhoLight,
	verde: colors.verde,
	verdeClaro: colors.verdeLight,
	azul: colors.principal,
	preto: colors.brancoTransparente,
	cinza: colors.cinza,
	branco: colors.cinzaLight,
};
