import { useEffect, useRef, useState } from 'react';
import TituloCard from '../../../../../../../components/TituloCard';
import { Chart } from 'chart.js';
import { ProgressBar } from 'primereact/progressbar';
import { buscarResultadoNoPeriodo } from './Requests';
import usePrevious from '../../../../../../../Common/Hooks/usePrevious';
import { ColorsCard } from '../../../../../../compras/NotasEntrada/Util/constantes';
import NenhumaInformacaoEncontrada from '../../../../components/NenhumaInformacaoEncontrada';
import FalhaAoCarregarAsInformacoes from '../../../../components/FalhaAoCarregarAsInformacoes';
import { colors } from 'Common';

const cardStyle = {
	padding: '7px',
	borderRadius: '5px',
	backgroundColor: 'white',
	boxShadow: '#00000033 0px 1px 3px 0px, #00000024 0px 1px 1px 0px, #0000001f 0px 2px 1px -1px',
	minHeight: '100%',
};

const dashboardContent = {
	display: 'flex',
	justifyContent: 'space-between',
	width: '100%',
};

let documentosPorStatus;

export default function ComprasPorSituacao(props) {
	const { dataInicial, dataFinal, atualizarGraficoNovamente, isMobile, dataAtualizacaoMenuLateral } = props;

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);

	const [totalPorcentagem, setTotalPorcentagem] = useState([]);
	const [categorias, setCategorias] = useState([]);
	const [corStatus, setCorStatus] = useState([]);

	const graficoDeComprasAgrupadoPorSituacao = useRef();
	const afterFirstRenderRef = useRef(false);
	const prevProps = usePrevious(props);

	function calculoPorcentagem(total, quantidade) {
		let calculo = (quantidade / total) * 100;
		return parseFloat(calculo).toFixed(2);
	}

	useEffect(() => {
		if (afterFirstRenderRef) {
			if (prevProps?.dataInicial !== dataInicial || prevProps?.dataFinal !== dataFinal) {
				carregarInformacoes();
			}

			if (prevProps?.atualizarGraficoNovamente !== atualizarGraficoNovamente) {
				carregarInformacoes(false);
			}
		} else afterFirstRenderRef = true;
	}, [dataInicial, dataFinal]);

	useEffect(() => {
		buscarResultadoNoPeriodo(
			dataInicial,
			dataFinal,
			({ data }) => {
				let situacoesResponse = [];
				let total = data.valorPendentes + data.valorFinalizadas + data.valorCanceladas;
				let coresStatus = [];
				let categorias = [];

				if (data.valorPendentes !== 0) {
					situacoesResponse.push(calculoPorcentagem(total, data.valorPendentes));
					coresStatus.push(ColorsCard['PENDENTE'].strongColor);
					categorias.push('Pendentes');
				}

				if (data.valorFinalizadas !== 0) {
					situacoesResponse.push(calculoPorcentagem(total, data.valorFinalizadas));
					coresStatus.push(ColorsCard['FINALIZADA'].strongColor);
					categorias.push('Finalizadas');
				}

				if (data.valorCanceladas !== 0) {
					situacoesResponse.push(calculoPorcentagem(total, data.valorCanceladas));
					coresStatus.push(ColorsCard['CANCELADA'].strongColor);
					categorias.push('Canceladas');
				}

				setCategorias(categorias);
				setCorStatus(coresStatus);
				setTotalPorcentagem(situacoesResponse);

				setLoading(false);
				setError(false);
			},
			() => {
				setLoading(false);
				setError(true);
			}
		);
	}, [loading, dataAtualizacaoMenuLateral]);

	useEffect(() => {
		buildChart();
	}, [totalPorcentagem]);

	function carregarInformacoes(exibirLoading = true) {
		setLoading(exibirLoading);
		setError(false);
	}

	function buildChart() {
		if (!graficoDeComprasAgrupadoPorSituacao.current) {
			return;
		}

		const myChartRef = graficoDeComprasAgrupadoPorSituacao.current.getContext('2d');

		if (typeof documentosPorStatus !== 'undefined') documentosPorStatus.destroy();
		documentosPorStatus = new Chart(myChartRef, {
			type: 'doughnut',
			data: {
				datasets: [
					{
						data: totalPorcentagem,
						backgroundColor: corStatus,
					},
				],
				labels: categorias,
			},
			options: {
				responsive: true,
				maintainAspectRatio: false,
				aspectRatio: 1.1,
				onHover: (event, chartElement) => {
					chartElement.length === 1
						? (event.native.target.style.cursor = 'pointer')
						: (event.native.target.style.cursor = 'default');
				},
				plugins: {
					datalabels: {
						color: colors.branco,
						font: {
							size: 11,
						},
						formatter: (value, ctx) => {
							let total = 0;
							let dataArr = ctx.chart.data.datasets[0].data;
							dataArr.forEach((data) => {
								total += parseFloat(data);
							});
							return ((value * 100) / total).toFixed(1) + '%';
						},
					},
					legend: {
						position: 'bottom',
						labels: {
							usePointStyle: true,
							pointStyle: 'circle',
						},
						onHover: (event) => {
							event.native.target.style.cursor = 'pointer';
						},
						onLeave: (event) => {
							event.native.target.style.cursor = 'default';
						},
					},
				},
				tooltips: {
					callbacks: {
						label: (tooltipItem, data) => {
							const quantidade = data.datasets[0].data[tooltipItem.index];
							if (quantidade === 1) {
								return ` ${quantidade} Documento`;
							}
							return ` ${quantidade} Documentos`;
						},
						title: (tooltipItem, data) => {
							const label = data.labels[tooltipItem[0].index];
							let total = 0;
							let dataArr = data.datasets[0].data;
							dataArr.forEach((data) => (total += data));
							const value = dataArr[tooltipItem[0].index];
							return label + ' (' + ((value * 100) / total).toFixed(1) + '%)';
						},
					},
				},
			},
		});
	}

	if (loading || error || totalPorcentagem.length === 0) {
		return (
			<div style={cardStyle}>
				<TituloCard
					title={'Compras por situação'}
					helpMessage="Gráfico de compras por situação no período selecionado"
				/>
				<div style={dashboardContent}>
					{loading && <ProgressBar mode="indeterminate" style={{ height: '3px', width: '100%', margin: '10px 0px' }} />}
					{error && <FalhaAoCarregarAsInformacoes />}
					{totalPorcentagem.length === 0 && <NenhumaInformacaoEncontrada />}
				</div>
			</div>
		);
	} else {
		return (
			<div style={cardStyle}>
				<TituloCard
					title={'Compras por situação'}
					helpMessage="Gráfico das compra por situação no período selecionado"
				/>
				<div>
					<canvas
						id="graficoDeComprasAgrupadoPorSituacao"
						ref={graficoDeComprasAgrupadoPorSituacao}
						style={{ maxWidth: '100%' }}
					/>
				</div>
			</div>
		);
	}
}
