import { useState } from 'react';
import propTypes from 'prop-types';
import Joyride, { STATUS, ACTIONS, LIFECYCLE } from 'react-joyride';
import { colors } from 'Common';

export default function Tutorial(props) {
	const { steps, visible } = props;
	const locale = {
		back: 'Voltar',
		close: 'Fechar',
		last: 'Pronto!',
		next: 'Próximo',
		skip: 'Pular',
	};

	const [stepIndex, setStepIndex] = useState(0);

	function callback(event) {
		if (props.callback) {
			props.callback(event);
		}

		if (event.status === STATUS.SKIPPED) {
			props.onHide(event);
		}

		if ([STATUS.FINISHED].includes(event.status) || [ACTIONS.CLOSE].includes(event.action)) {
			props.onHide(event);
			setStepIndex(0);
		} else {
			if (event.action === ACTIONS.NEXT && event.lifecycle === LIFECYCLE.COMPLETE) {
				setStepIndex(event.index + 1);
			}

			if (event.action === ACTIONS.PREV && event.lifecycle === LIFECYCLE.COMPLETE) {
				setStepIndex(event.index - 1);
			}
		}
	}

	function getSteps() {
		let result = steps.map((step) => {
			const res = { ...step };

			if (res.placement === undefined) {
				res.placement = 'auto';
			}

			if (res.disableBeacon === undefined) {
				res.disableBeacon = true;
			}
			return res;
		});

		return result.filter((item) => {
			let target = item.target.replace('.', '');
			target = target.replace('#', '');

			if (
				target === 'body' ||
				document.getElementById(target) != null ||
				document.getElementsByClassName(target).length > 0
			) {
				return item;
			}
			return null;
		});
	}

	return (
		<Joyride
			disableCloseOnEsc
			disableOverlayClose
			{...props}
			locale={locale}
			scrollOffset={50}
			callback={callback}
			run={visible}
			stepIndex={stepIndex}
			steps={getSteps()}
			styles={{
				options: {
					primaryColor: colors.principal,
					zIndex: 10000,
				},
				overlay: {
					zIndex: 10000,
				},
			}}
		/>
	);
}

Tutorial.propTypes = {
	visible: propTypes.bool,
	steps: propTypes.array,
	onHide: propTypes.func,
	showSkipButton: propTypes.bool,
	continuous: propTypes.bool,
	disableScrolling: propTypes.bool,
};
