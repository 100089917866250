import { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Menu } from 'primereact/menu';
import { confirm } from '../../../../components';

import { buscarDadosLoginLocalStorage, fazerLogout } from 'Common/Autenticacao';

import packageInfo from '../../../../../package.json';
import { connect } from 'react-redux';
import { colors } from 'Common';

function AppTopbar(props) {
	const { history, className, id, onToggleMenu, isMobile } = props;

	const [menuMinhaConta, setMenuMinhaConta] = useState([]);

	const localStorage = buscarDadosLoginLocalStorage();

	const organizacao = localStorage.organizacao;
	const autoridadeUsuario = localStorage.autoridade;
	const isHelpDesk = autoridadeUsuario === 'HELPDESK';
	const isAdmin = autoridadeUsuario === 'ADMIN';
	const showOrganizacaoDaContabilidade = organizacao?.id !== localStorage.organizacaoOriginal?.id;

	function montarMenuMinhaContaItens() {
		let menuMinhaContaItens = [
			{ label: 'Minha conta', command: () => alterarRota('/minhaconta') },
			{ label: 'Planos e preços', command: () => alterarRota('/planos') },
			{
				label: 'Politica de privacidade',
				command: () => politicaDePrivacidade(),
			},
			{ label: 'Sair do sistema', command: logoutClick },
			{ separator: true },
			{
				label: `Versão ${packageInfo.version}`,
				command: () => alterarRota('/notas_de_versao'),
				style: { opacity: '0.7' },
			},
		];

		if (isHelpDesk || isAdmin) {
			menuMinhaContaItens.unshift({
				label: 'Painel helpdesk',
				command: () => alterarRota('/painel_helpdesk'),
			});
		}

		return menuMinhaContaItens;
	}

	function logoutClick() {
		confirm(
			'Confirmação',
			'Deseja mesmo sair?',
			() => fazerLogout(),
			() => {},
			'Sair'
		);
	}

	function alterarRota(rota) {
		history.push(rota);
	}

	function politicaDePrivacidade() {
		window.open('https://msyssolutions.inf.br/politica-de-privacidade', '_blank');
	}

	return (
		<>
			<Menu model={montarMenuMinhaContaItens()} popup ref={(el) => setMenuMinhaConta(el)} />
			<div
				style={{ backgroundColor: isHelpDesk ? colors.verde : '#2e2e2e' }}
				className={`layout-topbar clearfix ${className}`}
				id={id}
			>
				<span className="layout-menu-button" onClick={onToggleMenu} style={{ width: '100px' }}>
					<span className="fa fa-bars" />
				</span>

				<span className="layout-menu-container">
					<span style={{ width: '100%' }}>
						<span style={{ display: 'flex', justifyContent: 'center' }}>
							{!isMobile && (
								<h3
									style={{ marginTop: '5px', marginRight: '20px' }}
									title={isHelpDesk ? `${organizacao?.nome} (${organizacao?.schema})` : null}
								>
									{isHelpDesk || showOrganizacaoDaContabilidade ? `${organizacao?.nome}` : 'Homologação'}
								</h3>
							)}
						</span>
					</span>

					<span
						style={{
							width: '100px',
							display: 'flex',
							justifyContent: 'flex-end',
						}}
					>
						<span className="layout-menu-container-icons" title="Minha conta">
							<span onClick={(event) => menuMinhaConta.toggle(event)} className="layout-topbar-icon fa fa-user"></span>
						</span>
					</span>
				</span>
			</div>
		</>
	);
}

AppTopbar.defaultProps = {
	onToggleMenu: null,
};

AppTopbar.propTypes = {
	onToggleMenu: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
});

export default withRouter(connect(mapStateToProps)(AppTopbar));
