import { colors } from 'Common';
import { tipoCampos } from 'components/PesquisaAvancada/Util/constantes';

export const situacaoOptions = [
	{ label: 'Ativo', value: 'ATIVO' },
	{ label: 'Inativo', value: 'INATIVO' },
];

export const optionsFiltroAvancadoModelo = [
	{ label: 'Nome', name: 'nome', type: tipoCampos.STRING },
	{
		label: 'Situação',
		name: 'situacao',
		type: tipoCampos.SELECT,
		optionSelect: situacaoOptions,
	},
];

export const Colors = {
	bgAtivo: colors.verdeLight,
	textAtivo: colors.verde,
	bgInativo: colors.vermelhoLight,
	textInativo: colors.vermelho,
};

export const styleBackground = {
	borderRadius: '20px',
	padding: '0.2rem 1.1rem',
};

export const styleButtonOptions = {
	borderRadius: '50%',
	padding: '5px',
	width: '30px',
	height: '30px',
	margin: '2px',
};
