import { colors } from 'Common';
import { tipoCampos } from '../../../../components/PesquisaAvancada/Util/constantes';

export const optionsFiltroAvancadoBoleto = [
	{ label: 'Descrição', name: 'descricao', type: tipoCampos.STRING },
	{ label: 'Emissão', name: 'emissao', type: tipoCampos.DATE },
	{ label: 'Vencimento', name: 'vencimento', type: tipoCampos.DATE },
	{ label: 'Nome pessoa', name: 'pessoa.nome', type: tipoCampos.STRING },
	{
		label: 'Situação',
		name: 'boleto.situacao',
		type: tipoCampos.SELECT,
		optionSelect: [
			{ label: 'Pendente', value: 'PENDENTE' },
			{ label: 'Aguardando', value: 'SALVO' },
			{ label: 'Falha', value: 'FALHA' },
			{ label: 'Emitido', value: 'EMITIDO' },
			{ label: 'Rejeitado', value: 'REJEITADO' },
			{ label: 'Registrado', value: 'REGISTRADO' },
			{ label: 'Liquidado', value: 'LIQUIDADO' },
			{ label: 'Baixado', value: 'BAIXADO' },
		],
	},
	{
		label: 'Situação conta a receber',
		name: 'status',
		type: tipoCampos.SELECT,
		optionSelect: [
			{ label: 'Recebido', value: 'RECEBIDA' },
			{ label: 'Não recebido', value: 'NAO_RECEBIDA' },
		],
	},
];

export const Situacao = {
	PENDENTE: {
		value: 'PENDENTE',
		label: 'Pendente',
	},
	SALVO: {
		value: 'SALVO',
		label: 'Aguardando',
	},
	FALHA: {
		value: 'FALHA',
		label: 'Falha',
	},
	EMITIDO: {
		value: 'EMITIDO',
		label: 'Emitido',
	},
	REJEITADO: {
		value: 'REJEITADO',
		label: 'Rejeitado',
	},
	REGISTRADO: {
		value: 'REGISTRADO',
		label: 'Registrado',
	},
	LIQUIDADO: {
		value: 'LIQUIDADO',
		label: 'Liquidado',
	},
	BAIXADO: {
		value: 'BAIXADO',
		label: 'Baixado',
	},
	PENDENTE_RETENTATIVA: {
		value: 'PENDENTE_RETENTATIVA',
		label: 'Aguardando',
	},
};

export const ColorsSituacao = {
	bgPendente: colors.azulLight,
	textPendente: colors.azul,
	bgSalvo: colors.amareloLight,
	textSalvo: colors.amarelo,
	bgFalha: colors.vermelhoLight,
	textFalha: colors.vermelho,
	bgEmitido: '#FBD6BC',
	textEmitido: '#D35D08',
	bgRejeitado: colors.vermelhoLight,
	textRejeitado: colors.vermelho,
	bgRegistrado: '#FBD6BC',
	textRegistrado: '#D35D08',
	bgLiquidado: colors.verdeLight,
	textLiquidado: colors.verde,
	bgBaixado: '#F3C2E4',
	textBaixado: '#9E0870',
	bgRetentativa: colors.amareloLight,
	textRetentativa: colors.amarelo,
};
