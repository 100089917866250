import { format, parseISO } from 'date-fns';
import { formatarMonetario } from '../../../../../../Common/Mascara';
import { montarLabelPessoa } from '../../../../../../components/select/SingleSelectPessoa/Util';

export function converterContaReceberParaFormulario(values) {
	return {
		id: values.id,
		filialId: values.filialId,
		identificador: values.identificador,
		valor: values.valor,
		emissao: values.emissao,
		vencimento: values.vencimento,
		descricao: values.descricao,
		pessoa: converterPessoaParaFormulario(values.pessoa),
		formaPagamento: converterValueParaSelect(values.formaPagamento),
		conta: converterValueParaSelect(values.conta),
		categoria: converterValueParaSelect(values.categoria),
		tipo: values.tipo,
		repetir: values.quantidadeRepeticoes > 0 ? true : false,
		itemRepeticao: values.itemRepeticao ?? null,
		quantidadeRepeticoes: values.quantidadeRepeticoes,
		financeiro: values.financeiro ? values.financeiro : [],
		valorAReceber: values.valorAReceber,
		ocorrencia: values.ocorrencia,
		status: values.status,

		vendaId: values.vendaId,
		nfeId: values.nfeId,
		nfceId: values.nfceId,
		ordemId: values.ordemId,
		nfseId: values.nfseId,
		cteId: values.cteId,

		recebido: values.recebido ?? false,
		valorRecebido: values.valorRecebido ?? null,
		dataRecebido: values.dataRecebido ?? null,
		contaRecebido: values.contaRecebido ?? null,
		pix: values.pix ?? null,
		boleto: values.boleto ?? null,

		competencia: values.competencia,
		numeroDocumento: values.numeroDocumento,
		dataDocumento: values.dataDocumento,
		observacao: values.observacao,

		compensacaoAutomatica: values.compensarAutomaticamente,
		tipoCompensacao: values.tipoCompensacao,
		dataCompensacao: values.dataCompensacao,
		tipoTaxa: values.tipoTaxa,
		taxaCategoria: converterValueParaSelect(values.taxaCategoria),
		taxaAliquota: values.taxaAliquota,
		taxaValor: values.taxaValor,

		origem: values.origem,
		recebimentos: converterRecebimentosParaFormulario(values.recebimentos),
		reparcelamentoOrigem: values.reparcelamentoOrigem ?? null,
		reparcelamentoDestino: values.reparcelamentoDestino ?? null,
	};
}

function converterPessoaParaFormulario(pessoaApi) {
	if (pessoaApi) {
		return {
			label: montarLabelPessoa(pessoaApi),
			value: pessoaApi.id,
			registro: pessoaApi,
		};
	}
	return null;
}

export function converterValueParaSelect(value, label) {
	return value
		? {
				value: value.id ?? null,
				label: label ?? value.nome ?? value.descricao,
				registro: value,
			}
		: null;
}

function converterRecebimentosParaFormulario(recebimentosApi) {
	let recebimentosFormulario = recebimentosApi.map((recebimento) => {
		const data = recebimento.data ? parseISO(recebimento.data, 'yyyy-MM-dd', new Date()) : null;
		return {
			...recebimento,
			data: recebimento.data,
			dataLabel: data ? format(data, 'dd/MM/yyyy') : null,
			valorLabel: formatarMonetario(recebimento.valor),
			jurosLabel: formatarMonetario(recebimento.juros),
			descontosLabel: formatarMonetario(recebimento.descontos),
			multaLabel: formatarMonetario(recebimento.multa),
			totalLabel: formatarMonetario(recebimento.total),
			conta: converterContaParaFormulario(recebimento.conta),
			formaPagamento: converterValueParaSelect(recebimento.formaPagamento),
			recebimentoNovoOuAlterado: recebimento.recebimentoNovoOuAlterado || false,
		};
	});
	return recebimentosFormulario;
}

function converterContaParaFormulario(contaApi) {
	if (contaApi) {
		return {
			label: contaApi.nome,
			value: contaApi.id,
			registro: contaApi,
		};
	}
	return null;
}

function converterRecebimentosParaApi(values) {
	console.log('values:', values);
	let recebimentosAPI = [];
	if (values.recebido) {
		recebimentosAPI.push({
			valor: values.valorRecebido,
			data: values.dataRecebido,
			formaPagamento: values.formaPagamento ? { id: values.formaPagamento.value } : null,
			conta: values.contaRecebido ? { id: values.contaRecebido.value } : null,
			juros: 0,
			multa: 0,
			desconto: 0,
			recebimentoNovoOuAlterado: values.recebimentoNovoOuAlterado || false,
		});
	} else {
		recebimentosAPI = values.recebimentos.map((recebimentoFormulario) => {
			return {
				...recebimentoFormulario,
				formaPagamento: recebimentoFormulario.formaPagamento
					? { id: recebimentoFormulario.formaPagamento.value }
					: null,
				conta: recebimentoFormulario.conta ? { id: recebimentoFormulario.conta.value } : null,
				valor: recebimentoFormulario.valor ?? 0,
				juros: recebimentoFormulario.juros ?? 0,
				multa: recebimentoFormulario.multa ?? 0,
				desconto: recebimentoFormulario.desconto ?? 0,
				valorAReceber: recebimentoFormulario.valorAReceber ?? 0,
				recebimentoNovoOuAlterado: recebimentoFormulario.recebimentoNovoOuAlterado || false,
			};
		});
	}
	return recebimentosAPI;
}

export function converterContaReceberParaApi(values) {
	return {
		...values,
		formaPagamento: values.formaPagamento ? { id: values.formaPagamento.value } : null,
		conta: values.conta ? { id: values.conta.value } : null,
		categoria: values.categoria ? { id: values.categoria.value } : null,
		pessoa: values.pessoa ? { id: values.pessoa.value } : null,
		recebimentos: values.recebimentos || values.recebido ? converterRecebimentosParaApi(values) : null,
		compensarAutomaticamente: values.compensacaoAutomatica ?? false,
		dataCompensacao: values.dataCompensacao ?? null,
		taxaCategoria: values.taxaCategoria ? { id: values.taxaCategoria.value ?? values.taxaCategoria } : null,
		taxaValor: values.taxaValor ?? null,
		taxaAliquota: values.taxaAliquota ?? null,
		tipoCompensacao: values.tipoCompensacao ?? null,
		tipoTaxa: values.tipoTaxa ?? null,
	};
}
