import { colors, permissoes, recursos, usuarioPossuiPermissao } from 'Common';
import { Button, NenhumRegistroEncontrado, Paginacao } from 'components';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

const styleButton = {
	borderRadius: '50%',
	padding: '5px',
	width: '30px',
	height: '30px',
	margin: '2px',
};

function TabelaEtiquetas(props) {
	const {
		registros,
		totalRecords,
		onEdit,
		onRemove,
		onPageChange,
		page,
		rows,
		sortField,
		sortOrder,
		setSortField,
		setSortOrder,
	} = props;
	const podeExcluir = usuarioPossuiPermissao(recursos.ETIQUETAS, permissoes.EXCLUIR);

	function renderCodigo(row) {
		return <span>{row.codigo ?? '-'}</span>;
	}

	function renderDescricao(row) {
		return <span>{row.descricao}</span>;
	}

	function renderAcoes(row) {
		const titleEditar = 'Editar';
		let titleExcluir = 'Excluir';
		const disableBtnEditar = false;
		let disableBtnExcluir = false;

		if (!podeExcluir) {
			titleExcluir = 'Você não possui permissão para executar essa ação';
			disableBtnExcluir = true;
		}

		return (
			<div>
				<Button
					style={styleButton}
					className="p-button p-button-primary"
					icon="fa fa-pencil"
					title={titleEditar}
					disabled={disableBtnEditar}
					onClick={() => onEdit(row)}
				/>
				<Button
					style={styleButton}
					color="danger"
					icon="fa fa-trash"
					title={titleExcluir}
					disabled={disableBtnExcluir}
					onClick={() => onRemove(row)}
				/>
			</div>
		);
	}

	function atualizarOrdenacao(e) {
		setSortField(e.sortField);
		setSortOrder(e.sortOrder);
	}

	return (
		<>
			<DataTable
				className="table"
				value={registros}
				responsive
				sortField={sortField}
				sortOrder={sortOrder}
				onSort={atualizarOrdenacao}
				emptyMessage={<NenhumRegistroEncontrado />}
			>
				<Column
					header="Código"
					className="step-listagem-order"
					field="codigo"
					sortable
					body={renderCodigo}
					style={{ color: colors.cinzaDark, width: '100px' }}
				/>
				<Column
					header="Nome"
					field="descricao"
					sortable
					body={renderDescricao}
					style={{ color: colors.cinzaDark, textOverflow: 'ellipsis', overflow: 'hidden' }}
				/>
				<Column
					header="Ações"
					className="step-listagem-acoes"
					body={renderAcoes}
					style={{
						width: '130px',
					}}
				/>
			</DataTable>
			<Paginacao totalElements={totalRecords} rows={rows} page={page} onPageChange={onPageChange} />
		</>
	);
}

export { TabelaEtiquetas };
