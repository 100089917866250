import { colors } from 'Common';

export const CORES_CARDS = {
	SUCCESS: {
		light: colors.verdeLight,
		strongColor: colors.verde,
	},
	DANGER: {
		light: colors.vermelhoLight,
		strongColor: colors.vermelho,
	},
	WARNING: {
		light: colors.amareloLight,
		strongColor: colors.amarelo,
	},
	INFO: {
		light: colors.azulLight,
		strongColor: colors.principal,
	},
	NEUTRAL: {
		light: colors.cinzaBackground,
		strongColor: colors.cinzaDark,
	},
};

export const STYLE_BUTTONS = {
	borderRadius: '50%',
	padding: '5px',
	width: '30px',
	height: '30px',
	margin: '2px',
};

export const INITIAL_VALUES = {
	titulos: [],
	totais: { titulos: 0, juros: 0, multa: 0, desconto: 0, quitado: 0 },
	juros: 0,
	multa: 0,
	desconto: 0,
	conta: null,
	formaPagamento: null,
};
