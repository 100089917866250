import { colors } from 'Common';

export const TRANSPORTES_MODULOS = {
	MDFE: 'MDFE',
	CTE: 'CTE',
};

export const OPTIONS_SELECT = [
	{
		label: 'MDF-e',
		value: TRANSPORTES_MODULOS.MDFE,
	},
	{
		label: 'CT-e',
		value: TRANSPORTES_MODULOS.CTE,
	},
];

export const TRANSPORTES_CORES = {
	vermelho: colors.vermelho,
	vermelhoClaro: colors.vermelhoLight,
	verde: colors.verde,
	verdeClaro: colors.verdeLight,
	azul: colors.principal,
	amarelo: colors.amarelo,
	preto: colors.brancoTransparente,
	cinza: colors.cinza,
	branco: colors.cinzaLight,
};
