import { colors } from 'Common';
import { addMonths, formatISO } from 'date-fns';

export const helpMessage = {
	identificador: 'Identificador único da conta a receber',
	descricao: 'Breve descrição da conta a receber',
	categoria: 'Categoria da conta a receber (Serão listadas aqui as categorias de receita)',
	formaPagamento: 'Forma de pagamento do conta a receber',
	conta: 'Conta de destino que o valor do pagamento será creditado',
	pessoa: 'Pessoa que originou a conta a receber',
	competencia: 'Data da competencia (efetivação) da conta a receber',
	emissao: 'Data da emissão (cadastro) da conta a receber',
	vencimento: 'Data do vencimento da conta a receber',
	valor: 'Valor da conta a receber',
	numeroDocumento: 'Identificação do documento que originou esta conta a receber',
	dataDocumento: 'Data do documento que originou esta conta a receber',
	ocorrencia: 'Ocorrência ou periodicidade da conta a receber',
	quantidadeRepeticoesParcelas: 'Quantidade de parcelas',
	quantidadeRepeticoesValorFixo: 'Número de vezes que esta conta a receber irá se repetir',
	observacao: 'Observações gerais da conta a receber',
};

export const buttonStyle = {
	width: '23px',
	height: '23px',
	borderRadius: '3px',
	color: colors.principal,
	backgroundColor: 'transparent',
	borderColor: 'transparent',
	fontSize: '15px',
	boxShadow: 'none',
	margin: 'unset',
	border: 'unset',
};

export const COLORS_SITUACAO = {
	SUCCESS: {
		bg: colors.verdeLight,
		text: colors.verde,
	},
	DANGER: {
		bg: colors.vermelhoLight,
		text: colors.vermelho,
	},
	WARNING: {
		bg: colors.amareloLight,
		text: colors.amarelo,
	},
	INFO: {
		bg: colors.azulLight,
		text: colors.azul,
	},
	NEUTRAL: {
		bg: colors.cinzaBackground,
		text: colors.cinzaDark,
	},
};

export const STYLE_DESCRICAO_RECEBIMENTO = {
	margin: '0',
	width: '100%',
	textAlign: 'end',
	padding: '0.5em',
};

export const OPTIONS_PERIODICIDADE = [
	{ label: 'Dias', value: 'DIARIA' },
	{ label: 'Semanas', value: 'SEMANAL' },
	{ label: 'Meses', value: 'MENSAL' },
	{ label: 'Trimestres', value: 'TRIMESTRAL' },
	{ label: 'Semestres', value: 'SEMESTRAL' },
	{ label: 'Anos', value: 'ANUAL' },
];

export const TIPO_CONTA_RECEBER = {
	UNICA: 'UNICA',
	VALOR_FIXO: 'VALOR_FIXO',
	VALOR_PARCELADO: 'VALOR_PARCELADO',
};

export const CONDICAO_PAGAMENTO_COMPENSACAO_TIPO = {
	EMISSAO: 'EMISSAO',
	VENCIMENTO: 'VENCIMENTO',
};

export const CONDICAO_PAGAMENTO_COMPENSACAO_TIPO_OPTIONS = [
	{ label: 'Emissão', value: CONDICAO_PAGAMENTO_COMPENSACAO_TIPO.EMISSAO },
	{ label: 'Vencimento', value: CONDICAO_PAGAMENTO_COMPENSACAO_TIPO.VENCIMENTO },
];

export const CONDICAO_PAGAMENTO_TAXA_TIPO = {
	PERCENTUAL: 'PERCENTUAL',
	VALOR: 'VALOR',
};

export const CONDICAO_PAGAMENTO_TAXA_TIPO_OPTIONS = [
	{ label: 'Percentual', value: CONDICAO_PAGAMENTO_TAXA_TIPO.PERCENTUAL },
	{ label: 'Valor', value: CONDICAO_PAGAMENTO_TAXA_TIPO.VALOR },
];

export const FORMA_PAGAMENTO_FISCAL = {
	DINHEIRO: 'DINHEIRO',
	CHEQUE: 'CHEQUE',
	CARTAO_CREDITO: 'CARTAO_CREDITO',
	CARTAO_DEBITO: 'CARTAO_DEBITO',
	CREDITO_LOJA: 'CREDITO_LOJA',
	VALE_ALIMENTACAO: 'VALE_ALIMENTACAO',
	VALE_REFEICAO: 'VALE_REFEICAO',
	VALE_PRESENTE: 'VALE_PRESENTE',
	VALE_COMBUSTIVEL: 'VALE_COMBUSTIVEL',
	BOLETO_BANCARIO: 'BOLETO_BANCARIO',
	SEM_PAGAMENTO: 'SEM_PAGAMENTO',
	DEPOSITO_BANCARIO: 'DEPOSITO_BANCARIO',
	PAGAMENTO_INSTANTANEO_PIX: 'PAGAMENTO_INSTANTANEO_PIX',
	TRANSFERENCIA_BANCARIA: 'TRANSFERENCIA_BANCARIA',
	PROGRAMA_DE_FINALIDADE: 'PROGRAMA_DE_FINALIDADE',
	OUTROS: 'OUTROS',
};

export const FORMA_PAGAMENTO_FISCAL_OPTIONS = [
	{ label: 'Dinheiro', value: FORMA_PAGAMENTO_FISCAL.DINHEIRO },
	{ label: 'Cheque', value: FORMA_PAGAMENTO_FISCAL.CHEQUE },
	{ label: 'Cartão de crédito', value: FORMA_PAGAMENTO_FISCAL.CARTAO_CREDITO },
	{ label: 'Cartão de débito', value: FORMA_PAGAMENTO_FISCAL.CARTAO_DEBITO },
	{ label: 'Crédito na loja', value: FORMA_PAGAMENTO_FISCAL.CREDITO_LOJA },
	{ label: 'Vale alimentação', value: FORMA_PAGAMENTO_FISCAL.VALE_ALIMENTACAO },
	{ label: 'Vale refeição', value: FORMA_PAGAMENTO_FISCAL.VALE_REFEICAO },
	{ label: 'Vale presente', value: FORMA_PAGAMENTO_FISCAL.VALE_PRESENTE },
	{ label: 'Vale combustível', value: FORMA_PAGAMENTO_FISCAL.VALE_COMBUSTIVEL },
	{ label: 'Boleto bancário', value: FORMA_PAGAMENTO_FISCAL.BOLETO_BANCARIO },
	{ label: 'Sem Pagamento', value: FORMA_PAGAMENTO_FISCAL.SEM_PAGAMENTO },
	{ label: 'Depósito bancário', value: FORMA_PAGAMENTO_FISCAL.DEPOSITO_BANCARIO },
	{ label: 'Pix - Pagamento instantâneo', value: FORMA_PAGAMENTO_FISCAL.PAGAMENTO_INSTANTANEO_PIX },
	{ label: 'Transferência bancária, Carteira digital', value: FORMA_PAGAMENTO_FISCAL.TRANSFERENCIA_BANCARIA },
	{
		label: 'Programa de fidelidade, Cashback, Crédito virtual',
		value: FORMA_PAGAMENTO_FISCAL.PROGRAMA_DE_FINALIDADE,
	},
	{ label: 'Outros', value: FORMA_PAGAMENTO_FISCAL.OUTROS },
];

export const INITIAL_VALUES = {
	id: null,
	identificador: null,
	valor: 0,
	emissao: formatISO(new Date()),
	vencimento: formatISO(addMonths(new Date(), 1)),
	descricao: null,
	pessoa: null,
	formaPagamento: null,
	conta: null,
	categoria: null,
	tipo: 'UNICA',
	quantidadeRepeticoes: 2,
	ocorrencia: 'MENSAL',
	recebido: false,
	valorRecebido: null,
	dataRecebido: formatISO(new Date()),
	contaRecebido: null,
	pix: null,
	boleto: null,

	competencia: formatISO(new Date()),
	numeroDocumento: null,
	dataDocumento: formatISO(new Date()),
	observacao: null,

	compensacaoAutomatica: false,
	tipoCompensacao: CONDICAO_PAGAMENTO_COMPENSACAO_TIPO.VENCIMENTO,
	dataCompensacao: formatISO(addMonths(new Date(), 1)),
	tipoTaxa: CONDICAO_PAGAMENTO_TAXA_TIPO.PERCENTUAL,
	taxaCategoria: null,
	taxaAliquota: 0,
	taxaValor: 0,

	origem: 'CONTA_RECEBER',
	recebimentos: [],
	activeIndex: null,
};
