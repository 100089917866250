import { colors } from 'Common';
import { tipoCampos } from 'components';

export const TABELA_REGIAO_SITUACAO = {
	ATIVO: 'ATIVO',
	INATIVO: 'INATIVO',
};

export const OPTIONS_FILTRO_AVANCADO_REGIAO_VENDA = [
	{ label: 'Nome', name: 'nome', type: tipoCampos.STRING },
	{
		label: 'Situação',
		name: 'situacao',
		type: tipoCampos.SELECT,
		optionSelect: [
			{ label: 'Ativa', value: TABELA_REGIAO_SITUACAO.ATIVO },
			{ label: 'Inativa', value: TABELA_REGIAO_SITUACAO.INATIVO },
		],
	},
];

export const TABELA_REGIAO_VIEW_SITUACAO = {
	[TABELA_REGIAO_SITUACAO.ATIVO]: {
		name: 'Ativa',
		description: 'Região ativa',
		lightColor: colors.azulLight,
		strongColor: colors.principal,
	},
	[TABELA_REGIAO_SITUACAO.INATIVO]: {
		name: 'Inativa',
		description: 'Região inativa',
		lightColor: colors.vermelhoLight,
		strongColor: colors.vermelho,
	},
};
