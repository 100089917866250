import { useEffect, useRef } from 'react';
import { useFormikContext } from 'formik';

import { ButtonAdicionarItem, Col, Grid, NenhumRegistroEncontrado } from 'components';
import { colors, copiarObjeto, gerarUUID } from 'Common';

import { useContextVendedores } from 'views/cadastros/vendas/Vendedores/Context';

import { RegioesTabelaPrecos } from '../../../RegioesTabelaPrecos';

const buttonAdicionarStyle = {
	background: 'none',
	border: 'none',
	fontWeight: 'bold',
	boxShadow: 'none',
};

const color = {
	lightColor: colors.azulLight,
	strongColor: colors.azul,
};

function TabRegioes() {
	const { values, setFieldValue } = useFormikContext();
	const { informacoesPermissoes } = useContextVendedores();

	const optionsRemoveRegiao = useRef([]);

	useEffect(() => {
		optionsRemoveRegiao.current = values.regioesTabelaPrecos?.map((option) => ({
			...option.regiao,
			idRegistro: option.regiao?.value,
		}));
	}, [values.regioesTabelaPrecos?.length]);

	function handleClickAdicionarProduto() {
		setFieldValue('regioesTabelaPrecos', [
			...values.regioesTabelaPrecos,
			{ id: null, idTemporario: gerarUUID(), regiao: null, tabelaPreco: null },
		]);
	}

	function deleteItem(index) {
		const arrayTemp = copiarObjeto(values.regioesTabelaPrecos);
		arrayTemp?.splice(index, 1);

		setFieldValue('regioesTabelaPrecos', arrayTemp);
	}

	return (
		<Grid style={{ padding: '8px' }} className="step-vendedor-regioes">
			{values.regioesTabelaPrecos?.length > 0 ? (
				values.regioesTabelaPrecos.map((item, index) => (
					<RegioesTabelaPrecos
						index={index}
						key={item.id ?? item.idTemporario}
						excluirItem={() => deleteItem(index)}
						optionsRemoveRegiao={optionsRemoveRegiao}
					/>
				))
			) : (
				<NenhumRegistroEncontrado />
			)}
			<Grid>
				<Col sm="12" md="12" lg="12" xl="12" style={{ paddingTop: '0', paddingBottom: '0' }}>
					<ButtonAdicionarItem
						label="Adicionar Região"
						style={{
							...buttonAdicionarStyle,
							color: color.strongColor,
							padding: '1rem 0.5rem',
						}}
						onClick={handleClickAdicionarProduto}
						{...informacoesPermissoes}
					/>
				</Col>
			</Grid>
		</Grid>
	);
}
export { TabRegioes };
