import { colors } from 'Common';

export const COLORS = {
	DARK_RED: colors.vermelho,
	LIGHT_RED: colors.vermelhoHoverTransparente,
	DARK_BLUE: colors.principal,
	LIGHT_BLUE: colors.principalHoverTransparente,
};

export const TIPO_CONSULTA = {
	PRODUTO: 'PRODUTO',
	NOTA_ENTRADA: 'NOTA_ENTRADA',
};

export const OPTIONS_TIPO_CONSULTA = [
	{
		label: 'Produtos',
		value: TIPO_CONSULTA.PRODUTO,
	},
	{
		label: 'Nota entrada',
		value: TIPO_CONSULTA.NOTA_ENTRADA,
	},
];

export const OPTIONS_CONTORNO = [
	{ label: 'Sim', value: true },
	{ label: 'Não', value: false },
];

export const TIPO_PAPEL = {
	BOBINA: 'BOBINA',
	FOLHA: 'FOLHA',
};
