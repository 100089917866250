import { Menu } from 'primereact/menu';
import { useRef } from 'react';
import { FaDownload, FaPrint, FaShareAlt, FaSyncAlt } from 'react-icons/fa';
import { baixarArquivo, colors } from '../../../../../../../Common';
import { imprimirDANFE, imprimirXMLNfe } from '../../../Requests';

const styleBotaoImprimir = {
	padding: '0px 10px',
	color: colors.cinzaDark,
	fontWeight: 'bold',
	fontSize: '15px',
	display: 'flex',
	alignItems: 'center',
	border: 'none',
};

export default function HeaderForm(props) {
	const {
		id,
		chave,
		numero,
		situacao,
		asyncSelectRegistro,
		setExibirLoadingImprimir,
		setModalEnviarPorEmailVisible,
		setModalEnviarPorWhatsappVisible,
		isMobile,
	} = props;
	const menuCompartilhar = useRef(null);

	function baixarXMLNotaFiscal() {
		imprimirXMLNfe(id, ({ data: file }) => {
			baixarArquivo(file, chave, 'application/xml');
		});
	}

	async function imprimirNotaFiscal() {
		setExibirLoadingImprimir(true);

		await imprimirDANFE(id, async ({ data: file }) => {
			let arquivo = new Blob([file], { type: 'application/pdf' });
			let arquivoURL = URL.createObjectURL(arquivo);
			let danfe = window.open(arquivoURL);
			danfe.onload = function () {
				setTimeout(() => {
					danfe.document.title = `NF-e Nº ${numero}.pdf`;
				}, 250);
			};

			setExibirLoadingImprimir(false);
		});
	}

	if (id) {
		let title = <span>Nota fiscal (NF-e)</span>;
		const botoesDisabled = situacao !== 'TRANSMITIDA' && situacao !== 'CANCELADA' && situacao !== 'CORRIGIDA';

		const menuCompartilharModel = [
			{
				label: 'Enviar por e-mail',
				icon: 'fa fa-send',
				command: () => setModalEnviarPorEmailVisible(true),
			},
			{
				label: 'Enviar por Whatsapp',
				icon: 'fa fa-whatsapp',
				command: () => setModalEnviarPorWhatsappVisible(true),
			},
		];

		if (situacao === 'AGUARDANDO_AUTORIZACAO') {
			return (
				<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
					<span>{isMobile ? 'NF-e' : 'Nota fiscal (NF-e)'}</span>
					<span
						title="Atualizar informações da nota"
						style={{ cursor: 'pointer' }}
						onClick={() => asyncSelectRegistro(id)}
					>
						<span style={{ ...styleBotaoImprimir }}>
							<FaSyncAlt style={!isMobile && { marginRight: '5px' }} />
							{!isMobile && <span>Consultar NF-e</span>}
						</span>
					</span>
				</div>
			);
		} else if (botoesDisabled) {
			return (
				<span
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					{title}
					<span
						style={{
							display: 'flex',
							alignItems: 'center',
							fontSize: '16px',
							cursor: 'default',
						}}
					>
						<span style={{ ...styleBotaoImprimir, opacity: '0.5' }}>
							<FaShareAlt size="15px" style={!isMobile && { marginRight: '5px' }} />
							{!isMobile && <span>Compartilhar</span>}
						</span>
						<span style={{ ...styleBotaoImprimir, opacity: '0.5' }} title="Transmita a nota fiscal para baixar o xml">
							<FaDownload size="15px" style={!isMobile && { marginRight: '5px' }} />
							{!isMobile && <span>Baixar XML</span>}
						</span>
						<span style={{ ...styleBotaoImprimir, opacity: '0.5' }} title="Transmita a nota fiscal para imprimir">
							<FaPrint size="15px" style={!isMobile && { marginRight: '5px' }} />
							{!isMobile && <span>Imprimir DANF-e</span>}
						</span>
					</span>
				</span>
			);
		} else {
			return (
				<span
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					{title}
					<span
						style={{
							display: 'flex',
							alignItems: 'center',
							fontSize: '16px',
							cursor: 'pointer',
						}}
					>
						<span
							style={{ ...styleBotaoImprimir, cursor: 'pointer' }}
							onClick={(event) => menuCompartilhar.current.toggle(event)}
						>
							<Menu
								style={{ width: '220px' }}
								model={menuCompartilharModel}
								popup
								ref={menuCompartilhar}
								id="menu_compartilhar"
							/>
							<FaShareAlt size="15px" style={!isMobile && { marginRight: '5px' }} />
							{!isMobile && <span>Compartilhar</span>}
						</span>
						<a
							style={{ ...styleBotaoImprimir }}
							target="_blank"
							rel="noopener noreferrer"
							onClick={baixarXMLNotaFiscal}
						>
							<FaDownload size="15px" style={!isMobile && { marginRight: '5px' }} />
							{!isMobile && <span>Baixar XML</span>}
						</a>

						<a style={{ ...styleBotaoImprimir }} target="_blank" rel="noopener noreferrer" onClick={imprimirNotaFiscal}>
							<FaPrint size="15px" style={!isMobile && { marginRight: '5px' }} />
							{!isMobile && <span>Imprimir DANF-e</span>}
						</a>
					</span>
				</span>
			);
		}
	} else {
		return <>Nova nota fiscal (NF-e)</>;
	}
}
