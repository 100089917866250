import { CardQuantidadeListagem, Col, Grid } from 'components';
import { ColorsCard, statusGestaoDocumento } from '../../Util/constantes';

import ampulheta from '../../../Images/ampulheta.svg';
import assinatura_efetuada from '../../../Images/assinatura_efetuada.svg';
import assinatura_rejeitada from '../../../Images/assinatura_rejeitada.svg';
import documento_expirado from '../../../Images/documento_expirado.svg';

function CardsStatusGestaoDocumento(props) {
	const { cards, selectedCard, setPage, setSelectedCard, setValorCard, isMobile } = props;

	function handleSelectCard(statusDocumento) {
		const card = statusDocumento;
		const filtroRSQL = `status==${statusDocumento}`;
		pesquisarRSQLCardTotais(card, filtroRSQL);
	}

	function pesquisarRSQLCardTotais(cardSelectedLocal, valueCard) {
		if (cardSelectedLocal === selectedCard) {
			setSelectedCard('');
			setValorCard('');
			setPage(0);
		} else {
			setSelectedCard(cardSelectedLocal);
			setValorCard(valueCard);
			setPage(0);
		}
	}

	return (
		<Grid justifyBetween>
			<Col xs="12" sm="6" md="6" lg="3" xl="3">
				<CardQuantidadeListagem
					name="cardPendente"
					title="Pendente"
					helpMessage={!isMobile ? 'Exibe a quantidade de documentos que estão pendentes' : ''}
					titleFiltro="Clique para filtrar pelos documentos pendentes"
					cores={ColorsCard.PENDENTE}
					value={cards.pendente}
					icon={ampulheta}
					selected={selectedCard === statusGestaoDocumento.PENDENTE}
					onSelect={() => handleSelectCard(statusGestaoDocumento.PENDENTE)}
				/>
			</Col>
			<Col xs="12" sm="6" md="6" lg="3" xl="3">
				<CardQuantidadeListagem
					name="cardVigente"
					title="Vigente"
					helpMessage={!isMobile ? 'Exibe a quantidade de documentos vigentes no período' : ''}
					titleFiltro="Clique para filtrar pelos documentos vigentes"
					cores={ColorsCard.VIGENTE}
					value={cards.vigente}
					icon={assinatura_efetuada}
					selected={selectedCard === statusGestaoDocumento.VIGENTE}
					onSelect={() => handleSelectCard(statusGestaoDocumento.VIGENTE)}
				/>
			</Col>
			<Col xs="12" sm="6" md="6" lg="3" xl="3">
				<CardQuantidadeListagem
					name="cardRescindidos"
					title="Rescindidos"
					helpMessage={!isMobile ? 'Exibe a quantidade de documentos rescindidos' : ''}
					titleFiltro="Clique para filtrar pelos documentos rescindidos"
					cores={ColorsCard.RESCINDIDO}
					value={cards.rescindido}
					icon={assinatura_rejeitada}
					selected={selectedCard === statusGestaoDocumento.RESCINDIDO}
					onSelect={() => handleSelectCard(statusGestaoDocumento.RESCINDIDO)}
				/>
			</Col>
			<Col xs="12" sm="6" md="6" lg="3" xl="3">
				<CardQuantidadeListagem
					name="cardVigenciaExpirada"
					title="Vigência expirada"
					helpMessage={!isMobile ? 'Exibe a quantidade de documentos com vigência expirada' : ''}
					titleFiltro="Clique para filtrar pelos documentos com vigência expirada"
					cores={ColorsCard.VIGENCIA_EXPIRADA}
					value={cards.vigenciaExpirada}
					icon={documento_expirado}
					selected={selectedCard === statusGestaoDocumento.VIGENCIA_EXPIRADA}
					onSelect={() => handleSelectCard(statusGestaoDocumento.VIGENCIA_EXPIRADA)}
				/>
			</Col>
		</Grid>
	);
}

export { CardsStatusGestaoDocumento };
