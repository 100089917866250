import { CardQuantidadeListagem, Col, Grid } from 'components';
import { ColorsCard, statusDocumento } from '../../Util/constantes';

import ampulheta from '../../../Images/ampulheta.svg';
import assinatura_efetuada from '../../../Images/assinatura_efetuada.svg';
import assinatura_rejeitada from '../../../Images/assinatura_rejeitada.svg';
import documento_expirado from '../../../Images/documento_expirado.svg';

function CardStatusAssinaturaEletronica(props) {
	const { cards, cardSelected, setCardSelected, setValorCard, setPage, isMobile } = props;

	function handleSelectCard(statusSelecionado) {
		const card = statusSelecionado;
		const filtroRSQL = `status==${statusSelecionado}`;
		pesquisarRSQLCardTotais(card, filtroRSQL);
	}

	function pesquisarRSQLCardTotais(cardSelectedProps, valueCard) {
		if (cardSelectedProps === cardSelected) {
			setCardSelected(null);
			setValorCard('');
			setPage(0);
		} else {
			setCardSelected(cardSelectedProps);
			setValorCard(valueCard);
			setPage(0);
		}
	}

	return (
		<Grid justifyBetween>
			<Col xs="12" sm="6" md="6" lg="3" xl="3">
				<CardQuantidadeListagem
					name="cardAguardandoAssinatura"
					title="Aguardando assinatura"
					helpMessage={!isMobile ? 'Exibe a quantidade de documentos que estão aguardando assinatura no período' : ''}
					titleFiltro="Clique para filtrar pelos documentos aguardando assinatura"
					cores={ColorsCard.AGUARDANDO_ASSINATURAS}
					value={cards?.aguardandoAssinaturas}
					icon={ampulheta}
					selected={cardSelected === statusDocumento.AGUARDANDO_ASSINATURAS}
					onSelect={() => handleSelectCard(statusDocumento.AGUARDANDO_ASSINATURAS)}
				/>
			</Col>
			<Col xs="12" sm="6" md="6" lg="3" xl="3">
				<CardQuantidadeListagem
					name="cardAssinados"
					title="Assinados"
					helpMessage={!isMobile ? 'Exibe a quantidade de documentos assinados no período' : ''}
					titleFiltro="Clique para filtrar pelos documentos assinados"
					cores={ColorsCard.ASSINADO}
					value={cards?.assinados}
					icon={assinatura_efetuada}
					selected={cardSelected === statusDocumento.ASSINADO}
					onSelect={() => handleSelectCard(statusDocumento.ASSINADO)}
				/>
			</Col>
			<Col xs="12" sm="6" md="6" lg="3" xl="3">
				<CardQuantidadeListagem
					name="cardRejeitados"
					title="Rejeitados"
					helpMessage={!isMobile ? 'Exibe a quantidade de documentos rejeitados no período' : ''}
					titleFiltro="Clique para filtrar pelos documentos rejeitados"
					cores={ColorsCard.REJEITADO}
					value={cards?.rejeitados}
					icon={assinatura_rejeitada}
					selected={cardSelected === statusDocumento.REJEITADO}
					onSelect={() => handleSelectCard(statusDocumento.REJEITADO)}
				/>
			</Col>
			<Col xs="12" sm="6" md="6" lg="3" xl="3">
				<CardQuantidadeListagem
					name="cardExpirados"
					title="Expirados"
					helpMessage={!isMobile ? 'Exibe a quantidade de documentos expirados no período' : ''}
					titleFiltro="Clique para filtrar pelos documentos expirados"
					cores={ColorsCard.EXPIRADO}
					value={cards?.expirados}
					icon={documento_expirado}
					selected={cardSelected === statusDocumento.EXPIRADO}
					onSelect={() => handleSelectCard(statusDocumento.EXPIRADO)}
				/>
			</Col>
		</Grid>
	);
}

export { CardStatusAssinaturaEletronica };
