import logo from "../../../../layout/Images/logo_msys.svg";
import { withRouter } from "react-router";
import { buscarDadosLoginLocalStorage } from "Common";
import {
  SelectPesquisaTela,
  SelectOrganizacaoFilial,
  Divider,
} from "components";

function AppInlineProfile(props) {
  const { history } = props;
  const dadosLocalStorage = buscarDadosLoginLocalStorage();

  function getUserName() {
    return dadosLocalStorage?.nome
      ? dadosLocalStorage?.nome
      : dadosLocalStorage?.email;
  }

  return (
    <>
      <div
        className="profile"
        style={{ paddingBottom: "0px", paddingTop: "10px" }}
      >
        <img
          src={logo}
          alt="Logo microsys sistemas"
          style={{
            width: "230px",
            marginTop: "10px",
            paddingBottom: "12px",
            cursor: "pointer",
          }}
          onClick={() =>
            history.location.pathname !== "/" ? history.push("/") : null
          }
        />

        <span
          className="username"
          style={{
            overflow: "hidden",
            display: "block",
            textOverflow: "ellipsis",
          }}
          title={
            dadosLocalStorage?.nome
              ? dadosLocalStorage?.nome
              : dadosLocalStorage?.email
          }
        >
          {getUserName()}
        </span>
        <div>
          <SelectOrganizacaoFilial />
          <Divider
            styleLine={
              dadosLocalStorage?.organizacao?.aplicacao === "MSYS_GESTOR"
                ? { opacity: "1", color: "rgb(177, 177, 177)" }
                : { opacity: "1", color: "#d3d9df" }
            }
            styleContainer={{ width: "100%", margin: "0.5rem 0rem 0rem" }}
          />
          <SelectPesquisaTela
            style={
              dadosLocalStorage?.organizacao?.aplicacao === "MSYS_GESTOR"
                ? {
                    paddingTop: "0.5rem",
                    paddingBottom: "0.5rem",
                    width: "100%",
                  }
                : { marginTop: "10px", marginBottom: "10px", width: "100%" }
            }
          />
        </div>
      </div>
    </>
  );
}

export default withRouter(AppInlineProfile);
