import { colors } from 'Common';
import { colorsStatus } from 'components/body/Util/constantes';

const secaoStyle = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	margin: '0',
};

const numeroStyle = {
	fontSize: '24px',
	color: colors.principal,
	fontWeight: 'bold',
};

export default function VendaNumeroCard({ status, numero, isMobile }) {
	const color = status ? colorsStatus[status].strongColor : null;

	return (
		<>
			<div
				style={{
					display: 'flex',
					justifyContent: isMobile ? 'center' : 'flex-start',
				}}
			>
				<div style={secaoStyle} title={!numero ? 'O número é gerado automaticamente pelo sistema' : null}>
					<div>Número</div>
					<div style={{ ...numeroStyle, color: color }}>{numero || '-'}</div>
				</div>
			</div>
		</>
	);
}
