import { colors } from 'Common';
import React from 'react';

export default function NoInternetBar() {
	return (
		<>
			<div style={{ width: '100%', height: '20px', background: colors.vermelho, position: 'fixed', zIndex: 1000 }}>
				<p style={{ margin: '0', color: colors.branco, fontWeight: 'bold', fontSize: '13px', textAlign: 'center' }}>
					Parece que você está sem internet :(
				</p>
			</div>
			<div style={{ width: '100%', height: '20px' }} />
		</>
	);
}
