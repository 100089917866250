import { Tooltip } from 'primereact/tooltip';

import { getEstoqueAtualProduto } from 'components/Produtos/Requests';
import { useState } from 'react';
import { colors } from 'Common';

function TooltipQuantidade({ produto, disabled, quantidadeSaida, indexProduto }) {
	const [quantidadeEstoque, setQuantidadeEstoque] = useState('');

	async function getEstoqueAtual() {
		await getEstoqueAtualProduto(produto.produto?.value, ({ data }) => {
			setQuantidadeEstoque(data ?? 0);
		});
	}

	return (
		<Tooltip
			target={`.id-quantidade-produto-listagem-${indexProduto}`}
			position="bottom"
			showDelay={500}
			onShow={() => getEstoqueAtual()}
			disabled={disabled || !produto.produto}
		>
			<span>
				Estoque atual:{' '}
				<b style={{ color: quantidadeSaida > quantidadeEstoque ? colors.vermelho : 'white' }}>{quantidadeEstoque}</b>
			</span>
		</Tooltip>
	);
}

export { TooltipQuantidade };
