import { colors } from 'Common';
import { Button, Col } from 'components';
import { InputText } from 'primereact/inputtext';

function InputFieldButtonImpl({
	id,
	value,
	min,
	placeholder,
	type,
	onFocus,
	onKeyDown,
	onChange,
	onBlur,
	onClickSearch,
	onClickClearInput,
	disabled,
	title,
}) {
	return (
		<Col>
			<div className="p-inputgroup step-etiqueta-impressao-input-search">
				<InputText
					style={{ height: '33px', width: '100%', marginRight: '-40px' }}
					id={id}
					type={type}
					value={value}
					onChange={onChange}
					onFocus={onFocus}
					onBlur={onBlur}
					onKeyDown={onKeyDown}
					autoComplete="off"
					min={min}
					placeholder={placeholder}
					disabled={disabled}
					title={title}
				/>
				<Button
					type="button"
					className="p-button-secondary"
					style={{
						height: '100%',
						padding: '0 0 2px 2px',
						backgroundColor: colors.brancoTransparente,
						border: 'none',
						visibility: !value ? 'hidden' : 'inherit',
						zIndex: 1,
						':hover': {
							border: '2px solid red !important',
						},
					}}
					icon="fa fa-times"
					onClick={onClickClearInput}
					disabled={disabled}
					title={title}
				/>
				<Button
					type="button"
					icon="fa fa-search"
					onClick={onClickSearch}
					style={{
						padding: '0.29em',
						boxShadow: 'none',
						borderRadius: '0 3px 3px 0',
						height: '33px',
						zIndex: 1,
					}}
					disabled={disabled}
					title={title}
				/>
			</div>
		</Col>
	);
}

export const InputFieldButton = InputFieldButtonImpl;
