import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { ScrollPanel } from 'primereact/scrollpanel';
import { BreadCrumb } from 'primereact/breadcrumb';
import { FaCogs } from 'react-icons/fa';
import '../../../node_modules/alertifyjs/build/css/alertify.min.css';
import '../../../node_modules/alertifyjs/build/css/themes/default.min.css';

import classNames from 'classnames';
import AppTopbar from './MenuLateral/AppTopbar';
import { AppMenu } from './MenuLateral/AppMenu';
import AppInlineProfile from './MenuLateral/AppInlineProfile';
import { buscarMenu } from './MenuLateral/itens';

import ModalConfiguracoes from '../fiscal/vendas/NFCe/Form/components/ModalConfiguracoes';
import { criarItensBreadcrumb } from './Functions';

//mexeu na ordem dos import, parou de funcionar
import { buscarDadosLoginLocalStorage } from '../../Common/Autenticacao';
import useStateCallback from '../../Common/Hooks/useStateCallback';

import { If, NormalButton, AutoProgressBar } from '../../components';

import CadastrosRoutes, { pathCadastrosRoutes } from './Routes/CadastrosRoutes';
import ComprasRoutes, { pathComprasRoutes } from './Routes/ComprasRoutes';
import DefaultRoutes, { pathDefaultRoutes } from './Routes/DefaultRoutes';
import EstoqueRoutes, { pathEstoqueRoutes } from './Routes/EstoqueRoutes';
import VendasRoutes, { pathVendasRoutes } from './Routes/VendasRoutes';
import FinancasRoutes, { pathFinancasRoutes } from './Routes/FinancasRoutes';
import ConfiguracoesRoutes, { pathConfiguracoesRoutes } from './Routes/ConfiguracoesRoutes';
import ControladoriaRoutes, { pathControladoriaRoutes } from './Routes/ControladoriaRoutes';
import DocumentosRoutes, { pathDocumentosRoutes } from './Routes/DocumentosRoutes';
import AdministracaoRoutes, { pathAdministracaoRoutes } from './Routes/AdministracaoRoutes';
import ServicosRoutes, { pathServicosRoutes } from './Routes/ServicosRoutes';
import { carregarOpaSuite } from 'Common/suporte/OpaSuite';
import TransportesRoutes, { pathTransporteRoutes } from './Routes/TransportesRoutes';
import { ModalAniversariantes } from 'views/cadastros/Pessoas/components/ModalAniversariantes';
import { ToastNotificacoes } from './components/ToastNotificacoes';
import { colors } from 'Common';

function InitialPage(props) {
	const {
		dataAtualizacaoMenuLateral,
		possuiSuporteViaChat,
		history,
		isMobile,
		isTablet,
		isFullScreen,
		possuiInternet,
	} = props;

	const [layoutMode, setLayoutMode] = useState('static');
	const [layoutColorMode, setLayoutColorMode] = useState('light');
	const [staticMenuInactive, setStaticMenuInactive] = useState(false);

	const [overlayMenuActive, setOverlayMenuActive] = useState(false);
	const [mobileMenuActive, setMobileMenuActive] = useState(false);
	const [exibirModalConfiguracoesLocais, setExibirModalConfiguracoesLocais] = useState(false);
	const [location, setLocation] = useStateCallback('');
	const [items, setItems] = useState([]);
	const [menu, setMenu] = useState(undefined);
	const [menuClick, setMenuClick] = useState(null);
	const [layoutMenuScroller, setLayoutMenuScroller] = useState(null);
	const [sidebar, setSidebar] = useState(null);
	const [isNfce, setIsNfce] = useState(false);
	const [posuiSuporteChat, setPosuiSuporteChat] = useState(false);

	const afterFirstRenderRef = useRef(false);
	const prevProps = useRef(props);

	const pathnameAtual = props.location.pathname;

	const dadosLocalStorage = buscarDadosLoginLocalStorage();

	const showSelectOrganizacao = ['HELPDESK', 'ACCOUNTANT'].includes(dadosLocalStorage.autoridade);

	const sidebarClassName = classNames('layout-sidebar', {
		'layout-sidebar-dark': layoutColorMode === 'dark',
	});

	const wrapperClass = classNames('layout-wrapper', {
		'layout-overlay': layoutMode === 'overlay',
		'layout-static': layoutMode === 'static',
		'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
		'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
		'layout-mobile-sidebar-active': mobileMenuActive,
	});

	useEffect(() => {
		setLocation(window.location.hash, createMenu());
		setItems(criarItensBreadcrumb(window.location.hash, history));

		setPosuiSuporteChat(possuiSuporteChat());

		if (afterFirstRenderRef) {
			if (location !== window.location.hash) {
				setLocation(window.location.hash);
				setItems(undefined);
			}

			if (prevProps.current?.dataAtualizacaoMenuLateral !== dataAtualizacaoMenuLateral) {
				createMenu();
			}

			if (prevProps.current?.possuiSuporteViaChat !== possuiSuporteViaChat) {
				setPosuiSuporteChat(possuiSuporteViaChat);
			}
		} else afterFirstRenderRef = true;
	}, []);

	useEffect(() => {
		if (pathnameAtual.includes('/nfces/cadastro') || pathnameAtual.includes('/nfces/importar_pedido')) {
			setIsNfce(true);
			closeMenuLateral();
		} else {
			setIsNfce(false);
			openMenuLateral();
			if (isFullScreen) {
				closeFullScreen();
			}
		}
	}, [pathnameAtual]);

	useEffect(() => {
		createMenu();
	}, [dataAtualizacaoMenuLateral]);

	useEffect(() => {
		setItems(criarItensBreadcrumb(window.location.hash, history));
	}, [window.location.hash]);

	function onWrapperClick() {
		if (menuClick) {
			setMenuClick(false);
		}
	}

	function possuiSuporteChat() {
		let organizacao = buscarDadosLoginLocalStorage()?.organizacao;
		return organizacao && organizacao.plano && organizacao.plano.suporteChat;
	}

	function onToggleMenu(event) {
		setMenuClick(true);

		if (isDesktop()) {
			if (layoutMode === 'overlay') {
				setOverlayMenuActive(!overlayMenuActive);
			} else if (layoutMode === 'static') {
				setStaticMenuInactive(!staticMenuInactive);
			}
		} else {
			setMobileMenuActive(!mobileMenuActive);
		}
		event?.preventDefault();
	}

	function closeMenuLateral() {
		if (!staticMenuInactive) {
			setStaticMenuInactive(true);
		}
	}

	function openMenuLateral() {
		if (staticMenuInactive) {
			setStaticMenuInactive(false);
		}
	}

	function onSidebarClick(event) {
		setMenuClick(true);
		setTimeout(() => {
			if (layoutMenuScroller) {
				layoutMenuScroller.moveBar();
			}
		}, 500);
	}

	function onMenuItemClick(event) {
		if (!event.item.items) {
			setOverlayMenuActive(false);
			setMobileMenuActive(false);
		}

		if (event.item.title === 'Nota fiscal de consumidor') {
			setMenuClick(true);
			onWrapperClick();

			if (pathnameAtual === prevProps.current.location.pathname) {
				onToggleMenu();
			}
		}
	}

	function alterarRota(rota) {
		const locationAntigo = window.location.hash;
		history.push(rota);
		if (locationAntigo !== window.location.hash) setItems([]);
	}

	function createMenu() {
		setMenu(buscarMenu(alterarRota));
	}

	function isDesktop() {
		return window.innerWidth > 1024;
	}

	function getStyleLayoutMain() {
		const mostrarChat = possuiSuporteViaChat;
		const estaNoDashboard = pathnameAtual === '/';

		if (isMobile) {
			return {
				paddingTop: '55px',
				paddingRight: '0px',
				paddingBottom: mostrarChat ? '75px' : '55px',
				paddingLeft: '0px',
			};
		} else {
			return {
				paddingTop: estaNoDashboard ? '70px' : isFullScreen ? '45px' : '94px',
				paddingBottom: isFullScreen ? '0px' : mostrarChat ? '40px' : '0px',
			};
		}
	}

	function closeFullScreen() {
		document.exitFullscreen();
	}

	function requestFullScreen() {
		closeMenuLateral();

		const element = document.getElementsByClassName('layout-fullscreen')[0];
		let requestMethod =
			element.requestFullScreen ||
			element.webkitRequestFullScreen ||
			element.mozRequestFullScreen ||
			element.msRequestFullscreen;

		if (requestMethod) {
			requestMethod.call(element);
		} else if (typeof window.ActiveXObject !== 'undefined') {
			let wscript = new ActiveXObject('WScript.Shell');
			if (wscript !== null) {
				wscript.SendKeys('{F11}');
			}
		}

		return false;
	}

	function replaceUuidInPathName(pathName) {
		return String(pathName).replace(
			/[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}/,
			':id'
		);
	}

	return (
		<>
			<div className={wrapperClass} onClick={onWrapperClick}>
				<AppTopbar onToggleMenu={onToggleMenu} />
				<div
					style={{
						height: '4px',
						top: possuiInternet ? '50px' : '70px',
						zIndex: 3,
						width: '100%',
						position: 'fixed',
					}}
				>
					<AutoProgressBar />
				</div>
				<div ref={(el) => setSidebar(el)} className={sidebarClassName} onClick={onSidebarClick}>
					<AppInlineProfile />
					<ScrollPanel
						ref={(el) => setLayoutMenuScroller(el)}
						style={{
							height: showSelectOrganizacao
								? `calc(100% - ${possuiInternet ? '320px' : '340px'})`
								: `calc(100% - ${possuiInternet ? '230px' : '250px'})`,
						}}
					>
						<div
							className="layout-sidebar-scroll-content"
							style={{
								width: '250px',
								height: '100%',
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'space-between',
								paddingBottom: '0',
							}}
						>
							<div>
								<div className="logo" />
								<AppMenu model={menu} onMenuItemClick={onMenuItemClick} />
								<ToastNotificacoes isMobile={isMobile} />
							</div>
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									fontSize: '12px',
									opacity: 0.8,
								}}
							></div>
						</div>
					</ScrollPanel>
				</div>
				<div className="layout-fullscreen">
					{/* Esconde o breadcrumb quando está no dashboard */}
					{pathnameAtual !== '/' && (
						<div
							className="layout-main color-template"
							style={{
								zIndex: 2,
								display: 'flex',
								position: 'fixed',
								padding: !isFullScreen ? '50px 0px 0px 0px' : '0px',
								justifyContent: 'space-between',
								alignItems: 'center',
								width: '-moz-available',
								width: '-webkit-fill-available',
								width: '100%',
							}}
						>
							<div hidden={isMobile}>
								<BreadCrumb
									model={items}
									home={{ icon: 'pi pi-home', url: '/#' }}
									style={{
										background: colors.brancoTransparente,
										border: 'none',
										whiteSpace: 'nowrap',
										overflow: 'overlay',
										textOverflow: 'ellipsis',
									}}
								/>
							</div>
							<If test={isNfce && !isMobile && !isTablet}>
								<div>
									<span
										style={{ margin: '0px 3px 0px 0px', cursor: 'pointer' }}
										title="Configurações locais"
										onClick={() => setExibirModalConfiguracoesLocais(true)}
									>
										<FaCogs size="20px" style={!isMobile && { marginBottom: '3px', marginRight: '3px' }} />
									</span>
									<NormalButton
										className="p-button-primary"
										type="button"
										title={!isFullScreen ? 'Entrar no modo tela cheia' : 'Sair do modo tela cheia'}
										icon={!isFullScreen ? 'fa fa-expand' : 'fa fa-compress'}
										style={{
											margin: '5px 16px 0px 0px',
											padding: '5px 0px',
											boxShadow: 'none',
										}}
										onClick={() => (!isFullScreen ? requestFullScreen() : closeFullScreen())}
									/>
								</div>
								<If test={exibirModalConfiguracoesLocais}>
									<ModalConfiguracoes
										visible={exibirModalConfiguracoesLocais}
										onHide={() => setExibirModalConfiguracoesLocais(false)}
										isFullScreen={isFullScreen}
									/>
								</If>
							</If>
						</div>
					)}

					<div className="layout-main" style={getStyleLayoutMain()}>
						<If test={posuiSuporteChat}>{carregarOpaSuite()}</If>

						<If test={pathCadastrosRoutes.includes(replaceUuidInPathName(pathnameAtual))}>
							<CadastrosRoutes />
						</If>

						<If test={pathComprasRoutes.includes(replaceUuidInPathName(pathnameAtual))}>
							<ComprasRoutes />
						</If>

						<If test={pathEstoqueRoutes.includes(replaceUuidInPathName(pathnameAtual))}>
							<EstoqueRoutes />
						</If>

						<If test={pathVendasRoutes.includes(replaceUuidInPathName(pathnameAtual))}>
							<VendasRoutes />
						</If>

						<If test={pathServicosRoutes.includes(replaceUuidInPathName(pathnameAtual))}>
							<ServicosRoutes />
						</If>

						<If test={pathFinancasRoutes.includes(replaceUuidInPathName(pathnameAtual))}>
							<FinancasRoutes />
						</If>

						<If test={pathConfiguracoesRoutes.includes(replaceUuidInPathName(pathnameAtual))}>
							<ConfiguracoesRoutes />
						</If>
						<If test={pathControladoriaRoutes.includes(replaceUuidInPathName(pathnameAtual))}>
							<ControladoriaRoutes />
						</If>

						<If test={pathDocumentosRoutes.includes(replaceUuidInPathName(pathnameAtual))}>
							<DocumentosRoutes />
						</If>

						<If test={pathAdministracaoRoutes.includes(replaceUuidInPathName(pathnameAtual))}>
							<AdministracaoRoutes />
						</If>

						<If test={pathTransporteRoutes.includes(replaceUuidInPathName(pathnameAtual))}>
							<TransportesRoutes />
						</If>

						<If test={pathDefaultRoutes.includes(replaceUuidInPathName(pathnameAtual))}>
							<DefaultRoutes />
						</If>
					</div>
				</div>
				<div className="layout-mask"></div>
			</div>
		</>
	);
}

const mapStateToProps = (state) => ({
	dataAtualizacaoMenuLateral: state.menuLateral.dataAtualizacaoMenuLateral,
	dataAtualizacaoPlano: state.plano.dataAtualizacaoPlano,
	possuiSuporteViaChat: state.possuiSuporteViaChat,
	isMobile: state.dispositivo.isMobile,
	isTablet: state.dispositivo.isTablet,
	isFullScreen: state.dispositivo.isFullScreen,
	possuiInternet: state.possuiInternet,
});

export default withRouter(connect(mapStateToProps)(InitialPage));
