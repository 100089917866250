import { Field } from 'formik';

import { If, Col, Fieldset, Grid, Checkbox, Subtitle } from 'components';

import { helpProdutosForm } from '../../../Help';

export default function FieldsDocumentos(props) {
	const { setFieldValue, values, informacoesPermissoes, possuiModuloVendas, possuiModuloDocumentosDigitais } = props;
	const { utilizarDocumentosDigitais, utilizaBalanca } = values;

	return (
		<Grid>
			<If test={possuiModuloDocumentosDigitais}>
				<Subtitle subtitle="Configurações para documentos" />
				<Col>
					<Fieldset className="fieldset">
						<Grid>
							<Field
								sm="12"
								md="12"
								lg="12"
								xl="12"
								component={Checkbox}
								label="Produto utilizado no módulo de documentos"
								name="utilizarDocumentosDigitais"
								helpMessage={helpProdutosForm.utilizarDocumentosDigitais}
								onChange={(e) => setFieldValue('utilizarDocumentosDigitais', !utilizarDocumentosDigitais)}
								checked={utilizarDocumentosDigitais}
								{...informacoesPermissoes}
							/>
						</Grid>
					</Fieldset>
				</Col>
			</If>
			<If test={possuiModuloVendas}>
				<Subtitle
					subtitle="Configurações para vendas"
					style={possuiModuloDocumentosDigitais ? { marginTop: '0em' } : {}}
				/>
				<Col>
					<Fieldset className="fieldset">
						<Grid>
							<Field
								sm="12"
								md="12"
								lg="12"
								xl="12"
								component={Checkbox}
								label="Produto utiliza balança"
								name="utilizaBalanca"
								helpMessage={helpProdutosForm.utilizaBalanca}
								onChange={(e) => setFieldValue('utilizaBalanca', !utilizaBalanca)}
								checked={utilizaBalanca}
								{...informacoesPermissoes}
							/>
						</Grid>
					</Fieldset>
				</Col>
			</If>
		</Grid>
	);
}
