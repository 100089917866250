import { useEffect, useState } from 'react';
import { Field, withFormik } from 'formik';
import Prompt from 'components/Route/Prompt';
import { usuarioPossuiModulo, usuarioPossuiPermissao } from 'Common/Autenticacao';
import Form from 'components/Form';
import FormActions from 'components/FormActions';
import FormContent from 'components/FormContent';
import Tutorial from 'components/Tutorial';
import { tutorialSteps } from 'components/Tutorial/steps/TributacaoEstadual';
import { estadosCadastro, modulos, permissoes, recursos } from 'Common/Constantes/autorizacao';
import Grid from 'components/Grid';
import Col from 'components/Col';
import '../Styles/index.css';
import InputField from 'components/input/InputField';
import Dropdown from 'components/select/Dropdown';
import Checkbox from 'components/input/Checkbox';
import ButtonNovo from 'components/Button/ButtonNovo';
import Divider from 'components/Divider';
import ModalTributacaoEstadualUf from './components/ModalTributacaoEstadualUf';
import { gerarUUID, removerCaracteres, validarUUID } from 'Common/ManipulacaoDeString';
import SingleSelectTributacaoFederal from 'components/select/SingleSelectTributacaoFederal';
import ListagemTributacaoUf from './components/ListagemTributacaoUf';
import ActionButton from './components/ActionButtons';
import { mensagensDeValidacao } from 'Common/Constantes/mensagens';
import { asyncGetTributacaoEstadual } from '../Requests';
import { converterTributacaoEstadualParaFormulario } from '../Util/tributacaoEstadualConverter';
import { buscarConfiguracaoUsuario, configuracoesUsuario, salvarConfiguracaoUsuario } from 'Common/ConfiguracaoUsuario';
import { helpTributacaoEstadualForm } from './Help';
import { colors } from 'Common';

const CADASTROURL = '/tributacoes/estadual/cadastro';
const PESQUISAURL = '/tributacoes/estadual';

const situacaoOptions = [
	{ label: 'Ativo', value: 'ATIVO' },
	{ label: 'Inativo', value: 'INATIVO' },
];

const secaoStyle = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	margin: '0px',
};

const codigoStyle = {
	fontSize: '24px',
	color: colors.principal,
	fontWeight: 'bold',
};

const initialValue = {
	id: '',
	descricao: '',
	tributacaoFederal: null,
	situacao: 'ATIVO',
	favorito: false,
	tributacoesUf: [],

	possuiModulos: {
		VENDAS: usuarioPossuiModulo(modulos.VENDAS),
	},
};

function TributacaoEstadualFormView(props) {
	const { values, dirty, resetForm, setFieldValue, isModal, hideModal, setHideBackground } = props;
	const [visibleModal, setVisibleModal] = useState(false);
	const [tutorialVisible, setTutorialVisible] = useState(false);
	const [headerModal, setHeaderModal] = useState('Incluir tributação UF');
	const [isNewTributacao, setIsNewTributacao] = useState(false);
	const [dadosBase, setDadosBase] = useState(null);
	const [tributacaoUf, setTributacaoUf] = useState();
	const [visibleModalFederal, setVisibleModalFederal] = useState(false);

	const idURL = props.match?.params.id;
	const podeInserir = usuarioPossuiPermissao(recursos.TRIBUTACAO, permissoes.INSERIR);
	const podeEditar = usuarioPossuiPermissao(recursos.TRIBUTACAO, permissoes.EDITAR);
	const podeExcluir = usuarioPossuiPermissao(recursos.TRIBUTACAO, permissoes.EXCLUIR);

	const informacoesPermissoes = {
		estadoCadastro: buscarEstadoCadastro(),
		podeInserir: podeInserir,
		podeEditar: podeEditar,
		podeExcluir: podeExcluir,
	};
	const validacaoNovaTributacao = idURL && !podeEditar;

	useEffect(() => {
		if (setHideBackground) {
			setHideBackground(visibleModal || visibleModalFederal);
		}
	}, [visibleModal, visibleModalFederal]);

	useEffect(() => {
		const deveExibirTutorial = buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_TRIBUTACAO_ESTADUAL);
		setTimeout(() => {
			if (document.getElementById('InputDescricaoTributacaoEstadual')) {
				document.getElementById('InputDescricaoTributacaoEstadual').focus();
			}
		}, 500);

		if (validarUUID(idURL) && !isNewTributacao && !isModal) {
			getTributacaoSelected();
		}

		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_TRIBUTACAO_ESTADUAL, false, null, false);
		}
	}, [idURL]);

	function getTributacaoSelected() {
		asyncGetTributacaoEstadual(idURL, async ({ data: tributacao }) => {
			let tributacaoConvertida = converterTributacaoEstadualParaFormulario(tributacao);
			resetForm({ values: tributacaoConvertida });
			setDadosBase(tributacaoConvertida);
		});
	}

	function buscarEstadoCadastro() {
		return values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO;
	}

	function onVisibleModal() {
		setVisibleModal(true);
	}

	function onHideModal() {
		setTributacaoUf(null);
		setVisibleModal(false);
	}

	function setValueTributacaoFederal(field, event) {
		if (event?.label && event?.value) {
			setFieldValue(field, { label: event.label, value: event.value });
		} else {
			setFieldValue(field, null);
		}
	}

	function salvarNovaTributacao(tributacao) {
		tributacao.idTemporario = gerarUUID();
		setFieldValue('tributacoesUf', [...values.tributacoesUf, tributacao]);
		onHideModal();
	}

	function atualizarTributacao(tributacaoModal) {
		let tributacoesAtualizadas = values.tributacoesUf.map((tributacao) => {
			if (tributacao.id && tributacaoModal.id === tributacao.id) {
				return tributacaoModal;
			} else if (tributacao.idTemporario && tributacaoModal.idTemporario === tributacao.idTemporario) {
				return tributacaoModal;
			} else {
				return tributacao;
			}
		});

		setFieldValue('tributacoesUf', tributacoesAtualizadas);
		onHideModal();
	}

	function excluirTributacao(tributacaoModal) {
		let tributacoesAtualizadas = values.tributacoesUf.filter((tributacao) => {
			if (
				(tributacao.id && tributacaoModal.id !== tributacao.id) ||
				(tributacao.idTemporario && tributacaoModal.idTemporario !== tributacao.idTemporario)
			) {
				return tributacao;
			}
		});
		setFieldValue('tributacoesUf', tributacoesAtualizadas);

		if (visibleModal) {
			onHideModal();
		}
	}
	return (
		<>
			<Prompt dirty={dirty} isModal={isModal} />

			<Tutorial
				steps={tutorialSteps}
				showSkipButton
				continuous
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>

			<Form header="Cadastro de tributação estadual" isModal={isModal} className="card-default screen-max-width">
				<FormActions className="screen-max-width">
					<ActionButton
						{...props}
						informacoesPermissoes={informacoesPermissoes}
						initialValue={initialValue}
						dadosBase={dadosBase}
						setDadosBase={setDadosBase}
						CADASTROURL={CADASTROURL}
						PESQUISAURL={PESQUISAURL}
						setIsNewTributacao={setIsNewTributacao}
						isModal={isModal}
						hideModal={hideModal}
					/>
				</FormActions>
				<FormContent>
					<Grid className="step-tributacao-estadual-principais">
						<Col sm="12" md="12" lg="12" xl="12" style={{ height: '42px', marginBottom: '1rem' }}>
							<div style={{ display: 'flex', justifyContent: 'flex-start' }}>
								<div
									style={secaoStyle}
									title={!values.codigo ? 'O código é gerado automaticamente pelo sistema' : null}
								>
									<div>Código</div>
									<div style={codigoStyle}>{values.codigo || '-'}</div>
								</div>
							</div>
						</Col>
						<Field
							sm="12"
							md="6"
							lg="6"
							xl="6"
							id="InputDescricaoTributacaoEstadual"
							component={InputField}
							label="Descrição"
							obrigatorio
							name="descricao"
							onBlur={() => {
								if (values.nome) {
									setFieldValue('descricao', values.nome.trim());
								}
							}}
							onChange={(e) => setFieldValue('descricao', removerCaracteres(e.target?.value, ['─']))}
							value={values.descricao}
							helpMessage={helpTributacaoEstadualForm.descricao}
							size={50}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="4"
							xl="4"
							component={SingleSelectTributacaoFederal}
							label="Tributação federal"
							obrigatorio
							name="tributacaoFederal"
							value={values.tributacaoFederal}
							helpMessage={helpTributacaoEstadualForm.tributacaoFederal}
							onChange={(event) => setValueTributacaoFederal('tributacaoFederal', event)}
							setVisibleModalFederal={setVisibleModalFederal}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="2"
							lg="2"
							xl="2"
							component={Dropdown}
							label="Situação"
							obrigatorio
							name="situacao"
							value={values.situacao}
							helpMessage={helpTributacaoEstadualForm.situacao}
							onChange={(event) => setFieldValue('situacao', event?.value)}
							options={situacaoOptions}
							showClear={false}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="3"
							xl="3"
							component={Checkbox}
							label="Tributação estadual favorita"
							obrigatorio
							name="favorito"
							helpMessage={helpTributacaoEstadualForm.favorito}
							checked={values.favorito}
							onChange={(event) => setFieldValue('favorito', !values.favorito)}
							showClear={false}
							{...informacoesPermissoes}
						/>
					</Grid>
					<Divider label="Tributações por UF" styleContainer={{ marginLeft: '0' }} />
					<ButtonNovo
						className="step-tributacao-estadual-estados-botao"
						label="Nova tributação UF"
						onClick={onVisibleModal}
						disabled={idURL && !informacoesPermissoes.podeEditar}
					/>
					<ListagemTributacaoUf
						values={values.tributacoesUf}
						setVisibleModal={setVisibleModal}
						setTributacaoUf={setTributacaoUf}
						excluirTributacao={excluirTributacao}
						validacaoNovaTributacao={validacaoNovaTributacao}
					/>
				</FormContent>
			</Form>
			<ModalTributacaoEstadualUf
				visible={visibleModal}
				onHide={onHideModal}
				header={headerModal}
				salvar={(tributacao) => salvarNovaTributacao(tributacao)}
				atualizar={atualizarTributacao}
				tributacaoUf={tributacaoUf}
				listaTributacoesUf={values.tributacoesUf}
				excluirTributacao={excluirTributacao}
				validacaoNovaTributacao={validacaoNovaTributacao}
				informacoesPermissoes={informacoesPermissoes}
				{...props}
			/>
		</>
	);
}

const TributacaoEstadualForm = withFormik({
	enableReinitialize: true,
	validateOnChange: true,
	validateOnBlur: false,

	mapPropsToValues(props) {
		return initialValue;
	},
	validate(values) {
		let errors = {};

		if (!values.descricao || values.descricao?.trim() === '') {
			errors.descricao = mensagensDeValidacao.OBRIGATORIO;
		}

		if (!values.tributacaoFederal) {
			errors.tributacaoFederal = mensagensDeValidacao.OBRIGATORIO;
		}

		if (!values.situacao) {
			errors.situacao = mensagensDeValidacao.OBRIGATORIO;
		}
		return errors;
	},

	handleSubmit: () => {},
})(TributacaoEstadualFormView);

export default TributacaoEstadualForm;
