import { colors } from 'Common';

export const INITIAL_VALUES_VENDEDORES = {
	id: null,
	codigo: null,
	nome: null,
	tipo: 'VENDEDOR',
	situacao: 'ATIVO',
	origemCobranca: 'FATURAMENTO',
	descontoMaximo: 0,
	comissao: 0,
	tabelaPrecoPadrao: null,
	superior: null,
	descendenteFavorito: null,
	usuario: null,
	regioesTabelaPrecos: [],
	filiais: [],
};

export const SITUACAO_VENDEDORES = [
	{ value: 'ATIVO', label: 'Ativo' },
	{ value: 'INATIVO', label: 'Inativo' },
];

export const TIPO_VENDEDORES = { VENDEDOR: 'VENDEDOR', SUPERVISOR: 'SUPERVISOR', GERENTE: 'GERENTE' };

export const TIPO_VENDEDORES_OPTIONS = [
	{ value: TIPO_VENDEDORES.VENDEDOR, label: 'Vendedor' },
	{ value: TIPO_VENDEDORES.SUPERVISOR, label: 'Supervisor' },
	{ value: TIPO_VENDEDORES.GERENTE, label: 'Gerente' },
];

export const ORIGEM_COBRANCA_VENDEDORES = [
	{ value: 'FATURAMENTO', label: 'Faturamento' },
	{ value: 'LIQUIDACAO', label: 'Liquidação' },
];

export const VENDEDORES_INFORMACOES_SITUACAO = {
	[SITUACAO_VENDEDORES.ATIVO]: {
		name: 'Ativa',
		description: 'Vendedor ativo',
		lightColor: colors.azulLight,
		strongColor: colors.principal,
	},
	[SITUACAO_VENDEDORES.INATIVO]: {
		name: 'Inativa',
		description: 'Vendedor inativo',
		lightColor: colors.vermelhoLight,
		strongColor: colors.vermelho,
	},
};

export const ClassNameTabViewColor = {
	ATIVO: 'tab-view-vendedor-ativo',
	INATIVO: 'tab-view-vendedor-inativo',
};
