import React, { Component } from 'react';
import Card from '../../../components/cards/Card/';
import logo from '../../../layout/Images/logo_msys.svg';
import Button from '../../../components/Button';
import { asyncConfirmEmail } from './Requests';
import { errorStyle, successStyle, contentStyle } from '../Styles';
import { fazerLogoutLocalStorange, buscarDadosLoginLocalStorage } from '../../../Common/Autenticacao';
import { withRouter } from 'react-router';
import AutoProgressBar from '../../../components/Loading/AutoProgressBar';

export class ConfirmPassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			resultConfirmation: {
				success: false,
				message: '',
			},
		};
	}

	componentDidMount() {
		const location = window.location.toString();
		if (location.indexOf('token=') === -1) {
			this.setState({ resultConfirmation: { message: 'Url de autenticação inválida!', success: false } });
		} else {
			const token = location.substring(location.indexOf('token=') + 6);

			asyncConfirmEmail(
				token,
				() => {
					if (buscarDadosLoginLocalStorage().senha) fazerLogoutLocalStorange();
					this.setState({ resultConfirmation: { message: 'E-mail registrado com sucesso!', success: true } });
				},
				e => {
					let message = 'Erro de conexão com a internet.';
					if (e.response) {
						message = 'Link de autenticação expirado!';
					}
					this.setState({ resultConfirmation: { message: message, success: false } });
				}
			);
		}
	}

	voltarParaATelaDeLogin() {
		fazerLogoutLocalStorange();
		window.location = '#/login';
	}

	render() {
		return (
			<>
				<AutoProgressBar />
				<div style={contentStyle}>
					<Card
						style={{ width: '430px', maxWidth: '100%', borderRadius: '5px', padding: '1rem' }}
						className="p-card-shadow"
					>
						<div style={{ textAlign: 'center' }}>
							<img
								alt="Card"
								style={{ padding: '15px 0', width: '100%', height: '100%', display: 'block' }}
								src={logo}
							/>
						</div>
						<div style={this.state.resultConfirmation.success ? successStyle : errorStyle}>
							<center>
								<h4
									style={{
										margin: 0,
										color: this.state.resultConfirmation.success ? '#185f10' : '#751111',
									}}
								>
									{this.state.resultConfirmation.message}
								</h4>
							</center>
						</div>
						<Button
							label="Retornar para a tela de login"
							icon="fa fa-arrow-left"
							style={{ width: '100%', padding: '0.5rem' }}
							size="large"
							onClick={this.voltarParaATelaDeLogin}
						/>
					</Card>
				</div>
			</>
		);
	}
}

export default withRouter(ConfirmPassword);
