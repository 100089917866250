import { useFormikContext } from 'formik';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useCallback, useEffect, useRef, useState } from 'react';
import { CiDiscount1, CiWarning } from 'react-icons/ci';

import {
	buscarDadosLoginLocalStorage,
	calcularImpostosServico,
	colors,
	copiarObjeto,
	formatarDecimais,
	formatarMonetarioDecimais,
	gerarUUID,
	getSafeValue,
	services,
} from 'Common';
import {
	Button,
	If,
	InputDouble,
	InputSelectPercentualOrValor,
	NenhumRegistroEncontrado,
	SingleSelectProduto,
	TextArea,
} from 'components';

import { NOVO_SERVICO, styleButton, styleColumn, TIPOS_PERCENTUAL_VALOR } from 'components/Servicos/Utils';
import { OverlayPanel } from 'primereact/overlaypanel';
import { aplicarPercentual } from 'views/Util';
import { TooltipValorUnitario } from '../TooltipValorUnitario';

function ListaServicos({
	isMobile,
	isTablet,
	disabled,
	montarServico,
	informacoesPermissoes,
	setVisibleModalServico,
	setIndexModalServico,
	setAtualizarServicos,
	adicionarNovoServico,
	onChangeServico,
	onChangeServicos,
	utilizaTabelaPreco,
	operacaoFiscalHeader,
	tabelaPreco,
	pagamentos,
	calcularValorVenda,
	calcularPercentualAdicionalTabelaPreco,
	recalcularTodosOsServicosComTabelaPreco,
	setRecalcularTodosOsServicosComTabelaPreco,
	descontoMaximoSuperior,
}) {
	const { values, errors, setFieldValue } = useFormikContext();

	const [indexAtualizarServico, setIndexAtualizarServico] = useState(null);
	const [currentServiceIndex, setCurrentServiceIndex] = useState(null);
	const [observacaoItem, setObservacaoItem] = useState('');

	const panelDetalhesServico = useRef();

	const { filialConectada } = buscarDadosLoginLocalStorage();
	const decimaisQtd = filialConectada?.parametrosCadastros?.decimaisQtd ?? 3;
	const decimaisPreco = filialConectada?.parametrosCadastros?.decimaisPreco ?? 2;
	const utilizaTabelaPrecoAcrescimoOuDescontoPorCondicao =
		filialConectada?.parametrosVendas?.utilizaTabelaPrecoAcrescimoOuDescontoPorCondicao;
	const utilizaTabelaPrecoDescontoQuantidade = filialConectada?.parametrosVendas?.utilizaTabelaPrecoDescontoQuantidade;

	const calcularImpostosServicoLocal = useCallback(async (servico) => {
		await calcularImpostosServico(servico);
	});

	const atualizarServico = useCallback(async (index) => {
		const servico = await recalcularValoresServico(index);

		setFieldValue(`servicos[${index}]`, servico);
		onChangeServico(index, servico);
	});

	useEffect(() => {
		if (indexAtualizarServico !== null) {
			atualizarServico(indexAtualizarServico);
			setIndexAtualizarServico(null);
		}
	}, [indexAtualizarServico]);

	useEffect(() => {
		if (recalcularTodosOsServicosComTabelaPreco) {
			const promises = values.servicos?.map((_, index) => recalcularValoresServico(index, true));
			Promise.all(promises).then((servicos) => {
				setFieldValue(`servicos`, servicos);
				onChangeServicos(servicos);
				setRecalcularTodosOsServicosComTabelaPreco(false);
			});
		}
	}, [recalcularTodosOsServicosComTabelaPreco]);

	async function recalcularValoresServico(index, alterarValor = false) {
		const servico = copiarObjeto(values.servicos[index]);

		if (servico.servico) {
			if (utilizaTabelaPreco) {
				const deveAlterarValorAutomaticamente =
					!servico.alterouValorManualmente || servico.alterouQuantidade || alterarValor;
				const adicionalTabelaPreco = await calcularPercentualAdicionalTabelaPreco(servico.quantidade);
				servico.adicionalTabelaPreco = { percentual: adicionalTabelaPreco.percentual, tipo: adicionalTabelaPreco.tipo };

				if (deveAlterarValorAutomaticamente) {
					servico.valor = parseFloat(
						calcularValorVenda(servico.valorOriginal, servico.adicionalTabelaPreco).toFixed(decimaisPreco)
					);

					servico.alterouValorManualmente = false;
					servico.alterouQuantidade = false;
					servico.valorAntesAlteracao = null;
				}
			}

			calcularImpostosServicoLocal(servico);

			servico.subtotal = parseFloat(
				(
					getSafeValue(servico.quantidade) * getSafeValue(servico.valor) -
					getSafeValue(servico.desconto) +
					getSafeValue(servico.valorIcmsSt) +
					getSafeValue(servico.tributos?.icms?.valorIcmsSt) +
					getSafeValue(servico.tributos?.ipi?.valorIpi)
				).toFixed(2)
			);

			servico.comissaoValor = servico.comissaoPercentual
				? parseFloat((servico.subtotal * (servico.comissaoPercentual / 100)).toFixed(2))
				: 0;

			return servico;
		}

		return servico;
	}

	function renderLabelServico(row, options) {
		const color = errors[options.rowIndex]?.servico ? colors.vermelho : colors.cinzaText;

		return (
			<div title={errors[options.rowIndex]?.servico} style={{ color: color, display: 'flex', alignItems: 'center' }}>
				{errors[options.rowIndex] && (
					<CiWarning style={{ width: '18px', height: '18px', marginRight: '8px', color: colors.vermelho }} />
				)}
				{row.servico?.label ?? ' - '}
				{row.utilizouTabelaPromocional && (
					<CiDiscount1
						style={{ width: '18px', height: '18px', marginLeft: '8px', color: colors.verdeButton }}
						title={
							row.servico.registro?.tabelaPreco
								? `Serviço presente na tabela promocional: ${row.servico.registro?.tabelaPreco?.codigo} - ${row.servico.registro?.tabelaPreco?.nome}`
								: 'Serviço presente em tabela promocional'
						}
					/>
				)}
			</div>
		);
	}

	function renderLabelQuantidade(row, options) {
		const color = errors[options.rowIndex]?.quantidade ? colors.vermelho : colors.cinzaText;
		return (
			<div title={errors[options.rowIndex]?.quantidade} style={{ color: color }}>
				{formatarDecimais(row.quantidade, decimaisQtd)}
			</div>
		);
	}

	function renderLabelValorUnitario(row, options) {
		const color = errors[options.rowIndex]?.valor ? colors.vermelho : colors.cinzaText;
		return (
			<>
				<If
					test={
						utilizaTabelaPreco &&
						(utilizaTabelaPrecoDescontoQuantidade || utilizaTabelaPrecoAcrescimoOuDescontoPorCondicao)
					}
				>
					<TooltipValorUnitario
						indexServico={options.rowIndex}
						servico={row}
						disabled={disabled}
						tabelaPreco={tabelaPreco}
						pagamentos={pagamentos}
						utilizaTabelaPreco={utilizaTabelaPreco}
						utilizaTabelaPrecoDescontoQuantidade={utilizaTabelaPrecoDescontoQuantidade}
						utilizaTabelaPrecoAcrescimoOuDescontoPorCondicao={utilizaTabelaPrecoAcrescimoOuDescontoPorCondicao}
					/>
				</If>
				<div
					className={`id-valor-unitario-servico-listagem-${options.rowIndex}`}
					title={errors[options.rowIndex]?.valor}
					style={{ color: color }}
				>
					{formatarMonetarioDecimais(row.valor, decimaisPreco)}
				</div>
			</>
		);
	}

	function renderLabelDesconto(row, options) {
		const descontoMaximoValor = row.descontoMaximoPercentual
			? row.quantidade * row.valor * (row.descontoMaximoPercentual / 100)
			: 0;
		const color =
			errors[options.rowIndex]?.desconto || (descontoMaximoValor && row.servico && row.desconto > descontoMaximoValor)
				? colors.vermelho
				: colors.cinzaText;
		const title =
			row.descontoMaximoPercentual && row.desconto > descontoMaximoValor
				? `Desconto máximo permitido: R$${parseFloat(descontoMaximoValor).toFixed(2).replace('.', ',')}`
				: errors[options.rowIndex]?.desconto;

		return (
			<div title={title} style={{ color: color }}>
				{formatarMonetarioDecimais(row.desconto, decimaisPreco)}
			</div>
		);
	}

	function renderEditServico(options) {
		return (
			<SingleSelectProduto
				id={`select-produto-${options.rowIndex}`}
				colStyle={{ padding: '0px', maxWidth: isMobile || isTablet ? '80%' : null }}
				url={`${services.GESTOR}/v1/produtos`}
				label=""
				name="servico"
				onChange={(event) => onChangeFieldServico(options, event)}
				value={options.value}
				placeholder="Selecione o serviço"
				filtroAdicionalUrl={
					utilizaTabelaPreco ? `;tipo=="SERVICO";tabelaPreco.id==${tabelaPreco?.value}` : ';tipo=="SERVICO"'
				}
				disabled={disabled || (utilizaTabelaPreco && !tabelaPreco?.value)}
				resolveBugDataTable
				esconderBotao
				isClearable={false}
				{...informacoesPermissoes}
			/>
		);
	}

	function renderEditQuantidade(options) {
		return (
			<InputDouble
				colStyle={{ padding: '0px', maxWidth: isMobile || isTablet ? '80%' : null }}
				label=""
				size={13}
				name="quantidade"
				value={options.value}
				disabled={disabled}
				onChange={(event) => onChangeField(options, event.target.value ?? 0)}
				decimalScale={decimaisQtd}
				allowNegative={false}
				errors={errors?.quantidade}
				{...informacoesPermissoes}
			/>
		);
	}

	function renderEditValorUnitario(options) {
		return (
			<InputDouble
				colStyle={{ padding: '0px', maxWidth: isMobile || isTablet ? '80%' : null }}
				label=""
				name="valor"
				value={options.value}
				onChange={(event) => onChangeField(options, event.target.value ?? 0)}
				size={13}
				prefix="R$ "
				allowNegative={false}
				errors={errors?.valor}
				touched={!!errors?.valor}
				disabled={
					disabled ||
					(utilizaTabelaPreco &&
						!tabelaPreco?.registro?.editarPrecoAbaixoValorTabela &&
						!tabelaPreco?.registro?.editarPrecoAcimaValorTabela)
				}
				decimalScale={decimaisPreco}
				{...informacoesPermissoes}
			/>
		);
	}

	function renderEditDesconto(options) {
		return (
			<InputSelectPercentualOrValor
				colStyle={{ padding: '0px', maxWidth: isMobile || isTablet ? '80%' : null }}
				label=""
				name="desconto"
				value={values?.servicos[options.rowIndex]?.desconto}
				onChange={(tipo, value, percentual) => onChangeField(options, value, percentual, tipo)}
				size={13}
				prefix="R$ "
				allowNegative={false}
				errors={errors?.desconto}
				touched={!!errors?.desconto}
				disabled={disabled}
				decimalScale={decimaisPreco}
				{...informacoesPermissoes}
			/>
		);
	}

	function onChangeField(options, value, percentual, tipo = TIPOS_PERCENTUAL_VALOR.VALOR) {
		if (tipo === TIPOS_PERCENTUAL_VALOR.PERCENTUAL) {
			const subtotal = parseFloat((options.rowData.quantidade * options.rowData.valorOriginal).toFixed(2));
			options.editorCallback(aplicarPercentual(subtotal, percentual));
		} else {
			options.editorCallback(value);
		}
	}

	function renderAcoesField(row, options) {
		function handleEditServico() {
			setVisibleModalServico(true);
			setIndexModalServico(options.rowIndex);
		}

		function handleDeleteServico() {
			const servicos = copiarObjeto(values.servicos);

			servicos.splice(options.rowIndex, 1);

			for (let i = 0; i < servicos.length; i++) {
				servicos[i].item = i + 1;
			}

			setFieldValue(`servicos`, servicos);
			setAtualizarServicos(true);
		}

		function handleShowOverlay(e, rowIndex) {
			document
				.getElementById('overlayPanel-detalhes')
				?.getElementsByClassName('p-overlaypanel-close p-link')[0]
				?.click();

			setObservacaoItem(values.servicos[options.rowIndex]?.descricao);
			setCurrentServiceIndex(rowIndex);
			panelDetalhesServico.current?.toggle(e);
		}

		return (
			<div>
				<Button
					style={styleButton}
					className="p-button p-button-primary"
					icon="fa fa-file-text-o"
					title="Observações do serviço"
					onClick={(e) => handleShowOverlay(e, options.rowIndex)}
					disabled={!row.servico}
				/>
				<Button
					style={styleButton}
					className="p-button p-button-primary"
					icon="fa fa-pencil"
					title="Acessar servico"
					onClick={handleEditServico}
					disabled={!row.servico}
				/>
				<Button
					style={styleButton}
					className="p-button p-button-danger"
					icon="fa fa-trash"
					title="Excluir servico"
					disabled={disabled || (options.rowIndex === 0 && values.servicos.length === 1)}
					onClick={handleDeleteServico}
				/>
			</div>
		);
	}

	async function onChangeFieldServico(options, value) {
		options.editorCallback(value);

		if (value) {
			const servico = await montarServico({ ...values.servicos[options.rowIndex], servico: value }, value);

			if (value && options.rowIndex === values.servicos.length - 1) {
				setFieldValue('servicos', [
					...values.servicos,
					{
						...copiarObjeto(NOVO_SERVICO),
						id: gerarUUID(),
						item: values.servicos.length + 1,
						operacaoFiscal: operacaoFiscalHeader ?? null,
					},
				]);
			}
			setFieldValue(`servicos[${options.rowIndex}]`, servico);

			setTimeout(() => {
				setAtualizarServicos(true);

				if (!options.value && value) {
					const elementHtmlServico = document
						.getElementById('id-servicos-listagem')
						?.getElementsByTagName('table')[0]
						?.getElementsByTagName('tr')
						[values.servicos.length + 1]?.getElementsByClassName('id-servicos-listagem-campo-servico')[0];
					elementHtmlServico?.click();
				}
			}, 200);
		}
	}

	async function onChangeColumn(e) {
		if (!disabled) {
			const servico = e.newRowData;
			if (servico) {
				if (descontoMaximoSuperior && descontoMaximoSuperior > servico.descontoMaximoPercentual) {
					servico.descontoMaximoVendedor = descontoMaximoSuperior;
					servico.descontoMaximoPercentual = descontoMaximoSuperior;
				}

				if (utilizaTabelaPreco) {
					if (
						(tabelaPreco?.registro?.editarPrecoAbaixoValorTabela ||
							tabelaPreco?.registro?.editarPrecoAcimaValorTabela) &&
						e.rowData.valor !== servico.valor
					) {
						servico.alterouValorManualmente = true;
						servico.valorAntesAlteracao = e.rowData.valor;
					} else if (utilizaTabelaPrecoDescontoQuantidade && e.rowData.quantidade !== servico.quantidade) {
						servico.alterouQuantidade = true;
					}
				}

				setFieldValue(`servicos[${e.rowIndex}]`, servico);
				setIndexAtualizarServico(e.rowIndex);
			}
		}
	}

	async function onExitLastCell(event) {
		if (event.rowIndex < values.servicos.length - 1) {
			onChangeColumn(event);
			const elementHtmlServico = document
				.getElementById('id-servicos-listagem')
				?.getElementsByTagName('table')[0]
				?.getElementsByTagName('tr')
				[event.rowIndex + 2]?.getElementsByClassName('id-servicos-listagem-campo-servico')[0];
			elementHtmlServico?.click();
		} else if (event.rowIndex === values.servicos.length - 1 && event?.newRowData?.servico) {
			await onChangeColumn(event);
			adicionarNovoServico();
		}
	}

	function renderDescricaoField() {
		if (currentServiceIndex === null) {
			return null;
		}

		const campo = `servicos[${currentServiceIndex}].descricao`;

		function onBlurDetalheField() {
			setFieldValue(campo, observacaoItem);
			setAtualizarServicos(true);
			setObservacaoItem('');
		}

		return (
			<TextArea
				label="Observação"
				name={campo}
				value={observacaoItem}
				onBlur={onBlurDetalheField}
				onChange={(e) => {
					setObservacaoItem(e.target.value);
				}}
				disabled={disabled}
				{...informacoesPermissoes}
			/>
		);
	}

	return (
		<>
			<DataTable
				id="id-servicos-listagem"
				className="table"
				rowClassName="table-row"
				cellClassName="table-row-cell"
				responsive
				editMode="cell"
				value={values?.servicos}
				style={{
					width: '100%',
					overflowY: values.servicos?.length > 6 ? 'scroll' : 'hidden',
					overflowX: 'hidden',
					maxHeight: isMobile ? '36.4rem' : '20.2rem',
				}}
				emptyMessage={<NenhumRegistroEncontrado />}
			>
				<Column
					header="Item"
					field="item"
					body={(row) => row.item}
					style={{
						...styleColumn,
						width: '40px',
					}}
				/>
				<Column
					bodyClassName="id-servicos-listagem-campo-servico"
					header="Serviço"
					field="servico"
					body={(row, options) => renderLabelServico(row, options)}
					editor={(options) => renderEditServico(options)}
					style={{
						...styleColumn,
					}}
				/>
				<Column
					header="Qtde."
					field="quantidade"
					body={(row, options) => renderLabelQuantidade(row, options)}
					editor={(options) => renderEditQuantidade(options)}
					onCellEditComplete={onChangeColumn}
					style={{
						...styleColumn,
						width: '120px',
					}}
				/>
				<Column
					header="Valor unit."
					field="valor"
					body={(row, options) => renderLabelValorUnitario(row, options)}
					editor={(options) => renderEditValorUnitario(options)}
					onCellEditComplete={onChangeColumn}
					style={{
						...styleColumn,
						width: '140px',
					}}
				/>
				<Column
					header="Desconto"
					field="desconto"
					body={(row, options) => renderLabelDesconto(row, options)}
					editor={(options) => renderEditDesconto(options)}
					onCellEditComplete={(event) => {
						onExitLastCell(event);
					}}
					style={{
						...styleColumn,
						width: '140px',
					}}
				/>
				<Column
					header="Total serviço"
					field="subtotal"
					body={(row) => formatarMonetarioDecimais(row.subtotal, decimaisPreco)}
					style={{
						...styleColumn,
						width: '120px',
					}}
				/>
				<Column
					header="Ações"
					field="acoes"
					body={(row, options) => renderAcoesField(row, options)}
					style={{
						...styleColumn,
						width: '130px',
					}}
				/>
			</DataTable>
			<OverlayPanel
				ref={panelDetalhesServico}
				id="overlayPanel-detalhes"
				style={{
					width: isMobile ? '90%' : '100%',
					maxWidth: '30.5rem',
					marginLeft: isMobile ? '1rem' : null,
					marginTop: '0px',
				}}
				onHide={() => setCurrentServiceIndex(null)}
				showCloseIcon
			>
				{renderDescricaoField()}
			</OverlayPanel>
		</>
	);
}

export { ListaServicos };
