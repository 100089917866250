import { useCallback, useEffect, useState } from 'react';
import { recursos, permissoes } from '../../../Common/Constantes/autorizacao';
import { buscarDadosLoginLocalStorage, usuarioPossuiPermissao } from '../../../Common/Autenticacao';
import Col from '../../../components/Col';
import Grid from '../../../components/Grid';
import CardTotalizadorListagem from './components/CardTotalizadorListagem';
import PesquisaAvancada from '../../../components/PesquisaAvancada';
import { optionsFiltroAvancado, tipoSaldo } from './Util/constantes';
import { construirUrl } from '../../../Common/Rsql';
import { services } from '../../../Common/Constantes/api';
import { salvarConfiguracaoUsuario, configuracoesUsuario } from '../../../Common/ConfiguracaoUsuario';
import {
	asyncEditLancamento,
	asyncGetLancamento,
	asyncGetLancamentos,
	asyncGetTotalizadores,
	asyncRemoveLancamento,
} from './Requests';
import DateInterval from '../../../components/DateInterval';
import If from '../../../components/If';
import DescricaoFiltroAvancado from '../../../components/DescricaoFiltroAvancado';
import Form from '../../../components/Form';
import FormActions from '../../../components/FormActions';
import FormContent from '../../../components/FormContent';
import { format, formatISO } from 'date-fns';
import { tipoMovimentacao } from './Util/constantes';
import TabelaMovimentacoes from './components/TabelaMovimentacoes';
import ModalSaidaEstoque from './components/ModalSaidaEstoque';
import ModalEntradaEstoque from './components/ModalEntradaEstoque';
import { ToastTypes, confirm } from '../../../components/Toast';
import InputSearchProdutos from './components/InputSearchProdutos';
import Button from '../../../components/Button';
import { useContextPesquisa } from 'views/Util/Context/ContextPesquisa';
import { useEffectOnce } from 'react-use';
import { colors, exibirToast } from 'Common';

const COLORS = {
	blue: colors.principal,
	red: '#ff0000cc',
	green: '#36a536',
};

function MovimentacoesEstoque(props) {
	const {
		valorPesquisa,
		setValorPesquisa,
		interval,
		setInterval,
		sortField,
		setSortField,
		sortOrder,
		setSortOrder,
		page,
		setPage,
		rows,
		filtroData,
		setFiltroData,
		filtroAvancado,
		setFiltroAvancado,
		descricaoFiltroAvancado,
		setDescricaoFiltroAvancado,
		selectedCard,
		setSelectedCard,
	} = useContextPesquisa();

	let decimaisQtd = buscarDadosLoginLocalStorage()?.filialConectada?.parametrosCadastros?.decimaisQtd ?? 3;

	const [registroSelecionado, setRegistroSelecionado] = useState(null);
	const [produtoSelecionado, setProdutoSelecionado] = useState(null);
	const [geradoAPartirDeUmaVenda, setGeradoAPartirDeUmaVenda] = useState(null);
	const [podeInserir, setPodeInserir] = useState(
		usuarioPossuiPermissao(recursos.ESTOQUE_MOVIMENTACOES, permissoes.INSERIR)
	);
	const [exibirModalLancamentoSaida, setExibirModalLancamentoSaida] = useState(false);
	const [exibirModalLancamentoEntrada, setExibirModalLancamentoEntrada] = useState(false);
	const [exibirModalLancamentoReserva, setExibirModalLancamentoReserva] = useState(false);
	const [tutorialVisible, setTutorialVisible] = useState(false);
	const [valorCard, setValorCard] = useState('');
	const [cards, setCards] = useState({
		saldoAnterior: 0,
		entradas: 0,
		saidas: 0,
		saldo: 0,
	});
	const [limparFiltroPesquisaAvancada, setLimparFiltroPesquisaAvancada] = useState(false);
	const [registros, setRegistros] = useState([]);
	const [totalRecords, setTotalRecords] = useState(0);
	const [size, setSize] = useState(10);
	const [firstRender, setFirstRender] = useState(true);
	const [exibirReservas, setExibirReservas] = useState(false);

	const pesquisarCallback = useCallback(() => {
		if (!firstRender) {
			pesquisar();
		}
	});

	useEffect(() => {
		pesquisarCallback();
	}, [page, rows, size, sortOrder, sortField, filtroAvancado, selectedCard, valorPesquisa, interval, exibirReservas]);

	useEffectOnce(() => {
		const { location } = props;

		if (tutorialVisible !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS, false, null, false);
		}

		pesquisar();

		setTimeout(() => {
			if (location && location.state) {
				onChangeProdutos([
					{
						label: location.state.codigo + ' - ' + location.state.nome,
						value: location.state.id,
						registro: location.state,
					},
				]);
			}
			document.getElementById('EstoqueInputSearchProdutos')?.getElementsByTagName('input')[0]?.focus();
		}, 500);
	});

	function onPesquisarFiltroAvancado(e) {
		setFiltroAvancado(e);
		setLimparFiltroPesquisaAvancada(false);
	}

	function onChangeProdutos(produtos) {
		setValorPesquisa(produtos);
	}

	useEffect(() => {
		setPage(0);
	}, [valorPesquisa]);

	function buscarFiltro() {
		if (filtroData) {
			let filtroRSQL = String(`?query=(${filtroData}`);

			if (valorPesquisa && valorPesquisa.length > 0) {
				filtroRSQL = filtroRSQL.concat(`;produtoId=in=(${valorPesquisa.map((elemento) => elemento.value)})`);
			}
			if (filtroAvancado) {
				filtroRSQL = filtroRSQL.concat(`;(${filtroAvancado})`);
			}
			if (valorCard) {
				filtroRSQL = filtroRSQL.concat(`;(${valorCard})`);
			}

			filtroRSQL = filtroRSQL.concat(`${!exibirReservas ? ';saldoTipo!=' + tipoSaldo.RESERVADO : ''}`);
			filtroRSQL = filtroRSQL.concat(`)`);
			return filtroRSQL;
		} else {
			let filtroRSQL;
			let produtoLocation;
			setFiltroData(
				`dataHora>=${formatISO(interval.dataInicial, {
					representation: 'date',
				})};dataHora<=${formatISO(interval.dataFinal, {
					representation: 'date',
				})}`
			);
			filtroRSQL = `?query=(dataHora>=${formatISO(interval.dataInicial, {
				representation: 'date',
			})};dataHora<=${formatISO(interval.dataFinal, {
				representation: 'date',
			})})`;
			produtoLocation = valorPesquisa;
			if (props.location && props.location.state) {
				produtoLocation = [
					{
						label: props.location.state.codigo + ' - ' + props.location.state.nome,
						value: props.location.state.id,
						registro: props.location.state,
					},
				];
			}
			if (produtoLocation && produtoLocation.length > 0) {
				filtroRSQL = filtroRSQL.concat(`;produtoId=in=(${produtoLocation.map((elemento) => elemento.value)})`);
			}
			filtroRSQL = filtroRSQL.concat(`${!exibirReservas ? ';saldoTipo!=' + tipoSaldo.RESERVADO : ''}`);

			return filtroRSQL;
		}
	}

	async function pesquisar() {
		if (interval.dataInicial != null && interval.dataFinal != null) {
			const filtro = buscarFiltro();
			let campoOrdenacao = sortField;
			let sentidoOrdenacao = sortOrder;
			if (firstRender) {
				setSortField('dataHora');
				setSortOrder(-1);
				campoOrdenacao = 'dataHora';
				sentidoOrdenacao = -1;
			}
			const url = construirUrl(
				`${services.GESTOR}/v1/estoque/movimentacoes/resumo`,
				filtro || '?',
				size,
				page,
				sentidoOrdenacao > 0 ? `${campoOrdenacao},asc&sort=dataHora,desc` : `${campoOrdenacao},desc&sort=dataHora,desc`
			);

			if (interval.dataInicial !== null && interval.dataFinal !== null) {
				asyncGetTotalizadores(interval, valorPesquisa, ({ data: totais }) => {
					setCards({
						saldoAnterior: totais.saldoAnterior,
						entradas: totais.totalEntradas,
						saidas: totais.totalSaidas,
						saldo: totais.saldoTotal,
					});
				});
			}

			asyncGetLancamentos(url, ({ data: lancamentos }) => {
				setRegistros(lancamentos.content);
				setTotalRecords(lancamentos.totalElements);
				setFirstRender(false);
			});
		}
	}

	function handleChangeInterval(interval) {
		setPage(0);
		setInterval(interval);
		setFiltroData(
			`dataHora>=${formatISO(interval.dataInicial, {
				representation: 'date',
			})};dataHora<=${formatISO(interval.dataFinal, {
				representation: 'date',
			})}`
		);
	}

	async function handleEditItem(item) {
		await asyncGetLancamento(item.id, ({ data: lancamento }) => {
			if (lancamento.saldoTipo === tipoSaldo.ESTOQUE && lancamento.tipo === tipoMovimentacao.ENTRADA) {
				setRegistroSelecionado({
					...lancamento,
					data: lancamento.dataHora,
					hora: lancamento.dataHora.substring(11, 16),
					produto: {
						label:
							lancamento.produto.codigo +
							' - ' +
							lancamento.produto.nome +
							(lancamento.produto.sku ? ' - [Ref: ' + lancamento.produto.sku + ']' : ''),
						value: lancamento.produto.id,
						registro: {
							...lancamento.produto,
							estoqueSaldo: lancamento.quantidade + lancamento.produto.estoqueSaldo,
						},
					},
				});
				setExibirModalLancamentoEntrada(true);
			} else if (lancamento.saldoTipo === tipoSaldo.ESTOQUE && lancamento.tipo === tipoMovimentacao.SAIDA) {
				setRegistroSelecionado({
					...lancamento,
					data: lancamento.dataHora,
					hora: lancamento.dataHora.substring(11, 16),
					produto: {
						label:
							lancamento.produto.codigo +
							' - ' +
							lancamento.produto.nome +
							(lancamento.produto.sku ? ' - [Ref: ' + lancamento.produto.sku + ']' : ''),
						value: lancamento.produto.id,
						registro: {
							...lancamento.produto,
							estoqueSaldo: lancamento.quantidade + lancamento.produto.estoqueSaldo,
						},
					},
				});
				setExibirModalLancamentoSaida(true);
				setGeradoAPartirDeUmaVenda(item.documento && item.documento.id);
			} else if (lancamento.saldoTipo === tipoSaldo.RESERVADO && lancamento.tipo === tipoMovimentacao.ENTRADA) {
				setRegistroSelecionado({
					...lancamento,
					data: lancamento.dataHora,
					hora: lancamento.dataHora.substring(11, 16),
					produto: {
						label:
							lancamento.produto.codigo +
							' - ' +
							lancamento.produto.nome +
							(lancamento.produto.sku ? ' - [Ref: ' + lancamento.produto.sku + ']' : ''),
						value: lancamento.produto.id,
						registro: {
							...lancamento.produto,
							estoqueSaldo: lancamento.quantidade + lancamento.produto.estoqueSaldo,
						},
					},
				});
				setExibirModalLancamentoReserva(true);
				setGeradoAPartirDeUmaVenda(item.documento && item.documento.id);
			}
		});
	}

	async function handleRemoveItem(item) {
		confirm('Confirmação', 'Tem certeza que deseja excluir esta movimentação?', async () => {
			await asyncRemoveLancamento(item.id, () => pesquisar());
		});
	}

	async function handleEfetivarItem(item) {
		confirm('Confirmação', 'Tem certeza que deseja efetivar esta reserva?', async () => {
			await asyncGetLancamento(item.id, async ({ data: lancamento }) => {
				if (lancamento.saldoTipo === tipoSaldo.RESERVADO) {
					lancamento.tipo = tipoMovimentacao.SAIDA;
					lancamento.saldoTipo = tipoSaldo.ESTOQUE;
					await asyncEditLancamento(item.id, lancamento, () => pesquisar());
				} else {
					exibirToast(null, 'Somente movimentações de reserva pode ser efetivadas', ToastTypes.ERROR);
				}
			});
		});
	}

	function onHideModalSaidaEstoque(values) {
		if (values) {
			pesquisar();
			let novoValorPesquisa = valorPesquisa;
			if (Array.isArray(valorPesquisa)) {
				novoValorPesquisa = valorPesquisa?.map((produto) => {
					if (produto.value === values.produto.value) {
						return {
							...produto,
							registro: {
								...produto.registro,
								estoqueSaldo: produto.registro.estoqueSaldo - values.quantidade,
							},
						};
					}
					return produto;
				});
			}
			setExibirModalLancamentoSaida(false);
			setProdutoSelecionado(null);
			setGeradoAPartirDeUmaVenda(null);
			setValorPesquisa(novoValorPesquisa);
		} else {
			setExibirModalLancamentoSaida(false);
			setProdutoSelecionado(null);
			setRegistroSelecionado(null);
			setGeradoAPartirDeUmaVenda(null);
		}
	}

	function onHideModalEntradaEstoque(values) {
		if (values) {
			pesquisar();
		}

		setExibirModalLancamentoEntrada(false);
		setProdutoSelecionado(null);
		setRegistroSelecionado(null);
		setGeradoAPartirDeUmaVenda(null);
	}

	function onHideModalReservaEstoque(values) {
		if (values) {
			pesquisar();
		}

		setExibirModalLancamentoReserva(false);
		setProdutoSelecionado(null);
		setRegistroSelecionado(null);
		setGeradoAPartirDeUmaVenda(null);
	}

	function onPesquisar() {
		setPage(0);
		pesquisar();
	}

	function handleSelectCardEntrada(cardName) {
		let filtro = 'tipo==ENTRADA';
		pesquisarRSQLCardTotais(cardName, filtro);
	}

	function handleSelectCardSaida(cardName) {
		let filtro = 'tipo==SAIDA';
		pesquisarRSQLCardTotais(cardName, filtro);
	}

	function pesquisarRSQLCardTotais(cardName, valorAplicadoNoFiltro) {
		if (selectedCard === cardName) {
			setSelectedCard('');
			setValorCard('');
			setPage(0);
		} else {
			setSelectedCard(cardName);
			setValorCard(valorAplicadoNoFiltro);
			setPage(0);
		}
	}

	function montarHeader() {
		const dataInicialFormatada = interval.dataInicial && format(interval.dataInicial, 'dd/MM/yyyy');
		const dataFinalFormatada = interval.dataFinal && format(interval.dataFinal, 'dd/MM/yyyy');
		return (
			<span
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<span>Movimentações de estoque</span>
			</span>
		);
	}

	function handleClickExibirModalLancamentoEntrada() {
		let produtoSelecionado = null;

		if (valorPesquisa && valorPesquisa.length === 1) {
			produtoSelecionado = valorPesquisa[0];
		}
		setExibirModalLancamentoEntrada(true);
		setProdutoSelecionado(produtoSelecionado);
	}

	function handleClickExibirModalLancamentoSaida() {
		let produtoSelecionado = null;

		if (valorPesquisa && valorPesquisa.length === 1) {
			produtoSelecionado = valorPesquisa[0];
		}
		setExibirModalLancamentoSaida(true);
		setProdutoSelecionado(produtoSelecionado);
	}

	function handleClickExibirModalLancamentoReserva() {
		let produtoSelecionado = null;

		if (valorPesquisa && valorPesquisa.length === 1) {
			produtoSelecionado = valorPesquisa[0];
		}
		setExibirModalLancamentoReserva(true);
		setProdutoSelecionado(produtoSelecionado);
	}

	return (
		<>
			<Form header={montarHeader()}>
				<FormActions>
					<Button
						label="Nova entrada"
						className="p-button-success"
						icon="fa fa-plus"
						title="Inserir uma nova movimentação de entrada"
						onClick={() => handleClickExibirModalLancamentoEntrada()}
						disabled={!podeInserir}
						style={{ margin: '5px' }}
					/>
					<Button
						label="Nova saída"
						className="p-button-danger"
						icon="fa fa-plus"
						title="Inserir uma nova movimentação de saída"
						onClick={() => handleClickExibirModalLancamentoSaida()}
						style={{ margin: '5px' }}
						disabled={!podeInserir}
					/>
					<Button
						label="Nova Reserva"
						className="p-button-primary"
						icon="fa fa-plus"
						title="Inserir uma nova movimentação de reserva"
						onClick={() => handleClickExibirModalLancamentoReserva()}
						style={{ margin: '5px' }}
						disabled={!podeInserir}
					/>
				</FormActions>
				<FormContent>
					<Grid justifyCenter verticalAlignCenter>
						<span style={{ padding: '12px' }}>
							<DateInterval onChange={handleChangeInterval} />
						</span>

						<InputSearchProdutos
							onPesquisar={onPesquisar}
							value={valorPesquisa}
							onChange={(value) => {
								onChangeProdutos(value);
							}}
							id="EstoqueInputSearchProdutos"
						/>

						<span style={{ padding: '12px' }}>
							<PesquisaAvancada
								optionsFiltros={optionsFiltroAvancado}
								onPesquisarClick={onPesquisarFiltroAvancado}
								onChangeFiltroRsql={setFiltroAvancado}
								onChangeDescricaoFiltro={setDescricaoFiltroAvancado}
								limparFiltro={limparFiltroPesquisaAvancada}
							/>
						</span>
					</Grid>
					<Grid justifyBetween>
						<Col xs="12" sm="6" md="6" lg="3" xl="3">
							<CardTotalizadorListagem
								name="cardSaldoAnterior"
								title="Saldo anterior"
								helpMessage="Soma de todas as movimentações antes do período selecionado"
								primaryColor={COLORS.blue}
								formatarValueDecimais={!!valorPesquisa?.length}
								value={!!valorPesquisa?.length ? cards.saldoAnterior : '—'}
								disabled={!valorPesquisa?.length}
								decimais={decimaisQtd}
							/>
						</Col>
						<Col xs="12" sm="6" md="6" lg="3" xl="3">
							<CardTotalizadorListagem
								name="cardEntrada"
								title="Quantidade de entradas"
								helpMessage="Soma de todas as movimentações de entrada realizadas no período selecionado"
								titleFiltro="Clique para filtrar pelas entradas realizadas"
								primaryColor={COLORS.green}
								formatarValueDecimais={!!valorPesquisa?.length}
								value={!!valorPesquisa?.length ? cards.entradas : '—'}
								selectable={!!valorPesquisa?.length}
								selected={selectedCard === 'cardEntrada'}
								onSelect={handleSelectCardEntrada}
								disabled={!valorPesquisa?.length}
								decimais={decimaisQtd}
							/>
						</Col>
						<Col xs="12" sm="6" md="6" lg="3" xl="3">
							<CardTotalizadorListagem
								name="cardSaida"
								title="Quantidade de saídas"
								helpMessage="Soma de todas as movimentações de saída realizadas no período selecionado"
								titleFiltro="Clique para filtrar pelas saídas realizadas"
								primaryColor={COLORS.red}
								formatarValueDecimais={!!valorPesquisa?.length}
								value={!!valorPesquisa?.length ? cards.saidas : '—'}
								selectable={!!valorPesquisa?.length}
								selected={selectedCard === 'cardSaida'}
								onSelect={handleSelectCardSaida}
								disabled={!valorPesquisa?.length}
								decimais={decimaisQtd}
							/>
						</Col>
						<Col xs="12" sm="6" md="6" lg="3" xl="3">
							<CardTotalizadorListagem
								name="cardSaldo"
								title="Saldo em estoque"
								helpMessage="Soma dos saldos de todas as movimentações. Esse valor é calculado somando todas as movimentações até o fim do período selecionado"
								primaryColor={COLORS.blue}
								formatarValueDecimais={!!valorPesquisa?.length}
								value={!!valorPesquisa?.length ? cards.saldo : '—'}
								disabled={!valorPesquisa?.length}
								decimais={decimaisQtd}
							/>
						</Col>
					</Grid>
					<DescricaoFiltroAvancado texto={descricaoFiltroAvancado} />
					<TabelaMovimentacoes
						registros={registros}
						totalRecords={totalRecords}
						rows={size}
						page={page}
						sortField={sortField}
						sortOrder={sortOrder}
						setSortField={(sortField) => {
							setSortField(sortField);
						}}
						setSortOrder={(sortOrder) => {
							setSortOrder(sortOrder);
						}}
						onEditItem={handleEditItem}
						onRemoveItem={handleRemoveItem}
						onEfetivar={handleEfetivarItem}
						onPageChange={(e) => {
							setSize(e.rows);
							setPage(e.page);
						}}
						exibirReservas={exibirReservas}
						setExibirReservas={setExibirReservas}
					/>
				</FormContent>
			</Form>
			<If test={exibirModalLancamentoSaida}>
				<ModalSaidaEstoque
					atualizarListagem={() => pesquisar()}
					produto={produtoSelecionado}
					registroSelecionado={registroSelecionado}
					geradoAPartirDeUmaVenda={Boolean(geradoAPartirDeUmaVenda)}
					onNovoClick={() => {
						setProdutoSelecionado(null);
						setGeradoAPartirDeUmaVenda(null);
						registroSelecionado(null);
					}}
					visible={exibirModalLancamentoSaida}
					onHide={onHideModalSaidaEstoque}
				/>
			</If>
			<If test={exibirModalLancamentoEntrada}>
				<ModalEntradaEstoque
					atualizarListagem={() => pesquisar()}
					produto={produtoSelecionado}
					registroSelecionado={registroSelecionado}
					onNovoClick={() => {
						setProdutoSelecionado(null);
						setRegistroSelecionado(null);
					}}
					visible={exibirModalLancamentoEntrada}
					onHide={onHideModalEntradaEstoque}
					saldoTipo={tipoSaldo.ESTOQUE}
				/>
			</If>
			<If test={exibirModalLancamentoReserva}>
				<ModalEntradaEstoque
					atualizarListagem={() => pesquisar()}
					produto={produtoSelecionado}
					registroSelecionado={registroSelecionado}
					onNovoClick={() => {
						setProdutoSelecionado(null);
						setRegistroSelecionado(null);
					}}
					visible={exibirModalLancamentoReserva}
					onHide={onHideModalReservaEstoque}
					saldoTipo={tipoSaldo.RESERVADO}
				/>
			</If>
		</>
	);
}

export default MovimentacoesEstoque;
