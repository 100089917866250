import { colors } from 'Common';
import { tipoCampos } from 'components/PesquisaAvancada/Util/constantes';

export const ColorsTributacaoMunicipal = {
	bgAtivo: colors.verdeLight,
	textAtivo: colors.verde,
	bgInativo: colors.vermelhoLight,
	textInativo: colors.vermelho,
};

export const OptionsFiltroAvancado = [
	{ label: 'Código', name: 'codigo', type: tipoCampos.INTEGER },
	{ label: 'Nome', name: 'nome', type: tipoCampos.STRING },
	{
		label: 'Situação',
		name: 'situacao',
		type: tipoCampos.SELECT,
		optionSelect: [
			{ label: 'Ativo', value: 'ATIVO' },
			{ label: 'Inativo', value: 'INATIVO' },
		],
	},
];

export const SituacaoOptions = [
	{ label: 'Ativo', value: 'ATIVO' },
	{ label: 'Inativo', value: 'INATIVO' },
];

export const TypesHeaderModalTributacaoMunicipal = {
	INCLUIR: 'Incluir tributação',
	EDITAR: 'Editar tributação',
};
